class ChatApi {
    static async fetchMessages(chatId) {
        const response = await fetch(`/admin/messages/${chatId}/messages`);
        if (!response.ok) throw new Error("Failed to fetch messages.");
        return response.json();
    }

    static async sendMessage(message, chattableId, chattableType) {
        const response = await fetch(`/messages`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
            },
            body: JSON.stringify({
                message: {
                    content: message,
                    chattable_id: chattableId,
                    chattable_type: chattableType,
                },
            }),
        });

        if (!response.ok) throw new Error("Failed to send message.");
        return response.json();
    }

    static async deleteMessage(messageId) {
        const response = await fetch(`/messages/${messageId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
            },
        });
        return response.json();
    }

    static async markMessagesAsRead(chatId, chatType) {
        await fetch(`/admin/messages/${chatId}/mark_as_read`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
            },
            body: JSON.stringify({ chattable_id: chatId, chattable_type: chatType }),
        });
    }
}

export default ChatApi;
