import React, {useEffect, useState} from 'react'
import {ChatIcon} from "../icons/chat_icon";

import ReactModal from "react-modal";
import {CancelIcon} from "../icons/cancel_icon";
import {ExclamationIcon} from "../icons/exclamation_icon";
import {HelpInstructionsAndRaiseHand} from "./help_instructions_and_raise_hand";

export const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const useDisableBodyScroll = (open) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);
};

// TODO: dedup w/ quote_renderer
function useMediaQuery() {
    const [screenSize, setScreenSize] = React.useState([0, 0]);

    React.useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize;
}

const ChatMessage = (props) => {
    const { chat, currentProfile, userCanDelete, onDeleteMessage } = props;
    let { name, content, time, public_name_differentiator, role, id } = chat;
    let displayName = currentProfile === `${name}-${public_name_differentiator}` ? `(You) ${name}` : name;

    return (
        <div className={'chat-message'}>
            <div className={'chat-message-header'}>
                {chat.deleted === true && <span className={'deleted-chat-message'}>[DELETED] </span>}
                <span>{displayName}</span>
                <span className={'role-tag'}>{` ${role}`}</span> | {new Date(time * 1000).toLocaleString()}
                {userCanDelete && !!chat.deleted === false && (
                    <button onClick={() => onDeleteMessage(id)} className="delete-chat-button">Delete</button>
                )}
            </div>
            <div className={`chat-message-content`}>{content}</div>
        </div>
    );
};


const Chats = (props) => {
    let { chats, currentProfile } = props
    useEffect(() => {
        let element = document.getElementById('bottom-of-chat-anchor');
        element.scrollIntoView()
    }, [chats.length])

    return <div id={'all-chat-messages'}>
        {chats.map( (chat, index) => {
            return <div key={chat.id}>
               <ChatMessage
                   {...{chat}}
                   userCanDelete={props.userCanDelete}
                   onDeleteMessage={props.onDeleteMessage}
                   currentProfile={currentProfile}
               />
            </div>
        })}
        <div id={'bottom-of-chat-anchor'}/>
    </div>
}

const ChatInput = (props) => {
    let { onSendMessage } = props
    let [preFlightMessageValue, setPreFlightMessageValue] = React.useState('')

    const submitMessage = () => {
        if(!preFlightMessageValue) {
            return;
        }

        let outgoingMessage = preFlightMessageValue
        setPreFlightMessageValue('')
        onSendMessage(outgoingMessage)
    }

    const onKeyUp = (e) => {
        if (e.key === "Enter") {
            submitMessage()
        }
    }

    return <div className={'chat-input'}>
        <input placeholder={'Type your message here.'}
               className={'chat-text-input'}
               onChange={(e) => { setPreFlightMessageValue(e.target.value)} }
               onKeyUp={onKeyUp}
               value={preFlightMessageValue}
        />
        <button className={'basic-link-button narrow short'} onClick={submitMessage}>Send</button>
    </div>
}

const HelpButton = (props) => {
    let { setShowHelp } = props

    return <div onClick={() => setShowHelp(true)} className={'chat-get-help-button'}>
        <div className={'chat-get-help-button-icon'}><ExclamationIcon size={'2rem'} /></div><div>Help</div>
    </div>
}

const ChatContent = (props) => {
    const { onClose, title, chats, currentProfile, onSendMessage, chatData, userCanDelete } = props;
    const [showHelp, setShowHelp] = useState(false);
    const [handRaised, setHandRaised] = useState(false);

    const onDeleteMessage = (chatId) => {
        fetch(`/messages/${chatId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    alert("Failed to delete chat: " + data.errors.join(", "));
                }
            })
            .catch(error => {
                console.error("Error deleting chat:", error);
                alert("An error occurred while deleting the chat.");
            });
    };

    return (
        <div className={'chat-content'}>
            <div onClick={onClose} className={'chat-close-icon'}>
                <CancelIcon size={'2rem'} />
            </div>
            <div className={'chat-title'}>{title}</div>
            {!showHelp && (
                <>
                    <div className={'chat-explanation'}>
                        Please use this chat when you need to coordinate with your session partner (e.g., audio difficulties). Acquaint admin/facilitators may join or read this chat to
                        offer/provide additional support.
                    </div>
                    <Chats {...{ chats, currentProfile, userCanDelete, onDeleteMessage }} />
                    <div className={'chat-bottom-options'}>
                        <HelpButton setShowHelp={setShowHelp} />
                        <ChatInput onSendMessage={onSendMessage} />
                    </div>
                </>
            )}
            {showHelp && <HelpInstructionsAndRaiseHand chatData={chatData} setShowHelp={setShowHelp} setHandRaised={setHandRaised} handRaised={handRaised} />}
        </div>
    );
};

const ChatUi = (props) => {
    let { onClose, chatOpen, alwaysOpen, title, chats, currentProfile, onSendMessage, chatData, userCanDelete} = props;
    const [width, height] = useMediaQuery();
    const [isMobile, setIsMobile] = useState(isMobileDevice());

    let contentStyle = {
        height: 'calc(100% - 10rem)',
        width: 'calc(100% - 80px)',
        margin: 0,
        padding: 0,
        border: 'solid .5rem #8bc6a5',
        maxHeight: 'calc(100% - 10rem)',
        overflow: 'auto',
        position: 'relative'
    }

    if(width < 600 || isMobile) {
        contentStyle = {
            height: '100%',
            width: '100%',
            margin: 0,
            left: 0,
            top: 0,
            padding: 0
        }
    }

    let appElement = $('.content-container')[0] || $('.public-info-content-container')[0]

    return (
        <ReactModal
            appElement={appElement}
            isOpen={alwaysOpen || chatOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => onClose()}
            style={{
                content: contentStyle
            }}
        >
            <ChatContent {...{onClose, title, chats, currentProfile, onSendMessage, chatData, userCanDelete}}/>
        </ReactModal>
    )

}


const ChatOpener = (props) => {
  let { onClick, unreadMessages } = props;
  useEffect(() => {
    if (unreadMessages) {
      document.getElementById("chat-notification-audio").play();
    }
  }, [unreadMessages]);

  const chatOpenerClassName = unreadMessages
    ? "chat-opener animated"
    : "chat-opener";

  return (
    <div className={chatOpenerClassName} onClick={onClick}>
      <div className={unreadMessages ? "red-notification-dot" : ""} />
      <audio
        id="chat-notification-audio"
        src="data:audio/mpeg;base64,/+MYxAAEaAIEeUAQAgBgNgP/////KQQ/////Lvrg+lcWYHgtjadzsbTq+yREu495tq9c6v/7vt/of7mna9v6/btUnU17Jun9/+MYxCkT26KW+YGBAj9v6vUh+zab//v/96C3/pu6H+pv//r/ycIIP4pcWWTRBBBAMXgNdbRaABQAAABRWKwgjQVX0ECmrb///+MYxBQSM0sWWYI4A++Z/////////////0rOZ3MP//7H44QEgxgdvRVMXHZseL//540B4JAvMPEgaA4/0nHjxLhRgAoAYAgA/+MYxAYIAAJfGYEQAMAJAIAQMAwX936/q/tWtv/2f/+v//6v/+7qTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"
      />
      <ChatIcon />
    </div>
  );
};

export function ChatInterface(props) {
    const {chats, chatData, currentProfile, onSendMessage, unreadMessages, setUnreadMessages, alwaysOpen, userCanDelete} = props
    const {title} = chatData
    const [chatOpen, setChatOpen] = React.useState(false)

    useDisableBodyScroll(chatOpen);

    const primeAudio = () => {
        try {
            if(!window.audioReady) {
                document.getElementById("chat-notification-sound-primer").play();
                window.audioReady = true
            }
        } catch (error) {
            console.log("Unable to prime audio, probably no click:", error);
        }
    }
    useEffect(() => {
        window.addEventListener('click', (event) => {
            primeAudio()
        });

    }, [])

    const goToPreviousPageIfChatClosed = () => {
        if (alwaysOpen) {
            history.back()
        }
    }
    return (
        <>
            <ChatUi
                {...{title, chatOpen, chats, currentProfile, onSendMessage, alwaysOpen, chatData, userCanDelete}}
                onClose={() => {goToPreviousPageIfChatClosed(alwaysOpen); setUnreadMessages(false); setChatOpen(false)}
                }
            />
            { !chatOpen && !alwaysOpen && <ChatOpener onClick={() => setChatOpen(true)} unreadMessages={unreadMessages} />}
            <audio id="chat-notification-sound-primer"
                   src="data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==">
            </audio>
        </>
    )
}
export default ChatInterface;
