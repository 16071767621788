import React, {useState} from 'react';
import Select from 'react-select';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    addParticipantSection: {
        marginTop: '30px',
    },
    addParticipantButton: {
        marginTop: '10px',
    },
});

const NewExperimentParticipantSelect = ({ options, onChange, initialValue }) => {
    const [selection, setSelection] = useState(null);

    const handleChange = (selectedOption) => {
        setSelection(selectedOption);
        if (onChange) {
            onChange(selectedOption);
        }
    };

    const customStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };

    return (
        <div className={'mb-2'}>
            <Select
                defaultValue={initialValue}
                options={options}
                onChange={handleChange}
                styles={customStyles}
                menuPortalTarget={document.body} // Use a portal to render the menu to the body
            />
        </div>
    );
};

const AddParticipant = ({ volunteerOptions, onParticipantSelected, onAddParticipant, hideNames }) => {
    const classes = useStyles();

    return (
        <div className={classes.addParticipantSection}>
            <h3>Add New Participants</h3>
            <NewExperimentParticipantSelect
                options={volunteerOptions.map(option => ({ value: option.value, label: (hideNames ? option.masked_name : option.label)}))}
                onChange={onParticipantSelected}
            />
            <button className={classes.addParticipantButton} onClick={onAddParticipant}>Add Participant</button>
        </div>
    );
};

export default AddParticipant;
