import React from 'react';
import USPhoneInput from "./USPhoneInput";
import '../../assets/stylesheets/us_phone_input.scss';


export default {title: 'US Phone Input'};



export const phoneInput = () => (<USPhoneInput initialPhone={'+15556667777'} name={'formName'}/>);
