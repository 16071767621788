import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const goodRating = 6;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const filterRowsByName = (rows, text) => {
  return rows.filter((row) => {
    return row.user_name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
  });
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedArray.map((el) => el[0]);
};

export default function FeedBackViewTable({ rows }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const filterTextChanged = (filterText) => {
    setPage(0);
    setFilterText(filterText.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleLikeDislikeText = (rating) => {
    if (rating === goodRating) {
      return "Liked";
    } else if (rating === null) {
      return "User has not liked/disliked";
    } else {
      return "Disliked";
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let filteredRows = filterRowsByName(rows, filterText);
  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
  return (
    <>
      <div>
        <div>Filter by first name of user:</div>
        <input type={"text"} onChange={filterTextChanged} value={filterText} />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "created_at"}
                  direction={orderBy === "created_at" ? order : "asc"}
                  onClick={() => handleRequestSort("created_at")}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
              <TableCell>User</TableCell>
              <TableCell align="right">Initial AI response</TableCell>
              <TableCell align="right">Initial User Input</TableCell>
              <TableCell align="right">AI feedback</TableCell>
              <TableCell align="right">Score</TableCell>
              <TableCell align="right">User feedback</TableCell>
              <TableCell align="right">User liked or disliked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow id={row.id}>
                  <TableCell>{new Date(row.created_at).toString()}</TableCell>

                  <TableCell>{row.user_name}</TableCell>
                  <TableCell align="right">{row.ai_initial_response}</TableCell>
                  <TableCell align="right">{row.user_input}</TableCell>
                  <TableCell align="right">{row.ai_feedback}</TableCell>
                  <TableCell align="right">{row.score}</TableCell>
                  <TableCell align="right">
                    {row.comments !== null
                      ? row.comments
                      : "User has not provided feedback"}
                  </TableCell>
                  <TableCell align="right">
                    {handleLikeDislikeText(row.ratings)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
