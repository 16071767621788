import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const requestWithCsrfToken = async (url, method, body) => {
    const response = await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );

    if (response.ok) {
        return response.json();
    } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
}

const VolunteerBackground = ({ initialFormValues = {
        current_city: '',
        hometown: '',
        languages: '',
        age_group: '',
        profession_or_study: '',
        hobbies: '',
        cultural_background: '',
        countries_visited: '',
        favorite_entertainment: '',
        past_volunteer_experience: '',
        consent: false
    } }) => {


    const validationSchema = Yup.object({
        current_city: Yup.string()
            .max(50, 'Must be 50 characters or less'),
        hometown: Yup.string()
            .max(50, 'Must be 50 characters or less'),
        languages: Yup.string()
            .max(100, 'Must be 100 characters or less'),
        age_group: Yup.string(),
        profession_or_study: Yup.string()
            .max(100, 'Must be 100 characters or less'),
        hobbies: Yup.string()
            .max(200, 'Must be 200 characters or less'),
        cultural_background: Yup.string()
            .max(200, 'Must be 200 characters or less'),
        countries_visited: Yup.string()
            .max(200, 'Must be 200 characters or less'),
        favorite_entertainment: Yup.string()
            .max(200, 'Must be 200 characters or less'),
        past_volunteer_experience: Yup.string()
            .max(300, 'Must be 300 characters or less'),
        consent: Yup.bool()
            .oneOf([true], 'Consent is required')
    });

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const response = await requestWithCsrfToken('/manage_volunteer_background', 'POST', {volunteer_background: values});
            window.location.replace('/home')
        } catch (error) {
            console.error('Error occurred while submitting the form:', error);
        } finally {
            setSubmitting(false); // you need to manage the loading state manually
        }

    };

    return (
        <div className="form-container">
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form className="formik-form">
                    <h3><b>Experimental AI Icebreaker: Your Background</b></h3>
                    <h4 className={'mb-2'}>Please note: all fields are OPTIONAL. If you do not want to share information, please leave it blank.</h4>
                    <h5 className={'mb-3'}>Please include any relevant information you think might be interesting in conversation or want to talk about AND you are happy to share with others</h5>
                    <label htmlFor="current_city" className="form-label">Current City, Country (can include state/terrority etc. also)</label>
                    <Field type="text" name="current_city" id="current_city" placeholder="Current city" className="input-field" />
                    <ErrorMessage name="current_city" component="div" className="error-message" />

                    <label htmlFor="hometown" className="form-label">Hometown, Country (can include state/terrority etc. also)</label>
                    <Field type="text" name="hometown" id="hometown" placeholder="Hometown" className="input-field" />
                    <ErrorMessage name="hometown" component="div" className="error-message" />

                    <label htmlFor="languages" className="form-label">Languages</label>
                    <Field type="text" name="languages" id="languages" placeholder="Languages" className="input-field" />
                    <ErrorMessage name="languages" component="div" className="error-message" />

                    <label htmlFor="age_group" className="form-label">Age Group</label>
                    <Field as="select" name="age_group" id="age_group" className="input-field">
                        <option value="">Select...</option>
                        <option value="Not Shared">Don't want to share</option>
                        <option value="15-25">15-25</option>
                        <option value="26-35">26-35</option>
                        <option value="36-45">36-45</option>
                        <option value="46-55">46-55</option>
                        <option value="56-65">56-65</option>
                        <option value="66-75">66-75</option>
                        <option value="76-85">76-85</option>
                        <option value="86-95">86-95</option>
                        <option value="96+">96+</option>
                    </Field>
                    <ErrorMessage name="age_group" component="div" className="error-message" />

                    <label htmlFor="profession_or_study" className="form-label">Profession or Area of Study</label>
                    <Field type="text" name="profession_or_study" id="profession_or_study" placeholder="Profession or Area of Study" className="input-field" />
                    <ErrorMessage name="profession_or_study" component="div" className="error-message" />

                    <label htmlFor="hobbies" className="form-label">Hobbies</label>
                    <Field type="text" name="hobbies" id="hobbies" placeholder="Hobbies" className="input-field" />
                    <ErrorMessage name="hobbies" component="div" className="error-message" />

                    <label htmlFor="cultural_background" className="form-label">Cultural Background</label>
                    <Field type="text" name="cultural_background" id="cultural_background" placeholder="Cultural Background" className="input-field" />
                    <ErrorMessage name="cultural_background" component="div" className="error-message" />

                    <label htmlFor="countries_visited" className="form-label">Countries Visited</label>
                    <Field type="text" name="countries_visited" id="countries_visited" placeholder="Countries Visited" className="input-field" />
                    <ErrorMessage name="countries_visited" component="div" className="error-message" />

                    <label htmlFor="favorite_entertainment" className="form-label">Favorite Entertainment</label>
                    <Field type="text" name="favorite_entertainment" id="favorite_entertainment" placeholder="Favorite Entertainment" className="input-field" />
                    <ErrorMessage name="favorite_entertainment" component="div" className="error-message" />

                    <label htmlFor="past_volunteer_experience" className="form-label">Past Volunteer Experience</label>
                    <Field type="text" name="past_volunteer_experience" id="past_volunteer_experience" placeholder="Past Volunteer Experience" className="input-field" />
                    <ErrorMessage name="past_volunteer_experience" component="div" className="error-message" />


                    <div className="consent-container">
                        <label className="consent-label mt-2"><b>IMPORTANT</b> I understand that this is an experimental initiative and I consent
                            to the use of my information for it.* I acknowledge that anything in this form may be
                            shared with my session partner, therefor I have not included anything that I would not be ok with
                            sharing in a session. I confirm that I have
                            not put anything hateful in the form and my answers are appropriate for the given field.
                            I understand that this data will only be used for purposes of enhancing the virtual icebreakers
                            by the Acquaint team. We will use your answers, the generated icebreakers themselves, and your feedback to
                            improve the AI icebreakers experience.
                            The data you provide in this form will be submit as is into a large language model hosted by a 3rd party
                            (OpenAI) and will be used to generate icebreakers for your session.<b>You must NOT include any personally
                            identifiable information like name or email in this form.</b> This data will not be shared with any
                            volunteers beyond your session partner. You may return to this page to update your info at any time.
                            Finally, AI generated icebreakers could be misleading or unintentionally offensive and are
                            only meant to help spark meaningful conversations.
                            Please don't hesitate to contact us if you have questions!
                            <div>
                                *If you ever want to remove consent to use this information, let us know at info@acquaint.org and we will remove it. Soon we will have a more automatic way of doing so!
                            </div>
                            <Field type="checkbox" name="consent" id="consent" className="input-checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <ErrorMessage name="consent" component="div" className="error-message" />

                    <button type="submit" className="submit-button">Submit</button>
                </Form>
            )}

            </Formik>
        </div>

    );

};

export default VolunteerBackground;
