import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import {Palette} from "../../utils/palette"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    clickableCell: {
        display: 'flex',
    }
});

const requestWithCsrfToken = async (url, method, body) => {
    return await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );
}

const approveMentee = async (mentorAssignmentId) => {
    return await requestWithCsrfToken('/mentor_assignments/approve_mentee', 'POST', {
        mentor_assignment_id: mentorAssignmentId
    })
};

const dismissMentee = async (mentorAssignmentId) => {
    return await requestWithCsrfToken('/mentor_assignments/dismiss_mentee', 'POST', {
        mentor_assignment_id: mentorAssignmentId
    })
};

const giveGuideRole = async (menteeAssignmentId) => {
    return await requestWithCsrfToken('/mentor_assignments/give_guide_role', 'POST', {
        mentor_assignment_id: menteeAssignmentId
    })
};

const StatusDetails = (rowData) => {
    let {dismissed, status, guide_role} = rowData

    let content = ''
    if (status) {
        content = content + "Complete"
    } else {
        content = content + "Not Approved"
    }

    if (guide_role) {
        content = content + " | Has guide Role"
    } else {
        content = content + " | Needs Guide Role"
    }

    if(dismissed) {
        content = 'dismissed'
    }
    return <div>
        {content}
    </div>
}
export default function FacilitatorTable(props) {
    const classes = useStyles();
    const {rows, currentUserId} = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [currentRows, setCurrentRows] = React.useState(rows)

    // sort by dismissed
    let sortedRows = currentRows.sort(function(x, y) {
        return (x.dismissed === y.dismissed)? 0 : x.dismissed? 1 : -1;
    });
    const ApproveMenteeButton = (props) => {
        let {mentor_assignment_id: mentorAssignmentId} = props
        const approveMenteeAndUpdate = async () => {
            if(!confirm("Are you sure you want to approve this mentee?")) {
                return
            }
            let result = await approveMentee(mentorAssignmentId)
            // if success, update row where ID is correct.
            let row = rows.find((row) => {return row.mentor_assignment_id === mentorAssignmentId})
            row.status = 'Complete'
            setCurrentRows([...rows])
        }
        return <button className={'basic-link-button on-table'} onClick={approveMenteeAndUpdate}>Approve</button>
    }

    const DismissMenteeButton = (props) => {
        let {mentor_assignment_id: mentorAssignmentId} = props
        const dismissMenteeAndUpdate = async () => {
            if(!confirm("Are you sure you want to dismiss this mentee?")) {
                return
            }
            let result = await dismissMentee(mentorAssignmentId)
            // if success, update row where ID is correct.
            let row = rows.find((row) => {return row.mentor_assignment_id === mentorAssignmentId})
            row.dismissed = true
            setCurrentRows([...rows])
        }
        return <button className={'basic-link-button on-table'} onClick={dismissMenteeAndUpdate}>Dismiss</button>
    }

    const GuidePermissionButton = (props) => {
        let {mentor_assignment_id: mentorAssignmentId} = props
        const assignGuideAndUpdate = async () => {
            let result = await giveGuideRole(mentorAssignmentId)
            let row = rows.find((row) => {return row.mentor_assignment_id === mentorAssignmentId})
            row.guide_role = true
            setCurrentRows([...rows])
        }
        return <button className={'basic-link-button on-table'} onClick={assignGuideAndUpdate}>Make Guide</button>
    }

    const MenteeAvailabilityButton = (props) => {
        let {mentor_assignment_id} = props

        return <a href={`mentor_assignments/mentee_availability/${mentor_assignment_id}`} className={'basic-link-button on-table'}>Availability</a>
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const Options = (props) => {
        return <div>
            {props.guide_role && !props.status && !props.dismissed && <ApproveMenteeButton {...props} />}
            {!props.guide_role && !props.dismissed && <GuidePermissionButton {...props} />}
            {!props.status && !props.dismissed && <MenteeAvailabilityButton {...props}/>}
            {!props.status && !props.dismissed && <DismissMenteeButton {...props}/>}
        </div>
    }
    return (
        <>
            {/*<div>*/}
            {/*    <div>Filter By Email:</div>*/}
            {/*    <input type={'text'} onChange={filterTextChanged} value={filterText}/>*/}
            {/*</div>*/}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Mentee Name</TableCell>
                            <TableCell align="right">Mentee Email</TableCell>
                            <TableCell align="right">Mentee Timezone</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Assigned</TableCell>
                            <TableCell align="right">Options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.mentor_assignment_id}>
                                <TableCell align="right">{row.mentee_name}</TableCell>
                                <TableCell align="right">{row.mentee_email}</TableCell>
                                <TableCell align="right">{row.mentee_timezone}</TableCell>
                                <TableCell align="right"><StatusDetails {...row}/></TableCell>
                                <TableCell align="right">{row.created_at_friendly}</TableCell>
                                <TableCell align="right"><Options {...row}/></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={currentRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}
