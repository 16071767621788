// TODO: store this in DB or somewhere it can be shared with backend...
export const IndividualOptions = [
    {
        title: "The Tourist",
        id: "tourist",
        price: 0,
        bulletPoints: [
            "Up to one session  per month",
            "Access to experience library"
        ]
    },
    {
        title: "The Traveler",
        id: "traveler",
        price: 5,
        bulletPoints: [
            "Up to one session per week",
            "Access to experience library"
        ]
    },
    {
        title: "The Intrepid",
        id: "intrepid",
        price: 9,
        bulletPoints: [
            "Unlimited sessions per month",
            "Access to experience library"
        ]
    },
    {
        title: "Conductor",
        id: "conductor",
        price: 0,
        bulletPoints: [
            "Guide and Start Sessions",
            "Train to support the community",
            "We assign you to sessions based on your availability"
        ]
    }
]

const TourBusOption = {
    title: "Tour Bus",
    id: "tour_bus",
    price: 50,
    bulletPoints: [
        "One group session per week",
        "Access to experience library"
    ]
}

export const OrganizationOptions = {
    '2_to_10': [
        {
            title: "Family Plan",
            id: "family_plan",
            price: 19,
            bulletPoints: [
                "Unlimited sessions per month",
                "Access to experience library",
                "Organizations or homes with 2-10 eligible people"
            ]
        }
    ],
    '11_to_50': [
        {
            title: "The Trolley",
            id: "trolley",
            price: 90,
            bulletPoints: [
                "Unlimited sessions per month",
                "Access to experience library",
                "Organizations with 11-50 eligible people"
            ]
        },
        TourBusOption
    ],
    '51_to_120': [
        {
            title: "The Coach Car",
            id: "coach_car",
            price: 150,
            bulletPoints: [
                "Unlimited sessions per month",
                "Access to experience library",
                "Organizations with 51-120 eligible people"
            ]
        },
        TourBusOption
    ]
}