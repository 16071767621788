import React from "react";
import Tour from "../Tour";

export function FeaturedTour(props) {
    return (
        <div className={'featured-tour'}>
            <div className={'featured-tour-header'}>
                Featured Experience
            </div>
            <Tour
                isEdit={false}
                {...props}
                // name={name}
                // description={description}
                // thumbnail={this.props.tourThumbnails[tour.id]}
                // tour_id={tour.id}
                // visualAssist={this.props.visualAssist || false}
                // tourId={tour.id}
            />
            <div className={'featured-tour-description'}>
                {props.description}
            </div>
        </div>
    )
}

