import React from "react";
import Select from 'react-select'

export function LanguageSelect(props) {
    return (
        <Select
            placeholder={'Select Language'}
            isSearchable={true}
            {...props}
        />

    )
}

