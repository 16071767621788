import React, {useState} from 'react'
import Select from "react-select"
import * as _ from 'underscore'
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import "@fullcalendar/common/main.css"

const mobilecheck = function () {
    var check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

const getUserSchedulings = async (userId) => {
    let response = await fetch(`/schedulings/${userId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    )
    return response.json();
};


const SelectedUsersList = (props) => {
    let {users, onUserRemoved} = props
    // iterate over users, and create a component with their name and an X next to it which will trigger a callback.

    const list = _.map(users, (user) => {

        return (<div key={user.value} className={'selected-user-row'}>
            {user.label}
            <button onClick={() => onUserRemoved(user)}>X</button>
        </div>)
    })

    return <div>{list}</div>
}

const sessionTotals = (sessions) => {
    // get total number of sessions, total number of canceled sessions, and a component in which we can display these
    return <div>totals here</div>
}

const SelectUser = (props) => {
    // Select an user , add to removable users list, clear selection.
    const {options, onUserSelected} = props

    return (
        <div className={'scheduling-assistant-user-select'}>
            <Select
                options={options}
                onChange={onUserSelected}
                styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
            />
        </div>
    )
}

const handleEventClick = async (arg) => {
    // get event type
    // get event ID
    // console.log(JSON.stringify(arg.event))
    const event_id = arg.event.id
    const type = arg.event.extendedProps.type

    const body = JSON.stringify({
        event_id: event_id,
        type: type
    })
    let response = await fetch(`/find_availability`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            },
            body: body
        }
    )
    response.json().then((data) => {
        alert(`The following users have availability around this time: ${data}`)
    });
}

const userRoleFilter = (onFilterSelected) => {
    // allow filter by role; eventually other properties.
    // only applies to selection
}

const scheduledSessionFilter = (onSessionSelected) => {
    // allows selection of a scheduled session and filters the users to that date, requires API call.
}

const EventCalendar = (props) => {
    // Display last week and next week,
    // show sessions and availability// allow toggle of sessions?
    // try to color code each, maybe we can have background have some pattern for sessions and availability no pattern.
    // NOT editable, NOT selectable.
    // perhaps display some scheduled session info, e.g., who guide and participant are & show on hover.
    const today = new Date()
    today.setDate(new Date().getDate() - 7)
    const end = new Date()
    end.setDate(new Date().getDate() + 14)

    const additionalEventContent = (arg) => {
        return <div className={'fc-session-event'}>
            {arg.event.extendedProps.canceled && <div className={'fc-session-canceled'}>Canceled</div>}
            <div className={'fc-session-data'}>{arg.timeText}</div>
            <div className={'fc-session-data'}>{arg.event.title}</div>
        </div>
    }
    const {eventSources, timeConstraints} = props;
    return <FullCalendar
            eventSources={eventSources}
            plugins={[timeGridPlugin]}
            initialView={mobilecheck() ? 'timeGridDay' : 'timeGridWeek'}
            initialEvents={[]}
            validRange={{
                start: today,
                end: end
            }}
            timeZone={false}
            allDaySlot={false}
            slotMinTime='00:00:00'
            slotMaxTime='24:00:00'
            slotLabelFormat={{
                hour12: false,
                hour: 'numeric',
                minute: '2-digit'
            }}
            businessHours={
                timeConstraints.map((constraint) => {
                    return {
                        daysOfWeek: [ 0, 1, 2, 3, 4, 5, 6 ], // Monday, Tuesday, Wednesday
                        startTime: constraint[0], // 8am
                        endTime: constraint[1] // 6pm
                    }
                })
            }
            selectConstraint={
                timeConstraints.map((constraint) => {
                    return {
                        daysOfWeek: [ 0, 1, 2, 3, 4, 5, 6 ], // Monday, Tuesday, Wednesday
                        startTime: constraint[0], // 8am
                        endTime: constraint[1] // 6pm
                    }
                })
            }
            selectConstraint={ [ // specify an array instead
                {
                    daysOfWeek: [ 1, 2, 3, 4, 5, 6, 7 ], // Monday, Tuesday, Wednesday
                    startTime: '00:00', // 8am
                    endTime: '4:00' // 6pm
                },
                {
                    daysOfWeek: [ 1, 2, 3, 4, 5, 6, 7], // Thursday, Friday
                    startTime: '16:00', // 10am
                    endTime: '24:00' // 4pm
                }
            ]}
            height={'auto'}
            eventContent={additionalEventContent}
            eventClick={handleEventClick}
    />
}
const ShowUserCheckbox = (props) => {
    let {userData, onUserSelected, onUserRemoved, checked} = props

    const handleChange = () => {
        if(checked) {
            onUserRemoved(userData)
        } else {
            onUserSelected(userData)
        }
    };

    return <input type="checkbox"
                  checked={checked}
                  onChange={handleChange}
           />
}

export default function SchedulingAssistant(props) {
    let {users, volunteersWithAvailability, timeConstraints} = props

    const [selectedUsers, setSelectedUsers] = useState([])
    const [eventSources, setEventSources] = useState([])

    // flip text color so we can always see it.
    const invertHex = (hex) => {
        return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
    }


    const userSelected = (userInfo) => {
        // TODO: ignore duplicates
        if (_.find(selectedUsers, (user) => user.value === userInfo.value)) {
            return
        }
        const colorHex = `${Math.floor(Math.random() * 16777215).toString(16)}`
        const color = `#${colorHex}`
        const inverseColorHex = invertHex(colorHex)
        const inverseColor = `#${inverseColorHex}`;
        getUserSchedulings(userInfo.value).then((events) => {
                // randomColor =;
                eventSources.push({
                    events: events,
                    color: color,
                    textColor: inverseColor,
                    id: userInfo.value
                })
                setEventSources([...eventSources])
            }
        )

        selectedUsers.push(userInfo)
        setSelectedUsers([...selectedUsers])
    }

    const onUserRemoved = (userInfo) => {
        const newSources = _.reject(eventSources, (eventSource) => eventSource.id === userInfo.value)
        setEventSources([...newSources])
        setSelectedUsers(selectedUsers.filter((entry) => entry.value !== userInfo.value))

    }

    users = users.map((userData) => {
        return {label: userData.email, value: userData.id}
    })

    return (
        <div>
            <SelectUser options={users} onUserSelected={userSelected}/>
            <SelectedUsersList users={selectedUsers} onUserRemoved={onUserRemoved}/>
            <EventCalendar eventSources={eventSources} timeConstraints={timeConstraints}/>
            <div>
                <h2>Conductors With Availability</h2>
                <div>
                        <div>
                            Email
                        </div>
                {
                    volunteersWithAvailability.map((volunteer) => {
                        let userData = {label: volunteer.email, value: volunteer.id}
                        return (<div key={volunteer.email}>
                            <span>{volunteer.flags.indexOf('mentor_approved') !== -1 && '*'}</span>
                            <span><ShowUserCheckbox userData={userData}
                                                    checked={_.find(selectedUsers, (user) => user.value === userData.value) || false}
                                                    onUserSelected={userSelected}
                                                    onUserRemoved={onUserRemoved}/>
                            </span>{' '}
                            <span className={volunteer.upcoming_sessions === 0 ? 'no-upcoming-sessions' : ''}>{volunteer.email}</span>{' '}|{' '}
                            <a target={'_blank'} href={`schedulings/volunteer_info/${volunteer.id}`}>scheduling info</a>{' '}|{' '}
                            Session Count:{' '}{volunteer.sessions_this_cycle}/{volunteer.max_sessions} this cycle ({volunteer.upcoming_sessions} Upcoming)
                        </div>)
                    })

                }
                </div>
            </div>
        </div>
    )
}
