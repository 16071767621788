import React, { useState } from 'react';

export default function LongTextRenderer(props) {
    // State to track if the content is expanded
    const [isExpanded, setIsExpanded] = useState(false);

    // Function to toggle the expansion state
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={'description-renderer'} onClick={toggleExpansion}>
            {/* Toggler Indicator */}
            <div className={'toggle-indicator'}>
                {isExpanded ? 'Hide Text ▲' : 'Show Text ▼'}
            </div>

            {/* Conditionally render content based on isExpanded state */}
            {isExpanded && (
                <p>{props.string}</p>
            )}
        </div>
    );
}