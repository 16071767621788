import React, {useCallback, useEffect, useRef, useState} from 'react';
import {broadcastMosaicData, MOSAIC} from "../../utils/guided_session";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import { createConsumer } from '@rails/actioncable';
import ColorPicker, {acquaintColorPalettes} from "./color_picker";
import RotationControl from "./rotation_control";

const useStyles = makeStyles({
    shapeSelector: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '96vw',
        flexDirection: 'row',
        marginBottom: '1rem',
        marginTop: '1rem',
        flexWrap: 'wrap',
    },
    shapeOption: {
        cursor: 'pointer',
        padding: '10px',
        border: '1px solid black',
        textAlign: 'center',
    },
    canvas: {
        border: '1px solid black',
        margin: 'auto',
        width: '100vw',
        height: '100vw',
        maxWidth: '600px',
        maxHeight: '600px',
    },
});

const shapes = [
    { type: 'square', size: 50 },
    { type: 'circle', radius: 25 },
    { type: 'triangle', size: 60 },
    { type: 'square', size: 25, label: 'small square' },
    { type: 'circle', radius: 12, label: 'small circle' },
    { type: 'triangle', size: 30, label: 'small triangle' },
    // Add more shapes as needed
];

const drawSquare = (context, x, y, size, rotation = 0, color = 'black', ants = false) => {
    context.save();
    context.fillStyle = color;
    context.translate(x, y); // Now it will translate to the center of the square.
    context.rotate(rotation);
    if (ants) {
        context.strokeStyle = 'black';
        context.setLineDash([5, 5]); // Example dashed line pattern
        context.strokeRect(-size / 2, -size / 2, size, size); // Draws the square centered on the translated origin.
    }else {
        context.fillRect(-size / 2, -size / 2, size, size); // Draws the square centered on the translated origin.
    }
    context.restore();
};

const drawCircle = (context, x, y, radius, rotation = 0, color = 'black', ants = false) => {
    context.save();
    context.fillStyle = color;
    context.translate(x, y);
    context.rotate(rotation);
    context.beginPath();
    context.arc(0, 0, radius, 0, 2 * Math.PI);
    if (ants) {
        context.strokeStyle = 'black';
        context.setLineDash([5, 5]); // Example dashed line pattern
        context.stroke();
    }else {
        context.fill();
    }
    context.restore();
};

const drawTriangle = (context, x, y, size, rotation = 0, color = 'black', ants= false) => {
    const height = size * (Math.sqrt(3) / 2);
    context.save();
    context.fillStyle = color;
    context.translate(x, y);
    context.rotate(rotation);
    context.beginPath();
    context.moveTo(0, -height / 2);
    context.lineTo(-size / 2, height / 2);
    context.lineTo(size / 2, height / 2);
    context.closePath();
    if (ants) {
        context.strokeStyle = 'black';
        context.setLineDash([5, 5]); // Example dashed line pattern
        context.stroke();
    }else {
        context.fill();
    }
    context.restore();
};

const drawPreviewWindow = (selectedShape, canvasRef, canvasSize) => {
    const context = canvasRef.current.getContext('2d');
    const previewSize = 100; // The size of the preview window
    const padding = 10; // Padding around the preview window
    const previewWindowX = canvasSize - previewSize - padding; // Position from the right edge of the canvas
    const previewWindowY = canvasSize - previewSize - padding; // Position from the bottom edge of the canvas

    if (selectedShape) {
        context.save();
        context.beginPath();
        context.rect(previewWindowX, previewWindowY, previewSize, previewSize);
        context.fillStyle = 'white';
        context.fill();
        context.lineWidth = 1;
        context.strokeStyle = 'black';
        context.stroke();
        context.closePath();

        // Draw the label
        context.fillStyle = 'black';
        context.textAlign = 'center';
        context.font = '1.5rem Arial';
        context.fillText('Preview', previewWindowX + previewSize / 2, previewWindowY - 5);

        // Draw the shape at the center of the preview window
        // The shape is drawn at (0,0) and then translated to the center of the preview window
        context.translate(previewWindowX + previewSize / 2, previewWindowY + previewSize / 2);
        // The shape is not scaled, it's drawn at its original size
        drawShape(0, 0, 1, selectedShape, canvasRef);

        context.restore();
    }
};

const drawShape = (x, y, scale, shape, canvasRef, drawAnts) => {
    const context = canvasRef.current.getContext('2d');

    let sizeValue = null;

    // Not currently used...
    const eraserScale = 1;

    switch (shape.type) {
        case 'square':
            sizeValue = shape.color === '#FFFFFF' ? sizeValue = shape.size * eraserScale : shape.size;
            drawSquare(context, x, y, sizeValue, shape.rotation, shape.color, drawAnts);
            break;
        case 'circle':
            sizeValue = shape.color === '#FFFFFF' ? shape.radius * eraserScale : shape.radius;
            drawCircle(context, x, y, sizeValue, shape.rotation, shape.color, drawAnts);
            break;
        case 'triangle':
            sizeValue = shape.color === '#FFFFFF' ? shape.size * eraserScale : shape.size;
            drawTriangle(context, x, y, sizeValue, shape.rotation, shape.color, drawAnts);
            break;
        // Add more cases as needed for different shapes
    }
};

const Canvas = (props) => {
    let { turns, selectedShape, onAddShape, currentTool, gameState, backgroundImagePath } = props;
    const classes = useStyles();

    const canvasRef = useRef(null);
    const [isPanning, setIsPanning] = useState(false);
    const [startPanPoint, setStartPanPoint] = useState({ x: 0, y: 0 });
    const [canvasOffset, setCanvasOffset] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);

    const TOTAL_SIZE = 1200;
    const CANVAS_SIZE = 800;
    const BORDER_SIZE = (TOTAL_SIZE - CANVAS_SIZE) / 2;
    const PIECE_SIZE = 50;

    // Bounds settings
    const maxScale = 3; // maximum zoom scale
    const minScale = CANVAS_SIZE/TOTAL_SIZE; // minimum zoom scale

    const background = useRef(new Image());

    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);

    const calculateBounds = (scale) => {
        // Original content size
        const contentWidth = TOTAL_SIZE;
        const contentHeight = TOTAL_SIZE;

        // Calculate the scaled content size
        const scaledContentWidth = contentWidth * scale;
        const scaledContentHeight = contentHeight * scale;

        // Calculate the difference between the scaled content and the canvas size
        const widthDiff = (scaledContentWidth - CANVAS_SIZE) / 2;
        const heightDiff = (scaledContentHeight - CANVAS_SIZE) / 2;

        // Set the bounds so the content can't be panned beyond its edges
        return {
            x: [-widthDiff, widthDiff],
            y: [-heightDiff, heightDiff]
        };
    };

    const [bounds, setBounds] = useState(calculateBounds(scale));

    useEffect(() => {
        setBounds(calculateBounds(scale));
    }, [scale]);

    const handleMouseDown = (e) => {
        if (selectedShape && currentTool !== 'drag') return; // No dragging if a shape is selected and the current tool is not 'drag'

        setIsPanning(true);
        setStartPanPoint({
            x: e.clientX - canvasOffset.x,
            y: e.clientY - canvasOffset.y
        });
    };

    const handleMouseMove = (e) => {
        if (isPanning) {
            let newX = e.clientX - startPanPoint.x;
            let newY = e.clientY - startPanPoint.y;

            // Enforce the bounds for X and Y
            newX = Math.min(Math.max(newX, bounds.x[0]), bounds.x[1]);
            newY = Math.min(Math.max(newY, bounds.y[0]), bounds.y[1]);

            setCanvasOffset({ x: newX , y: newY });
        }
    };

    const handleMouseUp = () => {
        setIsPanning(false);
    };

    const onMouseLeave = () => {
        setIsPanning(false);
    };

    const handleWheel = (e) => {
        const zoomFactor = 0.1;
        const direction = e.deltaY < 0 ? 1 : -1;
        let newScale = scale + zoomFactor * direction;

        // Enforce the scale bounds
        newScale = Math.min(Math.max(newScale, minScale), maxScale);

        setScale(newScale);
    };

    const getDistanceBetweenTouches = (e) => {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    let initialPinchDistance = null;

    const handleTouchStart = (e) => {
        if (e.touches.length === 2) {
            // Two fingers touched the screen
            initialPinchDistance = getDistanceBetweenTouches(e);
            return;
        }

        if (currentTool !== 'drag') return;

        setIsPanning(true);
        const touch = e.touches[0];
        setStartPanPoint({
            x: touch.clientX - canvasOffset.x,
            y: touch.clientY - canvasOffset.y
        });
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (e.touches.length === 2) {
            const newDistance = getDistanceBetweenTouches(e);
            if (initialPinchDistance != null) {
                const scaleChange = newDistance / initialPinchDistance;
                let newScale = scale * scaleChange;

                // Enforce the scale bounds
                newScale = Math.min(Math.max(newScale, minScale), maxScale);

                setScale(newScale);
            }

            return;
        }

        console.log("touch move", isPanning)
        if (isPanning) {
            const touch = e.touches[0];
            let newX = touch.clientX - startPanPoint.x;
            let newY = touch.clientY - startPanPoint.y;

            newX = Math.min(Math.max(newX, bounds.x[0]), bounds.x[1]);
            newY = Math.min(Math.max(newY, bounds.y[0]), bounds.y[1]);

            setCanvasOffset({ x: newX , y: newY });
        }
    };

    useEffect(() => {
        const element = canvasRef.current;
        if (!element) return;

        // Adding event listener with passive set to false
        element.addEventListener('touchmove', handleTouchMove, { passive: false });

        // Cleanup
        return () => {
            element.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isPanning]);

    const handleTouchEnd = () => {
        initialPinchDistance = null;

        setIsPanning(false);
    };

    const handleCanvasClick = (e) => {
        if (!selectedShape || currentTool === 'drag') return;

        const canvasRect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRect.width / CANVAS_SIZE;
        const scaleY = canvasRect.height / CANVAS_SIZE;

        // Calculate the position of the click within the transformed canvas coordinate system
        let x = ((e.clientX - canvasRect.left) / scaleX - CANVAS_SIZE / 2) / scale + CANVAS_SIZE / 2 - canvasOffset.x;
        let y = ((e.clientY - canvasRect.top) / scaleY - CANVAS_SIZE / 2) / scale + CANVAS_SIZE / 2 - canvasOffset.y;


        if(gameState === STATES.STAGED) {
            x = selectedShape.x;
            y = selectedShape.y;
        }

        const newShape = {
            ...selectedShape, // This contains type, size/radius, and potentially color and rotation
            x,
            y,
            color: selectedShape.color || 'black',
            rotation: selectedShape.rotation || 0,
        };

        onAddShape(newShape); // Pass new shape up to be added to the array of turns
    };

    useEffect(() => {
        const image = background.current;
        image.src = backgroundImagePath;
        image.onload = () => {
            setIsBackgroundLoaded(true);
        };

        image.onerror = () => {
            console.error('Error loading image.');
            setIsBackgroundLoaded(false);
        };

    }, [backgroundImagePath]);

    useEffect(() => {

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');


        context.save();
        context.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);

        context.translate(CANVAS_SIZE / 2, CANVAS_SIZE / 2);
        context.scale(scale, scale);
        context.translate(-CANVAS_SIZE / 2, -CANVAS_SIZE / 2);

        context.translate(canvasOffset.x, canvasOffset.y);

        // Redraw the content

        context.fillStyle = 'gray';
        context.fillRect(-BORDER_SIZE, -BORDER_SIZE, TOTAL_SIZE, TOTAL_SIZE);
        context.fillStyle = 'white';
        context.fillRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);

        if (isBackgroundLoaded) {
            const backgroundImage = background.current;
            context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
        }

        for (const turn of turns) {
            if (turn.shape === undefined) {
                continue;
            }
            drawShape(turn.shape.x, turn.shape.y, scale, turn.shape, canvasRef);
        }

        if (gameState === STATES.STAGED) {
            drawShape(selectedShape.x, selectedShape.y, scale, selectedShape, canvasRef);
            drawShape(selectedShape.x, selectedShape.y, scale, selectedShape, canvasRef, true);
        }

        context.restore();

        if (gameState === STATES.STAGED) return;
        drawPreviewWindow(selectedShape, canvasRef, CANVAS_SIZE);
    }, [turns, canvasOffset, scale, gameState, selectedShape, isBackgroundLoaded]);

    return (
        <canvas
            className={classes.canvas}
            ref={canvasRef}
            width={CANVAS_SIZE.toString()}//{window.innerWidth}
            height={CANVAS_SIZE.toString()}//{window.innerHeight}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onClick={handleCanvasClick}
            onMouseUp={handleMouseUp}
            onWheel={handleWheel}
            onMouseLeave={onMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            style={{ cursor: currentTool === 'drag' ? (isPanning ? 'grabbing' : 'grab') : 'default' }}
        />
    );
};

const STATES = {
    NOT_PLACED: 'not_placed',
    STAGED: 'staged',
    PLACED: 'placed'
};


// Add buttons or a toggle to change the current tool in the UI
const ToolSelector = ({ currentTool, setCurrentTool, isStageTwo, isMyTurn, onRemoveSelect}) => {
    const classes = useStyles();
    return (
        <div className={classes.shapeSelector}>
            <Button
                variant={currentTool === 'drag' ? 'contained' : 'outlined'}
                onClick={() => setCurrentTool('drag')}
                disabled={!isMyTurn}
            >
                Drag
            </Button>
            <Button
                variant={currentTool === 'shape' ? 'contained' : 'outlined'}
                onClick={() => setCurrentTool('shape')}
                disabled={!isMyTurn}
            >
                Shape
            </Button>
            <Button
                variant={currentTool === 'color' ? 'contained' : 'outlined'}
                onClick={() => setCurrentTool('color')}
                disabled={!isMyTurn}
            >
                Color
            </Button>
            <Button
                variant={currentTool === 'rotate' ? 'contained' : 'outlined'}
                onClick={() => setCurrentTool('rotate')}
                disabled={!isMyTurn}
            >
                Rotate
            </Button>
            <Button
                variant={currentTool === 'move' ? 'contained' : 'outlined'}
                onClick={() => onRemoveSelect()}
                disabled={!isStageTwo || !isMyTurn}
            >
                Remove
            </Button>
        </div>
    );
};

const InstructionTooltip = () => {
    const [isVisible, setIsVisible] = useState(false);
    const instructions = [
        "Instruction 1",
        "Instruction 2",
        "Instruction 3",
        // Add more instructions as needed
    ];

    const toggleTooltip = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            <button onClick={toggleTooltip}>Show Instructions</button>
            {isVisible && (
                <div style={{ border: '1px solid black', padding: '10px', marginTop: '10px' }}>
                    <ul>
                        {instructions.map((instruction, index) => (
                            <li key={index}>{instruction}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

const MosaicCreator = ({ experienceId, currentRole, currentUser, currentTurn, turns, setTurns, handleMosaicMessage, scheduledSessionId, backgroundImagePath }) => {
    const [selectedShape, setSelectedShape] = useState({...shapes[0], color: acquaintColorPalettes[0].colors[0]}); // Adding color here
    const [selectedColor, setSelectedColor] = useState(acquaintColorPalettes[0].colors[0]);
    const [selectedRotation, setSelectedRotation] = useState(0); // Rotation in degrees
    const [currentTool, setCurrentTool] = useState('drag'); // 'drag' or 'shape'
    const [loading, setLoading] = useState(false);
    const [gameState, setGameState] = useState(STATES.NOT_PLACED);

    const isEvenTurn = () => {
        return (turns.length % 2) === 0;
    }

    const turnLabel = () => {
        if (isEvenTurn() && currentRole === "G" || !isEvenTurn() && currentRole === "T") {
            return 'YOUR'
        } else {
            return 'your session partner\'s'
        }
    }

    const isMyTurn = useCallback(() => {
        return (isEvenTurn() && currentRole === "G") || (!isEvenTurn() && currentRole === "T");
    }, [turns, currentRole]);

    useEffect(() => {
        if (!isMyTurn()) {
            setCurrentTool('drag');
        } else {
            setGameState(STATES.NOT_PLACED);
        }
    }, [isMyTurn]);

    const handleShapeSelect = (shape) => {
        setSelectedShape({...selectedShape, ...shape});
    };

    const handleColorSelect = (color) => {
        setSelectedColor(color);
         setSelectedShape(prevShape => ({ ...prevShape, color: color }));
    };

    const handleRotationChange = (newRotationRadians) => {
        setSelectedRotation(newRotationRadians);
        setSelectedShape(prevShape => ({
            ...prevShape,
            rotation: newRotationRadians  // or newRotationRadians if you prefer to store radians
        }));
    };

    const onAddShape = (shape) => {
        if (loading) return;

        if (gameState === STATES.NOT_PLACED) {
            setGameState(STATES.STAGED);
            setSelectedShape(shape)
        } else if (gameState === STATES.STAGED) {
            setCurrentTool('drag')
            setLoading(true);
            setGameState(STATES.PLACED);
            handleTurnOver(shape);
        }
    }

    const onRemoveSelect = () => {
        if (loading) return;

        console.log("onMoveSelect")
        if (gameState === STATES.STAGED) {
            setGameState(STATES.NOT_PLACED);
        }
    }

    const handleTurnOver = (shape) => {
        const turn = { role: currentRole, shape: shape }; //currentRole };

        broadcastMosaicData({
            type: MOSAIC,
            from: currentUser,
            scheduledSessionId: scheduledSessionId,
            sub_type: 'game_turn',
            turn_order: turns.length,
            turn: turn
        });
    };

    let effectiveTool = isMyTurn() ? currentTool : 'drag';

    return (
        <div className={'mosaic-creator-component'}>
            <MosaicMessageHandler
                handleMosaicMessage={handleMosaicMessage}
                scheduledSessionId={scheduledSessionId}
                currentUser={currentUser}
                setLoading={setLoading}
            />
            <Canvas
                selectedShape={selectedShape}
                onAddShape={onAddShape}
                turns={turns}
                currentTool={effectiveTool}
                gameState={gameState}
                backgroundImagePath={backgroundImagePath}
            ></Canvas>
            <div className={'mosaic-indicator-text'}>It's {turnLabel()} turn. {gameState === STATES.STAGED && <b>Click or tap again to place, select "REMOVE" to undo.</b>}
                {gameState === STATES.PLACED && <b>Waiting for your session partner to place their shape.</b>}
                {gameState === STATES.NOT_PLACED && <b>Select shape, color, or rotate and click on the canvas to place piece.</b>}
            </div>

            {/* Conditionally render tool-specific components based on the currentTool and isMyTurn */}
            <div className={'tool-container'}>
                <ToolSelector currentTool={currentTool} setCurrentTool={setCurrentTool} onRemoveSelect={onRemoveSelect} isStageTwo={gameState === STATES.STAGED} isMyTurn={isMyTurn()} />

                {isMyTurn() && currentTool === 'shape' && (
                    <ShapeSelector onSelect={handleShapeSelect} />
                )}
                {isMyTurn() && currentTool === 'color' && (

                    <ColorPicker
                        selectedColor={selectedColor}
                        onColorSelect={handleColorSelect}
                    />
                )}
                {isMyTurn() && currentTool === 'rotate' && (
                    <RotationControl
                        onRotationChange={handleRotationChange}
                    />
                )}
            </div>

        </div>
    );
}

export default MosaicCreator;

const MosaicMessageHandler = (props) => {
    const { currentUser, scheduledSessionId, handleMosaicMessage, setLoading } = props;
    const [subscriptionActive, setSubscriptionActive] = useState(false);

    useEffect(() => {
        const consumer = createConsumer();
        // console.log(`Mosaic  message handler props: ${JSON.stringify(props)}`)

        let subscription = consumer.subscriptions.create(
            {
                channel: "MosaicSessionMessagesChannel",
                scheduled_session_id: scheduledSessionId
            }, {
                connected() {
                    // Handle connection logic
                    setSubscriptionActive(true);

                    console.log(`Connected to MosaicSessionMessagesChannel with scheduledSessionId: ${scheduledSessionId}`);
                },

                disconnected() {
                    // Handle disconnection logic
                    setSubscriptionActive(false);
                    console.log(`Disconnected from MosaicSessionMessagesChannel with scheduledSessionId: ${scheduledSessionId}`);
                },

                received(data) {
                    // Handle mosaic messages
                    if (data.type === 'MOSAIC') {
                        handleMosaicMessage(data);
                        setLoading(false);
                    }
                }
            }
        );

        return () => {
            console.log('removing subscription')
            consumer.subscriptions.remove(subscription);
        };
    }, [currentUser, scheduledSessionId]);

    return (
        <div className={'mosaic-indicator-text'}>
            {/* Render your component UI here */}
            {subscriptionActive ? <p>Mosaic Creator is Live</p> : <p>Mosaic Creator Disconnected</p>}
        </div>
    );
};

const ShapeSelector = ({ onSelect }) => {
    const classes = useStyles();

    return (
        <div className={classes.shapeSelector}>
            {shapes.map((shape, index) => (
                <div key={index} className={classes.shapeOption} onClick={() => onSelect(shape)}>
                    {shape.label || shape.type}
                </div>
            ))}
        </div>
    );
};

