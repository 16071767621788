import React, {useState} from 'react';
import DatePicker from 'react-datepicker'
import {utcToZonedTime} from 'date-fns-tz'


const setMinutes = (date, numMinutes) => {
    date.setMinutes(numMinutes);
    return date;
};

const setHours = (date, numHours) => {
    date.setHours(numHours);
    return date;
};

export default function ScheduledSessionDatePicker(props) {
    let {name, selected, startTime, timezone, startHour, endHour, datePickerNotes, maxDate, minDate} = props;
    // Ensure that the dates given are the dates excluded
    // excludeDates = excludeDates.map((date) => utcToZonedTime(new Date(date), 'Etc/UTC') )
    // This is a horrible hack; DatePicker seems to ONLY use the browser's timezone.
    startTime = utcToZonedTime(new Date(startTime.replace(' ', 'T')), timezone)
    if (!!maxDate) {
        maxDate = utcToZonedTime(new Date(maxDate.replace(' ', 'T')), timezone)
    }
    if (!!selected) {
        selected = utcToZonedTime(new Date(selected.replace(' ', 'T')), timezone)
    }

    const [startDate, setStartDate] = useState(selected || null);
    return (
        <>
            <DatePicker
                name={name}
                selected={startDate}
                minDate={minDate || startTime}
                maxDate={maxDate}
                placeholderText="(Click to select time)"
                minTime={setHours(setMinutes(new Date(), 0), startHour)}
                maxTime={setHours(setMinutes(new Date(), 30), endHour)}
                onChange={date => setStartDate(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd h:mm aa"
                autoComplete="off"
                // excludeDates={excludeDates}
            /> <b>Timezone:</b> {timezone}
            {datePickerNotes && <div className='date-picker-notes'>
                {datePickerNotes}
            </div>}
        </>
    )
}
