import React, { useState, useEffect } from 'react';
import QuizList from './quiz_list';
import QuizEditor from './quiz_editor';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    createFormContainer: {
        marginTop: theme.spacing(2),
        border: '1px solid #ddd',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
    input: {
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    quizListContainer: {
        marginTop: theme.spacing(2),
    },
}));

export default function QuizManager() {
    const classes = useStyles();

    const [quizzes, setQuizzes] = useState( []);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [newQuizData, setNewQuizData] = useState({
        display_name: '',
        internal_name: '',
        pass_score: '',
        description: '',
        is_survey: false, // New field
    });

    useEffect(() => {
        fetchQuizzes();
    }, []);


    const handleCreateQuiz = async () => {
        const response = await fetch('/quizzes', {
            method: 'POST',
            body: JSON.stringify(newQuizData),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
            }
        });
        if (response.ok) {
            fetchQuizzes();
            setShowCreateForm(false);
            setNewQuizData({ display_name: '', internal_name: '', pass_score: '', description: '' }); // Reset form
        } else {
            // Handle errors (e.g., display a message to the user)
        }
    };

    const fetchQuizzes = async () => {
        const response = await fetch('/quizzes');
        const data = await response.json();
        setQuizzes(data);
    };

    const handleSelectQuiz = (quiz) => {
        setSelectedQuiz(quiz);
    };

    const handleQuizUpdated = async () => {
        await fetchQuizzes();
        // Update the selectedQuiz state if it was updated in the list
        const updatedQuiz = quizzes.find(q => q.id === selectedQuiz.id);
        setSelectedQuiz(updatedQuiz);
    };

    const handleQuizDeleted = () => {
        fetchQuizzes();
        setSelectedQuiz(null);
    };

    const handleInputChange = (e) => {
        setNewQuizData({ ...newQuizData, [e.target.name]: e.target.value });
    };

    return (
        <div className={classes.container}>
            <button className={classes.button} onClick={() => setShowCreateForm(!showCreateForm)}>
                {showCreateForm ? 'Cancel' : 'Create New Quiz'}
            </button>
            {showCreateForm && (
                <div className={classes.createFormContainer}>
                    <input
                        type="text"
                        name="display_name"
                        placeholder="Display Name"
                        value={newQuizData.display_name}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="internal_name"
                        placeholder="Internal Name"
                        value={newQuizData.internal_name}
                        onChange={handleInputChange}
                    />
                    <label>
                        Is Survey:
                        <input
                            type="checkbox"
                            name="is_survey"
                            checked={newQuizData.is_survey}
                            onChange={e => setNewQuizData({ ...newQuizData, is_survey: e.target.checked })}
                        />
                    </label>
                    <input
                        type="number"
                        name="pass_score"
                        placeholder="Pass Score"
                        value={newQuizData.pass_score}
                        onChange={handleInputChange}
                    />
                    <textarea
                        name="description"
                        placeholder="Description"
                        value={newQuizData.description}
                        onChange={handleInputChange}
                    />
                    <button onClick={handleCreateQuiz}>Save New Quiz</button>                </div>
            )}
            <div className={classes.quizListContainer}>
                <QuizList quizzes={quizzes} onSelectQuiz={handleSelectQuiz} />
            </div>
            {selectedQuiz && (
                <QuizEditor
                    quiz={selectedQuiz}
                    onQuizUpdated={handleQuizUpdated}
                    onQuizDeleted={handleQuizDeleted}
                />
            )}
        </div>
    );
}
