import React from "react";

export const BulletPoints = (props) => {
    let {bulletPoints} = props
    return <ul>
        {
            bulletPoints.map((bullet_point, index) => {
                return <li key={`bullet-point-${index}`} className='bullet_point'>
                    <div className={'scene_script_bullet_point'} dangerouslySetInnerHTML={{__html: bullet_point}}/>
                </li>
            })
        }
    </ul>
}

export default BulletPoints