import React from "react"
import {Select} from "@material-ui/core"
import {LanguageSelect} from "./language_select/language_select"

export default class TourMetadataEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...props.tour};
    }

    handleInputChange = (event, fieldName) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [fieldName]: value
        });
    }

    handleSelectChange = (selection, fieldName) => {
        console.log(selection.value)
        this.setState({
            [fieldName]: selection.value
        });
    };

    render() {
        const {permissions} = this.props
        const isOwner = permissions === 'manager'
        return (
            <div>
                {isOwner && <div className={'field'}>
                    Name: <input
                    type={'text'}
                    value={this.state.name || ''}
                    name={'tour[name]'}
                    onChange={(event) => this.handleInputChange(event, 'name')}
                />
                </div>
                }
                {isOwner && <div>
                    <LanguageSelect
                        options={this.props.languageOptions}
                        className={'field'}
                        value={this.props.languageOptions.filter(option => option.value === this.state.language)}
                        name={'tour[language]'}
                        onChange={(event) => this.handleSelectChange(event, 'language')}
                    />
                </div>
                }
                <div className={'field tour-textarea'}>
                    <div className={'tour-textarea-label'}>Tour Description:</div>
                    <textarea
                        rows={4}
                        cols={50}
                        value={this.state.description}
                        name={'tour[description]'}
                        onChange={(event) => this.handleInputChange(event, 'description')}
                    />
                </div>
                <div className={'field tour-textarea'}>
                    <div className={'tour-textarea-label'}>Intro Script:</div>
                    <textarea
                        rows={4}
                        cols={50}
                        value={this.state.intro_script}
                        name={'tour[intro_script]'}
                        onChange={(event) => this.handleInputChange(event, 'intro_script')}
                    />
                </div>
                {isOwner && <>
                    <input
                        type={'hidden'}
                        name={'tour[is_public]'}
                        value={0}
                    />
                    <div className={'field'}>
                        Public?: <input
                        type={'checkbox'}
                        checked={this.state.is_public}
                        name={'tour[is_public]'}
                        onChange={(event) => this.handleInputChange(event, 'is_public')}
                    />
                    </div>
                </>
                }
            </div>
        )
    }
}
