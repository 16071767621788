import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from "@material-ui/core/styles";
import {Button, Radio} from "@material-ui/core";
import withTheme from "../with_theme";

const useStyles = makeStyles((theme) => ({
    quizContainer: {
        padding: theme.spacing(3),
        margin: 'auto',
        maxWidth: 720,
        border: '1px solid #ddd',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default, // Use the background color from theme
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    questionContainer: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#fff', // Consider if this needs to be themed
    },
    questionTitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        userSelect: 'none',
        fontSize: '1.25rem',
    },
    answerOption: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        border: '1px solid #eee',
        borderRadius: theme.shape.borderRadius,
        userSelect: 'none',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: theme.palette.action.hover, // Use generic hover background color from theme
            cursor: 'pointer',
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
        },
        '& .MuiRadio-root': {
            color: theme.palette.primary.primary, // Use primary color from theme
        },

    },
    radioLabel: {
        marginLeft: theme.spacing(1),
        textAlign: 'left',
    },
    submitButton: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1, 3),
        color: theme.palette.text.primary, // Updated to use text primary color, assuming it's havr_brown
        backgroundColor: theme.palette.primary.main, // Uses primary color from theme
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darken on hover
        },
    },
    errorText: {
        color: theme.palette.error.main, // Use error color from theme
        fontSize: '0.8rem',
        marginTop: theme.spacing(1),
    },
    textAreaInput: {
        width: '100%',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        minHeight: '100px',
        resize: 'vertical',
    },
    scorePassed: {
        color: theme.palette.success.main, // Use success color from theme
        fontWeight: 'bold',
    },
    scoreFailed: {
        color: theme.palette.error.main, // Use error color from theme
        fontWeight: 'bold',
    },
    quizCompletedContainer: {
        textAlign: 'center',
    },
    scoreText: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: theme.spacing(2, 0),
    },
    goHomeLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main, // Use primary color from theme
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    errorSummary: {
        marginBottom: theme.spacing(2),
        color: theme.palette.error.main, // Use error color from theme
    },
}));



const MultipleChoiceQuestion = ({ question }) => {
    const classes = useStyles();
    return (
        <>
            {question.answer_options.map((option) => (
                <label key={option.id} className={classes.answerOption}>
                    <Field
                        type="radio"
                        name={question.id.toString()}
                        value={option.id.toString()}
                        as={Radio}
                    />
                    <span className={classes.radioLabel}>{option.content}</span>
                </label>
            ))}
        </>
    );
};

const ShortAnswerQuestion = ({ question }) => {
    const classes = useStyles();
    return (
        <Field
            name={question.id}
            as="textarea"
            className={classes.textAreaInput}
        />
    );
};

const useNarrowStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        width: '100%',
        padding: theme.spacing(1), // Padding inside each option for better touch target and spacing
        border: `1px solid ${theme.palette.divider}`, // Gray border, adjust color as per your theme
        borderRadius: theme.shape.borderRadius, // Optional, for rounded corners
        backgroundColor: theme.palette.background.paper, // Optional, if you want to differentiate the background
    },
    radioLabel: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    numberHyphen: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(0.5),
        whiteSpace: 'nowrap',
    },
    textLabel: {
        flex: '1 1 auto',
        minWidth: 0,
        whiteSpace: 'normal',
    }
}));






const useWideStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)', // Can adjust to 'repeat(10, 1fr)' for up to 10 columns if needed later
        gridGap: theme.spacing(2),
        alignItems: 'center',
        justifyItems: 'center',
        marginBottom: theme.spacing(2),
    },
    gridItem: {
        textAlign: 'center',
    },
    labelRow: {
        gridRow: 1,
    },
    numberRow: {
        gridRow: 2,
    },
}));



const WideLayoutIntegerRangeQuestion = ({ question, labels, options }) => {
    const classes = useWideStyles(); // Assuming useStyles defines styles for the wide layout

    return (
        <div className={classes.gridContainer}>
            {options.map((option) => (
                <React.Fragment key={option}>
                    <div className={`${classes.gridItem} ${classes.labelRow}`}>
                        {labels[option] || ''}
                    </div>
                    <div className={`${classes.gridItem} ${classes.numberRow}`}>
                        <label>
                            <Field
                                type="radio"
                                name={question.id.toString()}
                                value={option.toString()}
                                as={Radio}
                            />
                            {option}
                        </label>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

const NarrowLayoutIntegerRangeQuestion = ({ question, labels, options }) => {
    const classes = useNarrowStyles(); // Using the refined narrow view styles

    return (
        <div className={classes.gridContainer}>
            {options.map((option) => (
                <div key={option} className={classes.gridItem}>
                    <label className={classes.radioLabel}>
                        <Field
                            type="radio"
                            name={question.id.toString()}
                            value={option.toString()}
                            as={Radio}
                        />
                        <span className={classes.numberHyphen}>{option} -</span>
                        <span className={classes.textLabel}>{labels[option] || ''}</span>
                    </label>
                </div>
            ))}
        </div>
    );
};

import { useMediaQuery } from '@material-ui/core';

const IntegerRangeQuestion = ({ question }) => {
    const matches = useMediaQuery('(min-width:620px)');
    const { min = 1, max = 5 } = question.metadata?.integerRange || {};
    const labels = question.metadata?.labels || {};
    const options = Array.from({ length: max - min + 1 }, (_, i) => i + min);

    return matches ? (
        <WideLayoutIntegerRangeQuestion question={question} labels={labels} options={options} />
    ) : (
        <NarrowLayoutIntegerRangeQuestion question={question} labels={labels} options={options} />
    );
};


// Helper function to shuffle an array
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}


// TODO: the relatedEventStuff is half baked.
// It looks like we can just modify the pass/fail message when this is a survey though.
function TakeQuiz({ quizId, relatedEventId, relatedEventType, relatedEventMessage }) {
    const [quiz, setQuiz] = useState(null);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [quizResult, setQuizResult] = useState({ scoreString: '', message: '', passed: false });
    const classes = useStyles();

    useEffect(() => {
        async function fetchQuiz() {
            const response = await fetch(`/quizzes/${quizId}/take`);
            const data = await response.json();
            setQuiz(data);
        }

        fetchQuiz();
    }, [quizId]);

    // Sort questions by position if position is provided
    // const sortedQuestions = quiz ? quiz.questions.sort((a, b) => {
    //     return (a.position || 1000) - (b.position || 1000);
    // }) : [];

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch('/handle_quiz_submission', {
                method: 'POST',
                body: JSON.stringify({
                    quiz_id: quizId,
                    answers: values,
                    related_event_id: relatedEventId,
                    related_event_type: relatedEventType,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
                },
            });

            if (response.ok) {
                const data = await response.json();
                setSubmitting(false);
                setQuizResult({ score: data.score, message: data.message, passed: data.passed, scoreString: data.score_string });
                setQuizCompleted(true);
            } else {
                console.error('Error submitting quiz');
                // Optionally handle error response
            }
        } catch (error) {
            console.error('Error occurred while submitting the form:', error);
            setSubmitting(false);
        }
    };

    if (!quiz) return <div>Loading...</div>;

    if (quizCompleted) {
        const defaultPassMessage = "Congratulations, you've passed the quiz!";
        const defaultFailMessage = "Unfortunately, you did not pass the quiz. Please try again!";
        return (
            <div className={classes.quizCompletedContainer}>
                <h2>Quiz Completed</h2>

                { quizResult.scoreString &&
                <>
                    <div className={quizResult.passed ? classes.scorePassed : classes.scoreFailed}>
                        <span className={classes.scoreText}>Score: {quizResult.scoreString}</span>
                    </div>
                </>
                }
                <p>{quizResult.message ||  (quizResult.passed ? defaultPassMessage : defaultFailMessage)}</p>

                <a href="/home" className={'basic-link-button'}>Go Home</a>
            </div>
        );
    }

// Dynamically generate initial form values and validation schema based on the quiz data
    const initialFormValues = quiz ? quiz.questions.reduce((values, question) => {
        values[question.id] = '';
        return values;
    }, {}) : {};

    const validationSchema = Yup.object().shape(
        quiz ? quiz.questions.reduce((schema, question) => {
            schema[question.id] = Yup.string().required('This field is required');
            return schema;
        }, {}) : {}
    );

    const generateErrorSummary = (errors, touched, questions) => {
        return Object.keys(errors)
            .filter(key => touched[key]) // Only consider fields that have been touched
            .map(key => {
                const question = questions.find(q => q.id.toString() === key);
                return `Missing answer for question: "${question.content}"`;
            });
    };

    const groupedAndShuffledQuestions = quiz ? Object.values(quiz.questions.reduce((acc, question) => {
        const position = question.position || 1000; // Default position
        if (!acc[position]) {
            acc[position] = [];
        }
        acc[position].push(question);
        return acc;
    }, {})).map(questionGroup => {
        shuffleArray(questionGroup); // Shuffle each group
        return questionGroup;
    }).flat() : []; // Flatten the array of arrays

    return (
        <div className={classes.quizContainer}>
            <h2>{quiz.display_name}</h2>
            <p>{relatedEventMessage}</p>
            <p>{quiz.description}</p>

            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched }) => (                    <Form>
                        {groupedAndShuffledQuestions.map((question) => (
                            <div key={question.id} className={classes.questionContainer}>
                                <h3 className={classes.questionTitle}>{question.content}</h3>
                                {(() => {
                                    switch (question.question_type) {
                                        case 'multiple_choice':
                                            return <MultipleChoiceQuestion question={question} />;
                                        case 'short_answer':
                                            return <ShortAnswerQuestion question={question} />;
                                        case 'integer_range': // Handle integer range questions
                                            return <IntegerRangeQuestion question={question} />;
                                        // Add more cases for other question types
                                        default:
                                            return null;
                                    }
                                })()}
                                <ErrorMessage name={question.id} component="div" className={classes.errorText} />
                            </div>
                        ))}
                        <div className={classes.errorSummary}>
                            {/* Generate and display the error summary */}
                            {generateErrorSummary(errors, touched, quiz.questions).map((error, index) => (
                                <div key={index} className={classes.errorText}>{error}</div>
                            ))}
                        </div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                            disabled={isSubmitting}
                        >
                            Submit
                        </Button>
                </Form>
                )}
            </Formik>
        </div>
    );
}


export default withTheme(TakeQuiz);