import React from 'react'
import '../../../assets/stylesheets/membership_management.scss'
import '../../../assets/stylesheets/application.scss'

import { MembershipOption } from './membership_option'

export default {title: 'Membership Option'};

export const standard = () => (<MembershipOption
    title={'Coachcar'}
    price={'159'}
    bulletPoints={['Unlimited sessions', 'Access to full experience library', '61-120 eligible members']}
/>);