import React from 'react'

export const MicrophoneIcon = (props) => {
    let {fill, size = "1.5rem"} = props;

    return (<div style={{height: size, width: size}}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 500 500">
            <g>
                <path fill={fill} d="M209.939,247.393v7.825c0,7.733-6.324,14.058-14.058,14.058h-67.099
		c11.935,56.414,61.979,98.77,121.948,98.77c59.974,0,110.018-42.355,121.952-98.77h-67.104c-7.725,0-14.058-6.324-14.058-14.058
		v-7.825c0-7.733,6.333-14.058,14.058-14.058h69.831v-31.447h-69.831c-7.725,0-14.058-6.324-14.058-14.058v-7.825
		c0-7.733,6.333-14.058,14.058-14.058h69.831v-31.446h-69.831c-7.725,0-14.058-6.324-14.058-14.058v-7.825
		c0-7.734,6.333-14.058,14.058-14.058h67.104C360.748,42.137,310.704-0.219,250.73-0.219c-59.97,0-110.019,42.356-121.948,98.779
		h67.099c7.734,0,14.058,6.324,14.058,14.058v7.825c0,7.733-6.324,14.058-14.058,14.058h-69.835v31.446h69.835
		c7.734,0,14.058,6.324,14.058,14.058v7.825c0,7.734-6.324,14.058-14.058,14.058h-69.835v31.447h69.835
		C203.615,233.335,209.939,239.659,209.939,247.393z"/>
                <path fill={fill} d="M452.627,258.787c1.217-12.877-8.237-24.309-21.114-25.535c-12.85-1.117-24.309,8.237-25.534,21.123
		c-13.766,145.482-148.521,141.73-154.154,141.611l-1.094-0.055l-1.094,0.055c-5.734,0.238-140.298,4.833-154.154-141.611
		c-1.217-12.886-12.607-22.166-25.53-21.123c-12.881,1.226-22.34,12.658-21.118,25.535
		c12.955,136.952,114.864,178.695,181.849,183.638v15.852h-38.837c-14.854,0-26.898,18.569-26.898,41.505h171.566
		c0-22.936-12.045-41.505-26.898-41.505h-38.842v-15.833C337.693,437.537,439.676,395.712,452.627,258.787z"/>
            </g>
        </svg>

    </div>)

}
