import React, {useEffect, useRef, useState} from "react";
import { Device } from 'twilio-client';
import {Palette} from "../../utils/palette"
import {CancelIcon} from "../icons/cancel_icon"
import {ContactPhoneIcon} from "../icons/contact_phone_icon"

export const readyTwilioDevice = async () => {
    let response = await fetch("/generate_user_calls_token", {
            method: "POST",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );

    // TODO: handle failures

    let data = await response.json()
    return data.token
};

const InfoLabel = (props) => <div className={'info-label'}>{props.text}&nbsp;</div>

const DialerHeaderSection = (props) => {
    const {header} = props

    return (<div className={'dialer-header-section'}>{header}</div>)
}

const CallStatusSection = (props) => {
    const {callStatus} = props
    return <div className={'call-status-section'}>
        <div className={'info-field'}><InfoLabel text={'Call Status:'}/>{callStatus}</div>
    </div>
}

const UserInfoSection = (props) => {
    const {nickname, dialingNotes, editLink, phonePresent} = props
    return <div className={'user-info-section'}>
        {editLink && <div>{!phonePresent && "(NO PHONE LISTED, YET)"}<a href={editLink}>Edit Contact Info</a></div>}
        <div className={'info-field'}><InfoLabel text={'Nickname:'}/>{nickname}</div>
        <div className={'info-field'}><InfoLabel text={'Notes to Dialer:'}/>{dialingNotes}</div>
    </div>
}

const CallButton = (props) => {
    const {action, disabled} = props

    return (
        <button className={'start-call-button'} disabled={disabled} onClick={action}>
            Call&nbsp;<ContactPhoneIcon fill={Palette.ui_green}/>
        </button>
    )
}

const CloseX = (props) => {
    const { onClick } = props;

    return <div onClick={onClick} className={'dialer-close-x'}>X</div>

}
const HangupButton = (props) => {
    const {action, disabled} = props

    return (
        <button className={'hangup-button'} disabled={disabled} onClick={action}>
            Hangup&nbsp;<CancelIcon fill={Palette.ui_red}/>
        </button>
    )
}

export default function Dialer(props) {
    const { targetUserInformation, currentUserId, display, sessionId, closeCallback} = props
    // Session facilitator page: dialer hovers on bottom, clicking on an user will trigger authentication.
    // and then enable the component

    // TODO: we should have a slack channel for monitoring outbound dials
    // const [callDisabled, setCallDisabled] = useState(true) // probably want to also check for presence of user profile ID & phone number here
    const [callStatus, setCallStatus] = useState('N/A')
    const [ready, setReady] = useState(false)
    const [connected, setConnected] = useState(false)

    // Allow connected state to determine whether or not we allow navigation away from the page.
    const connectedRef = useRef();
    connectedRef.current = connected;

    const callDisabled = () => {
        return connected ||
            !ready ||
            (!targetUserInformation.userProfileId && !sessionId) ||
            !targetUserInformation.phonePresent
    }

    const hangupDisabled = () => {
        return !connected
    }

    const dial = () => {
        console.log('dialing')
        let twilioParams = {
            calling_user_id: currentUserId,
        }

        // Handle facilitator dial vs guide dial:
        if(targetUserInformation.userProfileId) {
          twilioParams['target_user_profile_id'] = targetUserInformation.userProfileId
        } else if(sessionId){
          twilioParams['session_id'] = sessionId
        }
        Device.connect(twilioParams);
    }

    const close = () => {
        try {
            hangup();
        } catch(exception) {
            console.warn('closed dialer before it was set up...')
        }
        closeCallback();
    }

    const onLeaveDialerAction = (e) => {
        if (connectedRef.current) {
            if (!confirm(`This action will hang up your current call, are you sure?`)) {
                e.preventDefault();
                e.stopPropagation();
            }
            return null;
        }
    }

    const hangup = () => {
        // hangup and remove the navigation event listener...
        console.log('hangup')
        window.removeEventListener("turbolinks:before-visit", onLeaveDialerAction)
        Device.disconnectAll();
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        console.log('Initializing dialer')
        window.addEventListener("turbolinks:before-visit", onLeaveDialerAction)
        // Authenticate & ready twilio device
        const onCallStatusChange = (newStatus, hangupDisabled) => {
            setCallStatus(newStatus)
        }

        Device.on('ready',function (device) {
            console.log('ready to dial')
            setReady(true)
            onCallStatusChange("ready", true);
        });

        Device.on('disconnect', function() {
            console.log('disconnected')
            setConnected(false)
            onCallStatusChange('disconnected', true)
        });

        Device.on('connect', function() {
            console.log('connect')
            setConnected(true)
            onCallStatusChange('connected', true)
        });

        Device.on('error', function (error) {
            console.log(`Error: ${error}`)
        });

        async function fetchTokenAndSetup() {
            const token = await readyTwilioDevice()
            console.log('calling setup...')
            Device.setup(token, {
                debug: false,
                codecPreferences: ['opus', 'pcmu'],
                closeProtection: true,
                enableIceRestart: true
            });

            if(Device.status() === 'ready') {
                // ready event is never fired if the javascript isn't unloaded from the page and navigation takes place
                // however, setup needs to be called before checking the devices status... So we check it here in case
                // the ready status never fires.
                setReady(true)
            }
            console.log('finished setup')
        }
        fetchTokenAndSetup()

        return hangup;
    }, [false]); // Is there a right way to do this? I only want to call this once.

    return (
        <>
            {display ? <div className={'user-dialer'}>
                <CloseX onClick={close}/>
                <DialerHeaderSection header={`Call ${targetUserInformation.nickname}`}/>
                <UserInfoSection {...targetUserInformation} />
                <CallStatusSection callStatus={callStatus}/>
                <div className={'dialer-actions'}>
                   <CallButton action={dial} disabled={callDisabled()}/>
                   <HangupButton action={hangup} disabled={hangupDisabled()}/>
                </div>
            </div> : null}
        </>
    )
}
