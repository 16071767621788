import React, {useState} from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    TextField,
    Button, colors,
    MenuItem,
    Select
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ExclamationIcon} from "../icons/exclamation_icon";
import {HelpInstructionsAndRaiseHand} from "./help_instructions_and_raise_hand";

const useStyles = makeStyles((theme) => ({
    chatContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    appBar: {
        position: "relative",
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        maxWidth: '80%'
    },
    messageContainer: {
        flex: 1,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        backgroundColor: "#f5f5f5",
    },
    message: {
        display: "flex",
        alignItems: "flex-start",
        margin: theme.spacing(0.5, 0),  // Compact vertical spacing
        width: "100%",
    },
    messageContent: {
        padding: theme.spacing(1),
        borderRadius: "12px",
        maxWidth: "75%",
        wordWrap: "break-word",
        position: "relative",
        border: "1px solid transparent",
    },
    selfBubble: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(-0.25),
        marginLeft: "auto",
        "&::before": {
            content: "''",
            position: "absolute",
            right: "-8px",
            bottom: "0",
            width: "12px",
            height: "12px",
            backgroundColor: theme.palette.primary.main,
            clipPath: "polygon(0% 100%, 100% 100%, 100% 0%)",
        },
    },
    otherBubble: {
        backgroundColor: theme.palette.grey[300],
        marginRight: "auto",
        marginTop: theme.spacing(-0.25),
        marginBottom: theme.spacing(-0.25),
        "&::before": {
            content: "''",
            position: "absolute",
            left: "-8px",
            bottom: "0",
            width: "12px",
            height: "12px",
            backgroundColor: theme.palette.grey[300],
            clipPath: "polygon(100% 100%, 0% 100%, 0% 0%)",
        },
    },
    timestamp: {
        fontSize: "0.7rem",  // Slightly smaller text size
        opacity: 0.6,
        marginTop: 0,  // Smaller space between bubble and timestamp
        marginBottom: theme.spacing(-0.5),  // Pull timestamp closer to the next message
        alignSelf: "flex-end",  // Align with the bottom of the bubble
    },
    messageInput: {
        display: "flex",
        padding: theme.spacing(1),
        borderTop: "1px solid #ccc",
        backgroundColor: "#fff",
    },
    inputField: {
        flex: 1,
    },
    sendButton: {
        marginLeft: theme.spacing(1),
    },
    helpButton: {
        marginRight: theme.spacing(1),  // Push it to the left of input
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.warning.main,
        borderRadius: theme.shape.borderRadius,
        color: "#fff",
    },
    messageHeaderContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        flexWrap: "wrap",
    },
    roleTag: {
        marginLeft: theme.spacing(0.5),
        fontSize: "0.85rem",
        color: theme.palette.text.primary,
    },
    messageContentText: {
        marginLeft: theme.spacing(1),
    },
    deletedText: {
        opacity: 0.7,
        fontStyle: "italic",
        color: theme.palette.error.main,
    },
    statusIcon: {
        marginLeft: theme.spacing(0.5), // Space between timestamp and icon
        fontSize: "0.8rem", // Adjust size to match the timestamp
        display: "inline-block",
        color: theme.palette.text.secondary,
        "&.sent": {
            color: theme.palette.grey[500],
        },
        "&.delivered": {
            color: theme.palette.grey[500],
        },
        "&.read": {
            color: colors.blue[500], // Blue check
        },
        "&.undelivered": {
            color: theme.palette.error.main, // Red X
        },
    },
    templateSelect: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    profileButton: {
        textDecoration: "none",
        marginLeft: "auto",
        padding: "8px 12px",
        backgroundColor: "#007bff",
        color: "#ffffff",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "bold",
        textAlign: "center",
        display: "inline-block",
        "&:hover": {
            backgroundColor: "#0056b3",
        },
    },


}));


const TrashIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6l-1 14H6L5 6"></path>
        <path d="M10 11v6"></path>
        <path d="M14 11v6"></path>
        <path d="M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2"></path>
    </svg>
);

const ChatView = ({
                      selectedChat,
                      messages,
                      messageContainerRef,
                      handleDeleteMessage,
                      currentUser,
                      message,
                      setMessage,
                      setTemplateSid,
                      handleSendMessage,
                      userCanDelete,
                      chatSubTitle,
                      messageTemplates,
                      canSendFreeform,
                      isAdmin
                  }) => {
    const classes = useStyles();
    const [showHelp, setShowHelp] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState("");


    const handleTemplateChange = (event) => {
        const templateName = event.target.value;
        setSelectedTemplate(templateName);

        // Find the selected template's body and set it as the message text
        const template = messageTemplates?.find((t) => t.name === templateName);
        if (template) {
            setMessage(template.body);
            setTemplateSid(template.content_sid);
        }
    };

    const handleSendWithTemplate = () => {
        handleSendMessage();
    };

    const isSelf = (msg, currentUser) => {
        if (msg.data?.direction === "outbound" && msg.chattable_type === "WhatsAppContact") {
            return true
        }
        return (msg.name || "").trim().toLowerCase() === (currentUser.name || "").trim().toLowerCase() &&
            (msg.public_name_differentiator?.slice(0, 6) || "") === (currentUser.user_profile_id?.slice(0, 6) || "");
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case "sent":
                return "✔"; // Single gray check
            case "delivered":
                return "✔✔"; // Double gray checks
            case "read":
                return "✔✔"; // Double blue checks
            case "undelivered":
                return "❌"; // Red X
            default:
                return "";
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(undefined, {
            weekday: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const MediaLinks = ({ mediaUrls, mediaTypes }) => {
        if (!mediaUrls || mediaUrls.length === 0) {
            return null;
        }
        return (
            <Box marginTop={1} marginLeft={1}>
                <Typography variant="body2" color="textPrimary">Attachments:</Typography>
                {mediaUrls.map((url, index) => (
                    <Typography key={index} variant="body2">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {mediaTypes && mediaTypes[index] ? `Media ${index + 1} (${mediaTypes[index]})` : `Media ${index + 1}`}
                        </a>
                    </Typography>
                ))}
            </Box>
        );
    };

    // get the profile_link from the selectedChat, and a link button in the title below
    const profile_link = selectedChat?.profile_link;
    const email = selectedChat?.user_email;
    return (
        <Box className={classes.chatContent}>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.title}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="subtitle1">
                            {selectedChat
                                ? selectedChat.title || `Chat with ${selectedChat.name || "Unknown"}`
                                : "No chat selected"}
                        </Typography>
                        <Typography variant="body2">
                            {chatSubTitle}
                        </Typography>
                    </Box>
                    {profile_link && (
                        <a
                            href={profile_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.profileButton}
                        >
                            View Profile
                        </a>
                    )}
                    {email && `\u00A0${email}`}
                </Toolbar>
            </AppBar>

            {/* Toggle between Chat and Help */}
            {!showHelp ? (
                <>
                    <Box ref={messageContainerRef} className={classes.messageContainer}>
                        {selectedChat ? (
                            messages.map((msg) => {
                                if (!userCanDelete && msg.deleted) {
                                    return null;
                                }

                                const self = isSelf(msg, currentUser);
                                const whatsAppMessage= msg.chattable_type === "WhatsAppContact";
                                return (
                                    <Box
                                        key={msg.id}
                                        className={classes.message}
                                    >
                                        <Box
                                            className={`${classes.messageContent} ${
                                                self ? classes.selfBubble : classes.otherBubble
                                            }`}
                                        >
                                            <Typography
                                                component="div"
                                                className={classes.messageHeaderContainer}
                                            >
                                                <Box
                                                    component="span"
                                                >
                                                    <b>{msg.name}</b>
                                                    {msg.role && (
                                                        <span className={classes.roleTag}><i><u>{` ${msg.role}`}</u></i></span>
                                                    )}
                                                </Box>
                                                <Typography
                                                    component="span"
                                                    variant="body1"
                                                    className={`${classes.messageContentText} ${msg.deleted ? classes.deletedText : ''}`}
                                                >
                                                    {msg.deleted && "[Deleted] "}
                                                    {msg.content || msg.data.body}
                                                </Typography>
                                            </Typography>
                                            <Typography className={classes.timestamp}>
                                                {formatDate(msg.created_at)}
                                                {msg.data.message_status && <span className={`${classes.statusIcon} ${msg.data.message_status}`}>
                                                    {getStatusIcon(msg.data.message_status)}
                                                </span>}
                                            </Typography>
                                            <MediaLinks mediaUrls={msg.data.media_urls || []} mediaTypes={msg.data.media_content_types || []} />
                                        </Box>
                                        {userCanDelete && !msg.deleted && !whatsAppMessage && (
                                            <Box onClick={() => handleDeleteMessage(msg.id)}>
                                                <TrashIcon/>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })
                        ) : (
                            <Typography>No chat selected.</Typography>
                        )}
                    </Box>
                    <Box className={classes.messageInput}>
                        {!selectedChat || canSendFreeform || selectedChat?.chattable_type !== 'WhatsAppContact' ? (
                            <>
                                <div
                                    className={classes.helpButton}
                                    onClick={() => setShowHelp(true)}
                                >
                                    <ExclamationIcon size={"1.5rem"}/>
                                    Help
                                </div>
                                <TextField
                                    placeholder="Type a message..."
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className={classes.inputField}
                                    onKeyPress={(e) =>
                                        e.key === "Enter" && handleSendMessage()
                                    }
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSendMessage()}
                                    className={classes.sendButton}

                                >
                                    Send
                                </Button>
                            </>
                        ) : (
                            <>
                                <Select
                                    value={selectedTemplate}
                                    onChange={handleTemplateChange}
                                    displayEmpty
                                    className={classes.templateSelect}
                                >
                                    <MenuItem value="" disabled>
                                        Select a Template
                                    </MenuItem>
                                    {!selectedChat.not_opted_in && messageTemplates?.map((template) => (
                                        <MenuItem key={template.name} value={template.name}>
                                            {template.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    placeholder={selectedChat.not_opted_in ? "NOT OPTED IN" : "Message preview"}
                                    value={message}
                                    disabled
                                    className={classes.inputField}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendWithTemplate}
                                    className={classes.sendButton}
                                    disabled={!selectedTemplate || selectedChat.not_opted_in}
                                >
                                    Send
                                </Button>
                            </>
                        )}
                    </Box>
                </>
            ) : (
                <HelpInstructionsAndRaiseHand
                    setShowHelp={setShowHelp}
                    chatData={selectedChat}
                />
            )}
        </Box>
    );
};

export default ChatView;
