import React from "react"
import {RoundsAvailability} from "./rounds_availability/rounds_availability";


export default function AvailabilityView(props) {
    const {timeZone, roundsAvailabilityProps, mentorAssignmentId, menteeEmail, mentorApproved} = props;

    return (
        <>
            <div className={'rounds-availability-view'}>
                <RoundsAvailability {...roundsAvailabilityProps} 
                timeZone={timeZone} 
                menteeEmail={menteeEmail} 
                mentorAssignmentId={mentorAssignmentId}
                mentorApproved={mentorApproved}/>
            </div>
        </>
    )

}
