import React, {useState} from "react"

import hero from '../../../assets/images/onboarding/superhero_acquaint.png';


const SuperHero = () =>  {
    return (
        <div>
            {/* Use the `<img>` tag to render the image */}
            <img src={hero} alt="hero" className={'coverage-hero-image'} />
        </div>
    );
}

const submitCoverage = async (advancedCoverage, lastMinuteCoverage) => {
    return fetch(`/persist_recruit_coverage`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            },
            body: JSON.stringify({
                user_profile: {
                    coverage_advanced: advancedCoverage,
                    coverage_last_minute: lastMinuteCoverage
                },
            })
        }
    );
};


const Checkbox = (props) => {

    let {checked, setCheckmarkState, label, description} = props

    return (
        <div className={'coverage-checkbox'}>
            <div>
                <label style={{
                    display: "inline-block",
                    width: 32,
                    height: 32,
                    border: "3px solid black",
                    borderRadius: 8,
                    backgroundColor: "white",
                    marginBottom: 4,
                    marginRight: 8
                }}>
                    <input type="checkbox" style={{ display: "none" }} onChange={(event) => {
                        const checkmark = event.target.nextSibling;
                        if (event.target.checked) {
                            checkmark.classList.remove('hidden')
                        } else {
                            checkmark.classList.add('hidden')
                        }
                        setCheckmarkState(event.target.checked)

                    }} checked={checked} />
                    <svg className={checked ? "" : "hidden"}
                                 width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.24 16.125l-4.475-4.475L5.24 9.325 9.24 13.325 17.76 4.825z"
                            stroke="green"
                            fill="green"
                            transform="translate(-5, -3) scale(1.577)"
                        />
                    </svg>
                </label>
            </div>
            <div>
                <span className={'coverage-option-label'}>
                    {label}
                </span>
                &nbsp;
                <span>
                    {description}
                </span>
            </div>
        </div>
    );
}

const CheckboxOptions = (props) => {
    let {
        advancedCoverage,
        setAdvancedCoverage,
        lastMinuteCoverage,
        setLastMinuteCoverage,
        notInterested,
        setNotInterested
    } = props

    let lastMinuteCoverageCheckboxChecked = (checked) => {
        setLastMinuteCoverage(checked)
        setNotInterested(false)
    }

    let advancedCoverageCheckboxChecked = (checked) => {
        setAdvancedCoverage(checked)
        setNotInterested(false)
    }

    let notInterestedCheckboxChecked = (checked) => {
        setLastMinuteCoverage(false)
        setAdvancedCoverage(false)
        setNotInterested(checked)
    }

    return (
        <div className={'coverage-checkbox-area'}>
            <div className={'advanced-coverage'}>
                <Checkbox checked={advancedCoverage}
                          label={'Pre-scheduled coverage:'}
                          description={'Commit to specific days and times during your entered availability when you can be assigned to do a session only if needed.'}
                          setCheckmarkState={advancedCoverageCheckboxChecked}
                />
            </div>
            <div className={'last-minute-coverage'}>
                <Checkbox checked={lastMinuteCoverage}
                          label={'Last-minute coverage:'}
                          description={'The Acquaint team may reach out to you at any time if a coverage volunteer is needed. You may say “yes” or “no” to the session - there is no pressure!'}
                          setCheckmarkState={lastMinuteCoverageCheckboxChecked}
                />
            </div>
            <div className={'coverage-options-divider'}>
                OR
            </div>
            <div className={'not-interested'}>
                <Checkbox checked={notInterested}
                          label={'Not interested:'}
                          description={'I’m not interested in being a part of the coverage team at this time.'}
                          setCheckmarkState={notInterestedCheckboxChecked}
                />
            </div>
        </div>
    )
}

export const RecruitCoverage = (props) => {
    const [advancedCoverage, setAdvancedCoverage] = useState(false)
    const [lastMinuteCoverage, setLastMinuteCoverage] = useState(false)
    const [notInterested, setNotInterested] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const submit = () => {
        setButtonDisabled(true);
        submitCoverage(advancedCoverage, lastMinuteCoverage).then((response) => {window.location = '/home';})
    }
    return (
        <div className={'coverage-view-container'}>
            <div className={'recruit-coverage-title'}>Join the Acquaint Coverage Team!</div>

            <SuperHero/>
            <div className={'coverage-description-section desktop-only-section'}>
                If a power outage or last minute emergency prevents you from attending a
                scheduled session, is your fellow volunteer left with nobody to talk to?
                No. But why is that? Answer: Coverage Volunteers!
            </div>
            <div className={'coverage-description-section'}>
                Acquaint Coverage Volunteers step in to do sessions if a scheduled volunteer
                is suddenly unable to attend. They are heroes within the community because
                they allow Acquaint to be more inclusive. Would you consider joining our awesome
                volunteer coverage team? If so, please select one or both of the following coverage volunteer options:
            </div>
            <CheckboxOptions
                setAdvancedCoverage={setAdvancedCoverage}
                setLastMinuteCoverage={setLastMinuteCoverage}
                setNotInterested={setNotInterested}
                advancedCoverage={advancedCoverage}
                lastMinuteCoverage={lastMinuteCoverage}
                notInterested={notInterested}
            />

            <button disabled={!(advancedCoverage || lastMinuteCoverage || notInterested) || buttonDisabled}
                    className={'basic-link-button on-table'}
                    onClick={submit}
            >
                Submit
            </button>
        </div>
    )
}

export default RecruitCoverage