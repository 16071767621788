// components/ChatList.jsx
import React, {useState} from 'react';
import {Drawer, List, ListItem, ListItemText, Badge, Box, Typography, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withTheme from "../with_theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 300,
        flexShrink: 0,
        height: '100%',
    },
    drawerPaper: {
        width: 300,
        height: '100%',
        color: theme.palette.text.primary,
    },
    chatListItem: {
        color: theme.palette.text.primary,
        alignItems: 'flex-start',
        borderRadius: theme.shape.borderRadius, // Rounded corners
        border: `1px solid ${theme.palette.divider}`, // Subtle, single border
        padding: theme.spacing(1.5), // Ensure padding inside the border is comfortable
        marginBottom: theme.spacing(0.5), // Reduce margin between items
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
            transform: 'scale(1.01)', // Subtle hover scaling
            boxShadow: `0 2px 4px ${theme.palette.divider}`, // Softer shadow on hover
        },
    },
    whatsappContact: {
        backgroundColor: theme.palette.info.light, // Light green background
        '&:hover': {
            backgroundColor: theme.palette.info.main,
        },
    },
    chatList: {

    },
    header: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.default,
    },
    chatBadge: {
        marginRight: theme.spacing(2),
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.error.main, // Red background for unread indicator
            color: theme.palette.common.white, // White text
        },
    },
    secondaryText: {
        color: theme.palette.text.primary,  // Ensure secondary text inherits primary text color
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
    },
    overflowText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    timestamp: {
        marginTop: theme.spacing(0.5),
    },
}));

const ChatList = ({ chatsData, selectedChat, handleSelectChat, mobileOpen, handleDrawerToggle, isMobile }) => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');

    const sortedChats = chatsData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const filteredChats = sortedChats.filter((chat) =>
        chat.title?.toLowerCase().includes(searchText?.toLowerCase())
    );


    const handleChatSelection = (chat) => {
        handleSelectChat(chat);

        // Update the URL query parameters
        const params = new URLSearchParams(window.location.search);
        params.set('chattable_id', chat.chattable_id);

        // Use the History API to replace the current URL with the updated query string
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    };

    return (
        <Drawer
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            variant={isMobile ? 'temporary' : 'permanent'}
            open={isMobile ? mobileOpen : true}
            onClose={handleDrawerToggle}
        >
            <Box className={classes.chatList}>
                {/* Header Section */}
                <ListItem className={classes.header}>
                    <ListItemText
                        primary={
                            <Typography variant="h6" color="textPrimary">
                                Select a Chat
                            </Typography>
                        }
                    />
                </ListItem>
                {!isMobile && <ListItem className={classes.header}>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search chats"
                        fullWidth
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </ListItem>}
                <List>
                    {filteredChats.map((chat) => (
                        <ListItem
                            button
                            key={chat.id}
                            className={`${classes.chatListItem} ${chat.chattable_type === 'WhatsAppContact' ? classes.whatsappContact : ''}`}
                            selected={selectedChat?.id === chat.id}
                            onClick={() => handleChatSelection(chat)}
                        >
                            <Badge
                                badgeContent={chat.unreadCount}
                                invisible={!chat.unreadCount}
                                className={classes.chatBadge}
                            >
                                🗨️ {/* Placeholder for chat icon */}
                            </Badge>
                            <ListItemText
                                className={classes.chatListItem}
                                primary={chat.title}
                                secondary={
                                    <div className={classes.secondaryText}>
                                        <Typography
                                            variant="body2"
                                            className={classes.overflowText}
                                        >
                                            {chat.name || "Unknown"}: {chat.content || chat.data?.body || "No messages yet."}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            className={classes.timestamp}
                                        >
                                            Last active: {new Date(chat.created_at).toLocaleString()}
                                        </Typography>
                                    </div>
                                }
                            />
                        </ListItem>
                    ))}
                </List>


            </Box>
        </Drawer>
    );
};

export default withTheme(ChatList);