import React from "react"
import TourImagesSelect from "./tour_images/TourImagesSelect"

export default class TourImages extends React.Component {

    render() {
      let options = this.props.options.map(v => {
        return {
          label: v.name,
          value: v.id
        }
      })
        return (
          <React.Fragment>
            <TourImagesSelect
                options={options} // options['tour_image_select']?
            />
          </React.Fragment>
        )
    }

}
