import React, { useEffect, useState } from "react";
import consumer from "../../channels/consumer";
import ChatInterface from "./chat_interface";

export const createChat = (message, chattable, type) => {
    fetch("/messages", {  // TODO need to add auth on controller...
        method: "POST",
        body: JSON.stringify({
            message: {
                content: message,
                chattable_id: chattable,
                chattable_type: type
            }
        }),
        headers: {
            "content-type": "application/json",
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
        }
    });
};
``
export const deleteChat = (chatId) => {
    return fetch(`/messages/${chatId}`, {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
        }
    }).then(response => response.json());
};

export const ChatSubscriber = (props) => {
    const { chatData } = props;
    const [allChats, setAllChats] = useState(chatData.chats);
    const [unreadMessages, setUnreadMessages] = useState(false);

    const appendMessage = (message) => {
        setUnreadMessages(Date.now());
        setAllChats(prevChats => [...prevChats, message]);
    };

    const removeMessage = (chatId) => {
        // const updatedChats = allChats.filter(chat => chat.id !== chatId);

        // Setting state with a new array to ensure immutability
        setAllChats(prevChats => prevChats.filter(chat => chat.id !== chatId)) //[...updatedChats]);
    };

    const initActionCable = () => {
        return consumer.subscriptions.create(
            {
                channel: "ChatChannel",
                chattable_id: chatData.chattable
            }, {
                connected() {
                    console.log(`connected to channel with chat ID: ${chatData.chattable}`);
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                async received(data) {
                    if (data.action === "delete") {
                        removeMessage(data.message_id);
                    } else {
                        appendMessage(data);
                        console.log("received: " + JSON.stringify(data));
                    }
                }
            });
    };

    useEffect(() => {
        console.log('subscribing to chat');
        const subscription = initActionCable();
        return () => {
            console.log('unsubscribing from chat');
            subscription.unsubscribe();
        };
    }, []);

    return (
        <ChatInterface
            {...props}
            unreadMessages={unreadMessages}
            setUnreadMessages={setUnreadMessages}
            chats={allChats}
            onSendMessage={(message) => {
                createChat(message, chatData.chattable, chatData.type);
            }}
            onDeleteMessage={(chatId) => {
                deleteChat(chatId).then(data => {
                    if (data.success) {
                        removeMessage(chatId);
                    } else {
                        alert("Failed to delete chat: " + data.errors.join(", "));
                    }
                });
            }}
        />
    );
};

export default ChatSubscriber;
