import React, { useState, useEffect } from "react";


const KbContent = (props) => {
    const KB_BASE_URL = "/kb_contents";
    const [content, setContent] = useState([]);
    const [embeddings, setEmbeddings] = useState([]);

    useEffect(() => {
        setContent(props.data);
        setEmbeddings(props.embedding);
    }, []);

    const goBack = () => {
        window.location.replace(KB_BASE_URL);
    }
    return (
        <>
            <div>
                <button onClick={() => goBack()} className="basic-link-button" >Go Back</button>
            </div>

            <div className="container mt-5" style={{ alignText: 'left' }}>

                <div className="mb-3">
                    <h3><b>{content.topic} : {content.category} </b></h3> 
                </div>

                {embeddings.map((embedding) => (
                    <div style={{ textAlign: 'left', padding: "10px" }} key={embedding.id} className="card">{embedding.content_text}</div>
                ))}
            </div>
        </>
    );
};

export default KbContent;