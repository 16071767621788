import React from "react"
import Tour from './Tour'
import VisualAssistModal from "./VisualAssistModal";
import {FeaturedTour} from "./featured_tour/featured_tour";

export default class TourBrowser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props, modalVisible: false};
    }

    callCreateTour = async () => {
        return await fetch("tours", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                }
            }
        );
    };

    createAndAppendTour = async () => {
        let tourResponse = await this.callCreateTour();
        let createdTour = await tourResponse.json();

        if (this.state.tours.length !== 0) {
            this.setState({tours: [...this.state.tours, createdTour]});
        } else {
            this.setState({tours: [createdTour]});
        }
    };

    callDeleteTour = async (id) => {
        return await fetch(`tours/${id}`, {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                }
            }
        );
    };

    deleteAndRemoveTour = async (id) => {
        if (!confirm("Are you sure you want to delete this tour?")) {
            return
        }
        let response = await this.callDeleteTour(id);
        if (response.ok) {
            this.setState({tours: this.state.tours.filter(tour => tour.id !== id)});
        } else {
            // TODO: error handling... ?
        }
    };

    editTour = async (id) => {
        window.location = `tours/${id}`;
    };

    showModal = (selectedTourId, selectedTourName) => {
        this.setState({
            modalVisible: true,
            modalTourId: selectedTourId,
            modalTourName: selectedTourName
        });
    };

    hideModal = () => {
        this.setState({
            modalVisible: false,
            modalTourId: null,
            modalTourName: null
        });
    };


    render() {
        let {tours, isEdit} = this.state;
        const {currentUser, permissions, isVolunteer} = this.props;

        let toursList = tours
            .sort((a, b) => {
                return (a.name > b.name) ? 1 : -1
            })
            .map((tour) =>
                <li key={tour.id}>
                    <Tour
                        isEdit={isEdit}
                        name={tour.name}
                        isOwner={tour.user_id === currentUser || permissions === 'manager'}
                        description={tour.description}
                        thumbnail={this.props.tourThumbnails[tour.id]}
                        onDeleteClicked={() => this.deleteAndRemoveTour(tour.id)}
                        onEditClicked={() => this.editTour(tour.id)}
                        visualAssist={this.props.visualAssist || false}
                        // onSelected={() => this.showModal(tour.id, tour.name)}
                        tourId={tour.id}
                        isVolunteer={isVolunteer}
                    />
                </li>
            );

        if (toursList.length === 0) {
            toursList = "No Tours"
        }

        if (isEdit) {
            return (
                <div>
                    <ul>
                        {toursList}
                    </ul>
                    <button onClick={this.createAndAppendTour}>Add Tour</button>
                </div>
            )
        } else {
            return (
                <div>
                    <h3 class={'mx-2'}>You are in the traveler role. Please choose an experience to start the session.</h3>
                    {this.props.featuredTour && <FeaturedTour
                        name={this.props.featuredTour.name}
                        tourId={this.props.featuredTour.id}
                        visualAssist={this.props.visualAssist || false}
                        thumbnail={this.props.tourThumbnails[this.props.featuredTour.id]}
                        description={this.props.featuredTourDescription}
                    />}
                    <ul>
                        {toursList}
                    </ul>
                    <VisualAssistModal
                        tourId={this.state.modalTourId}
                        tourName={this.state.modalTourName}
                        isOpen={this.state.modalVisible}
                        onClose={this.hideModal}
                    />
                </div>
            )
        }
    }
}
