import React, { useState } from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from "@material-ui/core";
import {EventList} from "./EventList";
import ParticipantEventFilter from "./ParticipantEventFilter";
import AddParticipant from "./AddParticipant";
import API from "./api";

// Define your styles here
const useStyles = makeStyles({
    dashboardContainer: {
        margin: '20px',
    },
    table: {
        minWidth: 650,
        marginTop: '20px',
        marginBottom: '20px',
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
    },
    button: {
        margin: '5px',
    },
});

const Dashboard = ({ hideNames, experimentId, experimentWorkflow, experimentName, experimentParticipants, volunteerOptions, experimentEvents }) => {
    const classes = useStyles();
    const [experimentParticipantsState, setExperimentParticipantsState] = useState(experimentParticipants);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showEvents, setShowEvents] = useState(false);
    const [events, setEvents] = useState(experimentEvents); // Populate this array with your events data

    const availableVolunteerOptions = volunteerOptions.filter(volunteerOption =>
        !experimentParticipantsState.some(participant =>
            participant.user.id === volunteerOption.value && participant.state !== 'removed'
        )
    );
    const handleAddExperimentParticipant = async () => {
        if (selectedUser && !experimentParticipantsState.find(p => p.user_id === selectedUser.value)) {
            try {
                const newParticipantResponse = await API.addExperimentParticipant(experimentId, selectedUser.value);
                const newParticipant = {
                    ...newParticipantResponse,
                    user: {
                        id: selectedUser.value,
                        email: selectedUser.label
                    }
                };
                setExperimentParticipantsState([...experimentParticipantsState, newParticipant]);
            } catch (error) {
                console.error('Error adding participant:', error);
                alert(error.message);
            }
        }
    };

    const handleRemoveExperimentParticipant = async (participantId) => {
        try {
            await API.markParticipantAsRemoved(experimentId, participantId);
            setExperimentParticipantsState(experimentParticipantsState.map(participant => {
                if (participant.id === participantId) {
                    return { ...participant, state: 'removed' };
                }
                return participant;
            }));
        } catch (error) {
            console.error('Error removing participant:', error);
            alert(error.message);
        }
    };

    const handleInviteExperimentParticipant = async (experimentParticipantId) => {
        try {
            const updatedParticipant = await API.inviteExperimentParticipant(experimentParticipantId);
            // Direcupdate the state of the participant to 'invited' without needing the full updated object
            const updatedParticipants = experimentParticipantsState.map(participant => {
                if (participant.id === experimentParticipantId) {
                    return { ...participant, state: 'invited' }; // Update only the state
                }
                return participant;
            });
            setExperimentParticipantsState(updatedParticipants);
            alert('Invitation sent successfully.');
        } catch (error) {
            console.error('Failed to invite participant:', error.message);
            alert(error.message);
        }
    };



    return (
        <div className={classes.dashboardContainer}>
            <h2>Experiment {experimentName} Dashboard</h2>
            {/* Participants table */}
            <table className={classes.table}>
                <thead className={classes.tableHeader}>
                <tr>
                    <th>User</th>
                    <th>State</th>
                    <th>Group</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {experimentParticipantsState.filter(participant => participant.state !== 'removed').map(participant => (
                    <tr key={participant.id}>
                        <td>{(hideNames ? participant.user?.masked_name : participant.user?.email)}</td>
                        <td>{participant.state}</td>
                        <td>{participant.group}</td>
                        <td>
                            {participant.state === 'new' && <button className={classes.button} onClick={() => handleInviteExperimentParticipant(participant.id)}>Invite</button>}
                            <button className={classes.button} onClick={() => handleRemoveExperimentParticipant(participant.id)}>Remove</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {/* Volunteer options for adding new participants */}
            <AddParticipant
                volunteerOptions={availableVolunteerOptions}
                hideNames={hideNames}
                onParticipantSelected={(selection) => setSelectedUser(selection)}
                onAddParticipant={handleAddExperimentParticipant}
            />
            <ParticipantEventFilter
                hideNames={hideNames}
                experimentParticipants={experimentParticipantsState}
                experimentEvents={events}
            />
        </div>
    );
};

export default Dashboard;
