import React from "react";
import DeputyTable from "./deputy_table/deputy_table";

const DeputyView = ({ rows, current_user_id }) => {
  return (
    <React.Fragment>
      <DeputyTable rows={rows} current_user_id={current_user_id} />
    </React.Fragment>
  );
};

export default DeputyView;
