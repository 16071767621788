import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    input: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
    },
    homeButtonContainer: {
        margin: 'auto',
        width: '100%',
    }
}));

export default function VolunteerCancel({ sessionId, formattedSessionTime, sessionTimeLeft, sessionPast, cancelStatus }) {
    const classes = useStyles();
    const [messageFromVolunteer, setMessageFromVolunteer] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [status, setStatus] = useState(cancelStatus);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const response = await fetch(`/scheduled_sessions/${sessionId}/volunteer_cancel`, {
            method: 'POST',
            body: JSON.stringify({ message_from_volunteer: messageFromVolunteer }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
            }
        });

        if (response.ok) {
            setResponseMessage("Thanks for submitting your cancellation message. We aren't always able to get to these messages prior to the session, so you may still receive some reminders. However, we really appreciate you letting us know!");
            setSnackbarOpen(true);
            setMessageFromVolunteer('');
            setOpenDialog(false);
            setStatus('submit');
        } else {
            const errorText = await response.text();
            setResponseMessage(`Error: An error occurred while submitting your message, please message us directly!`)
            setSnackbarOpen(true);
            setOpenDialog(false);
            setIsSubmitting(false);
        }
    };

    return (
        <div className={classes.container}>
            {status === 'new' && (
                <>
                    <h1>Message Us to Cancel</h1>
                    <h2>Session Details:</h2>
                    <p>Scheduled for: {formattedSessionTime}</p>
                    {sessionPast ? (
                        <>
                            <p>This session has already started or passed. Please contact us directly:</p>
                            <p><a href="https://wa.me/13607975214" target="_blank">Message us on WhatsApp</a> or email us at <a href="mailto:support@acquaint.org">support@acquaint.org</a>.</p>
                            <p>Please note, email messages can take us a day or so to read.</p>
                        </>
                    ) : (
                        <>
                            {sessionTimeLeft < 24 && sessionTimeLeft >= 1 && (
                                <p><b>Please note:</b> we may not be able to act on cancellation requests that are made less than 24 hours in advance, but we appreciate you letting us know. If possible, please request cancellation more than 24 hours in advance.</p>
                            )}
                            <TextField
                                label="Please send a message and we will do our best to get the schedule updated."
                                variant="outlined"
                                value={messageFromVolunteer}
                                onChange={(e) => setMessageFromVolunteer(e.target.value)}
                                className={classes.input}
                                multiline
                                rows={4}
                                fullWidth
                                required
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => setOpenDialog(true)}
                                disabled={sessionPast || isSubmitting}
                            >
                                Send message
                            </Button>
                            <p><em>Messaging us helps us understand your needs as a volunteer and further improve the system. Notifying us of cancellations in advance or avoiding cancellations entirely is very helpful for the smooth operating of Acquaint. Please note: frequent cancellations may limit the number of sessions we are able to assign volunteers.</em></p>
                        </>
                    )}
                </>
            )}
            {status === 'submit' && (
                <>
                    <p>Thank you, we have received your cancellation message for this session scheduled on {formattedSessionTime} and will get back to you if possible. Please note you may still receive reminders for this session. If you canceled this in error, please message us via <a href="https://wa.me/13607975214" target="_blank">WhatsApp</a> or <a href="mailto:support@acquaint.org">email</a>, and we will get back to you if possible!</p>
                </>
            )}
            {status === 'complete' && (
                <>
                    <p>We have removed you from this session scheduled on {formattedSessionTime}. Please let us know if you think this was in error via <a href="https://wa.me/13607975214" target="_blank">WhatsApp</a> or <a href="mailto:support@acquaint.org">email</a>.</p>
                </>
            )}
            <Snackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={responseMessage}
                autoHideDuration={6000}
            />
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="confirm-cancel-title"
                aria-describedby="confirm-cancel-description"
            >
                <DialogTitle id="confirm-cancel-title">{"Confirm Cancellation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-cancel-description">
                        Are you sure you want to cancel this session?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.homeButtonContainer}>
                <a href={'/home'} className={'home-link-button'}>Go Home</a>
            </div>
        </div>
    );
}
