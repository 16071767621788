import React from "react"
import Select from 'react-select'

// These are basically all the same... not sure if we should just have a generic select...
export default class QuizSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: props.initialValue || null
        };
    }

    setValue = (selection) => {
        this.setState({ selection });
    };

    render = () => {
        let { options, name } = this.props;
        return (
            <div className={'mb-2'}>
                <Select
                    defaultValue={this.props.initialValue}
                    options={options}
                    onChange={this.setValue}
                    name={name}
                />
                {/* Display additional info if needed */}
            </div>
        )
    }
}
