import React from 'react';

import UserTable from "./user_table";


export default {title: 'User Table'};

const rows1 = [
    {
        email: "alex@homeagainvr.org",
        confirmed: false,
        volunteer: true,
        tour_builder: true,
        facilitator: true,
        qualifications: ['some_qualification'],
        links: [
            {
                label: 'edit',
                url: 'www.example.com'
            }
        ]
    }
]

export const theTable = () => (<UserTable rows={rows1}/>);
