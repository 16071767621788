import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "600px",
        margin: "0 auto",
        textAlign: "center",
    },
    spinnerWrapper: {
        display: "inline-block",
        width: "7rem",
        height: "7rem",
        overflow: "visible",
        position: "relative",
        marginTop: "1.5rem",
    },
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    description: {
        fontSize: "16px",
        marginBottom: theme.spacing(2),
        color: "#555",
    },
    phoneInfo: {
        marginBottom: theme.spacing(1),
        "& strong": {
            textDecoration: "underline",
            display: "block",
            marginBottom: theme.spacing(1),
        },
        "& small": {
            display: "block",
            fontSize: "14px",
            color: "#888",
        },
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        marginBottom: theme.spacing(4),
    },
    checkbox: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        "& input": {
            display: "none",
        },
        "& label": {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        },
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        border: "none",
        padding: theme.spacing(1.5, 3),
        borderRadius: "8px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s",
        "&:disabled": {
            backgroundColor: "#ccc",
        },
        "&:hover:not(:disabled)": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const updateWhatsAppPreference = async (status) => {
    const url = new URL("/home/update_whatsapp_preference", window.location.origin);
    url.searchParams.append("whatsapp_notification_status", status);

    return fetch(url.toString(), {
        method: "GET",
        headers: {
            "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
        },
    });
};


const WhatsAppPreference = ({ initialStatus, phoneNumber, countryName }) => {
    const classes = useStyles();
    const [status, setStatus] = useState(initialStatus);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setButtonDisabled(true);
        setLoading(true);
        updateWhatsAppPreference(status)
            .then(() => {
                window.location = "/home";
            })
            .catch(() => {
                alert("An error occurred. Please try again.");
                setButtonDisabled(false);
                setLoading(false);
            });
    };

    const Checkbox = ({ value, label }) => (
        <div className={classes.checkbox}>
            <label>
                <input
                    type="radio"
                    name="whatsapp_notification_status"
                    value={value}
                    checked={status === value}
                    onChange={() => setStatus(value)}
                />
                <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="26"
                        height="26"
                        rx="4"
                        fill={status === value ? "#4caf50" : "#ccc"}
                    />
                    {status === value && (
                        <path
                            d="M9.5 16l-3.5-3.5L7 11l2.5 2.5L18 6l1.5 1.5L9.5 16z"
                            fill="white"
                        />
                    )}
                </svg>
                {label}
            </label>
        </div>
    );

    return (
        <div className={classes.container}>
            {loading && (
            <div className={classes.overlay}>
                <div className={classes.spinnerWrapper}>
                    <div className="acquaint-spinner" />
                </div>
            </div>
            )}

            <h1 className={classes.title}>Get WhatsApp Notifications?</h1>
            <p className={classes.description}>
                Please select "Yes" below if you wish to receive WhatsApp reminders from us.
            </p>

            <div className={classes.phoneInfo}>
                <strong>Are your phone number and country correct?</strong>
                <div><b>{countryName}</b> {phoneNumber}</div>
                <small>
                    If not, <a href="/my_profile">click here to update it</a>.
                </small>
            </div>

            <div className={classes.checkboxContainer}>
                <Checkbox value="yes" label="Yes, I want to receive WhatsApp notifications" />
                <Checkbox value="no" label="No, I do not want WhatsApp notifications" />
                <Checkbox value="no_whatsapp" label="I don’t use WhatsApp" />
            </div>

            <button
                disabled={!status || buttonDisabled}
                className={classes.button}
                onClick={handleSubmit}
            >
                Save Preference
            </button>
        </div>
    );
};

export default WhatsAppPreference;
