// app/javascript/components/AiTrainingSelect.jsx
import React from 'react';
import Select from 'react-select';

export default class AiTrainingSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: props.initialValue || null
        };
    }

    setValue = (selection) => {
        this.setState({ selection });
        if (this.props.onChange) {
            this.props.onChange(selection);
        }
    };

    render() {
        const { options, name } = this.props;
        return (
            <div className={'form-group'}>
                <Select
                    defaultValue={this.state.selection}
                    options={options}
                    onChange={this.setValue}
                    name={name}
                />
            </div>
        );
    }
}
