import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { Tooltip } from "@material-ui/core";

const PairingsTable = ({ sortedPairings, unpairableVolunteers, round }) => {
    const [docOpen, setDocOpen] = useState(false);
    const [autopilot, setAutopilot] = useState(round.autopilot);
    const [actionsOpen, setActionsOpen] = useState(false);

    const handleAutopilotToggle = () => {
        const newAutopilotStatus = !autopilot;

        fetch("/volunteer_intelligence/update_autopilot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            },
            body: JSON.stringify({
                id: round.id,
                autopilot: newAutopilotStatus
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setAutopilot(data.autopilot);
                } else {
                    alert("Failed to update autopilot status: " + data.errors.join(", "));
                }
            })
            .catch(error => {
                console.error("Error updating autopilot status:", error);
                alert("An error occurred while updating the autopilot status.");
            });
    };

    const getRowStyle = (menteeStatus, currentlyPaired, suggestedPairing) => {
        let style = {};

        // Determine border color based on pairing status
        if (currentlyPaired && suggestedPairing) {
            // Both currently paired and suggested
            style = { border: "2px solid #ffeb3b", backgroundColor: "#e8f5e9" }; // Yellow border with light green background
        } else if (currentlyPaired) {
            style = { border: "2px solid #ffeb3b" }; // Yellow border for currently paired
        } else if (suggestedPairing) {
            style = { border: "2px solid #4caf50" }; // Green border for suggested pairings
        }

        // Set background color based on mentee status
        switch (menteeStatus) {
            case 0:
                style.backgroundColor = style.backgroundColor || "#e0f7fa"; // Light blue for Traveler Mentoring
                break;
            case 1:
                style.backgroundColor = style.backgroundColor || "#f3e5f5"; // Light purple for Guide Mentoring
                break;
            default:
                style.backgroundColor = style.backgroundColor || "#e8eaf6"; // Light grey for Non-Mentoring
        }
        return style;
    };

    const getScoreColor = (score) => {
        return score > 0 ? "green" : "black";
    };

    const getMenteeStatusLabel = (menteeStatus) => {
        switch (menteeStatus) {
            case 0:
                return "Traveler Mentoring";
            case 1:
                return "Guide Mentoring";
            default:
                return "Non-Mentoring";
        }
    };

    const getRoleLabel = (role) => {
        switch (role) {
            case "mentor":
                return "(Mentor)";
            case "traveler_mentee":
                return "(Mentee)";
            case "guide_mentee":
                return "(Mentee)";
            default:
                return "";
        }
    };

    return (
        <TableContainer component={Paper}>
            <Box marginBottom={2}>
                <Typography variant="h6" gutterBottom>
                    Autopilot Mode
                </Typography>
                <Switch
                    checked={autopilot}
                    onChange={handleAutopilotToggle}
                    color="primary"
                />
            </Box>
            <Box marginBottom={2}>
                <Button onClick={() => setDocOpen(!docOpen)} variant="outlined">
                    {docOpen ? "Hide Documentation" : "Show Documentation"}
                </Button>
                <Collapse in={docOpen} timeout="auto" unmountOnExit>
                    <Box marginTop={2} padding={2} bgcolor="#f5f5f5">
                        <Typography variant="h6" gutterBottom>
                            Score Documentation
                        </Typography>
                        <Typography paragraph>
                            Below are the details of how each score is calculated for pairings:
                        </Typography>
                        <Typography paragraph>
                            <strong>Role Balance Score:</strong> Penalty of <strong>-10</strong> applied if both volunteers have been in the same role for the last <strong>3</strong> sessions.
                        </Typography>
                        <Typography paragraph>
                            <strong>Country Difference Score:</strong> Bonus of <strong>+10</strong> if volunteers are from different countries.
                        </Typography>
                        <Typography paragraph>
                            <strong>Recent Pairings Score:</strong> Penalty applied based on recent pairings within the last <strong>5</strong> sessions:
                            <ul>
                                <li>Penalty of <strong>-25</strong> if paired once.</li>
                                <li>Penalty of <strong>-50</strong> if paired twice.</li>
                            </ul>
                        </Typography>
                        <Typography paragraph>
                            <strong>Same Country Pairing Score:</strong> Penalty applied based on recent pairings with the same country:
                            <ul>
                                <li><strong>-5</strong> for 1 recent pairing</li>
                                <li><strong>-10</strong> for 2 recent pairings</li>
                                <li><strong>-15</strong> for 3 recent pairings</li>
                                <li><strong>-20</strong> for 4 recent pairings</li>
                            </ul>
                        </Typography>
                        <Typography paragraph>
                            <strong>Successful Sessions Score:</strong> Bonus of <strong>+3</strong> points per successful session for non-mentees over the last <strong>10</strong> sessions.
                        </Typography>
                        <Typography paragraph>
                            <strong>Mentoring Sessions Penalty:</strong> Penalty of <strong>-3</strong> points per mentoring session in the last <strong>10</strong> sessions for non-mentees.
                        </Typography>
                        <Typography paragraph>
                            <strong>Mentee Pairing Penalty:</strong> Penalty applied if the mentee's partner is not a mentor:
                            <ul>
                                <li><strong>-100</strong> for traveler mentee</li>
                                <li><strong>-50</strong> for guide mentee</li>
                            </ul>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box marginBottom={2}>
                <Button onClick={() => setActionsOpen(!actionsOpen)} variant="outlined">
                    {actionsOpen ? "Hide Actions JSON" : "Show Actions JSON"}
                </Button>
                <Collapse in={actionsOpen} timeout="auto" unmountOnExit>
                    <Box marginTop={2} padding={2} bgcolor="#f5f5f5">
                        <Typography variant="h6" gutterBottom>
                            Actions JSON
                        </Typography>
                        <pre>
                            {JSON.stringify(round.actions, null, 2)}
                        </pre>
                    </Box>
                </Collapse>
            </Box>
            <Box padding={2}>
                <Typography variant="h6" gutterBottom>
                    Unpairable Volunteers
                </Typography>
                {unpairableVolunteers.length > 0 ? (
                    <ul>
                        {unpairableVolunteers.map((volunteer) => (
                            <li key={volunteer.id}>{volunteer.email}</li>
                        ))}
                    </ul>
                ) : (
                    <Typography>No unpairable volunteers found.</Typography>
                )}
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Volunteer 1</TableCell>
                        <TableCell>Volunteer 2</TableCell>
                        <TableCell>Total Score</TableCell>
                        <TableCell>Mentee Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedPairings.map((pairing, index) => (
                        <ExpandableRow
                            key={index}
                            pairing={pairing}
                            rowStyle={getRowStyle(pairing.mentee_status, pairing.currently_paired, pairing.suggested_pairing)}
                            getScoreColor={getScoreColor}
                            getMenteeStatusLabel={getMenteeStatusLabel}
                            getRoleLabel={getRoleLabel}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ExpandableRow = ({ pairing, rowStyle, getScoreColor, getMenteeStatusLabel, getRoleLabel }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow style={rowStyle}>
                <TableCell>
                    <Button onClick={() => setOpen(!open)} size="small">
                        {open ? "▲ Collapse" : "▼ Expand"}
                    </Button>
                </TableCell>
                <TableCell>
                    {pairing.volunteer1.email} {getRoleLabel(pairing.volunteer1.mentoring_role)}
                </TableCell>
                <TableCell>
                    {pairing.volunteer2.email} {getRoleLabel(pairing.volunteer2.mentoring_role)}
                </TableCell>
                <TableCell style={{ color: getScoreColor(pairing.total_score) }}>
                    {pairing.total_score}
                </TableCell>
                <TableCell>{getMenteeStatusLabel(pairing.mentee_status)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Score Details
                            </Typography>
                            <Table size="small" aria-label="scores">
                                <TableHead>
                                    <TableRow>
                                        <Tooltip title="Penalty for same role in recent sessions">
                                            <TableCell>Role Balance Score</TableCell>
                                        </Tooltip>
                                        <Tooltip title="Bonus for different countries">
                                            <TableCell>Country Difference Score</TableCell>
                                        </Tooltip>
                                        <Tooltip title="Penalty for recent pairings">
                                            <TableCell>Recent Pairings Score</TableCell>
                                        </Tooltip>
                                        <Tooltip title="Penalty for same-country pairings">
                                            <TableCell>Same Country Pairing Score</TableCell>
                                        </Tooltip>
                                        <Tooltip title="Bonus for successful sessions">
                                            <TableCell>Successful Sessions Score</TableCell>
                                        </Tooltip>
                                        <Tooltip title="Penalty for mentoring sessions">
                                            <TableCell>Mentoring Sessions Penalty</TableCell>
                                        </Tooltip>
                                        <Tooltip title="Penalty for pairing with non-mentor">
                                            <TableCell>Mentee Pairing Penalty</TableCell>
                                        </Tooltip>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.role_balance_score) }}>
                                            {pairing.scores.role_balance_score}
                                        </TableCell>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.country_difference_score) }}>
                                            {pairing.scores.country_difference_score}
                                        </TableCell>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.recent_pairings_score) }}>
                                            {pairing.scores.recent_pairings_score}
                                        </TableCell>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.same_country_pairing_score) }}>
                                            {pairing.scores.same_country_pairing_score}
                                        </TableCell>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.successful_sessions_score) }}>
                                            {pairing.scores.successful_sessions_score}
                                        </TableCell>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.mentoring_sessions_penalty) }}>
                                            {pairing.scores.mentoring_sessions_penalty}
                                        </TableCell>
                                        <TableCell style={{ color: getScoreColor(pairing.scores.mentee_pairing_penalty) }}>
                                            {pairing.scores.mentee_pairing_penalty}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PairingsTable;
