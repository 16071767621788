
import React, { useState, useEffect } from 'react';
import Select from 'react-select'

const NewContent = (props) => {

    const KB_BASE_URL = "/kb_contents";
    //   const KB_BASE_VIEW_URL = "/kb_contents/show";
    const KB_TOKEN_URL = "/kb_contents/get_token_count";
    const KB_UPDATE_URL = "/kb_contents/update_data";
    const KB_SETTINGS_URL = "/kb_contents/settings";
    const KB_SETTING_NAME = "kb_openai_settings";

    const [topic, setTopic] = useState("");
    const [category, setCategory] = useState("Default");
    const [categoryArray, setCategoryArray] = useState([]);
    const [status, setStatus] = useState("Private");
    const [statusArray, setStatusArray] = useState([]);
    const [sections, setSections] = useState([]);
    const [tokenCount, setTokenCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const id = props.data?.id;

    const token = document.querySelector('meta[name="csrf-token"]').content;

    const isEdit = (Object.keys(props).length === 0) ? false : true;

    useEffect(() => {

        // Add new Content
        if (Object.keys(props).length !== 0) {
            setTopic(props.data.topic);
            setCategory(props.data.category);

            setStatus(props.data.status);
            const contents = props.embedding.map(obj => obj.content_text);
            setSections(contents);
        }
        // Update data
        else {
            setSections([...sections, '']);
            setTokenCount([...tokenCount, 0]);
        }

        // Define a function to fetch data from the API endpoint
        const body = {
            "name": KB_SETTING_NAME
        };

        // get the setup data ( for the drop downs )
        const fetchData = async () => {
            try {
                const response = await fetch(KB_SETTINGS_URL
                    , {
                        method: "POST",
                        headers: {
                            "X-CSRF-Token": token,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body),
                    }
                ).then((response) => {
                    return response.json();
                })
                    .then((response) => {
                        const parsedData = JSON.parse(response.data);
                        setCategoryArray(parsedData.category.split(','));
                        setStatusArray(parsedData.status.split(','));
                    })
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        // Call the fetchData function
        fetchData();
    }, []); // The empty array ensures that this effect runs once after the initial render


    const currentTokenCount = (index, newSection) => {
        const body = {
            "content_data": newSection
        };

        fetch(KB_TOKEN_URL, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const newTokenCount = [...tokenCount];
                newTokenCount[index] = data.data;
                setTokenCount(newTokenCount);
            })
    }

    const handleAddSection = () => {
        setSections([...sections, '']);
        setTokenCount([...tokenCount, 0]);
    };

    const handleEditSection = (index, updatedSection) => {

        const updatedSections = [...sections];
        updatedSections[index] = updatedSection;
        currentTokenCount(index, updatedSection);
        setSections(updatedSections);
    };

    const handleDeleteSection = (index) => {
        const updatedSections = sections.filter((_, i) => i !== index);
        setSections(updatedSections);
        const newTokenCount = [...tokenCount];
        newTokenCount.splice(index, 1);
        setTokenCount(newTokenCount);

    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (category.trim() === "" || status.trim() === "" || topic.trim() === "") {
            alert("Category, Status, and Topic are mandatory fields.");
            return;
        }

        // Check if all sections are non-empty
        if (sections.some(section => section.trim() === "")) {
            alert("Sections cannot be empty.");
            return;
        }
        const body = {

            "topic": topic,
            "category": category,
            "status": status,
            "sections": sections.filter(s => s.trim() !== "")
        };
        if (isEdit) {
            body.id = id;
        }


        fetch((!isEdit ? KB_BASE_URL : KB_UPDATE_URL), {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Network response was not ok.");
            })
            .then((response) => window.location.replace("/kb_contents"))
            .catch((error) => console.log(error.message));
    };

    return (
        <div className="container mt-5" style={{ textAlign: 'left' }}>
            <div className="row">
                <div className="col">

                    <label style={{ float: 'left', fontWeight: 'bold', color: '#ed863d' }} htmlFor="category">Select a Category  :</label>
                    <div>
                        <Select id="category"  style={{ height: '300px'}}
                            value={category ? { value: category, label: category } : null}
                            onChange={selectedCategory => setCategory(selectedCategory.value)}
                            options={categoryArray.map(option => ({ value: option, label: option }))} />
                    </div>
                </div>
                <div className="col">

                    <label style={{ float: 'left', fontWeight: 'bold', color: '#ed863d' }} htmlFor="category">Select availability  :</label>
                    <div>
                        <Select id="category" style={{ height: '300px'}}
                            value={status ? { value: status, label: status } : null}
                            onChange={selectedStatus => setStatus(selectedStatus.value)}
                            options={statusArray.map(option => ({ value: option, label: option }))} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label style={{ float: 'left', fontWeight: 'bold', color: '#ed863d' }} htmlFor="topic">Enter Topic:</label>
                <input
                    type="text"
                    name="topic"
                    placeholder=""
                    id="topic"
                    className="form-control"
                    value={topic}
                    required
                    onChange={(e) => setTopic(e.target.value)}
                />
            </div>

            <div className="form-group">
                <label style={{ fontWeight: 'bold', color: '#ed863d' }} htmlFor="topic">Enter Sections:</label>
            </div>

            {sections.map((section, index) => (
                <div className="row mb-3" key={index}>
                    <div className="col">
                        <textarea
                            className="form-control"
                            rows="4"
                            value={section}
                            onChange={(e) => handleEditSection(index, e.target.value)}
                        />
                        <button className="xbasic-link-button" onClick={() => handleDeleteSection(index)}>
                            Delete Section
                        </button>

                        <span className="badge bg-secondary" style={{ float: 'right' }}>Token Count: {tokenCount[index]}</span>
                        <hr></hr>
                    </div>
                </div>
            ))}

            <div className="row mb-3 justify-content-between">
                <div className="col">
                    <button className="xbasic-link-button" style={{ float: 'left' }} onClick={handleAddSection}>
                        Add more Sections
                    </button>
                </div>


                <div className="col">
                    <button className="xbasic-link-button" style={{ float: 'right' }} onClick={handleSubmit}>
                        Save all Sections
                    </button>
                </div>
            </div>


        </div>

    );
};

export default NewContent;

