import React, {useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import {getCountryName} from "../utils/iso2_lookup";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    clickableCell: {
        display: 'flex',
    }
});

function getMostRecentScheduledAt(filteredSessions) {
    let mostRecentScheduledAt;
    if (filteredSessions.length > 0) {
        const scheduledAtValues = filteredSessions.map(session => new Date(session.scheduled_at).valueOf());
        const maxScheduledAt = Math.max(...scheduledAtValues);
        if (!isNaN(maxScheduledAt)) {
            mostRecentScheduledAt = new Date(maxScheduledAt).toISOString();
        } else {
            console.error('Unable to determine most recent scheduled_at value.');
        }
    } else {
        console.error('No sessions found.');
    }
    return mostRecentScheduledAt;
}

export default function IntlPhoneInput(props) {
    const classes = useStyles();
    let { rows, email  } = props
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // First, filter out sessions where role === 'coverage'
    const filteredSessions = rows.filter(session => session.role === 'coverage');

    // Next, count the filtered sessions
    const sessionCount = filteredSessions.length;

    // Finally, find the most recent scheduled_at date
    const mostRecentScheduledAt = getMostRecentScheduledAt(filteredSessions);

    return (
        <>
            <div><b>Sessions for User: {email}</b></div>
            <div></div>
            <div>Coverage sessions</div>
            <div>Total Coverage Sessions: {sessionCount}</div>
            <div>Most recent session: {mostRecentScheduledAt}</div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Scheduled At</TableCell>
                            <TableCell align="right">Role</TableCell>
                            <TableCell align="right">Partner</TableCell>
                            <TableCell align="right">Partner Country</TableCell>
                            <TableCell align="right">Total Time</TableCell>
                            <TableCell align="right">ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.session_id}>
                                <TableCell align="right">{row.scheduled_at}</TableCell>
                                <TableCell align="right">{row.role}{row.mentoring ? " (mentoring)" : ""}</TableCell>
                                <TableCell align="right">{row.partner_email}</TableCell>
                                <TableCell align="right">{getCountryName(row.partner_country_iso2)}</TableCell>
                                <TableCell align="right">{row.total_time}</TableCell>
                                <TableCell align="right">{row.session_id}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}