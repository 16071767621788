import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CourseImage from './course_image';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%', // Set a fixed width for the card
        maxWidth: 345, // Set a max width for the card
        height: 400, // Set a fixed height for the card
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer', // Make the card look clickable
        textDecoration: 'none', // Remove underline from the link
        color: 'inherit', // Inherit the color to maintain the card style
    },
    content: {
        flexGrow: 1, // Ensure the content area grows to fill remaining space
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        overflow: 'hidden', // Hides overflow text
        textOverflow: 'ellipsis', // Adds ellipsis (...) for overflow text
        display: '-webkit-box',
        WebkitLineClamp: 2, // Limits the text to 2 lines
        WebkitBoxOrient: 'vertical',
    },
    imageContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        height: 200, // Set a fixed height for the image container
        margin: 'auto',
    },
}));

const MicrocourseCard = ({ microcourse }) => {
    const classes = useStyles();
    const { name, image_url, course_url, completed } = microcourse;

    return (
        <a href={course_url} className={classes.card}>
            <Card className={classes.card}>
                <div className={classes.imageContainer}>
                    <CourseImage imageUrl={image_url} completed={completed} />
                </div>
                <CardContent className={classes.content}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                    {name}
                </Typography>
                </CardContent>
            </Card>
        </a>
    );
};

export default MicrocourseCard;
