import React from "react";
import { Hidden, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import DescriptionRenderer from "../DescriptionRenderer";

const useStyles = makeStyles({
  table: {
    minWidth: 340,
  },
});

export default function AiTrainingsViewTable({ rows }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let filteredRows = rows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <Hidden smDown>
                <TableCell align="right">Description</TableCell>
              </Hidden>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow id={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <Hidden smDown>
                    <TableCell align="right">
                      <DescriptionRenderer htmlString={row.description} />
                    </TableCell>
                  </Hidden>
                  <TableCell align="right">
                    <Link href={`/user_trainings/${row.id}`}>
                      Start Training
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href={`/user_trainings/${row.id}/get_ai_training_rounds_feedback`}
                    >
                      See Feedback
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
