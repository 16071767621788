import React from "react";
import FeedBackViewTable from "./feedback_view_table/FeedbackViewTable";

const ReviewAiTrainingFeedbackView = ({ rows }) => {
  return (
    <React.Fragment>
      <FeedBackViewTable rows={rows} />
    </React.Fragment>
  );
};

export default ReviewAiTrainingFeedbackView;
