import React from "react"
import MentoringTable from "./mentoring_table/mentoring_table"

export default class MentoringView extends React.Component {
    constructor(props) {
        super(props);
    }


    render = () => {
        let { rows, current_user_id } = this.props;
        return (
            <React.Fragment>
                <MentoringTable
                    rows={rows}
                    currentUserId={current_user_id}
                />
            </React.Fragment>
        )
    }
}
