import React from "react"

const submitAppreciationPreference = async (receiveAppreciation) => {
    return fetch(`/user_profiles/update_appreciation`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
        },
        body: JSON.stringify({
            receive_appreciation: receiveAppreciation
        })
    });
};

export default function AppreciationPreferenceLightbox(props) {
    const { setModalOpen } = props;

    const [loading, setLoading] = React.useState(false);
    const [submissionSuccessful, setSubmissionSuccessful] = React.useState(false);
    const [submissionError, setSubmissionError] = React.useState(null);

    const handleClose = () => {
        if (submissionSuccessful) {
            setModalOpen(false);
        } else {
            window.location.reload();
        }
    }

    const handleAppreciationPreference = async (preference) => {
        setLoading(true);
        const result = await submitAppreciationPreference(preference);

        if (result.status === 200) {
            setSubmissionSuccessful(true);
            handleClose();
        } else {
            const responseBody = await result.json();
            setSubmissionError(`Error updating preference: ${responseBody['errors'].join(", ")}`);
        }
        setLoading(false);
    }

    return (
        <div className={'appreciation-lightbox-container'}>
            {loading && <div className={'acquaint-spinner-wrapper'} style={{zIndex: "999", position: "absolute"}}><div className={'acquaint-spinner'}/></div>}`
            <div className={'appreciation-lightbox-header'}>
                Receive Appreciation Notes from Volunteers?
            </div>
            <div className={'appreciation-lightbox-content'}>
              We added a new <b>optional</b> way to track your impact. We believe the act of GIVING appreciation is a key way to amplify your impact on Acquaint.
            </div>
            <div className={'appreciation-lightbox-content'}>
                If, when, and how you can manage the appreciations you give and receive is a work in progress, but we encourage you to write appreciations that call out something specific
                you want to appreciate, rather than just saying "great conversation," in the meantime.
            </div >
            <div className={'appreciation-lightbox-content'}>
                If you are OK with receiving appreciation from other volunteers on the Acquaint app, please select "yes" below. <b>Not sure? You can always change this setting in your profile.</b>
            </div>
            <div className={'appreciation-lightbox-actions mt-3'}>
                <button onClick={() => handleAppreciationPreference(true)} className={'appreciation-lightbox-yes-button'}>Yes</button>
                <button onClick={() => handleAppreciationPreference(false)} className={'appreciation-lightbox-no-button'}>No</button>
            </div>
            {submissionError &&
                <div className={'appreciation-lightbox-error'}>
                    {submissionError}
                </div>
            }
        </div>
    )
}
