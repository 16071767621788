import React, {useState} from 'react';
import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import {CountryData} from "react-intl-tel-input/dist/types";

// NOTE: this is currently hard coded to US only...
const parsePhone = (phone) => {
    if(!phone ){
        return ''
    }
    // return phone;
    return `${phone.replace(/[()\- ]/, '')}`
}

// This component is specifically built to work with direct render from rails views, so that it may
// easily be embedded in a form.
export default function IntlPhoneInput(props) {
    let {initialPhone, name, countryFieldName, initialCountry, phoneValidFieldName} = props
    if(!initialPhone) {
        initialPhone = ''
    }
    
    let [phone, setPhone] = useState(initialPhone)
    let [country, setCountry] = useState(initialCountry)

    return        <>
        <IntlTelInput
            containerClassName="intl-tel-input"
            defaultValue={initialPhone}
            defaultCountry={country}
            preferredCountries={[]}
            onSelectFlag={(_, selectedCountryData) => {
                // Update country when a new flag is selected
                setCountry(selectedCountryData['iso2']);
            }}
            onPhoneNumberChange={(_, value, selectedCountryData, fullNumber) => {
                // Clean and parse phone number
                setPhone(parsePhone(fullNumber));
                // Ensure country stays updated
                setCountry(selectedCountryData['iso2']);
            }}
        />
        <input type="hidden" value={phone} name={name} />
        <input type="hidden" value={country} name={countryFieldName} />
        <input type="hidden" value="true" name="phoneValid" />
    </>
}
