import React, { useState } from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';

const useEventListStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    event: {
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
    },
    eventDetails: {
        display: 'none',
        '&.expanded': {
            display: 'block',
        },
    },
    toggleButton: {
        marginTop: theme.spacing(1),
    },
    heading: {
        fontWeight: 'bold',
    }
}));

export const EventList = ({ events }) => {
    const classes = useEventListStyles();

    // Using a state to track expanded event IDs
    const [expandedEventId, setExpandedEventId] = useState(null);

    const toggleExpansion = (eventId) => {
        if (expandedEventId === eventId) {
            setExpandedEventId(null); // Collapse if the same event is clicked again
        } else {
            setExpandedEventId(eventId); // Expand the clicked event
        }
    };

    return (
        <div className={classes.root}>
            {events.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map((event, index) => (
                <div key={index} onClick={() => toggleExpansion(event.id)} className={classes.event}>
                    <Typography className={classes.heading}>
                        {new Date(event.created_at).toLocaleDateString()} - {event.event_name}
                    </Typography>
                    <div className={`${classes.eventDetails} ${expandedEventId === event.id ? 'expanded' : ''}`}>
                        <Typography>
                            {JSON.stringify(event.data)}
                        </Typography>
                    </div>
                </div>
            ))}
        </div>
    );
};
