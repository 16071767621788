import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";

const ModalScriptsBulletPoint = () => {
  const [bulletPoint, setBulletPoint] = useState([]);
  const [script, setScript] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const handleUpdateInformation = (event) => {
      const newBulletPoint = event.detail.bulletPoint;
      const newScript = event.detail.script;
      setBulletPoint(newBulletPoint);
      setScript(newScript);
    };

    document.addEventListener("updateBulletPoint", handleUpdateInformation);

    return () => {
      document.removeEventListener(
        "updateBulletPoint",
        handleUpdateInformation
      );
    };
  }, []);

  return (
    <>
      <button onClick={openModal} className="scripts-modal-button mini-link-button">
        Script
      </button>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Script Modal"
      >
        {script.length > 0 ? (
          <h3>Script: {script}</h3>
        ) : (
          <h3>There is no script for this image</h3>
        )}
        {bulletPoint.length > 0 ? (
          <div className={'mt-3'}>
            <b>Bulletpoints for this image:{" "}</b>
            <ul>
              {bulletPoint.map((bullet) => (
                <li>{bullet}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div>There are no bulletpoints for this image.</div>
        )}
        <button onClick={closeModal} className="basic-link-button">
          close
        </button>
      </ReactModal>
    </>
  );
};

export default ModalScriptsBulletPoint;
