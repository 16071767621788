import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    listContainer: {
        listStyleType: 'none',
        padding: 0,
        margin: theme.spacing(1, 0),
    },
    listItem: {
        padding: theme.spacing(1),
        borderBottom: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
    },
    quizName: {
        fontWeight: 'bold',
    },
    quizLink: {
        marginRight: '4px',
        marginLeft: '4px',
    },
    editButton: {
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export default function QuizList({ quizzes, onSelectQuiz }) {
    const classes = useStyles();

    return (
        <div>
            <h2>Quizzes</h2>
            <ul className={classes.listContainer}>
                {quizzes.map(quiz => (
                    <li key={quiz.id} className={classes.listItem}>
                        <span className={classes.quizName}>{quiz.display_name}</span>
                        <span className={classes.quizLink}><a href={`/take_quiz/${quiz.id}`}>Take Quiz</a></span>
                        {quiz.locked ? (
                            <span className={classes.editButton} style={{ color: 'red', cursor: 'default' }}>
                                LOCKED
                            </span>
                        ) : (
                            <button
                                className={classes.editButton}
                                onClick={() => onSelectQuiz(quiz)}>
                                Edit
                            </button>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}


