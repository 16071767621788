// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels/guide")
require('jquery')
require('page/alerts')
require('page/cancel_session_warning')
require('page/public_header_dropdown_menu.js')
let jstz = require('jstimezonedetect')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);



// set time zone
$(document).on('turbolinks:load', () => {
    let tz = jstz.determine().name()
    window.timezone = tz

    if (window.location.href.indexOf('edit') !== -1) {
        // We don't want to override whatever is in the edit form.
        return;
    }

    let tzField = document.getElementById('user_timezone')
    if (tzField) {
        document.getElementById('user_timezone').value = tz
    }
    let tzField2 = document.getElementById('user_profile_timezone')
    if (tzField2) {
        tzField2.value = tz
    }
});

if (process.env.NODE_ENV === 'production') {
    const Sentry = require('@sentry/browser');
    Sentry.init({dsn: 'https://5d8b76bf6f584766a001b56f49bd4504@sentry.io/1814409'});
    $(document).ready(() => {
        Sentry.configureScope(function (scope) {
            scope.setUser({"email": document.body.getAttribute('data-email')});
        });
    });
}
