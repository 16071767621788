import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { EventList } from "./EventList";

const useStyles = makeStyles({
    formControl: {
        margin: '20px',
        minWidth: 120,
        width: '100%', // Ensure the select box stretches to match the button's width
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
    },
    showDetailsButton: {
        marginBottom: '20px', // Add space below the button
    },
});


export default function ParticipantEventFilter({hideNames, experimentParticipants, experimentEvents }) {
    const classes = useStyles();
    const [selectedParticipantId, setSelectedParticipantId] = useState(experimentParticipants[0]?.id || '');
    const [showEvents, setShowEvents] = useState(false);

    const filteredEvents = experimentEvents.filter(event =>
        selectedParticipantId === '' || event.participant_id === selectedParticipantId);

    const handleParticipantChange = (event) => {
        setSelectedParticipantId(event.target.value);
    };

    return (
        <div className={classes.container}>
            <Button
                variant="contained"
                color="primary"
                className={classes.showDetailsButton}
                onClick={() => setShowEvents(!showEvents)}
            >
                {showEvents ? 'Hide Events' : 'Show Events'}
            </Button>
            {showEvents &&
                <>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="participant-select-label">Participant</InputLabel>
                        <Select
                            labelId="participant-select-label"
                            id="participant-select"
                            value={selectedParticipantId}
                            onChange={handleParticipantChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {experimentParticipants.map((participant) => (
                                <MenuItem key={participant.id} value={participant.id}>{(hideNames ? participant.user?.masked_name : participant.user?.email)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <EventList events={filteredEvents} />
                </>
            }
        </div>
    );
}
