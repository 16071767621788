import React, { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { Switch } from '@material-ui/core';

// Styles
const styles = {
    container: {
        backgroundColor: '#b9ffb7',
        padding: '30px',
        borderRadius: '10px',
        fontFamily: 'Arial, sans-serif',
        maxWidth: '600px',
        margin: '20px auto',
    },
    title: {
        color: '#403233',
        fontSize: '24px',
        marginBottom: '40px',
        fontWeight: 'bold',
    },
    collapseButton: {
        backgroundColor: '#403233',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px'
    },
    statsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
    },
    statBox: {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
    },
    statTitle: {
        color: '#8bc6a5',
        marginBottom: '10px',
    },
    statValue: {
        color: '#ed863d',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    impactButton: {
        marginTop: '20px',
        backgroundColor: '#403233',
        color: '#b9ffb7',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    tooltip: {
        position: 'absolute',
        marginLeft: '10px',
        backgroundColor: '#4CAF50',
        color: 'white',
        borderRadius: '4px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        fontSize: '0.9em',
        fontWeight: 'bold',
    },
    certificateContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        backgroundColor: 'white',
        padding: '16px',
        marginTop: '16px',
        borderRadius: '10px',
    },
    certificateImage: {
        width: '100px',
        height: 'auto',
        marginRight: '10px',
    },
    certificateDetails: {
        flexGrow: 1,
    },
    disabledLink: {
        pointerEvents: 'none',
        color: 'gray',
        cursor: 'not-allowed',
        border: '1px solid gray',
        padding: '8px 12px',
        borderRadius: '5px',
        marginTop: '10px',
        display: 'inline-block',
    },
    shareLink: {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    copyTooltip: {
        position: 'relative',
        marginLeft: '10px',
        backgroundColor: '#4CAF50',
        color: 'white',
        borderRadius: '4px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        fontSize: '0.9em',
        fontWeight: 'bold',
        padding: '5px 10px',
    },
    linkButton: {
        color: '#0066cc',
        textDecoration: 'none',
        padding: '8px 12px',
        border: '1px solid #0066cc',
        borderRadius: '5px',
        backgroundColor: 'white',
        cursor: 'pointer',
        marginTop: '10px',
        marginRight: '10px', // Add spacing between buttons
        display: 'inline-block',
    },
};

const disclaimerStyle = {
    marginTop: '40px',
    backgroundColor: '#8bc6a5', // A shade from your color palette
    padding: '20px',
    borderRadius: '10px',
    color: '#403233', // Another shade from your palette
    fontSize: '14px',
    lineHeight: '1.6', // Spacing between lines
    maxWidth: '600px',
    margin: '40px auto 20px auto',
    fontStyle: 'italic'
};

const referralSectionStyle = {
    backgroundColor: '#f0f0f0', // Choose a suitable background color
    padding: '20px',
    borderRadius: '10px',
    margin: '20px auto',
    textAlign: 'center',
    maxWidth: '600px',
};

const toTitleCase = (str) => {
    return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const appreciationStyle = {
    color: '#403233',
    fontSize: '16px',
    margin: '10px 0',
    lineHeight: '1.4',
};

const attributionStyle = {
    fontWeight: 'bold',
    marginLeft: '8px',
    color: '#8bc6a5',  // A color from your palette for contrast
};

// Component to represent individual appreciation
const AppreciationNote = ({ text, userFriendlyName, writeNoteLink }) => (
    <p style={appreciationStyle}>
        "{text}" <span style={{color: '#403233'}}>—</span>
        <span style={attributionStyle}>{userFriendlyName || ""}</span>
        <span> {writeNoteLink && (<a href={writeNoteLink}>*Write {userFriendlyName} an appreciation back*</a>)}</span>
    </p>
);

// Component
const PersonalImpact = ({ stats, appreciations, written_appreciations, volunteerReferrerLink, setFormalNameUrl, certificates = [] }) => {
    const [showAllAppreciations, setShowAllAppreciations] = React.useState(false);
    const [showAllWrittenAppreciations, setShowAllWrittenAppreciations] = React.useState(false);
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const [showAchievements, setShowAchievements] = React.useState(false);
    const [certificatesState, setCertificatesState] = React.useState(certificates);
    const [copyTooltipVisible, setCopyTooltipVisible] = React.useState({}); // Track visibility of copy tooltips for each certificate

    const hasPendingCertificates = certificatesState.some(cert => cert.pending);

    const renderStats = (parentKey, nestedStats) => {
        return Object.entries(nestedStats).map(([key, value]) => {
            return (
                <div style={styles.statBox} key={`${parentKey}-${key}`}>
                    <div style={styles.statTitle}>{toTitleCase(key)}</div>
                    <div style={styles.statValue}>{value}</div>
                </div>
            );
        });
    }

    const copyToClipboard = (text, certificateId = null) => {
        if (typeof text !== 'string') {
            console.error('copyToClipboard expects a string. Received:', text);
            return;
        }

        navigator.clipboard.writeText(text)
            .then(() => {
                if (certificateId) {
                    // Show tooltip for this specific certificate
                    setCopyTooltipVisible(prevState => ({ ...prevState, [certificateId]: true }));
                    setTimeout(() => {
                        setCopyTooltipVisible(prevState => ({ ...prevState, [certificateId]: false }));
                    }, 2000);
                } else {
                    // Show tooltip for general link
                    setTooltipVisible(true);
                    setTimeout(() => setTooltipVisible(false), 2000);
                }
            })
            .catch(err => {
                console.error('Could not copy text to clipboard: ', err);
            });
    };


    const handleVisibilityToggle = (certificateId, newVisibility) => {
        fetch(`/certificate_instances/${certificateId}/toggle_visibility`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            },
            body: JSON.stringify({ is_public: newVisibility })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log('Visibility updated successfully!');
                const updatedCertificates = certificatesState.map(certificate => {
                    if (certificate.id === certificateId) {
                        return { ...certificate, is_public: newVisibility };
                    }
                    return certificate;
                });
                setCertificatesState(updatedCertificates);
            } else {
                alert('Failed to update visibility: ' + data.errors.join(", "));
            }
        })
        .catch(error => {
            console.error('Error updating visibility:', error);
            alert('An error occurred while updating the visibility.');
        });
    };

    const liReferrerLink = encodeURIComponent(`${volunteerReferrerLink}&tsrc=liref`)
    const fbReferrerLink = encodeURIComponent(`${volunteerReferrerLink}&tsrc=fbref`)

    const twitterMessage = 'Check out this amazing volunteer opportunity at Acquaint!';
    const twReferrerLink = encodeURIComponent(`${twitterMessage} ${volunteerReferrerLink}&tsrc=twref`)

    const telegramMessage = 'Join me in making a difference with Acquaint.';
    const tgReferrerLink = encodeURIComponent(`${volunteerReferrerLink}&tsrc=tgref`)

    const emailSubject = 'Join me as a volunteer at Acquaint!';
    const emailBody = `I've been volunteering with Acquaint and thought you might be interested in joining too. Here's the link to sign up: ${volunteerReferrerLink}&tsrc=emref`;

    return (
        <>
            <div style={styles.container}>
                <div style={styles.title}>Your Impact</div>
                <div style={styles.statsContainer}>
                    {Object.entries(stats).map(([parentKey, nestedStats]) => {
                        if (typeof nestedStats === 'object') {
                            return renderStats(parentKey, nestedStats);
                        } else {
                            return (
                                <div style={styles.statBox} key={parentKey}>
                                    <div style={styles.statTitle}>{toTitleCase(parentKey)}</div>
                                    <div style={styles.statValue}>{nestedStats}</div>
                                </div>
                            );
                        }
                    }).flat()}
                </div>

                <button style={styles.impactButton} onClick={() => window.history.back()}>Go Back</button>
            </div>

            <div id='referral-section' style={referralSectionStyle}>
                {certificates && certificates.length > 0 && (
                    <>
                        <div style={styles.title}>Your Certificates and Badges</div>
                        <Button style={styles.collapseButton} onClick={() => setShowAchievements(!showAchievements)}>
                            {showAchievements ? 'Hide Achievements' : 'Show Achievements'}
                        </Button>
                        {hasPendingCertificates && (
                            <p style={{ color: 'red', fontStyle: 'italic' }}>
                                NOTE: You have pending certificates. To finish rendering your certificates, please set the name you want to appear on your certificates <a href={setFormalNameUrl} target="_blank" rel="noopener noreferrer">HERE</a>.
                            </p>
                        )}
                        <Collapse in={showAchievements}>
                            {certificatesState.length > 0 ? (
                                certificatesState.map(certificate => (
                                    <div key={certificate.id} style={styles.certificateContainer}>
                                        {certificate.pending ? (
                                            <div style={{ ...styles.certificateImage, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <strong>PENDING</strong>
                                            </div>
                                        ) : (
                                            <img
                                                className='noselect'
                                                src={certificate.thumbnailUrl}
                                                alt={certificate.title}
                                                style={styles.certificateImage}
                                                onContextMenu={(e) => e.preventDefault()}
                                            />                                        )}
                                        <div style={styles.certificateDetails}>
                                            <strong>{certificate.title}</strong>
                                            <p>Awarded: {new Date(certificate.awardedDate).toLocaleDateString()}</p>
                                            <div>
                                                <Switch
                                                    checked={certificate.is_public}
                                                    onChange={() => handleVisibilityToggle(certificate.id, !certificate.is_public)}
                                                    color="primary"
                                                />
                                                <span>Make it public/allow sharing</span>
                                            </div>
                                            {certificate.pending ? (
                                                <a href={setFormalNameUrl} target="_blank" rel="noopener noreferrer" style={styles.linkButton}>
                                                    Set Name For Certificates
                                                </a>
                                            ) : (
                                                <>
                                                    <a href={certificate.share_url} target="_blank" rel="noopener noreferrer" style={styles.linkButton}>
                                                        Download Certificate
                                                    </a>
                                                    <button
                                                        style={certificate.is_public ? styles.linkButton : styles.disabledLink}
                                                        onClick={() => copyToClipboard(certificate.share_url, certificate.id)}
                                                        title={certificate.is_public ? "Copy shareable link" : "This certificate is private and cannot be shared"}
                                                    >
                                                        {certificate.is_public ? "Copy Share Link" : "Private (Cannot Share)"}
                                                    </button>
                                                    {copyTooltipVisible[certificate.id] && (
                                                        <span style={styles.copyTooltip}>Copied!</span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No certificates to display.</p>
                            )}
                        </Collapse>
                    </>
                )}
            </div>


            <div id="referral-section" style={referralSectionStyle}>
                <div style={styles.title}>Refer your friends to Acquaint!</div>
                <p>Invite friends to volunteer with Acquaint. Please customize the post to your liking (but keep the link the same so you get credit). Share your unique link:</p>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${fbReferrerLink}`} target="_blank" rel="noopener noreferrer">Share on Facebook</a><br/>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${liReferrerLink}`} target="_blank" rel="noopener noreferrer">Share on LinkedIn</a><br/>
                <a href={`https://twitter.com/intent/tweet?text=${twReferrerLink}`} target="_blank" rel="noopener noreferrer">Tweet</a><br/>
                <a href={`https://t.me/share/url?url=${tgReferrerLink}&text=${encodeURIComponent(telegramMessage)}`} target="_blank" rel="noopener noreferrer">Share on Telegram</a><br/>
                <a href={`mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}>Email</a>
                <div className={'mt-3'}><b>OR</b></div>
                <div className={'mt-2 mb-5'}>
                    <button
                        style={styles.impactButton}
                        onClick={() => copyToClipboard(volunteerReferrerLink)}
                    >
                        Click to Copy and Paste Your Shareable Link
                        {tooltipVisible && <span style={styles.copyTooltip}>Copied!</span>}
                    </button>
                </div>
            </div>
            {appreciations.length > 0 && (
                <div className={'appreciations-list'}>
                    <h2>Appreciation Notes Received</h2>
                    {showAllAppreciations ? appreciations.map((appreciation, index) => (
                        <AppreciationNote
                            key={index}
                            text={appreciation[0]}
                            userFriendlyName={appreciation[1]}
                            writeNoteLink={appreciation[2]}
                        />
                    )) : (
                        appreciations.length > 0 && (
                            <AppreciationNote
                                text={appreciations[0][0]}
                                userFriendlyName={appreciations[0][1]}
                                writeNoteLink={appreciations[0][2]}
                            />
                        )
                    )}
                    {appreciations.length > 1 && (
                        <button
                            style={styles.impactButton}
                            onClick={() => setShowAllAppreciations(!showAllAppreciations)}
                        >
                            {showAllAppreciations ? 'Show Less Appreciations' : 'Show More Appreciations'}
                        </button>
                    )}
                </div>
            )}
            {written_appreciations.length > 0 && (
                <div className={'written-appreciations-list mt-3'}>
                    <h2>Appreciation Notes Written</h2>
                    {showAllWrittenAppreciations ? written_appreciations.map((appreciation, index) => (
                        <AppreciationNote
                            key={index}
                            text={appreciation[0]}
                            userFriendlyName={appreciation[1]}
                        />
                    )) : (
                        written_appreciations.length > 0 && (
                            <AppreciationNote
                                text={written_appreciations[0][0]}
                                userFriendlyName={written_appreciations[0][1]}
                            />
                        )
                    )}
                    {written_appreciations.length > 1 && (
                        <button
                            style={styles.impactButton}
                            onClick={() => setShowAllWrittenAppreciations(!showAllWrittenAppreciations)}
                        >
                            {showAllWrittenAppreciations ? 'Show Less Written Appreciations' : 'Show More Written Appreciations'}
                        </button>
                    )}
                </div>
            )}
            <div style={disclaimerStyle}>
                *It is each volunteer's responsibility to track their total hours.
                This approximation here is for convenience. When calculating number of hours volunteer,
                for example for a service program, we use the following formula: total session time + total number of sessions * 10 minutes
                (waiting, entering availability, etc.) + 75 minutes for onboarding & quiz. Scheuled
                coverage sessions where you aren't need for a session typically take about 15-20 minutes
                of a volunteer's time.
            </div>
            <div style={disclaimerStyle}>
                Please note that it may take several hours after your last session for the statistics to update.

                This feature is still under development, and there might be inconsistencies in the data presented.
                Mentor session counts prior to January 2022 are not included in the statistics (but equally appreciated 🙂).
                If you have concerns about your statistics, please reach out to us at <a href="mailto:support@acquaint.org" style={{color: '#ed863d'}}>support@acquaint.org</a>.
            </div>
            <div>Thank you for your support! 😊</div>
        </>
    );
};

export default PersonalImpact;