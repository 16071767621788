import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function SessionPossibilitiesTable(props) {
    const classes = useStyles();
    const { possibilities } = props;

    // Filters
    const [filterEmail, setFilterEmail] = React.useState('');
    const [filterExisting, setFilterExisting] = React.useState('All');

    // Get the highest number of existing sessions for dropdown
    // const maxExisting = Math.max(...possibilities.map(p => p.users[0].counts.existing)) || 0;

    // Filtering possibilities based on the current filters
    const filteredPossibilities = possibilities.filter(possibility => {
        const matchesEmail = possibility.users[0].counts.email.includes(filterEmail);
        const matchesExisting = filterExisting === 'All' || possibility.users[0].counts.existing === parseInt(filterExisting);
        return matchesEmail && matchesExisting;
    });

    return (
        <>
            {/* Filters */}
            <div>
                <label>
                    Filter By Email:
                    <input type='text' onChange={e => setFilterEmail(e.target.value)} value={filterEmail} />
                </label>
            </div>

            <div>
                <label>
                    Filter By Existing Number of Sessions:
                    <select value={filterExisting} onChange={e => setFilterExisting(e.target.value)}>
                        <option value="All">All</option>
                        {[...Array(10).keys()].map(num => (
                            <option key={num} value={num}>{num}</option>
                        ))}
                    </select>
                </label>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="session possibilities table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Timeslot</TableCell>
                            <TableCell>V1</TableCell>
                            <TableCell>V2</TableCell>
                            <TableCell>V1 Existing</TableCell>
                            <TableCell>V2 Existing</TableCell>
                            <TableCell>V1 Total Options</TableCell>
                            <TableCell>V2 Total Options</TableCell>
                            <TableCell>V1 Session Info</TableCell>
                            <TableCell>V2 Session Info</TableCell>
                            <TableCell>Schedule Them!</TableCell>
                        </TableRow>                    </TableHead>
                    <TableBody>
                        {filteredPossibilities.map((possibility) => (
                            <TableRow key={possibility.timeslot} className={possibility.hasCoverage ? 'has-coverage' : ''}>
                                <TableCell className={possibility.sessionPresent ? 'fw-bold' : ''}>
                                    {possibility.timeslot} {/* Convert this to friendly datetime if needed */}
                                </TableCell>
                                <TableCell className={possibility.pairedRecently ? 'bg-danger' : ''}>
                                    {possibility.users[0].counts.email} ({possibility.availableTimesUser1Info})
                                </TableCell>
                                <TableCell className={possibility.bothNeedTravelerRole ? 'bg-danger' : ''}>
                                    {possibility.users[1].counts.email} ({possibility.availableTimesUser2Info})
                                </TableCell>
                                <TableCell>{`${possibility.users[0].counts.existing} / ${possibility.users[0].counts.limit}`}</TableCell>
                                <TableCell>{`${possibility.users[1].counts.existing} / ${possibility.users[1].counts.limit}`}</TableCell>
                                <TableCell>{possibility.users[0].total_options}</TableCell>
                                <TableCell>{possibility.users[1].total_options}</TableCell>
                                <TableCell>
                                    <a target="_blank" href={`/schedulings/volunteer_info/${possibility.users[0].id}`}>
                                        Scheduling Info
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <a target="_blank" href={`/schedulings/volunteer_info/${possibility.users[1].id}`}>
                                        Scheduling Info
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <a target="_blank" href={possibility.scheduleLink}>
                                        Schedule
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
