import React, {useState, useEffect} from "react";

// TODO: dedup w/ quote_renderer
function useMediaQuery() {
    const [screenSize, setScreenSize] = React.useState([0, 0]);

    React.useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize;
}

export function QuoteRenderer(props) {
    let {htmlStrings, wrapperClass} = props
    const [fadeProp, setFadeProp] = useState({fade: 'fade-in'})
    const [currentIndex, setCurrentIndex] = useState(0)
    const [width] = useMediaQuery();

    if(width < 500) {
        // These longer strings overflow on mobile..
        htmlStrings = htmlStrings.filter((string) => string.length < 220)
    }
        useEffect(() => {
        let fadeState = fadeProp.fade;
        let fade = fadeProp.fade === 'fade-out' ? 1100 : 9000
        const timeout = setInterval(() => {
            if (fadeState === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
            } else {
                if(currentIndex + 1 === htmlStrings.length) {
                    setCurrentIndex(0)
                } else {
                    setCurrentIndex(currentIndex + 1)
                }

                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, fade);
        return () => clearInterval(timeout)
    }, [fadeProp])

    return (
        <div className={`quote-wrapper-wrapper parent-of-${wrapperClass}`}>
            <div className={`${wrapperClass} ${fadeProp.fade}`}>
                <div className={'quote-renderer'}>
                    <div className={`${fadeProp.fade} quote-content`} dangerouslySetInnerHTML={{ __html: htmlStrings[currentIndex] }}></div>
                </div>
            </div>
        </div>
    )
}

export default QuoteRenderer;