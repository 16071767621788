import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hasSessionLockBorder: {
    border: "3px solid blue",
  },
  highlightedCell: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 255, 0, 100)",
    },
  },
  grayedOut: {
    backgroundColor: "#A9A9A9",
  },
  activeVolunteer: {
    backgroundColor: "lightblue",
  },
  inactiveVolunteer: {
    backgroundColor: "lightgray",
  },
  unclickableText: {
    cursor: "default",
    color: "#ccc",
    pointerEvents: "none",
  },
  coverageRow: {
    backgroundColor: "#ed863d",
  },
});

export default function DeputyTable(props) {
  const classes = useStyles();
  const { rows } = props;
  let [currentRows, setCurrentRows] = React.useState(rows);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [selectedSwapCell, setSelectedSwapCell] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ContactCell = (props) => {
    const { userInfo, row, selectedSwapCell, activeVolunteer } = props;

    const unclickableText = !!selectedSwapCell;

    const { nickname, userId } = userInfo;
    return (
        <TableCell
            className={`${classes.tableCell} ${activeVolunteer ? classes.activeVolunteer : classes.inactiveVolunteer}`}
            align="right"
        >
        {nickname}
        {
          <>
            <a
              target="_blank"
              className={`basic-link-button on-table ${
                unclickableText ? classes.unclickableText : ""
              }`}
              href={`https://52.224.52.153:8000/en-US/app/search/events_for_user?form.user_id=${userId}`}
            >
              Splunk
            </a>
          </>
        }
      </TableCell>
    );
  };

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Scheduled At</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Traveler</TableCell>
              <TableCell align="right">Guide</TableCell>
              <TableCell align="right">Experience State</TableCell>
              <TableCell align="right">Chat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.session_id}
                  className={
                    row.hasSessionLock ? classes.hasSessionLockBorder : ""
                  }
                >
                  <TableCell align="right">
                    {row.scheduled_at} <br />
                    (T: {row.participant_scheduled_at}) <br /> (G:{" "}
                    {row.guide_scheduled_at}){" "}
                  </TableCell>
                  <TableCell
                    className={
                      row.type.trim() === "ScheduledCoverage"
                        ? classes.coverageRow
                        : ""
                    }
                    align="right"
                  >
                    {row.type}
                  </TableCell>
                  <ContactCell
                    userInfo={row.participantUserInformation}
                    row={row}
                    selectedSwapCell={selectedSwapCell}
                    role={"participant"}
                    activeVolunteer={row.recentTravelerHeartbeat}
                  />
                  <ContactCell
                    userInfo={row.guideUserInformation}
                    row={row}
                    selectedSwapCell={selectedSwapCell}
                    role={"guide"}
                    activeVolunteer={row.recentGuideHeartbeat}
                  />
                  <TableCell align="right">
                    {row.experienceInfoString}
                  </TableCell>
                  <TableCell align="right">
                    <a href={row.chat}>Chat</a>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={currentRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}
