import React from "react"
import {Calendar} from "./calendar/calendar"
import ReactModal from "react-modal";


const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const ConfirmAndApplyModal = (props) => {
    console.log(props)
    let { addModalOpen, addEvent, setAddModalOpen, currentModalEvent} = props
    return <ReactModal appElement={$('.content-container')[0]} isOpen={addModalOpen}>
        <div className={'confirm-and-apply-modal-container'}>
            {/*<div className="confirm-and-apply-modal-content">*/}
            {/*    <div className={'main-text'}>*/}
            {/*        Would you like to use visual assist mode during your tour of {tourName}?*/}
            {/*    </div>*/}
            {/*    <div className={'sub-text'}>*/}
            {/*        Visual assist mode lets the guide control what part of the scene you are looking at. Use*/}
            {/*        this if you don't want to use a mouse, touchscreen, or VR headset to control what you are*/}
            {/*        looking at and instead let the guide do it for you.*/}
            {/*    </div>*/}
            {/*</div>*/}

            <h1>Apply to this week?</h1>
            <div>
                Recurring availability is automatically added to your calendar every week. We can add
                it right away if you want, or you can wait until next week for it to be added. What would you like?
            </div>
            <div className="confirm-and-apply-modal-actions">
                <a onClick={() => {
                    addEvent(currentModalEvent, false)
                    setAddModalOpen(false)
                    }
                }
                   className={'basic-link-button'}
                >
                   Next Week
                </a>
                <a onClick={() => {
                    addEvent(currentModalEvent, true)
                    setAddModalOpen(false)
                 }
                }
                   className={'basic-link-button'}
                >
                    Add Now
                </a>
                <a onClick={() => setAddModalOpen(false)}
                   className={'basic-link-button'}
                >
                    Cancel
                </a>
            </div>
        </div>
    </ReactModal>
}

const persistRecurringSetting = async (recurringAvailabilitySettingId, settings, immediateEvent) => {
    let response = await fetch(`/recurring_availability_settings/${recurringAvailabilitySettingId}.json`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            },
            body: JSON.stringify(
                {
                    recurring_availability_setting : settings,
                    immediate_event: immediateEvent
                }
            )
        }
    ).then((response) => {console.log(response.json())})

    return {}
};



const UTCDate = (year, month, day, hours, minutes) => {

    return new Date(Date.UTC(year, month, day, hours, minutes))
}

const toEvents = (flatEvents) => {
    console.log(flatEvents)
    return flatEvents.map ((flatEvent, index) =>
    {
        return {
            title: 'recurring',
            start: UTCDate(2000,9, 1 + flatEvent.day, flatEvent.startHour,flatEvent.startMinute,0),
            end: UTCDate(2000,9, 1 + flatEvent.day, flatEvent.endHour,flatEvent.endMinute,0),
            id: index
        }
    })
}


const handleEventClick = (clickInfo) => {
    if (clickInfo.event.title === 'available' || clickInfo.event.title === 'recurring') {
        if (confirm(`Are you sure you want to delete this recurring availability? Note: this will remove the same availability on your available times calendar.`)) {
            clickInfo.event.remove()
        }
    }
};


export default function RecurringAvailabilityView(props) {

    const {times, defaultMaxSessionsPerWeek, recurringAvailabilitySettingId, oneBlockInstructionImageUrl, timeConstraints, timeZone} = props;
        // const [tooShortModalOpen, setTooShortModalOpen] = React.useState(false)
        const [flatEvents, setFlatEvents] = React.useState(times)

        // TODO: not sure if we want this here or in user profile.
        const [maxSessionsPerWeek, setMaxSessionsPerWeek] = React.useState(1)
        // const [addModalOpen, setAddModalOpen] = React.useState(false)
        // const [currentModalEvent, setCurrentModalEvent] = React.useState(null)
        const startDate = new Date(2000,9,1,0,0, 0);
        const endDate = new Date(2000,9,7,0,0, 0);

        const addEvent = (eventInfo, scheduleImmediately) => {
            let day = eventInfo.end.getUTCDay()
            let startHour = eventInfo.start.getUTCHours()
            let startMinute = eventInfo.start.getUTCMinutes()
            let endHour = eventInfo.end.getUTCHours()
            let endMinute = eventInfo.end.getUTCMinutes()
            let recurringAvailabilityId = crypto.randomUUID();
            let newEvent = {day, startHour, startMinute, endHour, endMinute, recurringAvailabilityId}
            let newFlatEvents = [...flatEvents, newEvent]
            setFlatEvents(newFlatEvents)
            let immediateEvent = scheduleImmediately ? newEvent : null
            persistRecurringSetting(recurringAvailabilitySettingId, {
                max_sessions_per_week: maxSessionsPerWeek,
                times: newFlatEvents
            },
                immediateEvent
            )
        }

        const changeEvent = (eventInfo) => {
            // TODO: unused...
        }

        const deleteEvent = (eventInfo) => {
            let immediateEvent = flatEvents.splice(eventInfo.event.id, 1)[0]
            immediateEvent.delete = true

            let newFlatEvents = [...flatEvents]
            setFlatEvents(newFlatEvents)
            persistRecurringSetting(recurringAvailabilitySettingId, {max_sessions_per_week: maxSessionsPerWeek, times: newFlatEvents}, immediateEvent)
        }

        return (
            <div className={'availability-view'}>
                <div className={'availability-header'}>Set Your Availability</div>
                <div className={'availability-description'}>
                    Enter your availability for the next two weeks on the calendar below by clicking (or 'long-pressing' on touchscreen) and dragging over the time block during which you are available.
                </div>
                <div className={'one-block-instructions'}>
                    Please enter one big block if possible rather than several small blocks:
                    <img className={'one-block-instruction-image'} src={oneBlockInstructionImageUrl}></img>

                </div>
                <div className={'availability-description'}>
                    <b>To change or delete a time</b>, click on the time block and choose 'ok' to delete it then recreate the time block
                    with the desired time.
                </div>
                <div className={'availability-description'}>
                    <b>NOTE: Changes are automatically saved.</b>
                </div>
                <div className={'availability-description'}>Your Timezone: {timeZone}</div>

                <Calendar
                        eventChange={changeEvent}
                        eventRemove={deleteEvent}
                        persistEvent={(eventInfo) => {
                            addEvent(eventInfo, true)
                        }}
                        events={toEvents(flatEvents)}
                        timeConstraints={timeConstraints}
                        eventColor={'#71a187'}
                        startDate={startDate}
                        endDate={endDate}
                        navLinks={false}
                        dayHeaderContent={(dateInfo) => { return DAYS[dateInfo.dow].substr(0,3) }}
                        headerToolbar={false}
                        handleEventClick={handleEventClick}
                        slotLabelFormat={{
                            hour12: false,
                            hour: 'numeric',
                            minute: '2-digit'
                        }}
                />

                {/*<ConfirmAndApplyModal {...{addModalOpen, addEvent, setAddModalOpen, currentModalEvent}} />*/}
                {/*<ReactModal*/}
                {/*    appElement={$('.content-container')[0]}*/}
                {/*    isOpen={tooShortModalOpen}*/}
                {/*    shouldCloseOnOverlayClick={true}*/}
                {/*    onRequestClose={() => setTooShortModalOpen(false)}*/}
                {/*>*/}
                {/*    <div className={'too-short-modal-content'}>*/}
                {/*        <div className={'too-short-modal-heading'}>*/}
                {/*            Availability Block Too Short*/}
                {/*        </div>*/}
                {/*        <div className={'too-short-modal-text'}>*/}
                {/*            Please create a time block that is at least one hour long.*/}
                {/*        </div>*/}
                {/*        <a className={'basic-link-button'} onClick={() => setTooShortModalOpen(false)}>*/}
                {/*            Ok*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</ReactModal>*/}
            </div>
        )

}
