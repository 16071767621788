import {LanguageSelect} from "./language_select/language_select"
import React, {useState} from "react"
import Select from 'react-select'


export default function TourSelectionDropdown(props) {
    const { languageOptions, tourSelectionDropdownOptions, initialLanguage, initialTour, modelName } = props

    const givenLanguageOrDefault = initialLanguage ? initialLanguage : 'english'
    const [languageOption, setLanguageOption] = useState(languageOptions.filter(option => option.value === givenLanguageOrDefault)[0])
    const initialTourOptions = tourSelectionDropdownOptions.filter((tour) => tour.language === givenLanguageOrDefault)

    const givenTourOrDefault = initialTour ? tourSelectionDropdownOptions.find((tour) => tour.value === initialTour) : null
    const [selectedTour, setSelectedTour] = useState(givenTourOrDefault)

    const onLanguageSelect  = (selectedLanguage) => {
        if(selectedLanguage !== languageOption) {
            setLanguageOption(selectedLanguage)
            let newTourOptions = tourSelectionDropdownOptions.filter((tour) => tour.language === selectedLanguage.value)
            setSelectedTour(newTourOptions[0])
        }
    }

    const options = tourSelectionDropdownOptions.filter((tour) => tour.language === languageOption.value)
    options.unshift({label: '*Choose Later*', value: ''})

    return (
        <div>
            <b>OPTIONAL:</b> Choose your language and tour below. Selecting a tour gives your guide an opportunity to review
            before your session. This is (currently) for reference only and you are able to select another tour at the
            time of your session, if desired.

            <div className={'tour-pre-select-label'}>
                Your Language:
            </div>
            <LanguageSelect
                options={languageOptions}
                className={'field'}
                value={languageOption}
                onChange={onLanguageSelect}
            />

            <div className={'tour-pre-select-label'}>
                Choose Tour:
            </div>
            <Select
                options={options}
                className={'field'}
                value={selectedTour}
                name={`${modelName}[tour_id]`}
                onChange={setSelectedTour}
            />
        </div>
    )
}
