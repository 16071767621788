import React from "react"
import Select from 'react-select'

export default class UserSelect extends React.Component {
    constructor(props) {
        super(props);
    }

    setValue = (selection) => {
        this.setState({value: selection.value, selection: selection})
        if (this.props.onChange) {
            this.props.onChange(selection.value); 
        }
    };

    render = () => {
        let { options, name } = this.props;
        return (
            <div className={'mb-2'}>
                <Select
                    defaultValue={this.props.initialValue}
                    options={options}
                    onChange={this.setValue}
                    name={name}
                />
                <div>Timezone: {this.state?.selection?.timezone || this.props.initialValue?.timezone}</div>
                <div>First Name: {this.state?.selection?.first_name || this.props.initialValue?.first_name}</div>
            </div>
        )
    }
}


