import React from "react";
import AiTrainingsAdminViewTable from "./ai_trainings_admin_view_table/AiTrainingsAdminViewTable";

const AiTrainingsAdminView = ({ rows }) => {
    return (
        <React.Fragment>
            <AiTrainingsAdminViewTable rows = {rows} />
        </React.Fragment>
    );
};

export default AiTrainingsAdminView;