import React from "react"
import FacilitatorTable from "./facilitator_table/facilitator_table"
import CoveragerTable from "./coverager_table/coverager_table";

export default class AdminUsersView extends React.Component {
    constructor(props) {
        super(props);
    }


    render = () => {
        let { rows, coveragers, current_user_id } = this.props;
        return (
            <React.Fragment>
                <FacilitatorTable
                    rows={rows}
                    currentUserId={current_user_id}
                />
            </React.Fragment>
        )
    }
}
