import React from 'react'

export const CancelIcon = (props) => {
    let {fill, size="1rem"} = props;

    return (<div style={{height: size, width: size}}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             viewBox={`0 0 500 500`}
        >
            <g>
                <path fill={fill} d="M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0z M250,469.4
		c-120.9,0-219.4-98.4-219.4-219.4S129.1,30.6,250,30.6S469.4,129.1,469.4,250S370.9,469.4,250,469.4z"/>
                <path fill={fill} d="M357.1,183.2c10.4-10.4,10.4-27.3,0-37.6l-2.5-2.5c-10.4-10.4-27.3-10.4-37.6,0l-48,48
		c-10.4,10.4-27.3,10.4-37.6,0l-48-48c-10.4-10.4-27.3-10.4-37.6,0l-2.5,2.5c-10.4,10.4-10.4,27.3,0,37.6l48,48
		c10.4,10.4,10.4,27.3,0,37.6l-48,48c-10.4,10.4-10.4,27.3,0,37.6l2.5,2.5c10.4,10.4,27.3,10.4,37.6,0l48-48
		c10.4-10.4,27.3-10.4,37.6,0l48,48c10.4,10.4,27.3,10.4,37.6,0l2.5-2.5c10.4-10.4,10.4-27.3,0-37.6l-48-48
		c-10.4-10.4-10.4-27.3,0-37.6L357.1,183.2z"/>
            </g>
        </svg>
    </div>)

}
