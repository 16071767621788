// todo lets move into ai_trainings folder
import React from "react";

import AiTrainingsViewTable from "./ai_trainings/AiTrainingsViewTable";

const AiTrainingsView = ({ rows }) => {
  return (
    <React.Fragment>
      <AiTrainingsViewTable rows={rows} />
    </React.Fragment>
  );
};

export default AiTrainingsView;
