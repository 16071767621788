import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionRenderer from "../DescriptionRenderer";
import LongTextRenderer from "../LongTextRenderer";
import { Link } from "@material-ui/core";
import { useState } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
    table: {
      minWidth: 340,
    },
    linkContainer: {
        display: "flex",
        gap: "8px", 
    },
  });

export default function AiTrainingsAdminViewTable({ rows }) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filteredRows, setFilteredRows] = useState(rows);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDelete = async (row) => {
        if (window.confirm("Are you sure you want to delete this AI training?")) {
            try {
                const response = await fetch(`/ai_trainings/${row.id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                        "Accept": "application/json",
                    },
                });
                if (response.ok) {
                    setFilteredRows((prevRows) => prevRows.filter((temp) => temp.id !== row.id));
                } else {
                    console.error("Failed to delete AI training", response.statusText);
                }
            } catch (error) {
                console.error("Error during delete request", error);
              }
        }
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <Hidden smDown>
                                <TableCell>Description</TableCell>
                                <TableCell>Initial prompt</TableCell>
                                <TableCell>Reply prompt template</TableCell>
                                <TableCell>Created at</TableCell>
                            </Hidden> 
                            <TableCell>Options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow id={row.id} key={row.id}>
                                    <TableCell>{row.name}</TableCell>
                                    <Hidden smDown>
                                        <TableCell>
                                            <DescriptionRenderer htmlString={row.description} />
                                        </TableCell>
                                        <TableCell>
                                            <LongTextRenderer string={row.initial_prompt} />
                                        </TableCell>
                                        <TableCell>
                                            <LongTextRenderer string={row.reply_prompt_template} />
                                        </TableCell>
                                        <TableCell>
                                            {row.created_at && (
                                                new Date(row.created_at).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                })
                                            )}
                                        </TableCell>
                                    </Hidden>
                                    <TableCell>
                                        <div className={classes.linkContainer}>
                                            <Link href={`/ai_trainings/${row.id}`}>
                                                Show
                                            </Link>
                                            <Link href={`/ai_trainings/${row.id}/edit`}>
                                                Edit
                                            </Link>
                                            <Link
                                                onClick={() => handleDelete(row)}
                                            >
                                                Destroy
                                            </Link>
                                        </div>        
                                    </TableCell>

                                </TableRow>
                            )
                            )
                        }
                    </TableBody>
                    
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}