import React from 'react'

import {CancelIcon} from "./cancel_icon";
import {ContactPhoneIcon} from "./contact_phone_icon"
import {MicrophoneIcon} from "./microphone_icon"
import {ZoomInIcon} from "./zoom_in_icon"
import {ZoomOutIcon} from "./zoom_out_icon"

export default {title: 'Icons'};

export const cancelIconNormal = () => (<CancelIcon/>);
export const cancelIconRed = () => (<CancelIcon fill={'#FF0000'}/>);
export const cancelIconLarge = () => (<CancelIcon size={'4rem'}/>);

export const contactPhoneIconNormal = () => (<ContactPhoneIcon/>);

export const microphoneIconNormal = () => (<MicrophoneIcon/>);

export const zoomInIconNormal = () => (<ZoomInIcon/>)
export const zoomInIconRed = () => (<ZoomInIcon fill={'#FF0000'}/>)

export const zoomOutIconNormal = () => (<ZoomOutIcon/>)
