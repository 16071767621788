import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    rotationControl: {
        maxWidth: '220px',
        position: 'relative',
        userSelect: 'none',
        cursor: 'pointer',
        border: '1px solid black',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'space-between', // This spreads out the buttons
        alignItems: 'center',
        flexDirection: 'row',
        margin: 'auto',
    },
    button: {
        margin: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    svgArrow: {
        width: '24px',
        height: '24px',
    },
    resetButton: {

        borderRadius: '50%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.warning.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    heading: {
        textAlign: 'center',
    }
}));

const RotationControl = ({ onRotationChange }) => {
    const classes = useStyles();
    const [rotationRadians, setRotationRadians] = useState(0);
    const controlRef = useRef(null);

    const degreesToRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    };

    const rotateLeft = (event) => {
        event.preventDefault(); // Prevents default action
        const newRotation = rotationRadians - degreesToRadians(10);
        setRotationRadians(newRotation);
        onRotationChange(newRotation);
    };

    const rotateRight = (event) => {
        event.preventDefault(); // Prevents default action
        const newRotation = rotationRadians + degreesToRadians(10);
        setRotationRadians(newRotation);
        onRotationChange(newRotation);
    };

    const resetRotation = () => {
        setRotationRadians(0);
        onRotationChange(0);
    };

    return (
        <div>
            <h2 className={classes.heading}>Rotate Left/Right</h2>
            <div ref={controlRef} className={classes.rotationControl}>
                <button className={`${classes.button}`} onClick={rotateLeft}>
                    <svg className={classes.svgArrow} viewBox="0 0 24 24">
                        <path d="M12,2 C6,12 6,12 12,22" stroke="white" strokeWidth="2" fill="none" />
                    </svg>
                </button>
                <button className={classes.resetButton} onClick={resetRotation}>Reset</button>
                <button className={`${classes.button}`} onClick={rotateRight}>
                    <svg className={classes.svgArrow} viewBox="0 0 24 24">
                        <path d="M12,2 C18,12 18,12 12,22" stroke="white" strokeWidth="2" fill="none" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default RotationControl;
