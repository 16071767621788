import React from "react";
import Select from 'react-select';

export default class TemplateSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue?.value || '',
            label: props.initialValue?.label || '',
        };
    }

    setValue = (selection) => {
        this.setState({ value: selection.value, label: selection.label });
        if (this.props.onChange) {
            this.props.onChange(selection);
        }
    };

    render() {
        const { options, name } = this.props;
        return (
            <div className={'mb-2'}>
                <Select
                    defaultValue={this.props.initialValue}
                    options={options}
                    onChange={this.setValue}
                    name={name}
                />
                <div>Selected Template: {this.state.label}</div>
            </div>
        )
    }
}
