import React from 'react';

import FacilitatorTable from "./facilitator_table";


export default {title: 'Session Facilitator Table'};

const rows1 = [
    {
        session_id: 1,
        scheduled_at: Date.now(),
        type: 'Admin',
        participantUserInformation: {
            nickname: 'jane1234',
            firstName: 'Erica',
            userProfileId: 12345,
            dialingNotes: 'Ask for Jane to connect you with Erica.'
        },
        guideUserInformation: {
            nickname: 'celes5678',
            firstName: 'celes5678',
            userProfileId: 343434,
            dialingNotes: "This is Celes' mobile number"
        },
        state: ['guide_checked_in', 'participant_confirmed'], // TODO: this will included all the checkin info. canceled > checked in > confirmed
        scheduler_notes: 'some notes here',
        // repeating_session: true,
        posts: 'http://localhost:3000' // TODO: will be links here...
    }
]

export const facilitatorTable = () => (<FacilitatorTable rows={rows1}/>);
