import React, { useState } from "react";
import {
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    Table,
    Collapse,
    Box,
    Typography,
    Tooltip,
    Checkbox,
    Button,
} from "@material-ui/core";

const getVolunteerPath = (id) => `/schedulings/volunteer_info/${id}`;

const ProposedPairings = ({ data, beginNextCycleDate, additionalData }) => {
    const [expandedDates, setExpandedDates] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);

    console.log(additionalData)

    const toggleDateRow = (date) => {
        setExpandedDates((prev) =>
            prev.includes(date)
                ? prev.filter((d) => d !== date)
                : [...prev, date]
        );
    };

    const toggleRowExpansion = (rowIndex) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowIndex]: !prev[rowIndex],
        }));
    };

    const handleRowCheck = (rowId) => {
        setSelectedRows((prev) =>
            prev.includes(rowId)
                ? prev.filter((id) => id !== rowId)
                : [...prev, rowId]
        );
    };

    // Fetch CSRF token from meta tag
    const getCSRFToken = () =>
        document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    const handleBulkCreate = async () => {
        const payload = selectedRows.map((rowId) => {
            const [date, index] = rowId.split("|");

            const pairing = data[date][index];
            console.log(pairing)
            return {
                participant_id: pairing.traveler_id,
                guide_id: pairing.guide_id || null,
                scheduled_at: date,
                coverage: pairing.coverage || false,
            };
        });

        const response = await fetch("/admin_sessions/bulk_create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getCSRFToken(),
            },            body: JSON.stringify({ admin_sessions: payload }),
        });

        const result = await response.json();
        if (response.ok) {
            alert(`Successfully scheduled ${result.report.created} sessions`);
        } else {
            alert(`Failed to schedule sessions: ${result.error}`);
        }
    };

    const getRowStyle = (pairing) => {
        let baseStyle = {};

        if (pairing.is_mentoring) {
            baseStyle = { backgroundColor: "#e0f7fa" }; // Light blue for mentoring
        } else {
            baseStyle = { backgroundColor: "#e8eaf6" }; // Default light grey
        }

        if (pairing.scheduled) {
            baseStyle = {
                ...baseStyle,
                border: "2px solid #4caf50", // Add green border for scheduled
            };
        }

        return baseStyle;
    };

    const getFirstCellStyle = (pairing) => {
        return pairing.coverage
            ? { backgroundColor: "orange", color: "white", fontWeight: "bold" }
            : {};
    };

    const formatScore = (score) => {
        if (score === undefined || score === null) {
            return "N/A";
        }
        return score === 0 ? "0" : score;
    };

    const sortedData = Object.entries(data).sort(([dateA], [dateB]) =>
        new Date(dateA) - new Date(dateB)
    );

    const convertToLocalTime = (utcDate) => {
        const localDate = new Date(utcDate).toLocaleString("en-US", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
        return localDate;
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Traveler Email</TableCell>
                            <TableCell>Guide Email</TableCell>
                            <TableCell>Mentoring</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Scheduled</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map(([date, pairings], index) => (
                            <React.Fragment key={date}>
                                {index > 0 &&
                                    sortedData[index - 1][0] < beginNextCycleDate &&
                                    date >= beginNextCycleDate && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={8}
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    backgroundColor: "#f5f5f5",
                                                }}
                                            >
                                                ----- Next Cycle -----
                                            </TableCell>
                                        </TableRow>
                                    )}
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        onClick={() => toggleDateRow(date)}
                                        style={{
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                            backgroundColor: "#f5f5f5",
                                        }}
                                    >
                                        {convertToLocalTime(date)}{" "}
                                        {expandedDates.includes(date) ? "▼" : "▶"}
                                    </TableCell>
                                </TableRow>
                                {expandedDates.includes(date) &&
                                    pairings
                                        .sort((a, b) => {
                                            // Sort logic
                                            const getTypePriority = (pairing) => {
                                                if (!pairing.coverage && !pairing.is_mentoring) return 0;
                                                if (pairing.is_mentoring) return 1;
                                                if (pairing.coverage) return 2;
                                            };

                                            const typePriorityA = getTypePriority(a);
                                            const typePriorityB = getTypePriority(b);

                                            if (typePriorityA !== typePriorityB) {
                                                return typePriorityA - typePriorityB;
                                            }

                                            if (a.scheduled !== b.scheduled) {
                                                return a.scheduled - b.scheduled;
                                            }

                                            return b.total_scores - a.total_scores;
                                        })
                                        .map((pairing, index) => {
                                            const rowId = `${date}|${index}`;
                                            return (
                                                <React.Fragment key={rowId}>
                                                    <TableRow style={getRowStyle(pairing)}>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedRows.includes(rowId)}
                                                                onChange={() => handleRowCheck(rowId)}
                                                                disabled={pairing.scheduled}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={getFirstCellStyle(pairing)}>
                                                            {pairing.coverage
                                                                ? `${convertToLocalTime(date)} (coverage)`
                                                                : convertToLocalTime(date)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <a
                                                                href={getVolunteerPath(
                                                                    pairing.traveler_id
                                                                )}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    color: "#1976d2", // Subtle blue color
                                                                    fontWeight: "bold", // Slight emphasis
                                                                    textDecoration: "underline", // Underline for clear indication
                                                                }}
                                                            >
                                                                {pairing.traveler_email}
                                                            </a>
                                                        </TableCell>
                                                        <TableCell>
                                                            {pairing.guide_id ? (
                                                                <a
                                                                    href={getVolunteerPath(
                                                                        pairing.guide_id
                                                                    )}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{
                                                                        color: "#1976d2", // Subtle blue color
                                                                        fontWeight: "bold", // Slight emphasis
                                                                        textDecoration: "underline", // Underline for clear indication
                                                                    }}
                                                                >
                                                                    {pairing.guide_email}
                                                                </a>
                                                            ) : (
                                                                "Unpaired"
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {pairing.is_mentoring ? "Yes" : "No"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatScore(pairing.total_scores)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {pairing.scheduled ? "Yes" : "No"}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="View Score Details">
                                                                <span
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        textDecoration: "underline",
                                                                        marginLeft: "10px",
                                                                    }}
                                                                    onClick={() =>
                                                                        toggleRowExpansion(
                                                                            `${date}-${index}`
                                                                        )
                                                                    }
                                                                >
                                                                    {expandedRows[`${date}-${index}`]
                                                                        ? "Hide Details"
                                                                        : "View Details"}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={8}
                                                            style={{ padding: 0 }}
                                                        >
                                                            <Collapse
                                                                in={
                                                                    expandedRows[
                                                                        `${date}-${index}`
                                                                        ]
                                                                }
                                                                timeout="auto"
                                                                unmountOnExit
                                                            >
                                                                <Box margin={1}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        gutterBottom
                                                                    >
                                                                        Score Details
                                                                    </Typography>
                                                                    <Table
                                                                        size="small"
                                                                        aria-label="scores"
                                                                    >
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    Role Balance
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Country Difference
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Recent Pairings
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Same Country
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Successful Sessions
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Mentoring Penalty
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Mentee Pairing Penalty
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.role_balance_score
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.country_difference_score
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.recent_pairings_score
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.same_country_pairing_score
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.successful_sessions_score
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.mentoring_sessions_penalty
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatScore(
                                                                                        pairing
                                                                                            .scores
                                                                                            ?.mentee_pairing_penalty
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        })}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                color="primary"
                onClick={handleBulkCreate}
                disabled={selectedRows.length === 0}
            >
                Bulk Schedule
            </Button>
        </div>
    );
};

export default ProposedPairings;
