import React, {useState, useEffect, useRef} from "react";
import {
    Box,
    Toolbar,
    Typography,
    Button,
    IconButton
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ChatView from "./chat_view";
import useMessageHandler from "./useMessageHandler";
import {ChatSubscriber} from "../../channels/chat_channel";
import useChatActions from "./useChatActions";
import {ChatIcon} from "../icons/chat_icon";
import ReactModal from "react-modal";
import {CancelIcon} from "../icons/cancel_icon";
import { HelpInstructionsAndRaiseHand } from "./help_instructions_and_raise_hand";
import { ExclamationIcon } from "../icons/exclamation_icon";
import withTheme from "../with_theme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    },
    modalContent: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#fff",
        overflow: "auto",
        outline: "none",
        display: "flex",
        flexDirection: "column",
        zIndex: 2000,
    },
    closeButton: {
        position: "absolute",
        top: 20,
        right: 20,
        zIndex: 2100,
        background: "none",
        border: "none",
        cursor: "pointer",
        fontSize: "1.5rem",
    },
    chatContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "hidden",
    },
    chatToggle: {
        position: "absolute",
        bottom: 20,
        right: 20,
        zIndex: 1500,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        padding: theme.spacing(1, 3),
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
    },
    disclaimer: {
        marginBottom: theme.spacing(2),
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
    },
    helpButton: {
        alignSelf: "center",
        marginTop: "1rem",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
    },
    bottomOptions: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
    }
}));


const ChatOpener = (props) => {
    let {onClick, unreadMessages} = props;
    useEffect(() => {
        if (unreadMessages) {
            document.getElementById("chat-notification-audio").play();
        }
    }, [unreadMessages]);

    const chatOpenerClassName = unreadMessages
        ? "chat-opener animated"
        : "chat-opener";

    return (
        <div className={chatOpenerClassName} onClick={onClick}>
            <div className={unreadMessages ? "red-notification-dot" : ""}/>
            <audio
                id="chat-notification-audio"
                src="data:audio/mpeg;base64,/+MYxAAEaAIEeUAQAgBgNgP/////KQQ/////Lvrg+lcWYHgtjadzsbTq+yREu495tq9c6v/7vt/of7mna9v6/btUnU17Jun9/+MYxCkT26KW+YGBAj9v6vUh+zab//v/96C3/pu6H+pv//r/ycIIP4pcWWTRBBBAMXgNdbRaABQAAABRWKwgjQVX0ECmrb///+MYxBQSM0sWWYI4A++Z/////////////0rOZ3MP//7H44QEgxgdvRVMXHZseL//540B4JAvMPEgaA4/0nHjxLhRgAoAYAgA/+MYxAYIAAJfGYEQAMAJAIAQMAwX936/q/tWtv/2f/+v//6v/+7qTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"
            />
            <ChatIcon/>
        </div>
    );
};

const Messenger = ({currentUser, chatData, userCanDelete, alwaysOpen}) => {
    const classes = useStyles();
    const [chatOpen, setChatOpen] = useState(alwaysOpen || false);
    const [messages, setMessages] = useState(chatData.messages);
    const [unreadCount, setUnreadCount] = useState(0);
    const messageContainerRef = useRef(null);
    const [showHelp, setShowHelp] = useState(false);

    const [selectedChat, setSelectedChat] = useState(chatData);
    const selectedChatRef = useRef(chatData);  // Ref to track the current chat
    const [isMobile, setIsMobile] = useState(() => {
        const isSmallScreen = window.innerWidth < 600 || window.innerHeight < 600;
        return isSmallScreen;
    });

    useEffect(() => {
        selectedChatRef.current = chatData;
    }, [chatData]);




    useEffect(() => {
        const handleResize = () => {
            const isSmallScreen = window.innerWidth < 600 || window.innerHeight < 600;
            setIsMobile(isSmallScreen);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const scrollToBottom = () => {
        const container = messageContainerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    };

    useEffect(() => {
        // Delay scrolling to ensure DOM updates
        const timer = setTimeout(() => {
            scrollToBottom();
        }, 10);  // Small delay to ensure reflow
        return () => clearTimeout(timer);
    }, [messages]);

    const isAtBottom = () => {
        const container = messageContainerRef.current;
        return container
            ? Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 10
            : false;
    };
    const markUnreadChats = () => {
        if(!chatOpen) {
            setUnreadCount(1)
        }
    };

    // Use the custom hook for handling incoming messages
    const handleReceiveMessage = useMessageHandler(
        markUnreadChats,
        selectedChatRef,
        setMessages,
        isAtBottom,
        scrollToBottom
    );

    const {message, setMessage, handleSendMessage, handleDeleteMessage} =
        useChatActions(selectedChat, setMessages);

    const handleToggleChat = () => {
        if (!alwaysOpen) {
            setChatOpen((prev) => {
                const newState = !prev;

                // If opening, trigger scroll after DOM update
                if (!prev) {
                    setTimeout(() => {
                        scrollToBottom();
                    }, 10);  // Small delay to ensure reflow
                }

                setUnreadCount(0);

                return newState;
            });
        }
    };

    const handleCloseOrNavigateBack = () => {
        if (alwaysOpen) {
            window.history.back();  // Navigate back when modal close is triggered
        } else {
            handleToggleChat();
        }
    };

    const chatSubTitle = 'Acquaint admin/facilitators may join or read this chat to offer/provide additional support.'

    // Responsive Modal Content Styling
    let contentStyle = {
        height: 'calc(100% - 10rem)',
        width: 'calc(100% - 80px)',
        margin: 0,
        padding: 0,
        border: 'solid .5rem #8bc6a5',
        maxHeight: 'calc(100% - 10rem)',
        overflow: 'auto',
        position: 'relative'
    };

    if (isMobile) {
        contentStyle = {
            height: '100%',
            width: '100%',
            margin: 0,
            left: 0,
            top: 0,
            padding: 0,
            border: 'none',
            maxHeight: '100%',
        };
    }

    return (
        <>
            {/* Subscribe to incoming messages */}
            <ChatSubscriber handleReceiveMessage={handleReceiveMessage} chatData={chatData}/>
            {/* Chat Modal */}
            <ReactModal
                appElement={$('.app-content')[0]}
                isOpen={chatOpen}
                onRequestClose={handleCloseOrNavigateBack}
                contentLabel="Chat"
                shouldCloseOnOverlayClick={true}
                style={{
                    content: contentStyle,
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                }}
            >
                <Box className={classes.modalContent}>
                    <button className={classes.closeButton} onClick={handleCloseOrNavigateBack}>
                        <CancelIcon size={"2rem"} />
                    </button>
                    <Box className={classes.chatContainer}>
                        {/* Disclaimer Text */}
                         <>
                                <ChatView
                                    messages={messages}
                                    messageContainerRef={messageContainerRef}
                                    message={message}
                                    setMessage={setMessage}
                                    handleSendMessage={handleSendMessage}
                                    handleDeleteMessage={handleDeleteMessage}
                                    currentUser={currentUser}
                                    userCanDelete={userCanDelete}
                                    selectedChat={selectedChatRef.current}
                                    chatSubTitle={chatSubTitle}
                                />
                            </>
                    </Box>
                </Box>
            </ReactModal>
            {/* Chat Opener */}
            {!chatOpen && (
                <ChatOpener
                    onClick={handleToggleChat}
                    unreadMessages={unreadCount > 0}
                />
            )}
        </>
    );
};

export default withTheme(Messenger);
