import React from 'react'

export const ChatIcon = (props) => {
    let {fill, size="40px"} = props;
    // style="enable-background:new 0 0 60 60;"
    return (<div style={{height: size, width: size, margin: '8px', marginLeft:'6px'}}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="40px" height="40px" viewBox="0 0 479.219 479.219">
		<path d="M475.654,81.175c-0.036-1.077-0.071-2.153-0.107-3.234c-0.432-12.787-1.909-24.342-10.349-34.574
			c-7.744-9.392-20.805-12.454-32.28-13.51c-0.671-0.063-1.315,0.011-1.96,0.079c-0.361-0.016-0.681-0.089-1.057-0.079
			c-48.378,1.295-96.726,0.063-145.093-1.389c-0.411-0.03-0.777-0.119-1.204-0.119h-63.35c-0.244,0-0.447,0.059-0.686,0.068
			c-0.282-0.01-0.531-0.073-0.823-0.068c-24.333,0.391-44.895,13.609-49.29,38.625c-0.213,1.221-0.259,2.438-0.224,3.638
			c-0.086,0.495-0.218,0.952-0.259,1.478c-2.412,31.681-3.968,63.431-4.634,95.205c-0.337,16.216-0.441,32.44-0.312,48.659
			c0.137,16.836-1.94,34.419,7.178,49.582c8.069,13.426,21.579,17.854,36.18,20.524c0.676,0.122,1.396,0.173,2.146,0.188
			c0.054,0,0.104,0.01,0.302,0c0.457,0,0.878,0.056,1.369,0.01c31.006-2.828,62.053-4.225,93.176-4.458
			c2.158,1.279,4.337,2.514,6.535,3.712c0.188,0.127,0.32,0.279,0.519,0.396c32.103,19.632,65.638,36.526,99.543,52.791
			c6.973,3.342,13.548,0.579,17.342-4.185c2.736-2.487,4.184-6.124,3.158-10.593c-0.162-1.168-0.508-2.295-1.066-3.371
			l-15.508-40.329h11.974c0.695,0,1.315-0.112,1.955-0.193c11.064,1.239,22.47,0.65,31.828-6.221
			c18.18-13.329,17.972-38.702,18.383-58.831c0.594-28.787-0.386-57.619-1.539-86.376
			C476.877,112.795,476.207,96.987,475.654,81.175z M451.807,236.822c-0.681,5.492-2.371,10.626-6.23,14.571
			c-7.054,5.113-18.261,1.884-26.299,1.691c-6.927-0.173-14.36,0-21.054,0c-1.843,0-3.447,0.35-4.905,0.894
			c-7.814,0.7-14.787,6.967-11.202,16.285c3.804,9.901,7.612,19.799,11.421,29.695c-21.343-10.714-42.401-21.957-62.916-34.235
			c-0.736-0.67-1.544-1.274-2.468-1.741c-3.088-1.56-6.084-3.271-9.055-5.022c-2.184-2.601-5.418-4.393-9.861-4.372
			c-32.753,0.111-65.412,1.549-98.03,4.52c-0.203,0.02-0.375,0.086-0.576,0.111c-0.317-0.025-0.599-0.106-0.932-0.111
			c-4.347-0.082-7.67-0.854-10.994-2.818c-2.496-1.473-4.854-4.88-6.083-8.328c-0.333-0.935-0.754-1.818-1.231-2.656
			c0.14-0.878,0.233-1.797,0.208-2.782c-1.348-56.863,0.188-113.625,4.52-170.333c2.729-11.923,9.722-16.479,22.627-16.687
			c0.251-0.005,0.459-0.068,0.698-0.079c0.279,0.015,0.525,0.079,0.81,0.079h60.329c28.817,0.036,57.518,2.397,86.31,2.6
			c14.625,0.104,29.168-0.947,43.763-1.518c9.633-0.381,22.927-1.869,31.352,4.06c9.973,7.025,7.565,22.787,7.906,33.362
			c0.522,15.952,1.066,31.902,1.503,47.854c0.437,15.958,0.731,31.918,0.731,47.881c0,8.122-0.071,16.249-0.244,24.372
			C451.756,220.883,452.757,229.192,451.807,236.822z"/>
        <path d="M281.609,305.997c0,0,0,31.976,0.016,32.123c-0.66,1.539-1.098,3.22-1.295,4.972c0.248-2.219-0.528,1.96-0.528,1.945
			c-0.117,0.462-0.193,0.786-0.233,1c-0.092,0.173-0.198,0.36-0.355,0.69c-0.057,0.102-1.082,1.66-1.234,2.011
			c-0.492,0.559-1.016,1.082-1.559,1.579c0.121-0.233-1.834,1.234-1.996,1.325c-0.31,0.184-0.487,0.295-0.66,0.401
			c-0.208,0.051-0.508,0.138-1.051,0.32c-3.209,1.081-3.52,0.898-6.459,0.954c-0.417,0.011-0.772,0.102-1.168,0.143
			c-0.468-0.046-0.899-0.143-1.392-0.143H170.32c-4.133,0-7.226,1.61-9.29,4.018c-1.229,0.548-2.44,1.254-3.595,2.29
			c-11.641,10.48-22.983,21.246-34.063,32.28c1.582-6.997,3.047-14.025,4.28-21.114c3.682-21.17-22.734-15.498-34.238-15.198
			c-13,0.33-31.171,5.002-43.704,1.722c-3.984-1.046-13.5-6.282-13.744-11.228c-2.671-53.034-6.307-106.016-8.907-159.06
			c-0.005-0.114-0.041-0.213-0.046-0.328c-0.028-0.982-0.147-2.003-0.411-3.075c-1.354-5.464-1.31-10.217,0.269-13.071
			c7.015-12.657,31.298-8.704,42.774-7.724c21.163,1.811,42.353,1.661,63.584,2.45c16.503,0.614,16.447-24.974-0.01-25.583
			c-21.211-0.784-42.442-1.102-63.668-1.216c-22.701-0.125-48.715-2.326-64.03,18.017c-6.754,8.976-6.302,21.751-3.956,32.245
			c2.61,52.74,6.218,105.432,8.858,158.177c0.048,0.924,0.198,1.787,0.391,2.609c-0.083,2.042,0.277,4.194,1.358,6.403
			c15.927,32.605,57.285,26.197,87.491,25.029c-3.801,17.642-8.678,34.998-14.589,52.096c-1.696,4.9-0.426,8.916,2.27,11.648
			c4.131,6.135,12.756,9.136,19.111,2.082c21.424-23.806,44.026-46.423,67.786-67.888h89.456c0.406,0,0.752-0.081,1.138-0.111
			c0.472,0.035,0.914,0.122,1.422,0.111c10.531-0.208,19.601-3.326,27.751-9.983c7.525-6.139,10.633-15.787,11.791-25.044
			c0.858-1.661,1.392-3.605,1.392-5.906v-31.976C307.192,289.504,281.609,289.504,281.609,305.997z"/>

</svg>
    </div>)

}
