import React from "react";

export default class Tour extends React.Component {
  render() {
    let {
      name,
      description,
      isOwner,
      thumbnail,
      isEdit,
      onDeleteClicked,
      onEditClicked,
      visualAssist,
      tourId,
      isVolunteer,
    } = this.props;
    if (isEdit) {
      return (
        <div className={"edit-tour-option"}>
          {name || "NO NAME YET!"}
          {isOwner && (
            <button className="tour-browser-action" onClick={onDeleteClicked}>
              Delete
            </button>
          )}
          <button className="tour-browser-action" onClick={onEditClicked}>
            Edit
          </button>
        </div>
      );
    } else {
      return (
        <div className={"tour-select-option-section"}>
          <div className={"participant-tour-option"}>
            <a
              href={`/enter_vr/${tourId}?selection=true&visual_assist=${visualAssist}`}
            >
              <img className={"participant-tour-thumbnail"} src={thumbnail} />
              {name || "NO NAME YET!"}
            </a>
          </div>
        </div>
      );
    }
  }
}
