import React, {useEffect, useRef, useState} from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Badge,
    Box,
    TextField,
    Button, useTheme, IconButton,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import {AdminSubscriber} from "../../channels/admin_channel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChatView from "./chat_view";
import useMessageHandler from "./useMessageHandler";
import useChatActions from "./useChatActions";
import withTheme from "../with_theme";
import ChatList from "./chat_list";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
    },
    contentWrapper: {
        display: "flex",
        flex: 1,
        overflow: "hidden",
        marginLeft: 300,  // Match ChatList drawer width (300px)
        transition: "margin 0.3s ease",  // Smooth slide effect for non-mobile
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,  // No margin for mobile, drawer overlays
        },
    },
    contentShift: {
        marginLeft: 0,  // Content shifts when drawer opens
    },
    chatList: {
        overflowY: "auto",
        flexGrow: 1,
    },
    appBarTitle: {
        zIndex: 9999999,
        [theme.breakpoints.down('sm')]: {
            zIndex: 1
        },
    },
    senderInfo: {
        marginBottom: theme.spacing(0.5),
        color: theme.palette.text.primary,
        fontWeight: 500,
        textAlign: "left",
        width: '100%',
    },

    senderIdentifier: {
        color: theme.palette.grey[500],
    },

    messageWrapper: {
        width: "100%",  // Ensure the entire message row takes full width
        "&:hover $deleteButton": {
            display: "block",
        },
    },
}));

const AdminChatUI = ({ userCanDelete, currentUser, isAdmin, messageTemplates, filterChattableType, chatsData: initialChatsData, chattableId = [] }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [canSendFreeform, setCanSendFreeform] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [chatsData, setChatsData] = useState(
        [...initialChatsData].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    );    // Input


    // messages for a chain
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);

    // Ref to store the latest selectedChat
    const selectedChatRef = useRef(null);

    // Sync ref with state
    useEffect(() => {
        selectedChatRef.current = selectedChat;
    }, [selectedChat]);

    const fetchChatById = (id, chatsData) => {
        // Find the chat in the local chatsData array using the chattable_id
        return chatsData.find(chat => chat.chattable_id === id) || null;
    };

    useEffect(() => {
        const initializeChat = async () => {
            if (chattableId) {
                const chat = await fetchChatById(chattableId, chatsData);
                if (chat) {
                    console.log('chat found')
                    await handleSelectChat(chat); // Select the fetched chat
                } else {
                    console.error("Chat not found or error fetching chat.");
                }
            }
        };
        initializeChat();
    }, [chattableId]);

    // Ref for the message container
    const messageContainerRef = useRef(null);

// Scroll to the bottom of the chat
    const scrollToBottom = () => {
        const container = messageContainerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    };

// Track whether the user is at the bottom
    const isAtBottom = () => {
        const container = messageContainerRef.current;
        return container
            ? Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 10
            : false;
    };

// Sync ref with state on chat switch
    useEffect(() => {
        if (selectedChat) {
            scrollToBottom();  // Scroll to bottom when switching chats
        }
    }, [selectedChat, messages]);  // Trigger on chat switch or new messages


    // Handle chat selection
    const handleSelectChat = async (chat) => {
        setSelectedChat(chat);
        try {
            const response = await fetch(`/admin/messages/${chat.id}/messages`);
            if (!response.ok) throw new Error(`Failed to fetch messages for chat ${chat.id}`);
            const fetchedMessages = await response.json();
            setMessages(fetchedMessages);

            setCanSendFreeform(fetchedMessages[0]?.messaged_in_last_24_hours)

            // Reset unread count for selected chat
            setChatsData((prevChats) =>
                prevChats.map((c) =>
                    c.id === chat.id ? { ...c, unreadCount: 0 } : c
                )
            );
            handleDrawerToggle();
        } catch (error) {
            console.error(error);
            alert("Could not load messages for this chat.");
        }
    };

    const handleReceiveMessage = useMessageHandler(
        setChatsData,
        selectedChatRef,
        setMessages,
        isAtBottom,
        scrollToBottom,
        setCanSendFreeform,
        filterChattableType
    );



    const { message, setMessage, setTemplateSid, handleSendMessage, handleDeleteMessage } =
        useChatActions(selectedChat, setMessages);

    const handleSend = async () => {
        const sentMessage = await handleSendMessage();

        if (sentMessage) {
            setChatsData((prevChats) =>
                prevChats.map((chat) =>
                    chat.id === selectedChat.id
                        ? { ...chat, content: sentMessage.content, unreadCount: 0 }
                        : chat
                )
            );
        }
    };



    // 1. Mark as read on chat switch
    useEffect(() => {
        if (selectedChat && messages.length > 0) {
            markMessagesAsRead(selectedChat.id, selectedChat.type);
        }
    }, [selectedChat]);

    const markMessagesAsRead = async (chattableId, chattableType) => {
        try {
            await fetch(`/admin/messages/${chattableId}/mark_as_read`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
                },
                body: JSON.stringify({ chattable_id: chattableId, chattable_type: chattableType }),
            });
        } catch (error) {
            console.error('Failed to mark messages as read:', error);
        }
    };

    // 1. Mark as read on chat switch
    useEffect(() => {
        if (selectedChat && messages.length > 0) {
            markMessagesAsRead(selectedChat.id, selectedChat.type);
        }
    }, [selectedChat]);

    // 2. Mark as read on scroll to bottom
    const handleScroll = () => {
        const container = messageContainerRef.current;
        if (container) {
            const atBottom = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 30;
            if (atBottom && selectedChat) {
                markMessagesAsRead(selectedChat.id, selectedChat.type);
            }
        }
    };

    // Attach scroll listener
    useEffect(() => {
        const container = messageContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && selectedChat) {
                markMessagesAsRead(selectedChat.id, selectedChat.type);
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [selectedChat]);


    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <Box className={classes.root}>
            <AdminSubscriber onReceiveMessage={handleReceiveMessage} />
            <AppBar className={classes.appBarTitle} position="static"
            >
                <Toolbar>
                    {isMobile && (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            ☰
                        </IconButton>
                    )}
                    <Typography variant="h6">Admin Chat</Typography>
                    <Box sx={{ flexGrow: 1 }} />  {/* This pushes the next element to the right */}

                    <Button
                        onClick={handleGoBack}  // Your back navigation function
                        sx={{
                            color: 'inherit',
                            textTransform: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        ← Go Back
                    </Button>                </Toolbar>
            </AppBar>
            <Box className={classes.contentWrapper}>

                {/* Chat Browser Panel */}
                <Drawer
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isMobile ? mobileOpen : true}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    {/*<Toolbar />*/}
                    <ChatList
                        chatsData={chatsData}
                        selectedChat={selectedChat}
                        handleSelectChat={handleSelectChat}
                        mobileOpen={mobileOpen}
                        handleDrawerToggle={handleDrawerToggle}
                        isMobile={isMobile}
                    />
                </Drawer>

                <ChatView
                    selectedChat={selectedChat}
                    messages={messages}
                    messageContainerRef={messageContainerRef}
                    handleDeleteMessage={handleDeleteMessage}
                    currentUser={currentUser}
                    message={message}
                    setMessage={setMessage}
                    handleSendMessage={handleSend}
                    userCanDelete={userCanDelete}
                    messageTemplates={messageTemplates}
                    setTemplateSid={setTemplateSid}
                    canSendFreeform={canSendFreeform}
                    isAdmin={isAdmin}
                />
            </Box>
        </Box>
    );
};

export default withTheme(AdminChatUI);
