import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography, Link, Paper, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
import * as PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    lightbox: {
        padding: theme.spacing(4),
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    consentLink: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: '#28a745', // Make the button green
        color: '#ffffff',
        borderRadius: theme.shape.borderRadius,
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#218838', // Darker shade of green for hover effect
        },
    },
    actionButton: {
        marginTop: theme.spacing(2),
    },
    boldText: {
        fontWeight: 'bold',
    },
    list: {
        width: '100%', // Ensure the list takes the full width of the lightbox
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1], // Add a slight shadow for depth
    },
    listItem: {
        '&:not(:last-child)': {
            borderBottom: '1px solid', // Add a separator between list items
            borderColor: theme.palette.divider,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    divider: {
        margin: `${theme.spacing(1)}px 0`,
    },
    description: {
        margin: `${theme.spacing(2)}px 0`,
    },
    closeButton: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1.5),
        minWidth: 200, // Increase button width
        backgroundColor: theme.palette.primary.main, // Use theme's primary color
        color: '#ffffff',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darken on hover
        },
    },
    completed: {
        textDecoration: 'line-through',
        color: '#4caf50', // Use green color to indicate completion
        '&::before': {
            content: '"✓ "', // Unicode check mark
            color: '#4caf50', // Green color for the check mark
            fontWeight: 'bold',
        },
    },
    nonClickable: {
        color: theme.palette.action.disabled,
        pointerEvents: 'none', // Disable pointer events
    },
    clickableItem: {
        cursor: 'pointer', // Make the item look clickable
        color: theme.palette.primary.main, // Change text color to theme primary color
        '&:hover': {
            textDecoration: 'underline', // Underline on hover to mimic a link
        }
    },
}));

export default function ExperimentTodosLightbox({ todos, experimentName, experimentDescription, setModalOpen, userName }) {
    const classes = useStyles();

    const consentTodo = todos.find(todo => todo.data.type === 'requires_consent' && todo.data.status !== 'completed');    const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

    const toggleDescription = () => {
        setDescriptionExpanded(!isDescriptionExpanded);
    };
    const renderDescription = () => {
        if (experimentDescription.length > 100 && !isDescriptionExpanded) {
            return (
                <>
                    {experimentDescription.substring(0, 100)}...
                    <Button color="primary" onClick={toggleDescription}>read more</Button>
                </>
            );
        }
        return (
            <>
                {experimentDescription}
                {experimentDescription.length > 100 &&
                    <Button color="primary" onClick={toggleDescription}>read less</Button>
                }
            </>
        );
    };



    const sortedTodos = todos.sort((a, b) => {
        // Assign a very large sort_order value when undefined to ensure these items move to the end
        const sortOrderA = a.data.sort_order !== undefined ? a.data.sort_order : Number.MAX_SAFE_INTEGER;
        const sortOrderB = b.data.sort_order !== undefined ? b.data.sort_order : Number.MAX_SAFE_INTEGER;

        console.log(`Comparing:\n a: ${sortOrderA}, b: ${sortOrderB}`);
        const primarySort = sortOrderA - sortOrderB;
        console.log(`Primary sort result for ${a.id} vs ${b.id}: ${primarySort}`);
        if (primarySort !== 0) {
            console.log(`Returning primary sort result: ${primarySort}`);
            return primarySort;
        }

        // Fallback to secondary sort by scheduled_session_time, if available
        const timeA = a.data.scheduled_session_time || "9999-12-31T23:59:59Z";
        const timeB = b.data.scheduled_session_time || "9999-12-31T23:59:59Z";
        const secondarySort = timeA.localeCompare(timeB);
        console.log(`Secondary sort result for ${a.id} vs ${b.id}: ${secondarySort}`);
        return secondarySort;
    });

    console.log("Sorted Todos:");
    console.log(sortedTodos);


    const firstIncompleteIndex = sortedTodos.findIndex(todo => todo.data.status !== 'completed');

    console.log(firstIncompleteIndex)
    if (consentTodo) {
        return (
            <div className={classes.lightbox}>
                <Typography variant="h5" gutterBottom>
                    Help Test Acquaint’s New Training Modules for Volunteers!
                </Typography>
                <Typography paragraph>
                    Hello <span className={classes.boldText}>{userName}</span>,
                </Typography>
                <Typography paragraph>
                    We're excited to offer you an opportunity to be among the first to try out Acquaint's latest feature: <span className={classes.boldText}>{experimentName}</span> training module. In doing so, you will help shape the future of our program for volunteers who join after you. Your feedback will be invaluable in helping us refine and improve it.
                </Typography>
                <Typography paragraph>
                    <span className={classes.boldText}>What will it include?</span>
                </Typography>
                <ol>
                    <li>
                        <Typography>
                            <span className={classes.boldText}>Complete a Training Module:</span> After connecting for some Acquaint sessions, you will be given access to the training module. Completing it should take roughly 25 - 35 minutes.
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <span className={classes.boldText}>Feedback and Reporting:</span> We will give you several surveys to complete following Acquaint sessions. Each survey should take about 5 minutes.
                        </Typography>
                    </li>
                </ol>
                <Typography paragraph>
                    Interested in being a part of this exciting journey? Click below to join or decline!
                </Typography>
                <Link href={consentTodo.data.todo_url} target="_blank" rel="noopener noreferrer" className={classes.consentLink}>
                    Join or Decline Experiment
                </Link>
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.actionButton}
                    onClick={() => setModalOpen(false)}
                >
                    Decide Later
                </Button>
            </div>
        );
    }

    return (
        <div className={classes.lightbox}>
            <Typography variant="h5" gutterBottom>
                Acquaint Experiment To-Do List
            </Typography>
            <Typography variant="h6" gutterBottom>
                Thank you for taking part in Acquaint’s experiment, <span className={classes.boldText}>{experimentName}</span>:
            </Typography>
            <Typography variant="subtitle1" className={`${classes.description} ${classes.boldText}`}>
                {renderDescription()}
            </Typography>
            <List className={classes.list}>
                {sortedTodos.filter(todo => todo.data.type !== 'requires_consent').map((todo, index) => {
                    const isCompleted = todo.data.status === 'completed';
                    const isClickable = !isCompleted && (index <= firstIncompleteIndex || firstIncompleteIndex === -1);
                    return (
                        <React.Fragment key={index}>
                            {index > 0 && <Divider className={classes.divider} />}
                            <ListItem
                                button={isClickable}
                                component={isClickable ? "a" : "li"}
                                href={isClickable ? todo.data.todo_url : undefined}
                                target={isClickable ? "_blank" : undefined}
                                rel={isClickable ? "noopener noreferrer" : undefined}
                                className={`${classes.listItem} ${!isClickable ? classes.nonClickable : classes.clickableItem} ${isCompleted ? classes.completed : ''}`}
                            >
                                <ListItemText primary={`${index + 1}. ${todo.data.message}${isCompleted ? '' : isClickable ? ' (CLICK TO COMPLETE)' : ''}`} />                            </ListItem>
                        </React.Fragment>
                    );
                })}
            </List>
            <Typography variant="body2" color="textSecondary" align="center" className={classes.description}>
                Questions or concerns? Please contact <Link href="mailto:support@acquaint.org">support@acquaint.org</Link>.
                For more information on how we use this data, read our <Link href="/privacy_policy" target="_blank">Privacy Policy</Link>.
            </Typography>
            <Button onClick={() => setModalOpen(false)} variant="contained" className={classes.closeButton}>
                Close
            </Button>
        </div>
    );
}
