import React from 'react';
import {FeaturedTour} from "./featured_tour";
import '../../../assets/stylesheets/participant_home.scss'
import '../../../assets/stylesheets/featured_tours.scss'

export default {title: 'Featured Tour'};

export const featuredTour = () => (<FeaturedTour
    name={"National Museum of Anthropology"}
    tourId={'123'}
    visualAssist={false}
    thumbnail={'./images/common/default_tour_thumbnail.jpg'}
    description={'Welcome to this wonderful new tour, I hope you like it.'}
/>);
