import React from "react"
import {AudioInputTest, AudioOutputTest, testAudioInputDevice, testAudioOutputDevice, testMediaConnectionBitrate } from "@twilio/rtc-diagnostics";
import {connect, createLocalTracks, PreflightTestReport, runPreflight} from "twilio-video";
import {logExperienceData} from "../utils/guided_session";
import {CheckPermissions} from "./twilio_tests/CheckPermissions";
import {CheckBrowser} from "./twilio_tests/CheckBrowser";

const fetchTwilioToken = async () => {
    return await fetch("/generate_test_twilio_token", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );
};

export const logTestOutput = (testName, result) => {
    let { success, details, error } = result
    fetch("/log_test_output", {
            method: "POST",
            body: JSON.stringify({
                success: success,
                details: details,
                error: error,
                test: testName
            }),
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );
};

export const persistDeviceTest = (report) => {
    fetch("/persist_device_test", {
            method: "POST",
            body: JSON.stringify({
                report: report
            }),
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );
};

const turnServersReachable = (successfulSteps) => {
    return successfulSteps.indexOf('mediaAcquired') !== -1
}

const signalingGatewayReachable = (successfulSteps) => {
    return successfulSteps.indexOf('dtlsConnected') !== -1 || successfulSteps.indexOf('peerConnectionConnected') !== -1
}

const runPreflightTest = async (setResult, setProgress, setMessageText, setTestRunning, successfulSteps, setSuccessfulSteps) => {
    const twilioToken = await fetchTwilioToken().then(data => {
        return data.json()
    });

    let steps = []
    const preflightTest = runPreflight(twilioToken.token);

    preflightTest.on('progress', (progress) => {
        if(steps.indexOf(progress) === -1) {
            steps.push(progress)
            setSuccessfulSteps([...steps])
        }

        setProgress(progress)
    });

    preflightTest.on('completed', (report) => {
        const turnSuccess = turnServersReachable(steps)
        const signalingSuccess = signalingGatewayReachable(steps)

        if(turnSuccess && signalingSuccess) {
            setMessageText('Connection test successful!')
        } else {
            setMessageText('Connection test finished...')
        }
        setResult({success: true, details: {turnSuccess, signalingSuccess, report}})
        setTestRunning(false)
    });

    preflightTest.on('failed', (error) => {
        setMessageText('Test Failed.')
        setResult({success: false, details: 'preflight failed', error: error.message})
        setTestRunning(false)
    });

}

const runDeviceCheck = (setMediaFound) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        // TODO: Track this.
        console.log("enumerateDevices() not supported.");
        setMediaFound({foundInput, foundOutput, error: "Unable to list audio inputs/outputs on this device." })

        return;
    }

    // List cameras and microphones.

    let foundInput = null;
    let foundOutput = null;

    navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {
            console.log(devices)
            devices.forEach(function(device) {
                console.log(device.kind)
                if(device.kind === 'audioinput'){
                  if(foundInput) {
                     return
                  }
                  foundInput = device.label;
                } else if(device.kind === 'audiooutput') {
                    if(foundOutput) {
                        return
                    }
                    foundOutput = device.label;
                }
            });
            setMediaFound({foundInput, foundOutput})
        })
        .catch(function(err) {
            setMediaFound({foundInput, foundOutput, error: err.name + ": " + err.message })
        })
}

const executeAudioInputTest = (setVolume, setResult, setMessageText) => {
    const audioInputDeviceTest = testAudioInputDevice();

    audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
        // setMessageText('Test is running - make some noise into your microphone - you should see the color green in the volume indicator.')
        setVolume(volume)
    });

    audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
        setMessageText('There was an error while running the microphone test.')
        console.error(error);
        setResult({success: false, details: 'no input signal detected', error: error.message})
    });

    audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
        let messageTextDetail = "Success!"
        let signalValues = report.values.filter((value) => value > 10)
        if (signalValues.length > 1) {
            setResult({success: true, details: 'input signal detected', report: report})
        } else {
            messageTextDetail = "Quiet or no audio input detected. Check audio settings if you are on a laptop/PC. Try restarting device if mobile."
            setResult({success: false, details: 'no input signal detected'})
        }

        // TODO: log all values here...
        setMessageText(`Microphone test complete: ${messageTextDetail}`)
    });

    setTimeout(() => {
        audioInputDeviceTest.stop();
    }, 5000);
}

const executeAudioOutputTest = (setOutputVolume, setAudioTestRun) => {
    const audioOutputDeviceTest = testAudioOutputDevice({
        // deviceId: ...,
    });

    audioOutputDeviceTest.on(AudioOutputTest.Events.Volume, (volume) => {
        setOutputVolume(volume);
    });

    audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
        console.error(error);
    });

    audioOutputDeviceTest.on(AudioOutputTest.Events.End, (report) => {
        setAudioTestRun(true)
    });

    setTimeout(() => {
        audioOutputDeviceTest.stop();
    }, 3000);

}

const VolumeMeter = (props) => {
    const min = 0;
    const max = 200;
    let { value, title } = props
    return <meter min={min} max={max} value={value}>{title}</meter>
}

const PreflightMeter = (props) => {
    const min = 0;
    const max = 100;
    let { value, title } = props
    return <meter min={min} max={max} value={value}>{title}</meter>
}

const DeviceCheck = () => {
    let [defaultMediaValues, setDefaultMediaValues] = React.useState({
        foundInput: "",
        foundOutput: ""
    })

    return <div>
        <button type={'button'} onClick={() => runDeviceCheck(setDefaultMediaValues)}>Run Device Check</button>
        <div>
            <div>
                Input used for sessions: {defaultMediaValues.foundInput}
            </div>
            <div>
                Output used for sessions: {defaultMediaValues.foundOutput}
            </div>
            {defaultMediaValues.error && <div>ERROR: {defaultMediaValues.error} </div>}
        </div>
    </div>
}

const InputTest = (props) => {
    let {nextTest, addResult, results} = props;

    // const defaultMessageText = "Lets test your microphone! Please click \"Run Mic Test\" to run the test."
    const defaultMessageText = 'Make some noise into your microphone! We are testing input volume.'
    let [inputVolume, setInputVolume] = React.useState(0)
    let [messageText, setMessageText] = React.useState(defaultMessageText)
    let [started, setStarted] = React.useState(false)
    const resetTest = () => {
        setInputVolume(0)
        setMessageText(defaultMessageText)
        results['input'] = null
        setStarted(true)
        runTest()
    }

    // TODO: if test fails only give RETRY option
    const runTest = () => {
        executeAudioInputTest(
            setInputVolume,
            (result) => addResult('input', result),
            setMessageText
        )
    }

    if(!started) {
        setStarted(true)
        runTest()
    }

    return <div className={'volume-test'}>
        <TestText text={messageText} result={results['input'] || {}}/>

        {!results['input'] &&
        <div>
            {/*<BasicLinkButton disabled={running} text={'Run Mic Test'} onClick={runTest}/>*/}
            <VolumeMeter title={'Input Volume'} value={inputVolume}/>
        </div>
        }
        {results['input'] && <div>
            {results.input.success ? <OutputTest {...{nextTest, addResult, results}}/> : <BasicLinkButton text={'Retry'} onClick={resetTest}/>}
                {/*<BasicLinkButton text={'Proceed'} onClick={nextTest}/>*/}
            </div>
        }
    </div>
}


const OutputTest = (props) => {
    let { nextTest, results, addResult} = props
    let [outputVolume, setOutputVolume] = React.useState(0)
    let [audioTestRun, setAudioTestRun] = React.useState(false)
    let [audioTestStarted, setAudioTestStarted] = React.useState(false)

    const runTest = () => {
        setAudioTestStarted(true)
        executeAudioOutputTest(setOutputVolume, setAudioTestRun)
    }
    const resetTest = () => {
        setAudioTestStarted(false)
        setAudioTestRun(false)
    }

    return <div className={'volume-test'}>
        {!audioTestRun && <div>
            <TestText text={'Test your volume!'} result={{}}/>

            <BasicLinkButton disabled={audioTestStarted} text={'Run Volume Test'} onClick={runTest}/>
            <VolumeMeter title={'Output Volume'} value={outputVolume}/></div>
        }
        {audioTestRun && <div>
            <TestText text={
                `Were you able to hear anything? If not, check to make sure your volume is up and/or that your device is using the correct speaker, then retry`
            } result={{}}/>
            <div>
                <BasicLinkButton text={'Yes, Proceed'} onClick={nextTest}/>
                <BasicLinkButton text={'No, Retry'} onClick={resetTest}/>
            </div>
        </div>
        }
    </div>
}

const StartTest = (props) => {
    let { nextTest } = props
    return <>
        <h3>Test Your Device</h3>
        <TestText text={'Let\'s check you device setup to see if it is ready to use the Acquaint app. Click "Start Test!" below to begin.'} result={{}}/>
        <BasicLinkButton text={'Start Test!'} onClick={nextTest}/>
    </>
}

const MicTest = (props) => {
    return <CheckPermissions {...props}/>
}

const BrowserTest = (props) => {
    return <CheckBrowser {...props}/>
}

const TestText = (props) => {
    let {text, result} = props
    let additionalClass;
    if(result.success === true) {
        additionalClass = 'success'
    } else if(result.success === false) {
        additionalClass = 'failed'
    } else {
        additionalClass = 'info'
    }
    return <div className={`test-text ${additionalClass}`}>
        {text}
    </div>
}

const PreflightTest = (props) => {
    let { nextTest, results, addResult} = props
    let [testRunning, setTestRunning] = React.useState(false)
    let [messageText, setMessageText] = React.useState('Run connectivity tests')
    let [progress, setProgress] = React.useState(0)
    let [successfulSteps, setSuccessfulSteps] = React.useState([])

    const runTest = () => {
        setTestRunning(true)
        setMessageText('Running test. This may take a few moments...')
        runPreflightTest(
            (result) => addResult('preflight', result),
            setProgress,
            setMessageText,
            setTestRunning,
            successfulSteps,
            setSuccessfulSteps
        )
    }

    const resetTest = () => {
        results['preflight'] = null;
        setMessageText('run connectivity tests')
    }

    return <div className={'volume-test'}>
        <TestText text={messageText} result={results['preflight'] || {}}/>

        {!results['preflight'] && !testRunning &&
        <div>
            <BasicLinkButton disabled={testRunning} text={'Test Connection'} onClick={runTest}/>
        </div>
        }
        {testRunning && !results['preflight'] &&
            <div>
                 <div className={'twilio-test-spinner-wrapper'}><div className={'twilio-test-spinner'}/></div>
                {progress}
            </div>
        }
        {results['preflight'] && !testRunning && <div>
            {results.preflight.success === false && <BasicLinkButton text={'Retry'} onClick={resetTest}/>}
            {results.preflight.success && <BasicLinkButton text={'Proceed'} onClick={nextTest}/> }
        </div>
        }
    </div>
}

const DeviceReport = (props) => {
    let {results, onComplete} = props

    persistDeviceTest(results)

    return <div className={'device-report'}>
        <div className={'mb-2'}>
            <h3>Thank you! The tests we ran look good!</h3>
            <ul className={'outcome-list'}>
                <li>compatible browser</li>
                <li>microphone access granted</li>
                <li>your microphone seems loud enough</li>
                <li>volume is up</li>
                <li>network connection established</li>
            </ul>
            Note that connection quality may vary, so you will want to be in a location where you can get
            the best signal for your session.
        </div>

        <div className={'mb-3'}>
            <b>You're all done with the test!</b>
        </div>
        <a href={onComplete} data-turbolinks="false" className={'basic-link-button'}>Continue</a>
    </div>

}
const BasicLinkButton = (props) => {
    let {onClick, text, disabled} = props
    return <button type={'button'} disabled={disabled} className={'basic-link-button'} onClick={onClick}>{text}</button>
}

export default function TestAudioView(props) {

    let {onComplete} = props
    const tests = ['browser', 'mic_access', 'input', 'preflight'] //  'connectivity']

    const [runningTest, setRunningTest] = React.useState('')
    const [results, setResults] = React.useState({})

    const nextTest = () => {
        let currentIndex = tests.indexOf(runningTest)
        let nextIndex = currentIndex + 1
        if (nextIndex > tests.length) {
            setRunningTest('')
            return
        }
        if(!runningTest) {
            setRunningTest(tests[0])
            return
        }
        setRunningTest(tests[nextIndex])
    }

    const restart = () => {
        setResults([])
    }

    const addResult = (key, value) => {
        results[key] = value
        logTestOutput(key, value)
        setResults({...results})
    }

    const noResults = () => {
        return Object.keys(results).length === 0
    }

    return <div className={'test-audio-view mt-4'}>
        { noResults() && !runningTest && <StartTest nextTest={nextTest} /> }
        {runningTest === 'browser' && <BrowserTest {...{nextTest, addResult, results}}/>}
        {runningTest === 'mic_access' && <MicTest {...{nextTest, addResult, results}}/>}
        {runningTest === 'input' && <InputTest {...{nextTest, addResult, results}}/>}
        {runningTest === 'preflight' && <PreflightTest {...{nextTest, addResult, results}}/>}

        {/*{runningTest === 'DeviceCheck' && <DeviceCheck/>}*/}
        {/*{runningTest === 'Connectivity' && <ConnectivityTest {{nextTest, addResult, results}}/>}*/}

        { !noResults() && !runningTest && <DeviceReport
            onComplete={onComplete}
            {...{results, restart}}
        /> }
    </div>

}