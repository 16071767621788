import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HandOpenIcon } from "../icons/hand_open_icon";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxSizing: "border-box",
    },
    content: {
        flexGrow: 1,
        overflowY: "auto",
        padding: theme.spacing(4),
    },
    bottomOptions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2, 4),
        borderTop: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        flexShrink: 0,  // Prevent shrinking
    },
    raiseHandButton: {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        },
    },
    goBackButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    successText: {
        color: theme.palette.success.main,
    },
}));

export const raiseHand = (chattable, type) => {
    fetch("/messages/request_support", {
        method: "POST",
        body: JSON.stringify({
            message: {
                content: "Support request from session chat.",
                chattable_id: chattable,
                chattable_type: type,
            },
        }),
        headers: {
            "content-type": "application/json",
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
        },
    });
};

export const HelpInstructionsAndRaiseHand = (props) => {
    const { setShowHelp, chatData } = props;
    const classes = useStyles();

    const [handRaised, setHandRaised] = React.useState(false);

    const clickRaiseHandButton = () => {
        raiseHand(chatData.chattable_id, chatData.chattable_type);
        setHandRaised(true);
    };

    const handRaisedButtonClickedText =
        "Acquaint has been notified, we will try to check in via chat as soon as possible.";

    return (
        <Box className={classes.container}>
            {/* Scrollable Content */}
            <Box className={classes.content}>
                <Typography variant="h6" component="h2">
                    Help & Support
                </Typography>
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        If your session partner is not here after 5-10 minutes:
                    </Typography>
                    <ol>
                        <li>Use the "raise hand" feature below.</li>
                    </ol>
                    <Typography variant="subtitle1" gutterBottom>
                        If you are experiencing audio or other problems, try these (in order):
                    </Typography>
                    <ol>
                        <li>Hit End session and restart (once).</li>
                        <li>Check audio devices (mic/speaker)</li>
                        <li>Try a different browser or device.</li>
                        <li>Use the "raise hand" feature below.</li>
                    </ol>
                    <Typography variant="subtitle1" gutterBottom>
                        Raise Hand Feature:
                    </Typography>
                    <Typography variant="body2">
                        Click "raise hand" to get help from staff.{" "}
                        <i>Note: we may not be able to provide assistance at all times.</i>
                    </Typography>
                </Box>
            </Box>

            {/* Fixed Button Area */}
            <Box className={classes.bottomOptions}>
                {!handRaised ? (
                    <Button
                        variant="contained"
                        className={classes.raiseHandButton}
                        onClick={clickRaiseHandButton}
                        startIcon={<HandOpenIcon size={'1.5rem'} />}
                    >
                        Raise Hand
                    </Button>
                ) : (
                    <Typography className={classes.successText}>
                        {handRaisedButtonClickedText}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    className={classes.goBackButton}
                    onClick={() => setShowHelp(false)}
                >
                    Back To Chat
                </Button>
            </Box>
        </Box>
    );
};
