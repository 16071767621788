import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@material-ui/core";

const AutoPairingReports = ({ future_available_times }) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTimes, setSelectedTimes] = useState([]);

    // Fetch CSRF token from meta tag
    const getCSRFToken = () =>
        document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    const fetchReports = async () => {
        const response = await fetch("/volunteer_intelligence/auto_pairing_reports", {
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getCSRFToken(),
            },
        });
        const data = await response.json();
        setReports(data);
    };

    const generateReport = async () => {
        setLoading(true);
        const response = await fetch("/volunteer_intelligence/generate_auto_pairing_report", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getCSRFToken(),
            },
            body: JSON.stringify({
                selected_times: selectedTimes.map((option) => option.value),
            }),
        });

        const { report_id } = await response.json();
        const pollReport = async () => {
            const reportResponse = await fetch(`/volunteer_intelligence/poll_auto_pairing_report/${report_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": getCSRFToken(),
                },
            });
            const reportData = await reportResponse.json();

            if (reportData.status === "completed" || reportData.status === "failed") {
                setLoading(false);
                fetchReports();
            } else {
                setTimeout(pollReport, 2000);
            }
        };
        pollReport();
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const options = future_available_times.map((time) => ({
        value: time.value,
        label: time.label,
    }));

    return (
        <TableContainer component={Paper}>
            <h1>Auto Pairing Reports</h1>
            <div style={{ marginBottom: "20px" }}>
                <Select
                    isMulti
                    options={options}
                    onChange={(selectedOptions) => setSelectedTimes(selectedOptions)}
                    placeholder="Select available times..."
                />
            </div>
            <Button onClick={generateReport} disabled={loading}>
                {loading ? "Generating..." : "Generate Report"}
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Time Started</TableCell>
                        <TableCell>Time Completed</TableCell>
                        <TableCell>View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reports.map((report) => (
                        <TableRow key={report.id}>
                            <TableCell>{report.id}</TableCell>
                            <TableCell>{report.status}</TableCell>
                            <TableCell>{report.time_started || "N/A"}</TableCell>
                            <TableCell>{report.time_completed || "N/A"}</TableCell>
                            <TableCell>
                                <a href={`/volunteer_intelligence/proposed_pairings/${report.id}`}>
                                    View Pairings
                                </a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AutoPairingReports;
