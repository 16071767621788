import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useImageStyles = makeStyles((theme) => ({

    innerContainer: {
        position: 'relative',
        height: 200,
        width: 200,
    },
    courseImage: {
        width: '100%', // Makes the image responsive within the container
        height: '100%', // Ensures the image takes the full height of the container
        objectFit: 'contain', // Ensures the entire image is visible
    },
    completeBadge: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: 'green',
        color: 'white',
        padding: theme.spacing(1),
        zIndex: 1, // Ensure the badge appears above the image
    },
}));

const CourseImage = ({ imageUrl, completed }) => {
    const classes = useImageStyles();
    return (
            <div className={classes.innerContainer}>
                <img src={imageUrl} alt="Course Thumbnail" className={classes.courseImage} />
                {completed && <div className={classes.completeBadge}>COMPLETE</div>}
            </div>
    );
};

export default CourseImage;
