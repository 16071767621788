import React, {useState} from "react";
import {MembershipManagementSteps} from "./membership_management_steps/membership_management_steps";
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { useAlert } from 'react-alert'
import {CurrentMembershipInfo} from "./current_membership_info/current_membership_info";


// We probably want to configure this in the backend and pass it in as props.

// TODO: this probably belongs in util class somewhere...
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const replaceKeysWithSnakeCase = obj => {
    const output = {}
    for (let key in obj) {
        output[camelToSnakeCase(key)] = obj[key]
    }
    return output
}

const WrappedComponent = (props) => {
    const { currentMembershipInfo } = props

    const urlParams = new URLSearchParams(window.location.search);
    const upgrade = urlParams.get('upgrade');

    const [manageMembership, setManageMembership] = useState(!!upgrade)
    const alert = useAlert()
    const persistMembershipInfo = async (accountInfo, userProfile, membershipOptionId) => {
        // TODO: cleanup with async/await...
        const membershipSubmitResult = await fetch("/membership_management/submit", {
                method: "POST",
                body: JSON.stringify({
                    account_info: replaceKeysWithSnakeCase(accountInfo),
                    user_profile: replaceKeysWithSnakeCase(userProfile),
                    membership_option_id: membershipOptionId
                }),
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                }
            }
        );

        const data = await membershipSubmitResult.json()
        let stripe = Stripe(props.stripePublishableKey)
        if(data.session_id){
            // We've created a stripe session and will redirect to stripe
            const stripeResult = await stripe.redirectToCheckout({
                sessionId: data.session_id
            })
            // Something went wrong with stripe:
            if (stripeResult.error) {
                console.error(stripeResult.error)
                alert.show(result.error)
            }
        } else if(data.error_message) {
            alert.error(data.error_message)

        } else {
            // Its a free plan, handle accordingly...
            window.location.href = data.redirect_to
        }
    };

    const shouldShowMembershipInfo = () => {
        return !manageMembership && currentMembershipInfo.nextMembershipName !== 'Trial';
    }

    if (shouldShowMembershipInfo()) {
        return <CurrentMembershipInfo setManageMembership={setManageMembership} {...currentMembershipInfo} />
    } else {
        return <MembershipManagementSteps {...props} setManageMembership={setManageMembership} onSubmit={persistMembershipInfo}/>
    }
}

export default class AccountInfoView extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount () {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        // Todo we should probably make a generic react view HOC like this that can wrap each of our current react view components
        const alertProviderOptions = {
            position: 'bottom center',
            timeout: 5000,
            offset: '30px',
            transition: 'scale'
        }

        return (
            <AlertProvider template={AlertTemplate} {...alertProviderOptions}>
                <WrappedComponent {...this.props}/>
            </AlertProvider>
        )
    }
}
