import React, {useState} from "react"
import Select from 'react-select'


export const HoursPerWeekDropdown = (props) => {
    const { defaultHoursPerWeek, name } = props
    let [hoursPerWeek, setHoursPerWeek] = useState({label: defaultHoursPerWeek, value: defaultHoursPerWeek })

    return (
        <div className={'hours-per-week-dropdown'}>
            How Many Sessions Per Week Are You Able To Do?
            <Select
                defaultValue={hoursPerWeek || 1}
                options={[...Array(20).keys()].map((i) => {return {label: i,value: i}})}
                onChange={setHoursPerWeek}
                name={name}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
    )
}

export default HoursPerWeekDropdown