import React, {useEffect} from "react";

// Web-Push
// Public base64 to Uint
const urlBase64ToUint8Array = (base64String) => {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const Subscribed = (props) => {
    let {unsubscribe, test} = props
    return <div className={'subscribed-status'}>
        <div className={'subscribed-message'}>
            This browser is subscribed!
        </div>
        <a className={'basic-link-button'} onClick={unsubscribe}>
            Unsubscribe
        </a>
        <a className={'basic-link-button'} onClick={test}>
            Test
        </a>
    </div>
}

const Unsubscribed = (props) => {
    let {subscribe} = props
    return <div className={'subscribed-status'}>
        <div className={'subscribed-message'}>
            This browser is not subscribed!
        </div>
        <a className={'basic-link-button'} onClick={subscribe}>
            Subscribe
        </a>
    </div>
}

const NoPermissions = () => {
    return <div className={'subscribed-status'}>
        <div className={'subscribed-message'}>
            Notifications blocked, please enable notifications!
        </div>
    </div>
}

const SubscribeError = () => {
    return <div className={'subscribed-status'}>
        <div className={'subscribed-message'}>
            There was an error - please email Acquaint to fix!
        </div>
    </div>
}

const SubscribeNotSupported = () => {
    return <div className={'subscribed-status'}>
        <div className={'subscribed-message'}>
            Browser does not support notifications, please email Acquaint if you think this is an error!
        </div>
    </div>
}

export const PushNotificationManager = (props) => {
    let {vapidPublicKey, subscriptions} = props
    let [subscribed, setSubscribed] = React.useState('waiting')

    const testNotification = async () => {
        let reg = await navigator.serviceWorker.register('/service-worker.js', {scope: './'})
        let sub = await reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
        })
        sub = sub.toJSON()
        let endpoint = sub.endpoint
        let {auth, p256dh} = sub.keys

        fetch("/test_notification", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                },
                body: JSON.stringify({
                    push_notification_subscription: {
                        endpoint: endpoint,
                        auth: auth,
                        p256dh: p256dh,
                        user_agent: navigator.userAgent
                    }
                })
            }
        );
    }

    const unsubscribe = async () => {
        navigator.serviceWorker.ready.then(function (reg) {
            reg.pushManager.getSubscription().then(function (subscription) {
                subscription.unsubscribe().then(function (successful) {
                    if (successful) {
                        setSubscribed('unsubscribed')
                    }
                }).catch(function (e) {
                    setSubscribed('error')
                })
            })
        });
    }

    const enableNotifications = async () => {
        let permissionResult = await new Promise(function (resolve, reject) {
            // necessary according to: https://web.dev/push-notifications-subscribing-a-user/
            const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
            });

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        })
        if (permissionResult !== 'granted') {
            // TODO: handle no permissions : get image saying what to do
            setSubscribed('no_permissions')
            return
        }
        if (navigator.serviceWorker) {
            let reg = await navigator.serviceWorker.register('/service-worker.js', {scope: './'})
            let sub = await reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
            })

            if (sub === null) {
                setSubscribed('unsubscribed')
            } else {
                // We have a subscription, update the database and update UI
                subscribe(sub.toJSON())
                // TODO: we should probably handle errors here
                setSubscribed('subscribed')
            }
        }
    }

    const subscribe = async (subscription) => {
        let endpoint = subscription.endpoint
        let {auth, p256dh} = subscription.keys

        // let userAgentInfo = new UAParser().getResult();
        return await fetch("/subscribe_to_push_notification", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                },
                body: JSON.stringify({
                    push_notification_subscription: {
                        endpoint: endpoint,
                        auth: auth,
                        p256dh: p256dh,
                        user_agent: navigator.userAgent
                    }
                })
            }
        );
    };

    // NOTE: we will need to have people refresh the page if they
    useEffect(() => {
        const asyncEffect = async () => {
            if (!('serviceWorker' in navigator)) {
                // TODO: Service Worker isn't supported on this browser, disable or hide UI.
                setSubscribed('not_supported')

                return;
            }

            if (!('PushManager' in window)) {
                // TODO: Push isn't supported on this browser, disable or hide UI.
                setSubscribed('not_supported')
                return;
            }

            // TODO: see if we already have a subscription...
            if (navigator.serviceWorker) {
                let reg = await navigator.serviceWorker.register('/service-worker.js', {scope: './'})

                let sub = await reg.pushManager.getSubscription()
                if (sub) {
                    enableNotifications()
                    setSubscribed('subscribed')
                } else {
                    setSubscribed('unsubscribed')
                }
            }
        }
        asyncEffect();
    }, [])

    return <div className={'push-notification-manager'}>
        {(subscribed === 'waiting') && <div className={'manage-push-notifications-spinner-wrapper'}>
            <div className={'manage-push-notifications-spinner'}/>
        </div>}
        {(subscribed === 'subscribed') && <Subscribed unsubscribe={unsubscribe} test={testNotification}/>}
        {(subscribed === 'unsubscribed') && <Unsubscribed subscribe={enableNotifications}/>}
        {(subscribed === 'no_permissions') && <NoPermissions/>}
        {(subscribed === 'error') && <SubscribeError/>}
        {(subscribed === 'not_supported') && <SubscribeNotSupported/>}

        <div className={'push-notification-manager-description'}>
            <b>Acquaint notifications</b> are meant to be similar to what your phone does when a new text or other message comes into your phone, except they will be notifications about important Acquaint info, like when you are scheduled in a session or reminders to check in. <b>This will NOT work with iOS/Safari.</b>
        </div>

        <div className={'push-notification-manager-description'}>
            <b>Note:</b> On Android, you may be asked to "install" Acquaint to your home screen, this is optional. Installing will put a link on your home screen that gives you easy access to the Acquaint app and shows you the number of notifications you have but not much more.
        </div>

        <div className={'push-notification-manager-description'}>
            Please email us at <a href={'mailto:support@acquaint.org'}>Acquaint Support</a> if you have questions.
        </div>
    </div>
}

export default PushNotificationManager