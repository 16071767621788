import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const links = (email, givenLinks) => {
    return givenLinks.map((link, index) => {
        let prefix = index === 0 ? '' : ' | '
        return <>{prefix}<a key={`${email}-link-index`} href={link.url}>{link.label}</a></>
    })
}

const checkboxValue = (someBool) => {
    return someBool ? '✔' : ''
}

const filterRowsByEmail = (rows, text) => {
    return rows.filter((row) => { return row.email.indexOf(text) !== -1 })
}

export default function UserTable(props) {
    const classes = useStyles();
    const { rows } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filterText, setFilterText] = React.useState('')

    const filterTextChanged = (filterText) => {
        setPage(0);
        setFilterText(filterText.target.value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let filteredRows = filterRowsByEmail(rows, filterText)
    return (
        <>
            <div>
                <div>Filter By Email:</div>
                <input type={'text'} onChange={filterTextChanged} value={filterText}/>
            </div>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">Confirmed</TableCell>
                        <TableCell align="right">Guide</TableCell>
                        <TableCell align="right">Role</TableCell>
                        <TableCell align="right">Facilitator</TableCell>
                        <TableCell align="right">Qualifications</TableCell>
                        <TableCell align="right">Links</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.email}>
                            <TableCell component="th" scope="row">
                                {row.email}
                            </TableCell>
                            <TableCell align="right">{checkboxValue(row.confirmed)}</TableCell>
                            <TableCell align="right">{checkboxValue(row.volunteer)}</TableCell>
                            <TableCell align="right">{row.tour_builder}</TableCell>
                            <TableCell align="right">{checkboxValue(row.facilitator)}</TableCell>
                            <TableCell align="right">{row.qualifications && row.qualifications.join(' | ')}</TableCell>
                            <TableCell align="right">{links(row.email, row.links)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </>
            );
}
