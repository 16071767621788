import React from "react"
import TrainingRound, {getFeedbackMessage} from "../TrainingRound";
import TrainingRoundList from "../TrainingRoundList";
import withTheme from "../with_theme";

class AITraining extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            completedRounds: [],
            user_ranking: 0,
            user_average_score: 0,
            in_round: false,
            isRoundsCollapsed: true,
            challengeMode: this.props.challengeMode || false,
            challengeObject: this.props.challengeObject || null,
            finalChallengeRoundJustCompleted: false,
        };
    }

    componentDidMount() {
        this.fetchCompletedRounds();
        // Temporarily disable
        // this.fetchUserRanking();
    }

    fetchCSRFToken = () => {
        const csrfToken = document.querySelector("meta[name='csrf-token']");
        return csrfToken ? csrfToken.content : '';
    };

    fetchCompletedRounds = async () => {
        const userTrainingId = this.props.userTraining.id;
    
        try {
          const response = await fetch(`/user_trainings/${userTrainingId}/fetch_completed_rounds`, {
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              "X-CSRF-Token": this.fetchCSRFToken()
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            this.setState({
              completedRounds: data.reverse(),
            });
          } else {
            console.error('Failed to fetch completed rounds data');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    onChallengeCompletion = () => {
        this.setState({ finalChallengeRoundJustCompleted: true });
    }
    handleRoundCompletion = (completedRound) => {
        this.setState((prevState) => ({
            completedRounds: [completedRound, ...prevState.completedRounds],
            challengeObject: this.state.challengeMode ? {
                ...prevState.challengeObject,
                successfulRounds: completedRound.score >= 7 ? prevState.challengeObject.successfulRounds + 1 : prevState.challengeObject.successfulRounds,
                isComplete: prevState.challengeObject.successfulRounds + 1 >= prevState.challengeObject.totalRounds,
            } : prevState.challengeObject,
        }));
        this.setState({in_round: false})
    };

    markRoundAsDeleted = async (roundId) => {
        await fetch(`/ai_training_rounds/${roundId}/mark_as_deleted`, { // Adjust endpoint as necessary
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRF-Token": this.fetchCSRFToken(),
            },
            body: JSON.stringify({ deleted: true }),
        });

        this.fetchCompletedRounds(); // Refresh the list of completed rounds
    };

    toggleCompletedRounds = () => {
        this.setState(prevState => ({
            isRoundsCollapsed: !prevState.isRoundsCollapsed
        }));
    };

    render() {
        return (
          <div className="ai-training-container">
            <div className="rounds-container">
                {this.state.challengeObject?.backToMicrocourseUrl && (
                    <button className="back-to-microcourse-button" onClick={() => window.location.href = this.state.challengeObject.backToMicrocourseUrl}>
                        Back to Microcourse
                    </button>
                )}
                {this.state.challengeMode && (
                    <div>
                        <h4>Challenge Progress</h4>
                        {!this.state.finalChallengeRoundJustCompleted && <p>Successful Rounds: {this.state.challengeObject.successfulRounds} / {this.state.challengeObject.totalRounds}</p>}
                        {(this.state.challengeObject.isComplete || this.state.finalChallengeRoundJustCompleted ) && (
                            <p className="congratulations-message">Congratulations! You have completed the challenge.</p>
                        )}
                    </div>
                )}
                {!this.state.challengeObject?.isComplete && <TrainingRound
                    userTraining={this.props.userTraining}
                    aiTrainingChallengeId={this.state.challengeObject?.aiTrainingChallengeId}
                    onRoundCompletion={this.handleRoundCompletion}
                    onChallengeCompletion={this.onChallengeCompletion}
                />}

                {this.state.completedRounds.length > 0 && <>
                <div className={'button-container'}>
                    <button className='secondary-button' onClick={this.toggleCompletedRounds}>
                    {this.state.isRoundsCollapsed ? `Show Completed Rounds (${this.state.completedRounds.length})` : 'Hide Completed Rounds'}
                    </button>
                </div>
                {!this.state.isRoundsCollapsed &&
                    <>
                        {this.state.completedRounds.map((round, index, array) => (
                            <div className="round-container" key={round.id}>
                                <h5><strong>Round {array.length - index}</strong></h5>
                                <h6><strong>AI INITIAL RESPONSE: </strong></h6>
                                <p className="round-ai-response">{round.ai_initial_response}</p>
                                <h6><strong>YOUR INPUT: </strong></h6>
                                <p>{round.user_input}</p>
                                <h6><strong>FEEDBACK: </strong></h6>
                                <p>{getFeedbackMessage(round.score)}</p>
                                <h6><strong>AI FEEDBACK: </strong></h6>
                                <p>{round.ai_feedback}</p>
                                {!this.state.challengeMode && <button onClick={() => this.markRoundAsDeleted(round.id)} className="delete-round-button">Delete</button>}
                            </div>
                        ))}

                    </>
                }
                </>}
            </div>
          </div>
        );
    }
}

export default withTheme(AITraining);