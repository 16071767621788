import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import consumer from "../../channels/consumer";
import { ActionCableConsumer, ActionCableProvider } from "react-actioncable-provider";

// Define styles using makeStyles
const useStyles = makeStyles({
    waitingRoom: {
        padding: "2rem",
        margin: "auto",
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: (props) => getGradientBackground(props.status),
        transition: "background 0.5s ease-in-out", // Smooth transition for color change
        '@media (max-width: 600px)': { // Media query for smaller screens
            padding: "0", // Remove padding on smaller screens
        },
    },
    waitingRoomNotes: {
        textAlign: "left",
        maxWidth: "500px",
    },
    // Spinner wrapper styles
    spinnerWrapper: {
        display: "inline-block",
        width: "7rem",
        height: "7rem",
        overflow: "visible",
        position: "relative",
        marginTop: "1.5rem",
    },
    // Style for the status message
    statusMessage: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        margin: "1rem 0",
    },
    // Style for the conspicuous message
    conspicuousMessage: {
        fontSize: "1.1rem",
        color: "#ff0000", // Red color for high visibility
        margin: "1rem 0",
    },
});

// Function to create a smaller gradient background based on status
const getGradientBackground = (status) => {
    let color;
    switch (status) {
        case "on_time":
            color = "rgba(144, 238, 144, 1)"; // Intense light green
            break;
        case "limbo":
            color = "rgba(255, 105, 180, 1)"; // Intense brighter pink
            break;
        case "on_time_coverage":
            color = "rgba(255, 255, 224, 1)"; // Intense light yellow
            break;
        case "limbo_coverage":
            color = "rgba(255, 105, 180, 1)"; // Intense brighter pink
            break;
        case "locked_in":
            color = "rgba(173, 216, 230, 1)"; // Intense light blue
            break;
        default:
            color = "rgba(255, 255, 255, 1)"; // Intense white
    }
    // Adjust the size and focus of the gradient to be even smaller
    return `radial-gradient(circle at center, ${color} 10%, white 50%)`;
};

// Define messages for each status
const statusMessages = {
    on_time:
        "Waiting for your session partner to join! This screen will update as soon as they are ready, so please be attentive and prepared to start the session. We hope you have a great session!",
    limbo:
        "You were ready 5 or more minutes after the session start time, so we may no longer be able to find you a session partner. However, please wait at least until 10 minutes after the start time to see if we can get you paired!",
    on_time_coverage:
        "You're scheduled as backup/coverage 🙏 Thank you for being backup! It is an incredibly important volunteer role. Please keep an eye on this page and the chat until ~10 minutes after the session start. We will let you know if backup is needed for a session.",
    limbo_coverage:
        "You're scheduled as backup/coverage 🙏 Thank you for being backup! It is an incredibly important volunteer role. Please keep an eye on this page and the chat until ~10 minutes after the session start. We will let you know if backup is needed for a session.",
    locked_in:
        "You are locked in for the session. Waiting for the traveler to start. Please be ready to begin as soon as the session starts.",
    default:
        "Waiting for the session to start. We will start rearranging the session at 5 minutes after if needed.",
};

const WaitingRoom = (props) => {
    const { userId, isCoverage } = props;
    const [status, setStatus] = useState(props.status);
    // Apply styles using makeStyles
    const classes = useStyles({ status });

    const traveler_ack_to_guide_ready = () => {
        return fetch(`/scheduled_sessions/traveler_ack_to_guide_ready`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.link_to_join) {
                    window.location.href = data.link_to_join;
                }
            })
            .catch((error) => {
                console.log(error);
                // handle error
                // this is usually 404
                // and a normal condition.
            });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(`/scheduled_sessions/available`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.link_to_join) {
                        window.location.href = data.link_to_join;
                    }

                    // Update status only if it's "locked_in"
                    if (data.session_ready_status === "locked_in") {
                        setStatus(data.session_ready_status);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // handle error
                    // this is usually 404
                    // and a normal condition.
                });
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    const handleMessage = (data) => {
        if (data.link_to_join) {
            window.location.href = data.link_to_join;
        }

        if (data.type === "GUIDE_READY") {
            traveler_ack_to_guide_ready();
        }
    };

    const onConnected = () => {
        console.log("connected to waiting room");
    };

    // Get the appropriate message based on the status
    const message = statusMessages[status] || statusMessages.default;

    return (
        <ActionCableProvider cable={consumer}>
            <div className={classes.waitingRoom}>
                <div>
                    <h2>
                        {isCoverage
                            ? "You're scheduled as backup/coverage 🙏"
                            : status === "limbo"
                                ? "Session Partner Uncertain: Please Stand By"
                                : "Waiting for your session partner to join!"}
                    </h2>
                    <div className={classes.conspicuousMessage}>
                        Please do not leave this page while you are waiting for your session, or you may not get paired!
                    </div>
                    <div className={classes.statusMessage}>{message}</div>
                    <div className={classes.waitingRoomNotes}>
                        {isCoverage ? (
                            <>
                                <div className={"mb-2"}>
                                    <b>Timing:</b> Usually, we let backup/coverage volunteers
                                    know if they are needed by 5 minutes after, but it can be up
                                    to 10.
                                </div>
                                <div className={"mb-2"}>
                                    <b>Automatic:</b> If you are assigned to a session, you will
                                    automatically be joined into that session.
                                </div>
                                <div className={"mb-2"}>
                                    <b>Stay tuned:</b> Feel free to ask us any questions or use
                                    the help/raise hand buttons in the chat to get our attention!
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={"mb-2"}>
                                    <b>Punctuality:</b> If you are more than five minutes late
                                    and our team is unable to contact you, we may need to replace
                                    you with a coverage volunteer.
                                </div>
                                <div className={"mb-2"}>
                                    <b>Delays:</b> If your session partner is encountering
                                    technical challenges or our team needs to rearrange sessions,
                                    the session may start 5-10 minutes late. We appreciate your
                                    patience!
                                </div>
                                <div className={"mb-2"}>
                                    <b>Chat:</b> Feel free to message us using the chat window in
                                    the bottom right.
                                </div>
                            </>
                        )}
                    </div>
                    <ActionCableConsumer
                        channel={{ channel: `WaitingForSessionChannel`, user_id: userId }}
                        onReceived={handleMessage}
                        onConnected={onConnected}
                    />
                    <div className={classes.spinnerWrapper}>
                        <div className={"acquaint-spinner"} />
                    </div>
                </div>
            </div>
        </ActionCableProvider>
    );
};

export default WaitingRoom;
