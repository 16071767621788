import React, { useState, useEffect } from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
// Import other necessary modules

export default function AppreciationsTable({ userId }) {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`/admin/users/${userId}/appreciations`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSessions(data);
            }
        };

        fetchData();
    }, [userId]);

    const deleteAppreciation = async (id) => {
        try {
            const response = await fetch(`/admin/users/${userId}/appreciations/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Ensure CSRF token is sent with request for Rails
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Update the state to remove the deleted appreciation
            setAppreciations(currentAppreciations => currentAppreciations.filter(appreciation => appreciation.id !== id));
        } catch (error) {
            console.error("There was an error deleting the appreciation:", error);
            // Handle error appropriately in your UI
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Scheduled Session Date</TableCell>
                        <TableCell>Partner Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Appreciation Text</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessions.map((session) => (
                        <TableRow key={session.scheduled_session_id}>
                            <TableCell>{new Date(session.scheduled_session_date).toLocaleDateString()}</TableCell>
                            <TableCell>{session.partner_name}</TableCell>
                            <TableCell>{session.role}</TableCell>
                            <TableCell>{session.appreciation_text || 'No Appreciation'}</TableCell>
                            <TableCell>
                                {session.has_appreciation ? (
                                    <>
                                        <a href={`/appreciations/${session.appreciation_id}/edit`}>Edit</a>

                                        {/*Delete doesn't work as we have associated action... */}
                                        {/*<button onClick={() => deleteAppreciation(session.appreciation_id)}>Delete</button>*/}
                                    </>
                                ) : (
                                    <a href={`/appreciations/new?scheduled_session_id=${session.scheduled_session_id}`}>Create Appreciation</a>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
