import React from "react";
import {Step, StepLabel, Stepper, Button} from "@material-ui/core";
import {useState} from "react"
import Select from "react-select";
import {IndividualOptions, OrganizationOptions} from "./membershipOptions";
import {MembershipOption} from "../membership_option/membership_option";


function getSteps() {
    return ['Account Type', 'Account Info', 'Choose Membership'];
}

const MembershipOptionSelect = (props) => {
    const {accountInfo, userProfile, onOptionSelect, activeMembershipKey, companyName, userFacingDomain} = props
    let options = []
    if(accountInfo.organizationSize === 'more_than_120' && userProfile.accountType !== 'individual') {
        return <div className={'membership-management-options'}>
            <a href={`mailto:info@${userFacingDomain}?subject=Large Community Inquiry`}>Email {companyName}</a>&nbsp;or&nbsp;
            <a href={'tel:+13607975214'}>call us!</a>
        </div>
    }
    if(userProfile.accountType === 'organization') {
       options = OrganizationOptions[accountInfo.organizationSize]
    } else {
        options = IndividualOptions
    }

    return <div className={'membership-management-options'}>
        {
            options.map( (option) => <div key={option.title}>
                <MembershipOption {...option} onOptionSelect={onOptionSelect} activeMembershipKey={activeMembershipKey} />
            </div>)
        }
    </div>
}

const MembershipTypeSelection = (props) => {
    const {accountType, onMembershipTypeSelect} = props
    return <div className={'account-infos-fields'}>
        <div className={'account-info-organization-type-text'}>
            This account is for an:
        </div>
        <div onChange={onMembershipTypeSelect} className={'account-type-selection'}>
            <input type="radio" value="individual" name="accountType"
                   defaultChecked={accountType === 'individual'}/> Individual&nbsp;
            <input type="radio" value="organization" name="accountType"
                   defaultChecked={accountType === 'organization'}/> Organization
        </div>
    </div>
}

const Field = (props) => {
    const {name, type, label, value, callback, checked} = props;
    return (
        <div className={'account-info-field'}>
            <div className={'account-info-label'}>{label}:</div>
            <input type={type} checked={checked} name={name} value={value} onChange={callback}/>
        </div>
    )
}

function IndividualAccountInfo(props) {
    let {firstName, lastName, changeHandler} = props
    return <div>
        <Field
            name={"firstName"}
            type={"text"}
            label={"Member First Name"}
            value={firstName}
            callback={changeHandler}
        />
        <Field
            name={"lastName"}
            type={"text"}
            label={"Member Last Name"}
            value={lastName}
            callback={changeHandler}
        />
    </div>;
}

const organizationSizeOptions = [
    {label: '2 to 10', value: '2_to_10'},
    {label: '11 to 50', value: '11_to_50'},
    {label: '51 to 120', value: '51_to_120'},
    {label: 'More than 120', value: 'more_than_120'}
]

function OrganizationAccountInfo(props) {
    // TODO: Does your organization serve any of the following, check all that apply: children, memory care, patients in a medical setting.
    let {organizationName, firstName, lastName, changeHandler, organizationSize, children, memoryCare, medical } = props
    console.log(props)
    return <div>
        <Field
            name={"organizationName"}
            type={"text"}
            label={"Organization Name"}
            value={organizationName}
            callback={changeHandler}
        />
        <div className={"account-info-field"}>
            {/*TODO: tooltip...*/}
            <div className={"account-info-label"}>Number of people your organization serves</div>
            <Select
                onChange={option => changeHandler({
                    target: {
                        ...option,
                        name: 'organizationSize'
                    }
                })}
                options={organizationSizeOptions}
                value={organizationSizeOptions.filter(option => option.value === organizationSize)}
            />
        </div>
        <Field
            name={"firstName"}
            type={"text"}
            label={"Account Contact First Name"}
            value={firstName}
            callback={changeHandler}
        />
        <Field
            name={"lastName"}
            type={"text"}
            label={"Account Contact Last Name"}
            value={lastName}
            callback={changeHandler}
        />
        <div className={"account-info-special-considerations"}>
            <div className={"account-info-special-considerations-label"}>Check all that apply.<div/>
                <Field
                    name={"children"}
                    type={"checkbox"}
                    label={"Children"}
                    checked={children}
                    callback={changeHandler}
                />
                <Field
                    name={"memoryCare"}
                    type={"checkbox"}
                    label={"Memory Care"}
                    checked={memoryCare}
                    callback={changeHandler}
                />
                <Field
                    name={"medical"}
                    type={"checkbox"}
                    label={"Other Medical"}
                    checked={medical}
                    callback={changeHandler}
                />
            </div>
        </div>
    </div>;
}

const MembershipInfoEntry = (props) => {
    // TODO: we need tooltips
    // TODO: Languages?

    let {accountInfo, userProfile, changeHandler} = props;

    let {accountType} = userProfile;

    return (
        <div className={'account-info'}>
            {
                accountType === 'individual' &&
                    <IndividualAccountInfo {...accountInfo} changeHandler={changeHandler} />
            }
            {
                accountType === 'organization' &&
                    <OrganizationAccountInfo {...accountInfo} changeHandler={changeHandler} />
            }
        </div>
    )
}

export const MembershipManagementSteps = (props) => {
    const steps = getSteps();

    const { onSubmit, setManageMembership, companyName, userFacingDomain } = props;
    const { activeMembershipKey } = props.currentMembershipInfo

    let [activeStep, setActiveStep] = useState(0);
    let [errorMessage, setErrorMessage] = useState(null);
    const [userProfile, setUserProfile] = useState(props.userProfile);
    const [accountInfo, setAccountInfo] = useState(props.accountInfo);

    const userProfileHandler = e => {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            setUserProfile({...userProfile, [e.target.name]: value})
    }

    const accountInfoHandler = e => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setAccountInfo({...accountInfo, [e.target.name]: value})
    }

    const handleNext = () => {
        // TODO : all values
        let validationResult = validate(activeStep, userProfile, accountInfo)
        if (validationResult.valid === false) {
            setErrorMessage(validationResult.message)
        } else {
            setErrorMessage(null)
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // TODO: input content, forms here.
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return MembershipTypeSelection({
                    accountType: userProfile.accountType,
                    onMembershipTypeSelect: userProfileHandler
                })
            case 1:
                return MembershipInfoEntry({accountInfo: accountInfo, userProfile: userProfile, changeHandler: accountInfoHandler});
            case 2:
                let onOptionSelect = optionId => onSubmit(accountInfo,userProfile, optionId)
                return MembershipOptionSelect( {userProfile, accountInfo, onOptionSelect, activeMembershipKey, companyName, userFacingDomain })
            default:
                return 'Unknown step';
        }
    }

    const validate = (step) => {
        const missingNonBooleanFields = (requiredFields, obj) => {
            const missingFields = []
            for (let field of requiredFields) {
                let [name, label] = field
                if (!obj[name]) {
                    missingFields.push(label)
                }
            }
            return missingFields
        }

        switch (step) {
            case 0:
                if (!userProfile.accountType) {
                    return {
                        valid: false,
                        message: 'Please select an account type.'
                    }
                }
                break;
            case 1:
                let requiredFields = null;
                if (userProfile.accountType === 'individual') {
                    requiredFields = [['firstName', 'First Name'], ['lastName', 'Last Name']]
                } else {
                    requiredFields = [
                        ['firstName', 'First Name'],
                        ['lastName', 'Last Name'],
                        ['organizationName', 'Organization Name'],
                        ['organizationSize', 'Organization Size']
                    ]
                }
                let missingFields = missingNonBooleanFields(requiredFields, accountInfo)
                if (missingFields.length !== 0) {
                    return {
                        valid: false,
                        message: `Please enter values for the following fields: ${missingFields.join(', ')}`
                    }
                }
                break;
            case 2:
                return {
                    // TODO: we may not need this...
                    valid: true
                }
            default:
                return {
                    valid: false,
                    message: 'An unknown error occurred'
                }
        }
        return {
            valid: true
        }
    }


    // TODO: "most popular" option
    // TODO: action on clicking select
    return (
        <div className={'membership-management-steps'}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div className={'membership-management-steps-body'}>

                <div
                    className={'membership-management-steps-content'}>{getStepContent(activeStep)}</div>
                <div className={'membership-management-steps-control'}>
                    {activeStep !== 0 && <Button onClick={handleBack} className={'classes.button'}>
                        Back
                    </Button>}
                    {activeStep < steps.length - 1 && <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={'classes.button'}
                    >
                        Next
                    </Button>}
                </div>
                {errorMessage && <div className={'membership-management-error-message'}>{errorMessage}</div>}
                {activeMembershipKey !== 'trial' && activeStep === 0 && <div className={'membership-management-steps-go-back'}>
                    <button className={'basic-link-button'} onClick={() => setManageMembership(false)}>Back To Membership</button>
                </div>}
            </div>
        </div>
    )
}