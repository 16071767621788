import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import {ContactPhoneIcon} from "../icons/contact_phone_icon"
import {Palette} from "../../utils/palette"
import Dialer from "../dialer/dialer"

import { broadcastData, TERMINATED_BY_FACILITATOR } from '../../utils/guided_session';
import {Box, Switch, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    hasSessionLockBorder: {
        border:"3px solid blue",

    },
    tableCell: {
        // cursor: "pointer",
        // backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    highlightedCell: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 255, 0, 100)"
        }
    },
    grayedOut: {
        backgroundColor: "#A9A9A9",
    },
    activeVolunteer: {
        backgroundColor: "lightblue",
    },
    redCell: {
        backgroundColor: "#ff0000", // Use red color for cancellation requested
    },
    inactiveVolunteer: {
        backgroundColor: "lightgray",
    },
    unclickableText: {
        cursor: "default",
        color: "#ccc",
        pointerEvents: "none"
    },
    coverageRow: {
        backgroundColor: '#ed863d'
    }
});

// const checkboxValue = (someBool) => {
//     return someBool ? '✔' : ''
// }

// const filterRowsByEmail = (rows, text) => {
//     return rows.filter((row) => { return row.email.indexOf(text) !== -1 })
// }

const requestWithCsrfToken = async (url, method, body) => {
    const response = await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );

    if (response.ok) {
        return response.json();
    } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
}

const SilentCancelLink = ({ sessionId, handleCancelSession }) => {
    const handleClick = async (event) => {
        event.preventDefault();

        if (window.confirm("Are you sure you want to cancel this session silently?")) {
            await requestWithCsrfToken(`/scheduled_sessions/${sessionId}/admin_cancel_silent.json`, "POST", {});
            // request with csrf token will fail if the response is not ok. we should improve error handling...
            handleCancelSession(sessionId)
            alert("Session canceled silently");

        }
    };

    return (
        <>
            <a href="#" onClick={handleClick}>Silent Cancel</a>---
        </>
    );
};

const AdminCancelLink = ({ sessionId, handleCancelSession }) => {
    const handleClick = async (event) => {
        event.preventDefault();

        if (window.confirm("Are you sure you want to cancel this session?")) {
            const response = await requestWithCsrfToken(`/scheduled_sessions/${sessionId}/admin_cancel.json`, "POST", {});
            // request with csrf token will fail if the response is not ok. we should improve error handling...
            handleCancelSession(sessionId)
            alert("Session canceled");
        }
    };

    return (
        <>
            <a href="#" onClick={handleClick}>Cancel</a>---
        </>
    );
};

const AdminEditLink = ({ sessionId }) => {
    return (
        <>
            <a href={`/scheduled_sessions/${sessionId}/edit`}>edit</a>---
        </>
    );
};

const ToggleSessionLockLink = ({ sessionId, handleToggleSessionLock }) => {
    const handleClick = async (event) => {
        event.preventDefault();

        if (window.confirm("Are you sure you want to clear this session lock?")) {
            // TODO: we will use websockets to update this stuff in the future?

            await requestWithCsrfToken(`/facilitator/toggle_session_lock`, "POST", {session_id: sessionId});

            handleToggleSessionLock(sessionId);

            // request with csrf token will fail if the response is not ok. we should improve error handling...
            alert("Session Lock toggled.");

        }
    };

    return (
        <>
            <a href="#" onClick={handleClick}>Toggle Lock</a>---
        </>
    );
};

const KillSwitchButton = ({sessionId, currentUserId}) => {
    const handleClick = async (event) => {
        event.preventDefault();

        if (window.confirm("Are you sure you want to kill this ONGOING session?")) {
            broadcastData({type: TERMINATED_BY_FACILITATOR, sessionId: sessionId, userId: currentUserId});
        }
    }

    return (
        <>
        <a href="#" onClick={handleClick}>Reset Session</a>---
        </>
    )
}


const SessionLinks = (props) => {
    const { sessionId, status, handleCancelSession, currentUserId, handleToggleSessionLock } = props
    return (
        <div>
            {status !== "canceled" && (
                <>
                    <AdminCancelLink sessionId={sessionId} handleCancelSession={handleCancelSession}/>
                    <SilentCancelLink sessionId={sessionId} handleCancelSession={handleCancelSession }/>
                    <AdminEditLink sessionId={sessionId}/>
                    <ToggleSessionLockLink sessionId={sessionId} handleToggleSessionLock={handleToggleSessionLock}/>
                    <KillSwitchButton sessionId={sessionId} currentUserId={currentUserId} />
                </>
            )}
            {status === "canceled" && <span>Canceled</span>}
        </div>
    );
};


const FacilitateDropdown = ({ handleSelection, isCoverage, row, userInfo, disabled, role}) => {
    const options = ['-', 'drop', 'drop silently', 'swap', 'switch roles'];
    if (isCoverage) {
        options.push('apply coverage', 'apply coverage silently');
    }

    const handleChange = e => handleSelection({ value: e.target.value, row, userInfo, role });

    return (
        <select disabled={disabled} onChange={handleChange}>
            {options.map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

const CopyEmailButton = ({ email }) => {
    const handleCopyEmail = () => {
        navigator.clipboard.writeText(email).then(() => {
            alert("Email copied to clipboard!");
        }, (err) => {
            console.error('Error copying email to clipboard: ', err);
            alert("Failed to copy the email.");
        });
    };

    return (
        <button onClick={handleCopyEmail}>Copy Email</button>
    );
};


function swapUserInfo(state, rowA, rowB, userInfoFromRowA, userInfoFromRowB, roleA, roleB) {
    const newState = [...state];
    const rowAIndex = newState.findIndex(row => row.session_id === rowA.session_id);
    const rowBIndex = newState.findIndex(row => row.session_id === rowB.session_id);

    let tempUserInfoA, tempScheduledAtA, tempCheckInA, tempConfirmedA, tempHeartbeatA;
    let tempUserInfoB, tempScheduledAtB, tempCheckInB, tempConfirmedB, tempHeartbeatB;

    if (roleA === 'participant') {
        tempUserInfoA = newState[rowAIndex].participantUserInformation;
        tempScheduledAtA = newState[rowAIndex].participant_scheduled_at;
        tempCheckInA = newState[rowAIndex].participantCheckIn;
        tempConfirmedA = newState[rowAIndex].participantConfirmed;
        tempHeartbeatA = newState[rowAIndex].recentTravelerHeartbeat;
    } else {
        tempUserInfoA = newState[rowAIndex].guideUserInformation;
        tempScheduledAtA = newState[rowAIndex].guide_scheduled_at;
        tempCheckInA = newState[rowAIndex].guideCheckIn;
        tempConfirmedA = newState[rowAIndex].guideConfirmed;
        tempHeartbeatA = newState[rowAIndex].recentGuideHeartbeat;
    }

    if (roleB === 'participant') {
        tempUserInfoB = newState[rowBIndex].participantUserInformation;
        tempScheduledAtB = newState[rowBIndex].participant_scheduled_at;
        tempCheckInB = newState[rowBIndex].participantCheckIn;
        tempConfirmedB = newState[rowBIndex].participantConfirmed;
        tempHeartbeatB = newState[rowBIndex].recentTravelerHeartbeat;
    } else {
        tempUserInfoB = newState[rowBIndex].guideUserInformation;
        tempScheduledAtB = newState[rowBIndex].guide_scheduled_at;
        tempCheckInB = newState[rowBIndex].guideCheckIn;
        tempConfirmedB = newState[rowBIndex].guideConfirmed;
        tempHeartbeatB = newState[rowBIndex].recentGuideHeartbeat;
    }

    let payload = {session_a_id: rowA.session_id, session_a: {}, session_b_id: rowB.session_id, session_b: {}};

    if (tempUserInfoA.userProfileId === userInfoFromRowA.userProfileId) {
        if (roleA === 'participant') {
            newState[rowAIndex].participantUserInformation = tempUserInfoB;
            newState[rowAIndex].participant_scheduled_at = tempScheduledAtB;
            newState[rowAIndex].participantCheckIn = tempCheckInB;
            newState[rowAIndex].participantConfirmed = tempConfirmedB;
            newState[rowAIndex].recentTravelerHeartbeat = tempHeartbeatB;
            payload.session_a.participant_id = tempUserInfoB.userId || null;
        } else {
            newState[rowAIndex].guideUserInformation = tempUserInfoB;
            newState[rowAIndex].guide_scheduled_at = tempScheduledAtB;
            newState[rowAIndex].guideCheckIn = tempCheckInB;
            newState[rowAIndex].guideConfirmed = tempConfirmedB;
            newState[rowAIndex].recentGuideHeartbeat = tempHeartbeatB;
            payload.session_a.guide_id = tempUserInfoB.userId || null;
        }
    }

    if (tempUserInfoB.userProfileId === userInfoFromRowB.userProfileId) {
        if (roleB === 'participant') {
            newState[rowBIndex].participantUserInformation = tempUserInfoA;
            newState[rowBIndex].participant_scheduled_at = tempScheduledAtA;
            newState[rowBIndex].participantCheckIn = tempCheckInA;
            newState[rowBIndex].participantConfirmed = tempConfirmedA;
            newState[rowBIndex].recentTravelerHeartbeat = tempHeartbeatA;
            payload.session_b.participant_id = tempUserInfoA.userId || null;
        } else {
            newState[rowBIndex].guideUserInformation = tempUserInfoA;
            newState[rowBIndex].guide_scheduled_at = tempScheduledAtA;
            newState[rowBIndex].guideCheckIn = tempCheckInA;
            newState[rowBIndex].guideConfirmed = tempConfirmedA;
            newState[rowBIndex].recentGuideHeartbeat = tempHeartbeatA;
            payload.session_b.guide_id = tempUserInfoA.userId || null;
        }
    }

    requestWithCsrfToken('facilitator/swap', 'POST', payload);

    return newState;
}


const drop = (currentRows, sessionId, role, silent = true) => {
    const newState = [...currentRows];
    const targetRowIndex = newState.findIndex(row => row.session_id === sessionId);


    if(role === 'guide') {
        if(!newState[targetRowIndex].guideUserInformation){
            return newState
        }
        newState[targetRowIndex].guideUserInformation = {}
        newState[targetRowIndex].guide_scheduled_at = ''
    } else {
        if(!newState[targetRowIndex].participantUserInformation){
            return newState
        }
        newState[targetRowIndex].participantUserInformation = {}
        newState[targetRowIndex].participant_scheduled_at = ''
    }
    let payload = {
        session_id: sessionId,
        role: role,
        silent: silent
    }

    requestWithCsrfToken('facilitator/drop', 'POST', payload)

    return newState
}

export default function FacilitatorTable(props) {
    const classes = useStyles();
    const {rows, currentUserId} = props;
    let [currentRows, setCurrentRows] = React.useState(rows)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [showOption, setShowOption] = React.useState("current");
    const [sessionStatus, setSessionStatus] = React.useState({});
    const [autopilot, setAutopilot] = React.useState(rows.filter((row) => row.closest)[0]?.autopilot_enabled);

    const [selectedSwapCell, setSelectedSwapCell] = React.useState(false);

    const handleToggleSessionLock = (sessionId) => {
        const updatedRows = currentRows.map((row) => {
            if (row.session_id === sessionId) {
                return { ...row, hasSessionLock: !row.hasSessionLock };
            }
            return row;
        });
        setCurrentRows(updatedRows);
    };

    const handleSwapClick = (rowB, userInfoFromRowB, roleB) => {
        const newState = swapUserInfo(currentRows, selectedSwapCell.row, rowB, selectedSwapCell.userInfo, userInfoFromRowB, selectedSwapCell.role, roleB);
        setCurrentRows(newState);
        setSelectedSwapCell(null)
    }

    const handleDropClick = (row, role) =>  {
        const newState = drop(currentRows, row.session_id, role, true)
        setCurrentRows(newState);
    }

    const handleDropSilentClick = (row, role) =>  {

        const newState = drop(currentRows, row.session_id, role, false)
        setCurrentRows(newState);
    }

    const handleSwitchRolesClick = (row) => {
        const newState = swapUserInfo(
            currentRows,
            row,
            row,  // Swapping within the same row
            row.participantUserInformation,
            row.guideUserInformation,
            "participant",
            "guide"
        );
        setCurrentRows(newState);
    };

    const handleAutopilotToggle = () => {
        if (currentRows.length === 0) {
            alert("No sessions available to toggle autopilot.");
            return;
        }

        const firstRoundId = currentRows[0].round_id; // Get the round_id of the first session
        const newAutopilotStatus = !autopilot;

        fetch("/volunteer_intelligence/update_autopilot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            },
            body: JSON.stringify({
                id: firstRoundId,
                autopilot: newAutopilotStatus
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setAutopilot(data.autopilot);
                } else {
                    alert("Failed to update autopilot status: " + data.errors.join(", "));
                }
            })
            .catch(error => {
                console.error("Error updating autopilot status:", error);
                alert("An error occurred while updating the autopilot status.");
            });
    };


    // Necessary to get an user interaction before loading webrtc...:
    const [dialerInitialized, setDialerInitialized] = React.useState(false)

    // const [filterText, setFilterText] = React.useState('')

    // State affected by dialer
    const [selectedProfileId, setSelectedProfileId] = React.useState('')
    const [selectedUserInfo, setSelectedUserInfo] = React.useState({})

    const [showDialer, setShowDialer] = React.useState(false)
    // const [disableUserDialSelection, setDisableUserDialSelection] = React.useState(false)



    // const filterTextChanged = (filterText) => {
    //     setPage(0);
    //     setFilterText(filterText.target.value);
    // }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCancelSession = (sessionId) => {
        setSessionStatus({ ...sessionStatus, [sessionId]: "canceled" });
    };

    const selectUserForDial = (userInfo) => {
        if(selectedProfileId === userInfo.userProfileId) {
            // hide dialer when the user click on the same, already selected user, or hits x on the dialer
            setShowDialer(false)
            setSelectedProfileId(null)
            setSelectedUserInfo({})
            return
        }
        setDialerInitialized(true)
        setShowDialer(true)
        setSelectedProfileId(userInfo.userProfileId)
        setSelectedUserInfo(userInfo)
        // OTHERWISE: SHOW the dialer
    }


    const handleFacilitateSelection = ({ value, row, userInfo, role }) => {
        switch (value) {
            case 'drop':
                if (window.confirm("Are you sure you want to drop this volunteer from the session?")) {
                    handleDropClick(row, role)
                }
                break;
            case 'switch roles':
                if (window.confirm("Are you sure you want to swap volunteer roles?")) {
                    handleSwitchRolesClick(row)
                }
                break;
            case 'drop silently':
                if (window.confirm("Are you sure you want to drop this volunteer from the session?")) {
                    handleDropSilentClick(row, role)
                }
                // Add logic for "drop silently" option here
                break;
            case 'swap':
                if (window.confirm("Are you sure you want to do this swap?")) {
                    setSelectedSwapCell({row, userInfo, role})
                }
                break;
            case 'apply coverage':
                // Add logic for "apply coverage" option here
                break;
            case 'apply coverage silently':
                // Add logic for "apply coverage silently" option here
                break;
            default:
                break;
        }
    };



    const handleToggleClick = () => {
        if (showOption === "today") {
            setShowOption("tomorrow");
        } else if (showOption === "tomorrow") {
            setShowOption("current");
        } else {
            setShowOption("today");
        }
    };

    // currentRows = currentRows.filter((row) => {return row.tomorrow === showTomorrow})

    if (showOption === "current") {
        currentRows = currentRows.filter((row) => row.closest);
    } else if (showOption === "today") {
        currentRows = currentRows.filter((row) => !row.tomorrow);
    } else {
        currentRows = currentRows.filter((row) => row.tomorrow);
    }

    const ContactCell = (props) => {
        const { userInfo, handleFacilitateSelection, row, selectedSwapCell, role, activeVolunteer } = props;
        const { session_id, state, type } = row;

        let isSelectedSwap = !!selectedSwapCell && selectedSwapCell.row.session_id === session_id && selectedSwapCell.userInfo.userProfileId === userInfo.userProfileId;
        const unclickableText = !!selectedSwapCell;

        const isCoverage = type === "ScheduledCoverage";
        const { nickname, userId, email, waLink, maskedFriendlyName, status } = userInfo;

        let cellColorClass = classes.inactiveVolunteer;

        if (activeVolunteer) {
            cellColorClass = classes.activeVolunteer;  // Active volunteer is marked with blue
        } else if (state.includes(role + '_requested_cancel')) {
            cellColorClass = classes.redCell;  // Red cell if cancellation was requested
        }

        return (
            <TableCell
                onClick={() => {
                    if (!isSelectedSwap && selectedSwapCell) {
                        handleSwapClick(row, userInfo, role);
                    }
                }}
                className={`
                ${classes.tableCell} ${selectedSwapCell && !isSelectedSwap ? classes.highlightedCell : ""} 
                ${isSelectedSwap ? classes.grayedOut : ""} 
                ${cellColorClass}
            `}
                align="right"
            >
                {nickname && (
                    <button
                        onClick={unclickableText ? undefined : () => selectUserForDial(userInfo)}
                        className={`${classes.clickableCell} ${unclickableText ? classes.unclickableText : ""}`}
                    >
                        {nickname}&nbsp;{email}
                        <ContactPhoneIcon size={"1rem"} fill={Palette.brown} />
                    </button>
                )}
                <div>
                    Status: {status}
                </div>
                <div>
                    <CopyEmailButton email={email} />
                    <a
                        target="_blank"
                        className={`basic-link-button on-table ${unclickableText ? classes.unclickableText : ""}`}
                        href={`https://52.224.52.153:8000/en-US/app/search/events_for_user?form.user_id=${userId}`}
                    >
                        Splunk
                    </a>
                    <a
                        target="_blank"
                        className={`basic-link-button on-table ${unclickableText ? classes.unclickableText : ""}`}
                        href={waLink}
                    >
                        WhatsApp
                    </a>
                    <a
                        target={"_blank"}
                        className={`basic-link-button on-table ${unclickableText ? classes.unclickableText : ""}`}
                        href={`schedulings/volunteer_info/${userInfo.userId}`}
                    >
                        sessions
                    </a>
                    <FacilitateDropdown
                        disabled={unclickableText}
                        handleSelection={handleFacilitateSelection}
                        isCoverage={isCoverage}
                        row={row}
                        userInfo={userInfo}
                        role={role}
                    />
                </div>
            </TableCell>
        );
    }


    return (
        <>
            {/*<div>*/}
            {/*    <div>Filter By Email:</div>*/}
            {/*    <input type={'text'} onChange={filterTextChanged} value={filterText}/>*/}
            {/*</div>*/}
            <div>
                <button className={"basic-link-button ampm-button"} onClick={handleToggleClick}>
                    {showOption === "today" && "Show Tomorrow"}
                    {showOption === "tomorrow" && "Show Current"}
                    {showOption === "current" && "Show Today"}
                </button>
            </div>
            {showOption === "current" && currentRows.length > 0 && (
                <Box marginBottom={2}>
                    <Typography variant="h6" gutterBottom>
                        Autopilot Mode
                    </Typography>
                    <Switch
                        checked={autopilot}
                        onChange={handleAutopilotToggle}
                        color="primary"
                    />
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Scheduled At</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Traveler</TableCell>
                            <TableCell align="right">Guide</TableCell>
                            <TableCell align="right">Links</TableCell>
                            <TableCell align="right">State</TableCell>
                            <TableCell align="right">Notes</TableCell>
                            <TableCell align="right">Chat</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.session_id} className={row.hasSessionLock ? classes.hasSessionLockBorder : ''}>
                                <TableCell align="right">{row.scheduled_at} <br/>(T: {row.participant_scheduled_at}) <br/> (G: {row.guide_scheduled_at}) </TableCell>
                                <TableCell className={row.type.trim() === 'ScheduledCoverage' ? classes.coverageRow : ''} align="right">{row.type}</TableCell>
                                <ContactCell userInfo={row.participantUserInformation}
                                             row={row}
                                             handleFacilitateSelection={handleFacilitateSelection}
                                             selectedSwapCell={selectedSwapCell}
                                             role={'participant'}
                                             activeVolunteer={row.recentTravelerHeartbeat}
                                />
                                <ContactCell
                                    userInfo={row.guideUserInformation}
                                    row={row} handleFacilitateSelection={handleFacilitateSelection}
                                    selectedSwapCell={selectedSwapCell}
                                    role={'guide'}
                                    activeVolunteer={row.recentGuideHeartbeat}
                                />
                                <TableCell align="right">
                                    <SessionLinks
                                        sessionId={row.session_id}
                                        status={row.state?.length > 0 && (row.state[0]?.indexOf('canceled') !== -1) ? 'canceled' : sessionStatus[row.session_id]}
                                        handleCancelSession={handleCancelSession}
                                        currentUserId={currentUserId}
                                        handleToggleSessionLock={handleToggleSessionLock}  // Pass the function down
                                    />
                                </TableCell>
                                <TableCell align="right">{row.state.join(' | ')} <b>EXPERIENCE STATE: {row.experienceInfoString || 'none yet'}</b></TableCell>
                                <TableCell align="right">{row.scheduler_notes}</TableCell>
                                <TableCell align="right"><a href={row.chat}>Chat</a></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={currentRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {dialerInitialized && <Dialer targetUserInformation={selectedUserInfo} currentUserId={currentUserId} display={showDialer} />}
        </>
    );
}
