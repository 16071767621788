import React, { useState, useEffect } from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ddd',
        borderRadius: theme.shape.borderRadius,
    },
    questionContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
    },
    answerOptionContainer: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        border: '1px dashed #bbb',
        borderRadius: theme.shape.borderRadius,
    },
    input: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fieldContainer: {
        marginBottom: theme.spacing(2),
    },
    label: {
        display: 'block',
        marginBottom: theme.spacing(1),
    },
}));

export default function QuizEditor({ quiz, onQuizUpdated, onQuizDeleted }) {
    const classes = useStyles();

    const [quizData, setQuizData] = useState({
        ...quiz,
        questions: quiz.questions?.map(q => ({ ...q, answer_options: q.answer_options || [] })) || []
    });


    useEffect(() => {
        if (quiz.id !== quizData.id) {
            setQuizData({
                ...quiz,
                questions: quiz.questions?.map(q => ({ ...q, answer_options: q.answer_options || [] })) || []
            });
        }
    }, [quiz]);

    const handleSave = async () => {
        const quizPayload = {
            ...quizData,
            questions_attributes: quizData.questions.map(question => ({
                ...question,
                answer_options_attributes: question.answer_options
            }))
        };
        delete quizPayload.questions; // Remove the original questions key

        const response = await fetch(`/quizzes/${quizData.id}`, {
            method: 'PUT',
            body: JSON.stringify({ quiz: quizPayload }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
            },
        });
        if (response.ok) {
            onQuizUpdated();
            alert('Quiz saved successfully!');
        } else {
            alert('Error saving quiz');
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this quiz?");
        if (confirmDelete) {
            // Delete the quiz using the API
            const response = await fetch(`/quizzes/${quiz.id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
                },
            });
            if (response.ok) {
                onQuizDeleted();
            } else {
                // Handle errors
                alert('Error deleting quiz');
            }
        }
    };


    const handleAddQuestion = () => {
        const newQuestion = {
            content: '',
            question_type: 'multiple_choice', // default type
            points: 0,
            answer_options: []
        };
        setQuizData({
            ...quizData,
            questions: [...quizData.questions, newQuestion]
        });
    };

    const handleUpdateQuestion = (questionIndex, updatedQuestion) => {
        if (updatedQuestion.question_type === 'integer_range') {
            // Automatically set metadata for integer range questions
            updatedQuestion.metadata = {
                ...updatedQuestion.metadata, // Preserve existing metadata
                integerRange: { min: 1, max: 5 } // Default range
            };
        }

        const updatedQuestions = quizData.questions.map((q, idx) => {
            if (idx === questionIndex) {
                return updatedQuestion;
            }
            return q;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };

    const handleRemoveQuestion = (questionIndex) => {
        const updatedQuestions = quizData.questions.map((q, idx) => {
            if (idx === questionIndex) {
                return { ...q, _destroy: true };
            }
            return q;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };

    const handleAddAnswerOption = (questionIndex) => {
        const newOption = { content: '', correct: false };
        const updatedQuestions = quizData.questions.map((q, idx) => {
            if (idx === questionIndex) {
                return { ...q, answer_options: [...q.answer_options, newOption] };
            }
            return q;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };

    const handleUpdateAnswerOption = (questionIndex, optionIndex, updatedOption) => {
        const updatedQuestions = quizData.questions.map((q, idx) => {
            if (idx === questionIndex) {
                const updatedOptions = q.answer_options.map((o, oi) => {
                    if (oi === optionIndex) {
                        return updatedOption;
                    }
                    return o;
                });
                return { ...q, answer_options: updatedOptions };
            }
            return q;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };

    const handleRemoveAnswerOption = (questionIndex, optionIndex) => {
        const updatedQuestions = quizData.questions.map((q, idx) => {
            if (idx === questionIndex) {
                const updatedOptions = q.answer_options.map((o, oi) => {
                    if (oi === optionIndex) {
                        return { ...o, _destroy: true };
                    }
                    return o;
                });
                return { ...q, answer_options: updatedOptions };
            }
            return q;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };

    const handleUpdateQuestionMetadata = (questionId, key, value) => {
        const updatedQuestions = quizData.questions.map((question) => {
            if (question.id === questionId) {
                return { ...question, metadata: { ...question.metadata, [key]: value } };
            }
            return question;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };

    const handleUpdateQuestionLabels = (questionId, labelValue, label) => {
        const updatedQuestions = quizData.questions.map((question) => {
            if (question.id === questionId) {
                const updatedLabels = { ...question.metadata.labels, [labelValue]: label };
                return { ...question, metadata: { ...question.metadata, labels: updatedLabels } };
            }
            return question;
        });
        setQuizData({ ...quizData, questions: updatedQuestions });
    };



    return (
        <div className={classes.container}>
            <h3>Edit Quiz</h3>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Display Name:</label>
                <input
                    className={classes.input}
                    type="text"
                    value={quizData.display_name}
                    onChange={(e) => setQuizData({ ...quizData, display_name: e.target.value })}
                />
            </div>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Internal Name:</label>
                <input
                    className={classes.input}
                    type="text"
                    value={quizData.internal_name}
                    onChange={(e) => setQuizData({ ...quizData, internal_name: e.target.value })}
                />
            </div>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Is Survey:</label>
                <input
                    type="checkbox"
                    checked={quizData.is_survey}
                    onChange={(e) => setQuizData({ ...quizData, is_survey: e.target.checked })}
                />
            </div>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Pass Score:</label>

                <input
                    className={classes.input}
                    type="number"
                    value={quizData.pass_score}
                    onChange={(e) => setQuizData({ ...quizData, pass_score: e.target.value })}
                />
            </div>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Description:</label>
                <textarea
                    className={classes.input}
                    value={quizData.description}
                    onChange={(e) => setQuizData({ ...quizData, description: e.target.value })}
                />
            </div>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Pass Message:</label>
                <textarea
                    className={classes.input}
                    value={quizData.pass_message}
                    onChange={(e) => setQuizData({ ...quizData, pass_message: e.target.value })}
                />
            </div>
            <div className={classes.fieldContainer}>
                <label className={classes.label}>Fail Message:</label>
                <textarea
                    className={classes.input}
                    value={quizData.fail_message}
                    onChange={(e) => setQuizData({ ...quizData, fail_message: e.target.value })}
                />
            </div>
            <div>
                {quizData.questions.map((question, index) => !question._destroy && (
                    <div key={index} className={classes.questionContainer}>
                        <div className={classes.fieldContainer}>
                            <label className={classes.label}>Field Name:</label>
                            <input
                                className={classes.input}
                                type="text"
                                placeholder="Field Name"
                                value={question.field_name}
                                onChange={(e) => handleUpdateQuestion(index, { ...question, field_name: e.target.value })}
                            />
                        </div>
                        <div className={classes.fieldContainer}>
                            <label className={classes.label}>Question Content:</label>
                            <textarea
                                className={classes.input}
                                placeholder="Question Content"
                                value={question.content}
                                onChange={(e) => handleUpdateQuestion(index, { ...question, content: e.target.value })}
                            />
                        </div>

                        <div className={classes.fieldContainer}>
                            <label className={classes.label}>Question Type:</label>
                            <select
                                className={classes.input}
                                value={question.question_type}
                                onChange={(e) => handleUpdateQuestion(index, { ...question, question_type: e.target.value })}
                            >
                                <option value="multiple_choice">Multiple Choice</option>
                                <option value="short_answer">Short Answer</option>
                                <option value="integer_range">Integer Range</option>
                                {/* Other question types */}
                            </select>
                        </div>

                        <div className={classes.fieldContainer}>
                            <label className={classes.label}>Points:</label>
                            <input
                                className={classes.input}
                                type="number"
                                placeholder="Points"
                                value={question.points}
                                onChange={(e) => handleUpdateQuestion(index, { ...question, points: e.target.value })}
                            />
                        </div>

                        {question.question_type === 'multiple_choice' && (
                            <div>
                                {question.answer_options.map((option, optionIndex) => !option._destroy && (
                                    <div key={optionIndex} className={classes.answerOptionContainer}>
                                        <div className={classes.fieldContainer}>
                                            <label className={classes.label}>Answer Option</label>
                                            <textarea
                                                className={classes.input}
                                                value={option.content}
                                                onChange={(e) => handleUpdateAnswerOption(index, optionIndex, { ...option, content: e.target.value })}
                                            />
                                        </div>
                                        <div className={classes.fieldContainer}>
                                            <label className={classes.label}>Correct</label>
                                            <input
                                                type="checkbox"
                                                checked={option.correct}
                                                onChange={(e) => handleUpdateAnswerOption(index, optionIndex, { ...option, correct: e.target.checked })}
                                            />
                                        </div>
                                        <button
                                            className={classes.button}
                                            onClick={() => handleRemoveAnswerOption(index, optionIndex)}>
                                            Remove Option
                                        </button>
                                    </div>
                                ))}
                                <button
                                    className={classes.button}
                                    onClick={() => handleAddAnswerOption(index)}>
                                    Add Option
                                </button>
                            </div>
                        )}
                        {question.question_type === 'integer_range' && (
                            <div className={classes.fieldContainer}>
                                <label className={classes.label}>Integer Range:</label>
                                <p>1 to 5</p>
                            </div>
                        )}
                        {question.question_type === 'integer_range' && (
                            <>
                                <div className={classes.fieldContainer}>
                                    <label className={classes.label}>Min Value:</label>
                                    <input
                                        className={classes.input}
                                        type="number"
                                        value={question.metadata?.integerRange?.min || 1}
                                        onChange={(e) => handleUpdateQuestionMetadata(question.id, 'integerRange', { ...question.metadata.integerRange, min: parseInt(e.target.value, 10) })}
                                    />
                                </div>
                                <div className={classes.fieldContainer}>
                                    <label className={classes.label}>Max Value:</label>
                                    <input
                                        className={classes.input}
                                        type="number"
                                        value={question.metadata?.integerRange?.max || 5}
                                        onChange={(e) => handleUpdateQuestionMetadata(question.id, 'integerRange', { ...question.metadata.integerRange, max: parseInt(e.target.value, 10) })}
                                    />
                                </div>
                            </>
                        )}
                        {question.question_type === 'integer_range' && (
                            Array.from({ length: (question.metadata?.integerRange?.max || 5) - (question.metadata?.integerRange?.min || 1) + 1 }, (_, i) => i + (question.metadata?.integerRange?.min || 1)).map((value) => (
                                <div key={value} className={classes.fieldContainer}>
                                    <label className={classes.label}>Label for {value}:</label>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        value={question.metadata?.labels?.[value] || ''}
                                        onChange={(e) => handleUpdateQuestionLabels(question.id, value, e.target.value)}
                                    />
                                </div>
                            ))
                        )}

                        <button
                            className={classes.button}
                            onClick={() => handleRemoveQuestion(index)}>
                            Remove Question
                        </button>
                    </div>
                ))}
                <button
                    className={classes.button}
                    onClick={handleAddQuestion}>
                    Add Question
                </button>
            </div>
            <button className={classes.button} onClick={handleSave}>Save</button>
            <button className={classes.button} onClick={handleDelete}>Delete</button>
        </div>
    );
}
