import React from 'react';

import Dialer from "./dialer";
import '../../../assets/stylesheets/user_dialer.scss'


export default {title: 'Dialer'};

const data = {
    currentUserId: 'xxxx',
    targetUserInformation: {
        firstName: 'Jan',
        dialingNotes: 'This is a senior community. Please ask for the activities director Beverly or Joan.',
        userProfileId: 'some-uuid-here',
        nickname: 'schmoopie'
    }
}

export const dialer = () => (<Dialer {...data} />);
export const invisibleDialer = () => (<Dialer {...data} display={false} />);
