import React, {useState} from "react"
import Expire from "../expire";
import halfMoonImage from 'images/public/half-moon.png';
import sunImage from 'images/public/sun-icon.png';

const COLUMN_WIDTH = 200

// Todo: need to take in mobile vs non mobile
function useMediaQuery() {
    const [screenSize, setScreenSize] = React.useState([0, 0]);

    React.useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize;
}

const requestAddEvent = async (timerange) => {
    let response = await fetch("/available_times.json", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            },
            body: JSON.stringify({
                available_time: {
                    timerange: timerange,
                    source: "rounds_availability"
                }
            })
        }
    )
    return response.json();
};


const requestDeleteEvent = async (id) => {
    await fetch(`/available_times/${id}.json`, {
            method: "DELETE",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );
};


const EmptyRoundDayColumn = (props) => {
    // this will be a column of buttons
    let { index } = props
    return (
        <div key={index} className={'empty-rounds-day-column rounds-day-column'}>

        </div>
    )
}

const RoundDayColumn = (props) => {
    // this will be a column of buttons
    let { timeslot, first, onRoundSelected, amPm} = props

    return (
        <div className={`rounds-day-column ${first ? 'first' : ''}`}>
            <div className={'rounds-day-column-header'}>{timeslot.day}</div>
            {timeslot.slots.map((slot, i) => {
                // get the margin top to space them out appropriately
                let top = i === 0 ? slot[1] * 24 : ((slot[1] - timeslot.slots[i - 1][1]) * 24) - 42

                return <RoundTimeButton amPm={amPm} top={top} key={`${slot[0]}-${slot[4]}`} onRoundSelected={onRoundSelected} slot={slot}/>
            })}
        </div>
    )
}

const RoundTimeButton = (props) => {
    // TODO: on click have little check animation or something.
    let { top, slot, onRoundSelected, amPm } = props
    let timeOfDay = slot[1] < 7 || slot[1] > 21 ? 'night' : 'day'
    let timeOfDayIcon = timeOfDay === 'night' ? halfMoonImage : sunImage
    let idPresentClass = slot[3] ? 'selected' : 'unselected'
    let displayTime = amPm === '24 Hour' ? slot[2].split('|')[1] : slot[2].split('|')[0]
    return (
        <div style={{position: 'relative', marginTop: `${top}px`}}className={`round-time-button-container ${idPresentClass}`}>
            <div onClick={() => {onRoundSelected(slot) } } className={`rounds-time-button basic-link-button ${timeOfDay}`}>
                { displayTime }
                <img className={'time-of-day-icon'} src={timeOfDayIcon}></img>
                {slot[4] &&
                    <Expire delay="3000">
                        <div className={`${idPresentClass === 'selected' ? 'xround-time-enabled' : 'xround-time-disabled'}`}>
                            {slot[3] ? "Saved!" : "Removed!"}
                        </div>
                    </Expire>
                }
                {/*<div className={'round-time-disabled-success-icon'}></div>*/}
                {/*<div className={'action-failed-icon'}></div>*/}
            </div>
        </div>
    )
}

const RoundTimeTable = (props) => {
    let {numVisibleColumns, timeslots, firstSlot, onRoundSelected, amPm} = props

    return <div className={'round-time-table'}>
        {
            [...Array(numVisibleColumns).keys()].map((i) => {
                    let index = firstSlot + i
                    let first = i === 0
                    if (timeslots[index]) {
                        return <RoundDayColumn amPm={amPm} key={timeslots[index].day} onRoundSelected={onRoundSelected} first={first} timeslot={timeslots[index]}/>
                    } else {
                        return <EmptyRoundDayColumn index={i}/>
                    }
                }
            )
        }
    </div>
}

const getColumnsCount = (screenWidth, totalSlots) => {
    return Math.min(totalSlots, Math.floor(screenWidth/COLUMN_WIDTH))
}

const PreviousDayButton = (props) => {
    let { firstSlot, totalSlots, setFirstSlot } = props
    let disabled = firstSlot === 0

    return (
        <button className={'basic-link-button'} disabled={disabled} onClick={() => setFirstSlot(firstSlot - 1)}>{"Previous Day"}</button>
    )
}

const NextDayButton = (props) => {
    let { firstSlot, totalSlots, setFirstSlot, numVisibleColumns } = props
    let disabled = firstSlot === totalSlots - numVisibleColumns - 1

    return (
        <button className={'basic-link-button'} disabled={disabled} onClick={() => setFirstSlot(firstSlot + 1)}>{"Next Day"}</button>
    )
}

export const RoundsAvailability = (props) => {
    let { timeZone, mentorAssignmentId, menteeEmail, mentorApproved } = props
    let [timeslots, setTimeslots] = useState(props.timeslots)
    let [lastSelected, setLastSelected] = useState(null)
    const totalSlots = timeslots.length
    // ID is the available times ID, if present it is enabled.
    // timeslot -> [timestamp, label, id]
    // timeslots: Map of string to timeslot, where string is the day column header/ friendly name for the day

    let [blockClicks, setBlockClicks] = useState(false)
    let [firstSlot, setFirstSlot] = useState(0)
    let [amPm, setAmPm] = useState('AM/PM')
    const [width, height] = useMediaQuery();
    const numVisibleColumns = getColumnsCount(width, totalSlots)

    const onRoundSelected = async (slot) => {
        if(mentorAssignmentId) {

            let slotId = slot[3]
            if(!slotId) {
                return
            }

            window.location.replace(`/mentor_assignments/schedule_mentee/${mentorAssignmentId}/${slotId}`);
            return;
        }

        if(blockClicks) {
            return;
        }
        setBlockClicks(true)
        if(slot[3]) {
            // call delete
            await requestDeleteEvent(slot[3])
            slot[3] = null
            setTimeslots([...timeslots])
        } else {
            let result = await requestAddEvent(slot[0])
            slot[3] = result.id
            setTimeslots([...timeslots])
        }

        setLastSelected(slot[0])
        setBlockClicks(false)
    }
    timeslots.map((slots) => {
            slots.slots.map((slot) => {
                if (slot[0] === lastSelected) {
                    slot[4] = true
                } else {
                    slot[4] = false
                }
            })
        }
    )

    const title = mentorAssignmentId ? `Select a time to schedule with ${menteeEmail}` : "Set Your Availability"
    return (
        <div className={'rounds-availability'}>
            <div className={'rounds-availability-header'}>
                <div className={'availability-header'}>{title}</div>
                <div className={'availability-description'}>Your Timezone: {timeZone}</div>
                {!mentorApproved && <div className={'availability-description fw-bold'}>
                    Please try to select at least 5 available timeslots *per week* while you are in the mentoring process as this will help us find your mentor sooner.
                </div>}
                {mentorApproved && !mentorAssignmentId && <div className={'availability-description fw-bold'}>
                    Please select at least 2 timeslots *per week* in order to be paired.
                </div>}
                {mentorAssignmentId && <div className={'availability-description fw-bold'}>
                    Mentee is available at highlighted timeslots
                </div>}
                <div>
                    <button className={'basic-link-button ampm-button'} onClick={() => setAmPm((amPm === '24 Hour' ? 'AM/PM' : '24 Hour'))} >{amPm}</button>
                </div>
                <div className={'mb-2'}>
                    <PreviousDayButton {...{firstSlot, totalSlots, setFirstSlot}}/>
                    <NextDayButton {...{firstSlot, totalSlots, numVisibleColumns, setFirstSlot}}/>
                </div>
            </div>
            <RoundTimeTable timeslots={timeslots}
                            numVisibleColumns={numVisibleColumns}
                            firstSlot={firstSlot}
                            setFirstSlot={setFirstSlot}
                            onRoundSelected={onRoundSelected}
                            amPm={amPm}
            />

        </div>
    )
}