import {useEffect} from "react";
import consumer from "./consumer";

export const ChatSubscriber = ({ handleReceiveMessage, chatData }) => {
    const initChatCable = () => {
        return consumer.subscriptions.create(
            {
                channel: "ChatChannel",
                chattable_id: chatData.chattable_id
            },{
                connected() {
                    console.log("Connected to Chat Channel");
                },
                disconnected() {
                    console.log("Disconnected from Chat Channel");
                },
                received(data) {
                    console.log("Chat message received");
                    handleReceiveMessage(data);
                },
            }
        );
    };

    useEffect(() => {
        const subscription = initChatCable();
        return () => subscription.unsubscribe();
    }, []);

    return null;
};