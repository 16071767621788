import React from "react";

export const CurrentMembershipInfo = (props) => {
    let {
        name,
        description,
        paid,
        remainingTimeInThisPeriod,
        currentPeriodStartDate,
        currentPeriodEndDate,
        nextPeriodStartDate,
        nextPeriodEndDate,
        sessionsThisPeriod,
        sessionsNextPeriod,
        anchorDay,
        canScheduleThisPeriod,
        canScheduleNextPeriod,
        nextMembershipName,
        nextMembershipDescription,
        nextMembershipPaid,
        setManageMembership,
        stillTimeToScheduleThisPeriod,
        canChangeMembership,
        nextMembershipCreatedDate,
        price,
        nextMembershipPrice,
        userFacingDomain
    } = props

    const isTrial = () => {
        return nextMembershipName === "Trial"
    }

    const currentMembershipRenewDate = () => {
        if(nextMembershipName !== name) {
            return "N/A"
        } else if(renewDate(currentPeriodEndDate) < new Date()) {
            return renewDate(nextPeriodEndDate)
        } else {
            return renewDate(currentPeriodEndDate)
        }
    }

    const nextMembershipRenewDate = () => {
        return renewDate(nextPeriodEndDate)
    }

    const renewDate = (date) => {
        let endDate = new Date(date)
        endDate.setDate(endDate.getDate() - 1)
        return endDate.toISOString().substring(0, 10);
    }
    const CurrentMembership = () => {
        return (
        <div className={'membership-for-period'}>
            <div className={'membership-info-details with-options'}>
                <div className={'membership-info-label'}>Current membership:</div>
                <div className={'membership-info-value'}>{name}</div>
                {nextMembershipName !== name ? <div className={'membership-info-upgraded-note'}>
                    &nbsp;*Upgraded to "{nextMembershipName}" on {nextMembershipCreatedDate}
                    . Your new membership will take effect on {nextPeriodStartDate}
                </div> :
                    <div className={'membership-info-options'}>
                        {canChangeMembership && <button className={'basic-link-button'} onClick={() => setManageMembership(true)}>Change</button>}
                        { !isTrial() && <a className={'basic-link-button'} href={'/membership_management/cancel'}>Cancel</a>}
                        { name === nextMembershipName && paid && <a className={'basic-link-button-wide'} href={'/membership_redirects/stripe_customer_portal'}>Payment Info</a>}
                    </div>
                }

            </div>
            { /* we don't need description here because it will already be at the top.  */}
            <div className={'membership-info-details'}>
                <div className={'membership-info-label'}>Period Dates:</div>
                <div className={'membership-info-value'}>{currentPeriodStartDate} to {currentPeriodEndDate}</div>
            </div>
            <div className={'membership-info-details'}>
                <div className={'membership-info-label'}>Description:</div>
                <div className={'membership-info-value'}>{description}</div>
            </div>
            <div className={'membership-info-details'}>
                <div className={'membership-info-label'}>Cost:</div>
                <div className={'membership-info-value'}>{price}</div>
            </div>
            <div className={'membership-info-details'}>
                <div className={'membership-info-label'}>Auto Renews On:</div>
                <div className={'membership-info-value'}>{currentMembershipRenewDate()}</div>
            </div>
        </div>
        )
    }

    const UpcomingMembership = () => {
        return (
            <div className={'membership-for-period'}>
                <div className={'membership-info-details'}>
                    <div className={'membership-info-label'}>Upcoming membership:</div>
                    <div className={'membership-info-value'}>{nextMembershipName}</div>
                    {nextMembershipName !== name &&
                        <div className={'membership-info-options'}>
                            {canChangeMembership && <button className={'basic-link-button'} onClick={() => setManageMembership(true)}>Change</button>}
                            { !isTrial() && <a className={'basic-link-button'} href={'/membership_management/cancel'}>Cancel</a>}
                            { nextMembershipPaid && <a className={'basic-link-button-wide'} href={'/membership_redirects/stripe_customer_portal'}>Payment Info</a>}
                        </div>
                    }

                </div>
                { /* we don't need description here because it will already be at the top.  */}
                <div className={'membership-info-details'}>
                    <div className={'membership-info-label'}>Upcoming Membership Period:</div>
                    <div className={'membership-info-value'}>{nextPeriodStartDate} to {nextPeriodEndDate}</div>
                </div>
                <div className={'membership-info-details'}>
                    <div className={'membership-info-label'}>Description:</div>
                    <div className={'membership-info-value'}>{nextMembershipDescription}</div>
                </div>
                <div className={'membership-info-details'}>
                    <div className={'membership-info-label'}>Cost:</div>
                    <div className={'membership-info-value'}>{nextMembershipPrice}</div>
                </div>
                <div className={'membership-info-details'}>
                    <div className={'membership-info-label'}>Auto Renews On:</div>
                    <div className={'membership-info-value'}>{nextMembershipRenewDate()}</div>
                </div>
            </div>
        )
    }


    return (
        <div className={'current-membership-info'}>
            <div className={'current-membership-info-header'}>Membership Info</div>
            {/*<div className={'membership-info-main-fact'}>*/}
                {/*<div className={'membership-info-main-fact-text'}>*/}
                {/*    <div className={'membership-info-label'}>Membership:</div>*/}
                {/*    <div className={'membership-info-value'}>{nextMembershipName || name}</div>*/}
                {/*</div>*/}
                {/*<div className={'membership-info-options'}>*/}
                {/*    {canChangeMembership && <button className={'basic-link-button'} onClick={() => setManageMembership(true)}>Change Membership</button>}*/}
                {/*    <a className={'basic-link-button'} href={'/participant_sessions/new'}>Schedule Tours</a>*/}
                {/*    { !isTrial() && <a className={'basic-link-button'} href={'/membership_management/cancel'}>Cancel Membership</a>}*/}
                {/*</div>*/}
                {/*{!canChangeMembership && <div>*/}
                {/*    In the last 48 hours of a membership period, you must cancel your membership first, before selecting a new membership.*/}
                {/*</div>*/}
                {/*}*/}
            {/*</div>*/}
            {/*<div className={'membership-description'}>*/}
            {/*    {nextMembershipDescription || description}*/}
            {/*</div>*/}

            <CurrentMembership/>
            {/*<div className={'membership-for-period'}>*/}
            {/*    <div className={'membership-period-header'}>*/}
            {/*        Upcoming Membership (Next Period)*/}
            {/*    </div>*/}
            {/*    {!canChangeMembership && <div>Note: If you want to change your membership in the last 2 days of a membership period, you must cancel first.</div>}*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Name:</div>*/}
            {/*        <div className={'membership-info-value'}>{nextMembershipName || name}</div>*/}
            {/*    </div>*/}
            {/*    { /* we don't need description here because it will already be at the top.  *!/*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Period Dates:</div>*/}
            {/*        <div className={'membership-info-value'}>{nextPeriodStartDate} to {nextPeriodEndDate}</div>*/}
            {/*    </div>*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Total Tours Scheduled next period:</div>*/}
            {/*        <div className={'membership-info-value'}>{sessionsNextPeriod}</div>*/}
            {/*    </div>*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Tours remaining next period?</div>*/}
            {/*        <div className={'membership-info-value'}>{canScheduleNextPeriod ? "yes" : "no"}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            { name !== nextMembershipName && <UpcomingMembership/>}
            {/*<div className={'membership-for-period'}>*/}
            {/*    <div className={'membership-period-header'}>*/}
            {/*        Membership This Period*/}
            {/*    </div>*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Name:</div>*/}
            {/*        <div className={'membership-info-value'}>{name}</div>*/}
            {/*    </div>*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        /!* Note: we dont need this if there is only one membership... *!/*/}
            {/*        <div className={'membership-info-label'}>Description:</div>*/}
            {/*        <div className={'membership-info-value'}>{description}</div>*/}
            {/*    </div>*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Period Dates:</div>*/}
            {/*        <div className={'membership-info-value'}>{currentPeriodStartDate} to {currentPeriodEndDate}</div>*/}
            {/*    </div>*/}
            {/*    <div className={'membership-info-details'}>*/}
            {/*        <div className={'membership-info-label'}>Total Tours Scheduled this period:</div>*/}
            {/*        <div className={'membership-info-value'}>{sessionsThisPeriod}</div>*/}
            {/*    </div>*/}
            {/*    { stillTimeToScheduleThisPeriod ?*/}
            {/*        <div className={'membership-info-details'}>*/}
            {/*            <div className={'membership-info-label'}>Tours remaining this period?</div>*/}
            {/*            <div className={'membership-info-value'}>{canScheduleThisPeriod ? "yes" : "no"}</div>*/}
            {/*        </div> : <div className={'membership-info-details'}>Scheduling has closed for this membership period.</div>*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={'current-membership-schedule-tours-section'}>
                <a className={'basic-link-button'} href={'/participant_sessions/new'}>Schedule</a>
            </div>
            <div className={'current-membership-info-contact-instructions'}>
                Questions? Contact <a href={`mailto:info@${userFacingDomain}`}>info@{userFacingDomain}</a>
            </div>
            {/* TODO: if paid, show stripe integration? */}
        </div>
    )
}
