import React, { useState, useEffect } from 'react';
import {broadcastData} from "../../utils/guided_session";
import MosaicCreator from "./mosaic_creator";

const TravelerMosaicComponentWrapper = (props) => {
    // Initialize state using the useState hook
    const [turns, setTurns] = useState(props.turns);
    const [currentTurn, setCurrentTurn] = useState(props.currentTurn);

    const handleMosaicMessage = (data) => {
        setTurns(prevTurns => [...prevTurns, data.turn]);
    }



    useEffect(() => {
        // Attach the method to the window object
        window.handleMosaicMessage = handleMosaicMessage;

        return () => {
            // Clean up to avoid memory leaks
            delete window.handleMosaicMessage;
        }
    }, [turns]); // Added turns to dependency array to ensure correct behavior

    return (
    <div>
        <h3 className={'tour-header-3'}>Acquaint: Mosaics for Peace</h3>
        <div className={'mosaic-creator-traveler-session-controls'}>
            <a className=" basic-link-button havr-end-session-button">
                END SESSION
            </a>
            <a className="havr-button basic-link-button">
                I'm Ready!
            </a>
            <a className="havr-mute">Mute</a>
        </div>
        <div className={'mosaic-guide-name-panel-wrapper'}>
            <div className="guide-name-panel-content"></div>
        </div>
        <MosaicCreator
            broadcastData={broadcastData}
            turns={turns}
            currentUser={props.currentUser}
            currentRole={"T"}
            experienceId={window.experienceId}
            handleMosaicMessage={handleMosaicMessage}
            scheduledSessionId={props.scheduledSessionId}
            backgroundImagePath={props.backgroundImagePath}
        />
    </div>
    );
}

export default TravelerMosaicComponentWrapper;