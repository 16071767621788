import React from 'react'

export const ExclamationIcon = (props) => {
    let {fill = '#ffffff', size = "1rem"} = props;

    return (<div style={{height: size, width: size}}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" display={'block'}
             viewBox="0 0 500 500">
                <path fill={fill}  d="M245,0C109.684,0,0,109.684,0,245s109.684,245,245,245s245-109.684,245-245S380.316,0,245,0z M245,459.375
		c-118.213,0-214.375-96.163-214.375-214.375S126.787,30.625,245,30.625S459.375,126.787,459.375,245S363.212,459.375,245,459.375z"
                />
                <polygon fill={fill}  points="266.836,286.987 275.196,114.874 214.788,114.874 223.532,286.987 	"/>
                <path fill={fill}  d="M245.184,305.974c-20.136,0-34.178,14.424-34.178,34.576c0,19.738,13.674,34.576,34.178,34.576
		c20.503,0,33.825-14.823,33.825-34.576C278.611,320.399,265.304,305.974,245.184,305.974z"/>
        </svg>
    </div>)

}
