import React, { useState, useEffect } from 'react';

export default function DescriptionRenderer(props) {
    // State to track if the content is expanded
    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    // Use useEffect to set isExpanded based on alwaysExpanded prop
    useEffect(() => {
        if (props.alwaysExpanded) {
            setIsExpanded(true); // Always expanded if alwaysExpanded prop is true
        }
    }, [props.alwaysExpanded]); // Depend on alwaysExpanded prop

    // Function to toggle the expansion state
    const toggleExpansion = () => {
        if (!props.alwaysExpanded) { // Only allow toggling if not always expanded
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <div className={'description-renderer'}>
            {/* Conditionally render toggle indicator based on alwaysExpanded prop */}
            {!props.alwaysExpanded && (
                <div className={'toggle-indicator'} onClick={toggleExpansion}>
                    {isExpanded ? 'Hide Instructions ▲' : 'Show Instructions ▼'}
                </div>
            )}

            {/* Always render content if alwaysExpanded is true, otherwise depend on isExpanded state */}
            {(isExpanded || props.alwaysExpanded) && (
                <div dangerouslySetInnerHTML={{ __html: props.htmlString }}></div>
            )}
        </div>
    );
}
