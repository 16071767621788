import {useState} from "react";

export default function useChatActions(selectedChat, setMessages) {
    const [message, setMessage] = useState("");
    const [templateSid, setTemplateSid] = useState(null);

    const handleSendMessage = async () => {
        if (!message.trim() || !selectedChat) return null;

        // console.log( JSON.stringify({
        //         message: {
        //             content: message,
        //             chattable_id: selectedChat.chattable_id,
        //             chattable_type: selectedChat.chattable_type,
        //         },
        //     })
        //)

        try {
            const currentMessage = message;
            const currentTemplateSid = templateSid;

            setMessage("");
            setTemplateSid(null);
            const response = await fetch(`/messages`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
                },
                body: JSON.stringify({
                    message: {
                        content: currentMessage,
                        chattable_id: selectedChat.chattable_id,
                        chattable_type: selectedChat.chattable_type,
                        content_sid: currentTemplateSid
                    },
                }),
            });

            if (response.ok) {
                // const newMessage = await response.json();
                // setMessages((prev) => [...prev, newMessage]);
                // setMessage("");
                // return newMessage;
            }
        } catch (error) {
            console.error("Failed to send message:", error);
        }

        return null;
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            const response = await fetch(`/messages/${messageId}`, {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
                },
            });

            const result = await response.json();
            if (result.success) {
                setMessages((prev) =>
                    prev.map((msg) => msg.id === messageId ? { ...msg, deleted: true } : msg)
                );
            }
        } catch (error) {
            console.error("Failed to delete message:", error);
        }
    };

    return {
        message,
        setMessage,
        handleSendMessage,
        handleDeleteMessage,
        setTemplateSid
    };
}
