import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    input: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default function DeleteUsers() {
    const classes = useStyles();
    const [deletionRecordData, setDeletionRecordData] = useState({ email: '', reason: '' });
    const [openDialog, setOpenDialog] = useState(false);

    const handleInputChange = (e) => {
        setDeletionRecordData({ ...deletionRecordData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        const response = await fetch('/submit_delete_user', {
            method: 'POST',
            body: JSON.stringify({ deletion_record: deletionRecordData }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
            }
        });

        if (response.ok) {
            // Handle successful submission
            // Optionally, you can fetch updated data or redirect the user
            setOpenDialog(false);
            setDeletionRecordData({ email: '', reason: '' }); // Reset form
        } else {
            // Handle errors
            const errorText = await response.text();
            // Display the error message to the user
            // For example, using an alert or updating the component state to show an error message
            alert(`Error: ${errorText}`);
        }
    };

    return (
        <div className={classes.container}>
            <TextField
                label="Email"
                type="email"
                name="email"
                variant="outlined"
                value={deletionRecordData.email}
                onChange={handleInputChange}
                className={classes.input}
                fullWidth
            />
            <TextField
                label="Reason for deletion"
                name="reason"
                variant="outlined"
                value={deletionRecordData.reason}
                onChange={handleInputChange}
                className={classes.input}
                multiline
                rows={4}
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => setOpenDialog(true)}
            >
                Delete User
            </Button>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
