import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import {ContactPhoneIcon} from "../icons/contact_phone_icon"
import {Palette} from "../../utils/palette"
import Dialer from "../dialer/dialer"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    clickableCell: {
        display: 'flex',
    }
});

// const checkboxValue = (someBool) => {
//     return someBool ? '✔' : ''
// }

// const filterRowsByEmail = (rows, text) => {
//     return rows.filter((row) => { return row.email.indexOf(text) !== -1 })
// }

export default function CoveragerTable(props) {
    const classes = useStyles();
    let {rows, currentUserId} = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [showTomorrow, setShowTomorrow] = React.useState(false)

    // Necessary to get an user interaction before loading webrtc...:
    const [dialerInitialized, setDialerInitialized] = React.useState(false)

    // const [filterText, setFilterText] = React.useState('')

    // State affected by dialer
    const [selectedProfileId, setSelectedProfileId] = React.useState('')
    const [selectedUserInfo, setSelectedUserInfo] = React.useState({})

    const [showDialer, setShowDialer] = React.useState(false)
    // const [disableUserDialSelection, setDisableUserDialSelection] = React.useState(false)

    // const filterTextChanged = (filterText) => {
    //     setPage(0);
    //     setFilterText(filterText.target.value);
    // }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const selectUserForDial = (userInfo) => {
        if(selectedProfileId === userInfo.userProfileId) {
            // hide dialer when the user click on the same, already selected user, or hits x on the dialer
            setShowDialer(false)
            setSelectedProfileId(null)
            setSelectedUserInfo({})
            return
        }
        setDialerInitialized(true)
        setShowDialer(true)
        setSelectedProfileId(userInfo.userProfileId)
        setSelectedUserInfo(userInfo)
        // OTHERWISE: SHOW the dialer
    }

    // rows = rows.filter((row) => {return row.tomorrow === showTomorrow})
    const ContactCell = (props) => {
        const {userInfo } = props
        const {nickname, userId, firstName, userProfileId, dialingNotes, waLink} = userInfo
        return (<TableCell align="right">
            { nickname && <button onClick={() => selectUserForDial(userInfo)}
                    className={classes.clickableCell}>
                {nickname}&nbsp;
                <ContactPhoneIcon size={'1rem'} fill={Palette.brown}/>
            </button>}
            <a target="_blank" className={'basic-link-button on-table'}  href={`https://52.224.52.153:8000/en-US/app/search/events_for_user?form.user_id=${userId}`}>Splunk</a>
            <a target="_blank" className={'basic-link-button on-table'}  href={waLink}>WhatsApp</a>
        </TableCell>)
    }

    return (
        <>
            {/*<div>*/}
            {/*    <div>Filter By Email:</div>*/}
            {/*    <input type={'text'} onChange={filterTextChanged} value={filterText}/>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <button className={'basic-link-button ampm-button'} onClick={() => setShowTomorrow(!showTomorrow)} >{showTomorrow ? "Show Today" : 'Show Tomorrow'}</button>*/}
            {/*</div>*/}
            <h2>
                Coverage Volunteers
            </h2>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Contact</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.userProfileId}>
                                <TableCell align="right">{row.firstName}</TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <ContactCell userInfo={row}/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {dialerInitialized && <Dialer targetUserInformation={selectedUserInfo} currentUserId={currentUserId} display={showDialer} />}
        </>
    );
}
