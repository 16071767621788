import React from 'react'

export const ContactPhoneIcon = (props) => {
    let {fill, size = "1rem", className = "contact-phone-icon"} = props;

    return (<div className={className} style={{height: size, width: size}}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
            <g>
                <path fill={fill} d="M402.8,160.206c0,0,74.606-38.066,93.893-70.551c0,0-1.604-18.561-15.312-37.619l-129.355,90.037
		L402.8,160.206z"/>
                <path fill={fill} d="M89.66,496.689c32.476-19.274,70.542-93.885,70.542-93.885l-18.133-50.775L52.03,481.385
		C71.1,495.086,89.66,496.689,89.66,496.689z"/>
                <path fill={fill} d="M418.604,11.566c-73.306-19.521-219.509,94.149-266.2,140.84C105.715,199.104-7.95,345.296,11.562,418.595
		c6.725,25.271,18.461,42.754,30.944,54.839l92.635-137.523l-8.425-21.407c-14.931-52.489,62.093-125.687,62.093-125.687
		s73.217-77.023,125.685-62.095l21.417,8.417L473.431,42.51C461.352,30.019,443.861,18.273,418.604,11.566z"/>
            </g>
        </svg>
    </div>)

}
