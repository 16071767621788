import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2'

// NOTE: this is currently hard coded to US only...
const parseUSFormattedPhone = (phone) => {
    if(!phone ){
        return ''
    }
    return `+1${phone.replace(/[()\- ]/, '')}`
}

// This component is specifically built to work with direct render from rails views, so that it may
// easily be embedded in a form.
export default function USPhoneInput(props) {
    let {initialPhone, name} = props

    if(!initialPhone) {
        initialPhone = ''
    }
    
    let [phone, setPhone] = useState(initialPhone.replace('+1', ''))

    return <><PhoneInput
        country={'us'}
        value={phone}
        onChange={setPhone}
        countryCodeEditable={true}
        disableCountryCode={true}
        disableDropdown={true}
        onlyCountries={['us']}
        specialLabel={''}
        placeholder={'Enter US phone number'}
    />
        <input
            type="hidden"
            value={parseUSFormattedPhone(phone)}
            name={name}
        />
    </>
}
