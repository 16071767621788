import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const requestWithCsrfToken = async (url, method, body) => {
    return await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            }
        }
    );
}

const VolunteerHoursForm = () => {

    const validationSchema = Yup.object({
        start_date: Yup.date().required('Start date is required'),
        end_date: Yup.date().required('End date is required'),
        hours: Yup.number().required('Hours is required'),
        description: Yup.string()
            .min(10, 'Must be at least 10 characters')
            .max(600, 'Must be 300 characters or less')
            .required('Please enter a description of the work you have done')
    });

    const onSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
            const response = await requestWithCsrfToken('/volunteer_hours_submissions', 'POST', {volunteer_hours_submission: values});
        } catch (error) {
            console.error('Error occurred while submitting the form:', error);
        } finally {
            // TODO: create a success page and redirect to it
            window.location.replace('/home');
            setSubmitting(false); // you need to manage the loading state manually
        }
    };

    return (
        <div className="form-container">
            <Formik
                initialValues={{
                    start_date: '',
                    end_date: '',
                    hours: '',
                    description: ''
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit} // Add your onSubmit function here
            >
                {({ isSubmitting }) => (
                    <Form className="formik-form">
                        <h3><b>Volunteer Hours Submission</b></h3>

                        <label htmlFor="start_date" className="form-label">Start Date</label>
                        <Field type="date" name="start_date" id="start_date" className="input-field" />
                        <ErrorMessage name="start_date" component="div" className="error-message" />

                        <label htmlFor="end_date" className="form-label">End Date</label>
                        <Field type="date" name="end_date" id="end_date" className="input-field" />
                        <ErrorMessage name="end_date" component="div" className="error-message" />

                        <label htmlFor="hours" className="form-label">Hours</label>
                        <Field type="number" name="hours" id="hours" className="input-field" />
                        <ErrorMessage name="hours" component="div" className="error-message" />

                        <label htmlFor="description" className="form-label">Description</label>
                        <Field as="textarea" name="description" id="description" className="input-field input-textarea" />
                        <ErrorMessage name="description" component="div" className="error-message" />

                        <button type="submit" className="submit-button">Submit</button>
                    </Form>
                )}

            </Formik>
        </div>

    );

};

export default VolunteerHoursForm;
