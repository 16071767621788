import React from "react";

export function ErrorIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7461 1.52332L22.4767 10.2539C23.4411 11.2183 23.4411 12.7818 22.4767 13.7462L13.7461 22.4768C12.7818 23.4411 11.2182 23.4411 10.2539 22.4768L1.52327 13.7462C0.55891 12.7818 0.55891 11.2183 1.52327 10.2539L10.2539 1.52332C11.2182 0.55896 12.7818 0.55896 13.7461 1.52332ZM13.4 16.2C13.4 16.9732 12.7732 17.6 12 17.6C11.2268 17.6 10.6 16.9732 10.6 16.2C10.6 15.4269 11.2268 14.8 12 14.8C12.7732 14.8 13.4 15.4269 13.4 16.2ZM11.9856 7.80005H12.0142C12.8694 7.81042 13.5156 8.62935 13.3826 9.54159L12.7839 12.6744C12.7269 13.0891 12.3943 13.4 12.0047 13.4H11.9951C11.6055 13.4 11.2729 13.0891 11.2159 12.6744L10.6172 9.54159C10.4842 8.62935 11.1399 7.80005 11.9856 7.80005Z"
                fill="#D61F1F"
            />
        </svg>
    );
}

export function CopyIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6812 3.59414C14.6188 2.97958 14.0998 2.5 13.4688 2.5H3.71875L3.59414 2.50629C2.97958 2.5687 2.5 3.08772 2.5 3.71875V13.4688L2.50629 13.5934C2.5687 14.2079 3.08772 14.6875 3.71875 14.6875H5.3125V16.0938C5.3125 16.8704 5.9421 17.5 6.71875 17.5H16.0938C16.8704 17.5 17.5 16.8704 17.5 16.0938V6.71875C17.5 5.9421 16.8704 5.3125 16.0938 5.3125H14.6875V3.71875L14.6812 3.59414ZM13.75 5.3125V3.71875C13.75 3.58561 13.6575 3.47408 13.5332 3.44493L13.4688 3.4375H3.71875C3.58561 3.4375 3.47408 3.53001 3.44493 3.65426L3.4375 3.71875V13.4688C3.4375 13.6019 3.53001 13.7134 3.65426 13.7426L3.71875 13.75H5.3125V6.71875C5.3125 5.9421 5.9421 5.3125 6.71875 5.3125H13.75ZM6.71875 6.25H16.0938C16.3526 6.25 16.5625 6.45987 16.5625 6.71875V16.0938C16.5625 16.3526 16.3526 16.5625 16.0938 16.5625H6.71875C6.45987 16.5625 6.25 16.3526 6.25 16.0938V6.71875C6.25 6.45987 6.45987 6.25 6.71875 6.25Z"
                fill="#606B85"
            />
        </svg>
    );
}