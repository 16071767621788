import { createTheme } from '@material-ui/core/styles';

// Define your colors
const havr_green = '#b9ffb7';
const havr_teal = '#abedc6';
const havr_gray_green = '#8bc6a5';
// For lighter or darker colors, Material-UI theme can use palette augmentColor utility if needed
// but for simplicity, you may calculate the lighter version of havr_gray_green directly
const havr_gray_green_lighter = '#a0d0b6'; // Adjusted manually for example; replace with actual calculated color
const havr_orange = '#ed863d';
const havr_brown = '#403233';
const havr_red = '#e74c3c';
const acquaint_landing_green = '#71a187';
const ui_red = '#FF0000';
const ui_green = '#50C878';
const acq_peach = '#f1d5c0';

const theme = createTheme({
    palette: {
        primary: {
            main: havr_green, // Use havr_green as primary color
        },
        secondary: {
            main: havr_teal, // Use havr_teal as secondary color
        },
        error: {
            main: havr_red, // Optional: Use havr_red for error states
        },
        warning: {
            main: havr_orange, // Optional: Use havr_orange for warnings
        },
        info: {
            main: acquaint_landing_green,
            light: havr_gray_green,
        },
        success: {
            main: ui_green, // Optional: Use ui_green for success states
        },
        background: {
            default: havr_gray_green_lighter, // Optional: Background color of the application
        },
        text: {
            primary: havr_brown, // Optional: Primary text color
            secondary: acq_peach, // Optional: Secondary text color
        },
        // You can add more custom colors as needed
    },
    // overrides: {
    //     MuiButton: {
    //         // Override styles for root (applies to all variants)
    //         root: {
    //             '&:hover': {
    //                 // color: '#ed863d', // havr_orange for text color on hover
    //             },
    //         },
    //         // For contained buttons, you might need to specify the contained variant explicitly
    //         containedPrimary: {
    //             '&:hover': {
    //                 backgroundColor: 'rgba(237, 134, 61, 0.5)', // Optionally adjust the hover background color
    //                 color: havr_brown, // havr_orange for text color on hover
    //             },
    //         },
    //     },
    // },
    // Continue with other theme customizations
});

export default theme;
