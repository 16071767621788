import React from 'react'
import '../../../assets/stylesheets/membership_management.scss'
import '../../../assets/stylesheets/application.scss'

import { MembershipManagementSteps } from './membership_management_steps'

export default {title: 'Membership Management Steps'};

export const standard = () => (<MembershipManagementSteps
    userProfile={{
        membershipType: 'individual'
    }}
    accountInfo={{
        firstName:'',
        lastName:'',
        organizationName: ''
    }}
/>);