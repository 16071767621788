import React from "react"

export default class TourSceneInput extends React.Component {

    render() {
        let {scene, onEditClicked, onDeleteClicked, thumbnailPath, permissions} = this.props;

        return (
            <div className={'tour-scene-item'}>
                <div className={'tour-scene-thumbnail-container'}>
                    <img className={'tour-scene-thumbnail'} src={thumbnailPath}></img>
                </div>
                <div className={'tour-scene-data'}>
                    <div className={'tour-scene-item-part'}>
                        <b>Name: {scene.name || 'NO NAME'}</b>
                    </div>
                    <div className={'tour-scene-item-part'}>
                        Order: {scene.order}
                    </div>
                    <div className={'tour-scene-item-part'}>
                        Script: {scene.script}
                    </div>
                    <div className={'tour-scene-item-part'}>
                        {permissions === 'manager' && <button className={'tour-scene-button'} onClick={onDeleteClicked}>Delete</button>}
                        <button className={'tour-scene-button'} onClick={onEditClicked}>Edit</button>
                    </div>
                </div>
            </div>
        )
    }
}
