import React, {useEffect, useState} from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const colorPickerStyles = makeStyles((theme) => ({
    colorPickerContainer: {
        position: 'relative',
        minWidth: '320px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    colorDisplayButton: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        cursor: 'pointer',
        minWidth: '320px',
        maxWidth: '520px',
        marginTop: '0',

        width: '100%',
        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    paletteSelector: {
        margin: theme.spacing(1),
        width: 'calc(100% - 8px)', // Adjust the width as needed
        padding: theme.spacing(1), // Increases the height of the dropdown
        fontSize: '1.1rem', // Larger text size
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius, // Rounded corners
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        minHeight: '48px',
        '&:focus': {
            outline: `2px solid ${theme.palette.primary.main}`, // Focus outline
        },
    },
    colorOptions: {
        position: 'relative',
        // bottom: 0, // Position at the bottom for mobile view
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: theme.shadows[1],
        zIndex: theme.zIndex.tooltip,
        backgroundColor: 'gray',
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            bottom: 'auto',
        },
    },
    colorOption: {
        width: 30,
        height: 30,
        margin: theme.spacing(0.5),
        border: 'none',
        cursor: 'pointer',
    },
}));

export const acquaintColorPalettes = [
    {
        "name": "Earth",
        "colors": ["#DD9669", "#B89249", "#898D36", "#528736", "#007E46", "#00725E"]
    },
    {
        "name": "Pastel",
        "colors": ["#F0B2EE", "#B4A7F2", "#5F9EEA", "#0093D1", "#0085A7", "#007270"]
    },
    {
        "name": "Dusk",
        "colors": ["#C42959", "#A53777", "#7B4586", "#514D82", "#344D70", "#2F4858"]
    },
    {
        "name": "Sunset",
        "colors": ["#EB6900", "#EB8a00", "#FFA359", "#FF937A", "#FF8AA1", "#E797EB"]
    },
    {
        "name": "Citrus",
        "colors": ["#20DBCF", "#4CE5BE", "#77EDA9", "#A1F393", "#CCF77F", "#F9F871"]
    },
    {
        "name": "Eraser",
        "colors": ["#FFFFFF"]
    },
]

const ColorPicker = ({ selectedColor, onColorSelect }) => {
    const classes = colorPickerStyles();

    // Only store the name of the selected palette
    const defaultPaletteName = acquaintColorPalettes[0].name;
    const [selectedPaletteName, setSelectedPaletteName] = useState(defaultPaletteName);

    // Derive the selected palette colors from its name
    const selectedPaletteColors = acquaintColorPalettes.find(p => p.name === selectedPaletteName).colors;

    const handleColorClick = (color) => {
        onColorSelect(color);
    };

    const handlePaletteChange = (event) => {
        setSelectedPaletteName(event.target.value);
    };

    // Load the selected palette name from local storage on mount
    useEffect(() => {
        const savedPaletteName = localStorage.getItem('selectedPaletteName');
        if (savedPaletteName) {
            setSelectedPaletteName(savedPaletteName);
        }
    }, []);

    // Save the selected palette name to local storage on change
    useEffect(() => {
        localStorage.setItem('selectedPaletteName', selectedPaletteName);
    }, [selectedPaletteName]);

    return (
        <div className={classes.colorPickerContainer}>
            <select className={classes.paletteSelector} value={selectedPaletteName} onChange={handlePaletteChange}>
                {acquaintColorPalettes.map(palette => (
                    <option key={palette.name} value={palette.name}>{palette.name}</option>
                ))}
            </select>
            <div className={classes.colorOptions}>
                {selectedPaletteColors.map((color) => (
                    <Button
                        key={color}
                        className={classes.colorOption}
                        style={{ backgroundColor: color }}
                        onClick={() => handleColorClick(color)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ColorPicker;