import React, {useState} from 'react';


const toUnderscoreCase = (str) => {
    return str
        // Convert camelCase to underscores
        .replace(/([a-z])([A-Z])/g, '$1_$2')
        // Convert spaces and dashes to underscores
        .replace(/[\s-]+/g, '_')
        // Convert to lowercase
        .toLowerCase();
}

function ActionWrapper({componentName, ...props}) {

    const initialData = () => {
        const initial = {...props.data};
        const key = toUnderscoreCase(componentName);
        if (!(key in initial)) {
            initial[key] = {};
        }
        return initial;
    };


    const [data, setData] = useState(initialData)


    const handleSubmit = async (approved, redirectToNext = false) => {
        try {
            const response = await fetch(`/acquaint_actions/handle_decision/${props.id}`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                },
                body: JSON.stringify({
                    acquaint_action: { data: { ...data, approved: approved } }
                })
            });

            if (response.ok) {
                const { redirect_url } = await response.json();

                if (redirectToNext) {
                    // Redirect to the next action
                    window.location.href = redirect_url;
                } else {
                    // Stay on the same page or perform other actions
                    console.log("Action processed successfully. Next URL:", redirect_url);
                }
            } else {
                console.error("Failed to process the action.");
            }
        } catch (error) {
            console.error("Error during submission:", error);
        }
    };


    let Component;
    switch (componentName) {
        case 'VolunteerHoursSubmission':
            Component = VolunteerHoursSubmissionView;
            break;
        case 'Appreciation':
            Component = AppreciationView;
            break
        case 'VolunteerCancelRequest':
            Component = VolunteerCancelRequestView;
            break;
        // Add more cases here as needed
        // case 'AnotherComponentName': Component = AnotherComponent; break;
        default:
            return <div>Error: Component {componentName} not found</div>;
    }

    return (
        <div className={'action-wrapper acquaint-action'}>
            <div className="action-item">
                <div className={'action-label'}>Type:</div>
                <div> {props.type}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Created:</div>
                <div>{props.formattedCreateDate}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Expires:</div>
                <div>{props.formattedExpiry}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>State:</div>
                <div>{props.state}</div>
            </div>
            <Component data={data} setData={setData} content={props.input_sourceable_content}/>
            <div>
                <div>
                    <button onClick={() => handleSubmit(true, true)}>Approve and Next</button>
                    <button onClick={() => handleSubmit(false, true)}>Dismiss and Next</button>
                    <button onClick={() => handleSubmit(true)}>Approve</button>
                    <button onClick={() => handleSubmit(false)}>Dismiss</button>
                    <button onClick={() => handleSubmit(null)}>Save</button>
                </div>
            </div>
        </div>
    );
}

function VolunteerHoursSubmissionView(props) {
    let {data, setData, content} = props;
    return (
        <div>
            <div className="action-item">
                <div className={'action-label'}>Volunteer Email:</div>
                <div>{content.email}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Hours Requested:</div>
                <div>{content.hours}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Volunteer's Description of Work:</div>
                <div>{content.description}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Dates of Service:</div>
                <div>{content.dates}</div>
            </div>
            <div className="action-item">
                <div className={"action-label-with-subtext"}>
                    <div className={'action-label'}>Enter Notes On Volunteer Hours:</div>
                    <div>Note: the volunteer will be able to see this!</div>
                </div>
            <textarea
                rows={4}
                cols={40}
                value={data.volunteer_hours_submission.notes}
                onChange={(e) => setData({
                    ...data,
                    volunteer_hours_submission: {...data.volunteer_hours_submission, notes: e.target.value}
                })}
            />
            </div>
        </div>
    );
}

function AppreciationView(props) {
    let {data, setData, content} = props;

    return (
        <div>
            <div className="action-item">
                <div className={'action-label'}>Volunteer Email:</div>
                <div>{content.submission_user_email}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Volunteer Name:</div>
                <div>{content.submission_user_name}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Receiver Email:</div>
                <div>{content.submission_receiver_email}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Receiver Name:</div>
                <div>{content.submission_receiver_name}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Appreciation Text:</div>
                <div>{content.appreciation_text}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Scheduled Session Id:</div>
                <div>{content.scheduled_session_id}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>AI score:</div>
                <div>{content.ai_score}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>AI feedback:</div>
                <div>{content.ai_feedback_text}</div>
            </div>
            <div className="action-item">
                <div className={"action-label-with-subtext"}>
                    <div className={'action-label'}>Enter Notes On the Appreciation:</div>
                    <div>Note: the volunteer will be able to see this!</div>
                </div>
            <textarea
                rows={4}
                cols={40}
                value={data.appreciation.notes}
                onChange={(e) => setData({
                    ...data,
                    appreciation: {...data.appreciation, notes: e.target.value}
                })}
            />
            </div>
        </div>
    )
}

function VolunteerCancelRequestView({data, setData, content}) {
    return (
        <div>
            <div className="action-item">
                <div className={'action-label'}>Volunteer Email:</div>
                <div>{content.user_email}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Volunteer First Name:</div>
                <div>{content.volunteer_first_name}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Scheduled Session Date:</div>
                <div>{content.scheduled_session_date}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Scheduled Session Type:</div>
                <div>{content.scheduled_session_type}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Message from Volunteer:</div>
                <div>{content.message_from_volunteer}</div>
            </div>
            <div className="action-item">
                <div className={'action-label'}>Message to Volunteer:</div>
                <textarea
                    rows={3}
                    cols={50}
                    value={data.message_to_volunteer}
                    onChange={(e) => setData({...data, message_to_volunteer: e.target.value})}
                />
            </div>
        </div>
    );
}


export default ActionWrapper;
