// Content for Intro
import React from "react"
// import USPhoneInput from "../USPhoneInput";
import Select from 'react-select'
// import PhoneInput from 'react-phone-input-2'
import Intro1 from 'images/intro/desktop/intro_1.jpg'
import Intro2 from 'images/intro/desktop/intro_2.jpg'
import Intro3 from 'images/intro/desktop/intro_3.jpg'
import Intro4 from 'images/intro/desktop/intro_4.jpg'
import Intro5 from 'images/intro/desktop/intro_5.jpg'

import IntroMobile1 from 'images/intro/mobile/intro_1.jpg'
import IntroMobile2 from 'images/intro/mobile/intro_2.jpg'
import IntroMobile3 from 'images/intro/mobile/intro_3.jpg'
import IntroMobile4 from 'images/intro/mobile/intro_4.jpg'
import IntroMobile5 from 'images/intro/mobile/intro_5.jpg'
import IntlTelInput from "react-intl-tel-input";

// import 'react-intl-tel-input/dist/main.css';

const parseUSFormattedPhone = (phone) => {
    if (!phone) {
        return ''
    }
    return `+1${phone.replace(/[()\- ]/, '')}`
}


const submitProfile = async (userProfileId, data) => {
    return fetch(`/update_required_profile_info/${userProfileId}`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
            },
            body: JSON.stringify({
                phone_valid: data.phoneValid,
                user_profile: {
                    primary_phone: data.phone,
                    country_iso2: data.country,
                    timezone: data.timezone?.value,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    hours_per_week: data.hoursPerWeek
                }
            })
        }
    );
};

const WhyDoWeNeedThis = () => {
    let [open, setOpen] = React.useState(false)
    return (
        <div className={'phone-input-description'}>
            <a href={'#'} onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
            }} className={'intro-lightbox-phone-tooltip-link'}>Why do we need this?</a>

            {open && <div>
                <div onClick={() => setOpen(false)} className={'intro-lightbox-tooltip'}>
                    This number will be used to remind you of your scheduled sesions, offer tech
                    support, or answer any other questions you may have. Our system lets other volunteers
                    call your phone (US phones only) at the time of your session WITHOUT seeing your number.
                    Your number will never be used for marketing purposes.
                    <div className={'close-button-wrapper'}>
                        <a className={'basic-link-button'}>Close</a>
                    </div>
                </div>
            </div>}
        </div>
    )
}

const ContactForm = (props) => {
    const parsePhone = (phone) => {
        if(!phone ){
            return ''
        }
        return `${phone.replace(/[()\- ]/, '')}`
    }

    let {
        timezone,
        timezoneOptions,
        setTimezone,
        hoursPerWeek,
        setHoursPerWeek,
        setPhone,
        phone,
        phoneValid,
        setPhoneValid,
        country,
        setCountry,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        defaultPhoneNumber,
        submissionError,
        isMobile
    } = props
    firstName = firstName || ''
    lastName = lastName || ''
    return (<div className={'intro-lightbox-contact-form'} key={'contact-form'}>
        <div className={'intro-lightbox-complete-profile-header'}>Let's get started!</div>
        <div className={'intro-lightbox-field'}>
            <div className={'intro-lightbox-field-label'}>
                First Name (Other Volunteers See This):
            </div>
            <input className={'intro-lightbox-text-input'} value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
            />
        </div>
        <div className={'intro-lightbox-field'}>
            <div className={'intro-lightbox-field-label'}>
                Last Name (Hidden From Other Volunteers):
            </div>
            <input className={'intro-lightbox-text-input'} value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
            />
        </div>
        <div className={'intro-lightbox-field'}>
            {!isMobile && <div className={'intro-lightbox-field-label'}>
                Phone:
            </div>}
            <IntlTelInput
                defaultValue={defaultPhoneNumber}
                containerClassName="intl-tel-input"
                useMobileFullscreenDropdown={false}
                preferredCountries={[]}
                onSelectFlag={(
                    currentNumber,
                    selectedCountryData,
                    fullNumber,
                    isValid) => {
                    setPhoneValid(isValid)
                    setCountry(selectedCountryData['iso2'])
                }
                }
                onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber, extension) => {
                    setPhone(parsePhone(fullNumber))
                    setCountry(selectedCountryData['iso2'])
                    if(['au', 'zw', 'gh', 'co'].indexOf(selectedCountryData['iso2']) !== -1) {
                        isValid = true
                    }
                    setPhoneValid(isValid)
                }}
            />
        </div>
        <div className={'intro-lightbox-field'}>
            <div className={'intro-lightbox-field-label'}>
                Please confirm your timezone:
            </div>
            <Select
                defaultValue={timezone || timezoneOptions[0]}
                options={timezoneOptions}
                onChange={setTimezone}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
        <div className={'intro-lightbox-field'}>
            <div className={'intro-lightbox-field-label'}>
                How Many Sessions Per Week Are You Able To Do?
                <Select
                    defaultValue={{label: hoursPerWeek, value: hoursPerWeek } }
                    options={[...Array(20).keys()].map((i) => {return {label: i,value: i}})}
                    onChange={setHoursPerWeek}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </div>
        </div>

        <div className={'intro-lightbox-errors-wrapper'}>
            {submissionError &&<div className={'intro-lightbox-errors'}>{submissionError}</div>}
        </div>
    </div>)
}

export default function IntroLightbox(props) {
    let {
        userProfileId,
        setModalOpen,
        defaultCountry,
        defaultPhoneNumber,
        defaultTimezone,
        timezoneOptions,
        defaultFirstName,
        defaultLastName,
        defaultHoursPerWeek
    } = props

    if (!defaultTimezone) {
        defaultTimezone = window.timezone
    }
    if(!defaultPhoneNumber) {
        defaultPhoneNumber = ''
    }

    let [phone, setPhone] = React.useState(defaultPhoneNumber || '')
    let [timezone, setTimezone] = React.useState(timezoneOptions.find(el => el.value === defaultTimezone))

    let [firstName, setFirstName] = React.useState(defaultFirstName)
    let [lastName, setLastName] = React.useState(defaultLastName)
    let [country, setCountry] = React.useState(defaultCountry || 'au')
    let [phoneValid, setPhoneValid] = React.useState(!!defaultPhoneNumber)

    let [loading, setLoading] = React.useState(false)
    let [submissionError, setSubmissionError] = React.useState(null)
    let [submissionSuccessful, setSubmissionSuccessful] = React.useState(false)
    let [step, setStep] = React.useState(1)
    let [hoursPerWeek, setHoursPerWeek] = React.useState(defaultHoursPerWeek)
    const [width, setWidth] = React.useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = (width <= 600);

    const close = () => {
        // There is probably a better way to do this, but users hitting back after navigating to handbook
        // get a cached version of page that has the modal open.
        window.location.reload()
    }
    // TODO: add nickname in here...?

    const onContactFormSubmit = async () => {
        setLoading(true)
        let submissionResult = await submitProfile(userProfileId, {
            phone: phone,
            country: country,
            timezone: timezone,
            firstName: firstName,
            lastName: lastName,
            phoneValid: phoneValid,
            hoursPerWeek: hoursPerWeek?.value  || 1
        })

        let submissionBody = await submissionResult.json()
        if (submissionResult.status === 200) {
            setSubmissionError(null)
            setSubmissionSuccessful(true)
            // TODO: we have hidden all but the first step
            close()
            // setStep(6)
        } else {
            // rails model validation error
            if (submissionBody['errors']['attribute'] === 'missing_fields') {
                setSubmissionError(`Please complete the following fields: ${submissionBody['errors']['type'].join(', ')}`)
            } else if (submissionBody['errors']['attribute'] === 'primary_phone') {
                setSubmissionError('Phone number invalid? (note: country code is included for you after selecting country.)')
            } else {
                setSubmissionError(`Error while getting your profile set up: ${submissionBody['errors'].join(", ")}`)
            }
        }
        setLoading(false)
    }

    const ControlsBox = () => {
        // NOTE: controls box isn't shown on the last page, for what its worth.
        let className = (step !== 1 && isMobile) ? 'mobile-intro-lightbox-controls-box' : 'intro-lightbox-controls-box'
        return <div className={className}>
            { step !== 1 && step !== 6 && <button className={'basic-link-button'} onClick={onBack}>
                Back
            </button>}
            { step !== 6 && <button className={'basic-link-button'} onClick={onNext}>
                Next
            </button> }
            { step === 6 && <button className={'basic-link-button'} onClick={close}>Alright!</button>}
        </div>
    }

    const IntroPartOne = (isMobile) => {
        return <div className={'intro-lightbox-image-slide'}>
            <img className={'intro-lightbox-image'} src={isMobile ? IntroMobile1 : Intro1}/>
        </div>
    }

    const IntroPartTwo = (isMobile) => {
        return <div className={'intro-lightbox-image-slide'}>
            <img className={'intro-lightbox-image'} src={isMobile ? IntroMobile2 : Intro2}/>
        </div>
    }
    const IntroPartThree = (isMobile) => {
        return <div className={'intro-lightbox-image-slide'}>
            <img className={'intro-lightbox-image'} src={isMobile ? IntroMobile3 : Intro3}/>
        </div>
    }

    const IntroPartFour = (isMobile) => {
        return <div className={'intro-lightbox-image-slide'}>
            <img className={'intro-lightbox-image'} src={isMobile ? IntroMobile4 : Intro4}/>
        </div>
    }

    const IntroPartFive = (isMobile) => {
        // TODO: Do we want thyis
        return <div className={'intro-lightbox-image-slide'}>
            <img className={'intro-lightbox-image'} src={isMobile ? IntroMobile5 : Intro5}/>
        </div>
    }

    const getStepContent = (step, isMobile) => {
        switch(step) {
            case 1:
                return ContactForm({
                    timezone,
                    timezoneOptions,
                    setTimezone,
                    hoursPerWeek,
                    setHoursPerWeek,
                    setPhone,
                    phone,
                    country,
                    setCountry,
                    firstName,
                    setFirstName,
                    lastName,
                    setLastName,
                    phoneValid,
                    setPhoneValid,
                    defaultPhoneNumber,
                    submissionError,
                    isMobile
                })
            case 2:
                return IntroPartOne(isMobile)
            case 3:
               return IntroPartTwo(isMobile)
            case 4:
                return IntroPartThree(isMobile)
            case 5:
                return IntroPartFour(isMobile)
            case 6:
                // fireworks
                return IntroPartFive(isMobile)
        }
    }

    const onBack = () => {
        if(loading) {
            return
        }
        switch(step) {
            case 1:
                break;
            case 2:
                setStep(1)
                break;
            case 3:
                setStep(2)
                break;
            case 4:
                setStep(3)
                break;
            case 5:
                setStep(4)
                break;
        }
    }

    const onNext = () => {
        if(loading) {
            return
        }
        switch(step) {
            case 1:
                onContactFormSubmit()
                break;
            case 2:
                setStep(3)
                break;
            case 3:
                setStep(4)
                break;
            case 4:
                setStep(5)
                break;
            case 5:
                setStep(6)
                break;
        }
    }

    return (
        <div className={'intro-lightbox-content'} key={'intro-lightbox-content'}>
            {loading && <div className={'acquaint-spinner-wrapper'} style={{zIndex: "999", position: "absolute"}}><div className={'acquaint-spinner'}/></div>}
            {getStepContent(step, isMobile)}
            <ControlsBox/>
            {/*{!loading && !submissionSuccessful && <IntroPartOne/> }*/}
            {/*{submissionSuccessful && <Fireworks/>}*/}
        </div>
    )
}