import React, { useEffect } from 'react';
import MosaicCreator from "./mosaic_creator";

const GuideMosaicComponentWrapper = (props) => {
    console.log(props)

    // TODO: include the UI needed to connect to the session.
    return <MosaicCreator
        {...props}
        currentRole='G'
    >

    </MosaicCreator>
}


export default GuideMosaicComponentWrapper;