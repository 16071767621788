import React from "react"
import Select from 'react-select'

export default class TourImagesSelect extends React.Component {
    // Take in callback for add and remove...

    render() {
        let { options } = this.props;
        return (
            <Select options={options} />
        )
      }
}