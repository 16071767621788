import React from "react"
import UserTable from "./user_table/user_table"

export default class AdminUsersView extends React.Component {
    constructor(props) {
        super(props);
    }

    setValue = (selection) => {
        this.setState({value: selection.value})
    };

    render = () => {
        let { rows } = this.props;
        return (
            <React.Fragment>
                <UserTable
                    rows={rows}
                />
            </React.Fragment>
        )
    }
}
