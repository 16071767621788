import React, {useEffect} from "react"
import ReactModal from "react-modal";
import IntroLightbox from "./intro_lightbox/IntroLightbox";
import AppreciationPreferenceLightbox from "./appreciation_preference_lightbox/AppreciationPreferenceLightbox";
import ExperimentTodosLightbox from "./experiments/ExperimentTodosLightbox";


export const useDisableBodyScroll = (open) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);
};

// TODO: dedup w/ quote_renderer
function useMediaQuery() {
    const [screenSize, setScreenSize] = React.useState([0, 0]);

    React.useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize;
}

export default function Lightbox(props) {
    let [open, setOpen] = React.useState(true)
    const [width, height] = useMediaQuery();
    let contentStyle = {
        height: 'calc(100% - 10rem)',
        width: 'calc(100% - 80px)',
        margin: 0,
        padding: 0,
        border: 'solid .5rem #8bc6a5',
        maxHeight: 'calc(100% - 10rem)',
        overflow: 'auto',
        position: 'relative'
}

    if(width < 600) {
        contentStyle = {
            height: '100%',
            width: '100%',
            margin: 0,
            left: 0,
            top: 0,
            padding: 0
        }
    }
    useDisableBodyScroll(open)
    // Modal.setAppElement($('.content-container'))

    return (
        <ReactModal
            appElement={$('.app-content')[0]}
            isOpen={open}
            shouldCloseOnOverlayClick={false}
            style={{
            content: contentStyle
            }}
            >
            {props.showIntroLightbox && <IntroLightbox {...props} width={width} height={height}  setModalOpen={setOpen} />}
            {props.showAppreciationPreferencesLightbox && <AppreciationPreferenceLightbox {...props} width={width} height={height}  setModalOpen={setOpen} />}
            {props.showExperimentTodosLightbox && <ExperimentTodosLightbox {...props} width={width} height={height} setModalOpen={setOpen} />}
        </ReactModal>
    )

}