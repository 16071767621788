import React, {useState} from "react"
import Rating from './Rating';
import {makeStyles} from "@material-ui/core/styles";
import DescriptionRenderer from "./DescriptionRenderer";


const RATING_GOOD = 6;
const RATING_BAD = 1;

export const getFeedbackMessage = (score) => {
    return score >= 7 ? "Great" : "Try Again";
};

const useStyles = makeStyles((theme) => ({
    userTrainingRoundContainer: {
        minHeight: "400px",
        marginLeft: "4px",
        marginRight: "4px",
        "& .error-message": {
            color: "red",
            margin: "10px 0",
        },
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        columnGap: "10px",
        "& .primary-button": {
            marginTop: "auto",
            marginBottom: "1rem",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            width: "200px",
            border: "none",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
            },
            "&:active": {
                backgroundColor: theme.palette.primary.darker,
                boxShadow: "inset 0 2px 4px rgba(0,0,0,0.2)",
            },
        },
    },
    userInputTextarea: {
        backgroundColor: "#EFEFEF",
        border: "none",
        borderBottom: "1px solid #000",
        width: "100%",
        padding: "10px",
        marginBottom: "10px",
    },
    spinnerWrapper: {
        width: "100%",
        margin: "auto",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    // Rest of your styles here...
}));


function TrainingRound(props) {
    const [trainingRound, setTrainingRound] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const [finalRoundJustCompleted, setFinalRoundJustCompleted] = useState(false);
    const classes = useStyles();

    const startNewRound = async () => {
        const userTrainingId = props.userTraining.id;
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`/user_trainings/${userTrainingId}/new_training_round`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData.error);
                setIsLoading(false);
                setError(errorData.error);
                return;
            }

            const data = await response.json();
            setTrainingRound(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            setError(error.message);
        }
    };

    const submitUserInput = async (userInput) => {
        setIsLoading(true);
        setError(null);
        const userTrainingId = props.userTraining.id;
        try {
            const response = await fetch(`/user_trainings/${userTrainingId}/save_training_round`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                },
                body: JSON.stringify({
                    userInput: userInput,
                    trainingRound: trainingRound,
                    aiTrainingChallengeId: props.aiTrainingChallengeId
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData.error);
                setIsLoading(false);
                setError(errorData.error);
                return;
            }

            const data = await response.json();
            setTrainingRound(data);
            setIsLoading(false);
            if (data.user_input) {
                setIsComplete(true);
            }
            if(data.completed_by_user === true) {
                setFinalRoundJustCompleted(true);
                props.onChallengeCompletion();
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            setError("Failed to submit user input. Please try again.");
        }
    };

    const handleNextRound = () => {
        props.onRoundCompletion(trainingRound);
        setTrainingRound(null);
        setIsComplete(false);
        startNewRound();
    };

    const handleRatingSubmit = async (ratings, comments) => {
        setIsLoading(true);

        try {
            const response = await fetch(`/user_trainings/${trainingRound.id}/update_training_feedback`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                },
                body: JSON.stringify({ id: trainingRound.id, ratings: ratings, comments: comments }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData.error);
                setIsLoading(false);
                setError(errorData.error);
                return;
            } else {
                setIsLoading(false);
                setIsComplete(true);
                alert("Feedback submitted !!");
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            setError("Failed to submit user input. Please try again.");
        }
    };


    return (
        <div className={classes.userTrainingRoundContainer}>
            {/* Error message display */}
            {error && (
                <div className={classes.errorMessage}>
                    Error: {error}
                </div>
            )}
            {!trainingRound && isLoading && (
                <div className={classes.spinnerWrapper}><div style={{position: 'relative'}} className={'acquaint-spinner'}/></div>
            )}
            {!trainingRound && (
                <div className={classes.buttonContainer}>
                    <button className='primary-button' onClick={startNewRound} disabled={isLoading}>
                        {isLoading ? "Generating training data, please wait..." : "Start New Round"}
                    </button>
                </div>
            )}
            {trainingRound && isComplete && !finalRoundJustCompleted && (
                <div className={classes.buttonContainer}>
                    <button className='primary-button' onClick={handleNextRound} disabled={isLoading}>
                        {isLoading ? "Generating training data, please wait..." : "Click for next round"}
                    </button>
                </div>
            )}
            {trainingRound && (
                <div>
                    {!isComplete &&
                        <h5><strong>New Round</strong></h5>
                    }
                    <h6><strong>AI INITIAL RESPONSE: </strong></h6>
                    <div className="round-ai-response" dangerouslySetInnerHTML={{ __html: trainingRound.ai_initial_response }}></div>

                    {!trainingRound.user_input && (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            const userInput = e.target.elements.userInput.value;
                            if (!userInput.trim()) {
                                alert("Please enter a response before submitting.");
                            } else {
                                submitUserInput(userInput);
                            }
                        }}>
                            <textarea className={classes.userInputTextarea} name="userInput" placeholder="Enter your response here to get training feedback" />
                            <div className={classes.buttonContainer}>
                                <button className='primary-button' type="submit" disabled={isLoading}>
                                    {isLoading? "Submitting your response for feedback, please wait..." : "Submit"}
                                </button>
                            </div>
                        </form>
                    )}
                    {trainingRound.user_input && (
                        <div>
                            <h6><strong>YOUR INPUT: </strong></h6>
                            <p>{trainingRound.user_input}</p>
                            <h6><strong>FEEDBACK: </strong></h6>
                            <p>{getFeedbackMessage(trainingRound.score)}</p>
                            <h6><strong>AI FEEDBACK: </strong></h6>
                            <p>{trainingRound.ai_feedback}</p>
                            <hr></hr>
                            <Rating
                                onRatingSubmit={handleRatingSubmit} showComments={true} showRating={true} downRating={RATING_BAD} upRating={RATING_GOOD}
                            />
                        </div>
                    )}
                    {trainingRound && isComplete && !finalRoundJustCompleted && (
                        <div className={classes.buttonContainer}>
                            <button className='primary-button' onClick={handleNextRound} disabled={isLoading}>
                                {isLoading ? "Generating training data, please wait..." : "Click for next round"}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TrainingRound;