const cancelLink = (event) => {
    if ($(event.target).hasClass('cancel-session-link')) {
        if (!confirm(`Are you sure you want to cancel?`)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
};

$(document).on('turbolinks:load', () => {
    $('.cancel-session-link').on('click', cancelLink);
});

$(document).on('turbolinks:before-cache', () => {
    $('.cancel-session-link').off('click', cancelLink);
});
