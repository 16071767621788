import React from "react";
import TourScene from "./TourScene";

export default class TourSceneBrowser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props};
    }

    callCreateTourScene = async (tourId) => {
        return await fetch(`${tourId}/tour_scenes`, {
                method: "POST",
                body: JSON.stringify({
                  tour_id: tourId
                }),
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                }
            }
        );
    };

    createAndAppendTourScene = async () => {
        let tourSceneResponse = await this.callCreateTourScene(this.props.tour.id);
        let createdTourScene = await tourSceneResponse.json();

        if(this.state.tourScenes.length !== 0) {
            this.setState({tourScenes: [...this.state.tourScenes, createdTourScene]});
        } else {
            this.setState({tourScenes: [createdTourScene]});
        }
    };

    callDeleteTourScene = async (id) => {
        return await fetch(`/tour_scenes/${id}`, {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                }
            }
        );
    };

    deleteAndRemoveTourScene = async (id) => {
        if(!confirm("Are you sure you want to delete this scene?")) {
            return
        }
        let response = await this.callDeleteTourScene(id);
        if(response.ok) {
            this.setState({tourScenes: this.state.tourScenes.filter(tourScene => tourScene.id !== id)});
        } else {
            // TODO: error handling... ?
        }
    };

    editTourScene = async (id) => {
        window.location = `/tour_scenes/${id}`;
    };

    render() {
        const { permissions } = this.props
        const scenes = this.state.tourScenes.map((scene) =>
            <li className={'tour-scene-list-item'} key={scene.id}>
                <TourScene
                    thumbnailPath={this.props.tourSceneThumbnails[scene.id]}
                    scene={scene}
                    onDeleteClicked={() => this.deleteAndRemoveTourScene(scene.id)}
                    onEditClicked={() => this.editTourScene(scene.id)}
                    permissions={permissions}
                />
            </li>
        );

        return (
            <div>
                <div className={'tour-section-header'}>Manage Scenes</div>
                <ul className={'tour-scene-list'}>{scenes}</ul>
                {permissions === 'manager' &&
                    <button className={'basic-link-button'} onClick={this.createAndAppendTourScene}>Add New Scene</button>
                }
            </div>
        )
    }
}
