import React from 'react';

import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Paper,
    InputAdornment,
    IconButton,
    TableCell,
    TableBody, TableRow, TableHead, TextField, Link, Table
} from '@material-ui/core';
import Video from 'twilio-video';
import {ErrorIcon, CopyIcon} from "./TwilioIcons";

const UAParser = require('ua-parser-js');


const useStyles = makeStyles({
    paper: {
        display: 'inline-block',
        padding: '1.5em',
        borderRadius: '8px',
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tableHead: {
        background: '#F4F4F6',
    },
    table: {
        '& td': {
            padding: '0.9em',
        },
    },
    copyButton: {
        borderLeft: 'solid 1px #E1E3EA',
        maxHeight: '2.5em',
        display: 'flex',
    },
    errorIcon: {
        position: 'absolute',
        right: 'calc(100% + 18px)',
    },
    caption: {
        marginTop: '1.3em',
    }
});

export function UnsupportedBrowser(props) {
    let { isIOS } = props
    const classes = useStyles();

    const appURL = window.location.href;

    const copyAppLink = () => {
        navigator.clipboard.writeText(appURL);
    };

    return (
        <>
            <Container alignItems={"center"}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item md={5}>
                        <Typography variant="h4" gutterBottom style={{ position: 'relative', marginLeft: '2rem' }}>
                            <div className={classes.errorIcon}>
                                <ErrorIcon />
                            </div>{' '}
                            New browser needed
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Please use one of the browsers from the list below. You may copy this url and paste
                            it into your new browser to restart the test.
                            {' '}<b>NOTE: On iOS Devices (iPhone, iPad) you MUST use Safari</b>
                        </Typography>
                        <TextField
                            fullWidth={true}
                            id="read-only-app-link"
                            defaultValue={appURL}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div className={classes.copyButton}>
                                            <IconButton edge="end" onClick={copyAppLink}>
                                                <CopyIcon />
                                            </IconButton>
                                        </div>
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    ​
                    <Grid item md={5} className={classes.paperContainer}>
                        <Paper className={classes.paper}>
                            <SupportedList {...{isIOS}}/>
                        </Paper>
                        <Typography variant="caption" align="center" className={classes.caption}>
                            We support the most recent and the two previous versions. You can{' '}
                            <Link
                                color="inherit"
                                underline="always"
                                target="_blank"
                                rel="noopener"
                                href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                            >
                                visit Twilio's docs
                            </Link>{' '}
                            for a full list of supported browsers (we use Twilio for the audio connection on Acquaint).
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export function SupportedList(props) {
    const classes = useStyles();
    let {isIOS} = props
    return (
        <>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1">
                                <strong>Supported browsers</strong>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isIOS && <><TableRow>
                        <TableCell>
                            <Typography variant="body1">
                                <Link
                                    color="inherit"
                                    underline="always"
                                    target="_blank"
                                    rel="noopener"
                                    href="https://www.google.com/chrome/"
                                >
                                    Chrome
                                </Link>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1">
                                <Link
                                    color="inherit"
                                    underline="always"
                                    target="_blank"
                                    rel="noopener"
                                    href="https://www.mozilla.org/en-US/firefox/new/"
                                >
                                    Firefox
                                </Link>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderBottom: 'none' }}>
                            <Typography variant="body1">
                                <Link
                                    color="inherit"
                                    underline="always"
                                    target="_blank"
                                    rel="noopener"
                                    href="https://www.microsoft.com/en-us/edge"
                                >
                                    Edge
                                </Link>
                            </Typography>
                        </TableCell>
                    </TableRow></>
                    }
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1">
                                <Link
                                    color="inherit"
                                    underline="always"
                                    target="_blank"
                                    rel="noopener"
                                    href="https://support.apple.com/downloads/safari"
                                >
                                    Safari
                                </Link>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export function SupportedBrowser(props) {

    let { nextTest, userAgentInfo } = props
    // const { dispatch,  } = useAppStateContext();
    const classes = useStyles();

    const browser = userAgentInfo.browser;
    const operatingSystem = userAgentInfo.os;

    return (
        <>
            <Container>
                <Grid container alignItems="center" justifyContent={'center'}>
                    <Grid item md={5}>
                        <Typography variant="h4" gutterBottom>
                            Browser supported
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Looks like your browser is good to go!
                        </Typography>
                        <button
                            type="button"
                            className={'basic-link-button mb-3'}
                            onClick={nextTest}
                            style={{ marginRight: '0' }}
                        >
                            Proceed
                        </button>
                    </Grid>

                    <Grid item className={classes.paperContainer}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" >
                                <strong>Browser: </strong>
                                {browser.name} {browser.version}
                            </Typography>
                            ​
                            <Typography variant="body1">
                                <strong>Operating System: </strong>
                                {operatingSystem.name} {operatingSystem.version}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export function CheckBrowser(props) {
    let { addResult, results } = props
    let userAgentInfo = new UAParser().getResult();

    let goodWithTwilio = Video.isSupported
    const isIOS = userAgentInfo.os.name === 'iOS'
    let iosWithoutSafari = isIOS && !/Safari/.test(userAgentInfo.browser.name)
    let acceptableBrowser = goodWithTwilio && !iosWithoutSafari

    if(results.browser && Object.keys(results.browser).length === 0) {
        addResult('browser',
            {
                success: acceptableBrowser,
                details: {
                    userAgentInfo: userAgentInfo,
                    iosWithoutSafari: iosWithoutSafari
                }
            }
        )
    }
    return <>{acceptableBrowser ? <SupportedBrowser {...{userAgentInfo, ...props}} /> : <UnsupportedBrowser {...{isIOS, ...props}} />}</>;
}
