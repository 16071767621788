import React from "react";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { StylesProvider } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import SessionInstructions from "./traveler_instructions/traveler_instructions";
import Badge from '@material-ui/core/Badge';

export default function ScheduledSessionCard(props) {
    let {
        title,
        checkinUrl,
        confirmUrl,
        canJoin,
        volunteerCancelUrl,
        checkedIn,
        manageNotificationsUrl,
        type,
        showCancelRequestSubmitBadge
    } = props;

    return (
        <StylesProvider injectFirst>
            <Card className={'session-card'}>
                {showCancelRequestSubmitBadge && (
                    <Badge badgeContent="Cancel Request Message Sent" color="secondary" className="cancel-request-badge">
                        {/* This is a placeholder for wherever you want the badge to be located */}
                    </Badge>
                )}
                <CardContent>
                    <Typography className="havr-font" gutterBottom variant="h5" component="h2" role="heading" aria-level="2" aria-roledescription="Title with date and time of upcoming session">
                        {title}
                    </Typography>
                    {type === "ScheduledCoverage" && (
                        <Typography className="havr-font" gutterBottom variant="h5" component="h2">
                            You are scheduled for Coverage/Backup
                        </Typography>
                    )}
                    {checkedIn && (
                        <Typography aria-label="check in message">
                            You're checked in!
                        </Typography>
                    )}
                    {(!confirmUrl && !checkedIn) && (
                        <Typography aria-label="confirmation message for session">
                            You are confirmed.
                        </Typography>
                    )}
                </CardContent>

                {!canJoin && (
                    <div>If it is time for your session and you don't see the "Join Session" button, please refresh the page. It should show up 10 minutes before the scheduled time.</div>
                )}

                <CardActions>
                    <div className='session-card-action-buttons'>
                        {confirmUrl && (
                            <a href={confirmUrl} className="home-link-button" role="button" aria-roledescription="Button to confirm session">Confirm</a>
                        )}
                        {canJoin && (
                            <a href={'/volunteer_home'} className="home-link-button start-current-session-button" role="button" aria-roledescription="Button to start session">Join Session</a>
                        )}
                        {!checkedIn && checkinUrl && (
                            <a href={checkinUrl} className="home-link-button" role="button" aria-roledescription="Button to check in for session">Check In</a>
                        )}
                    </div>
                </CardActions>
                <SessionInstructions />

                <div>
                    {volunteerCancelUrl && (
                        <a href={volunteerCancelUrl} role="button" aria-roledescription="link to cancel session">Need to cancel? Click Here</a>
                    )}
                </div>
                <div>
                    Want reminders? go to <a href={manageNotificationsUrl} role="link" aria-roledescription="Link to manage notifications">Manage Notifications</a>
                </div>
            </Card>
        </StylesProvider>
    );
}
