import React from "react"
import PropTypes from "prop-types"
import consumer from "../channels/consumer"
import {
  broadcastData,
  WebRtcAudioClient,
  SESSION_EXPIRED,
  JOIN_CALL,
  IMAGE_UPDATED,
  EXCHANGE,
  LEAVE_CALL,
  UPDATE_IMAGE,
  BEEP,
  SET_ROTATION,
  ANSWER,
  END_SESSION,
  SESSION_ENDED,
  SET_FOV,
  HANGUP,
  logExperienceData,
  IN_ENDED_EXPERIENCE,
  TERMINATED_BY_FACILITATOR,
  MOSAIC
} from '../utils/guided_session';

import Heartbeat from '../utils/heartbeat'
import {ZoomOutIcon} from "./icons/zoom_out_icon"
import {ZoomInIcon} from "./icons/zoom_in_icon"
import * as Sentry from '@sentry/browser';
import {connect, createLocalTracks} from "twilio-video";
import GuideMosaicComponentWrapper from "./mosaic_creator/guide_mosaic_component_wrapper";

class ImageLink extends React.Component {
  render() {
    let callback = () => {
      // TODO: This logic should get pushed up
      this.props.callback(this.props.imageObject)
    };

    let className = '';
    if(this.props.spinner){
      className = 'spinner'
    }

    let imageVisitedStateClass = '';
    if (this.props.visited) {
      imageVisitedStateClass = ' image-visited'
    }

    return (
      <button className={`image-link-button ${imageVisitedStateClass}`} key={this.props.index} onClick={callback}>
        <i className={className}/>
        {this.props.index + 1}. {this.props.imageObject.label}
      </button>
    )
  }
}

class ImageLinkList extends React.Component {
  render() {
    let images = [];
    for (const [index, value] of this.props.images.entries()) {
      images.push(<ImageLink
        index={index}
        imageObject={value}
        visited={this.props.visitedImages.indexOf(value.image_id) !== -1}
        callback={this.props.callback}
        spinner={value.image_id === this.props.buttonSpinner}
        key={index}
      />)
    }
    return images
  }
}


const dataChannelDataReceived = (event) => {

  // TODO: fix wrap around!

  // TODO: Theoretically we might get other events than headset orientation here
  //  ^^ this should be made to look at an event type or similar?
  let data = JSON.parse(event.data);
  let radiansPercent = .1591549;
  let canvas = $('#eye-tracker-canvas')[0];
  let radiansInCircle =  6.28319;
  // Not sure why X and Y are inverted here; something to do with spherical coordinates probably #math
  // we need to get the modulo here as the camera rotation is not world(?) coordinates but literally the number of radians its been rotated
  let yRadians = parseFloat(data.x) % radiansInCircle;
  let xRadians = parseFloat(data.y) % radiansInCircle;
  let baseX =  Math.round(canvas.width * xRadians * radiansPercent);
  let baseY = Math.round(canvas.height * yRadians * radiansPercent * 2);
  let y = (canvas.height / 2) - baseY;
  let x = ((canvas.width / 2) - baseX) % canvas.width;

  const vFOVPercent = parseFloat(data.vFOV) / 360 // FOV is in degrees rather than radians...
  const hFOVPercent = parseFloat(data.hFOV) / 360

  const viewBoxHeight = canvas.height * vFOVPercent * 2
  const viewBoxWidth = canvas.width * hFOVPercent

  let context = canvas.getContext("2d")
  context.fillStyle = "#FF0000"
  context.strokeStyle = "#FF0000"
  context.clearRect(0,0, canvas.width, canvas.height)
  context.lineWidth = 4
  context.strokeRect(x - (viewBoxWidth / 2), y - (viewBoxHeight / 2) , viewBoxWidth, viewBoxHeight)
  context.strokeRect(x - (viewBoxWidth / 2) - canvas.width, y - (viewBoxHeight / 2) , viewBoxWidth, viewBoxHeight)
  context.strokeRect(x - (viewBoxWidth / 2) + canvas.width, y - (viewBoxHeight / 2) , viewBoxWidth, viewBoxHeight)

};

const TourImageCanvas = (props) => {
  let {previewImageUrl, showIsMentor} = props;
  let mentoringNotificationClass = 'mentoring-session-notification hidden';
  //
  if(showIsMentor) {
    // TODO: currently, this is not used.
    mentoringNotificationClass = 'mentoring-session-notification'
  }

  return(
      <div className='tour-canvas-container' >
        <div className={mentoringNotificationClass}>YOU ARE MENTORING</div>

        <canvas className ='tour-canvas'
            id="eye-tracker-canvas"
        >
        </canvas>
        <img className='tour-canvas-image'
             id='preview'
             src={previewImageUrl}
             crossOrigin={'anonymous'}/>
      </div>
  )
};


class TwilioClient {
  twilioToken = null;
  experienceId = null;
  room = null;
  onConnectionStateChange = null;

  constructor(experienceId, onConnectionStateChange, updateTravelerMutedState) {
    this.experienceId = experienceId
    this.onConnectionStateChange = onConnectionStateChange
    this.updateTravelerMutedState = updateTravelerMutedState
  }

  
  hangup = async () => {
    if(this.room) {
      this.room.disconnect();
    }
  }

  initiateHangup = async () => {
    // this.addDebugEvent([new Date(), `initiateHangup`])
    console.log('Twilio hangup initiated')
    await this.hangup();
    // This should hang up twilio on their end.
    broadcastData({
      type: HANGUP,
      from: this.userId,
      experienceId: this.experienceId
    })
  };

  call = async (experienceId) => {
    if(!this.twilioToken) {
      this.twilioToken = await this.fetchTwilioToken().then(data => {
        return data.json()
      });
    }
    const result = await createLocalTracks({
      audio: true,
    }).then(localTracks => {
      logExperienceData(experienceId, 'microphone_access_granted',false)
      return connect(this.twilioToken.token, {
        tracks: localTracks,
        maxAudioBitrate: 16000 // TODO: make this configurable.
      });
    }, (e) => {
      logExperienceData(experienceId, 'microphone_access_denied',true)
      window.location.replace("/no_microphone_access");
    }).then(room => {
      this.room = room;
      console.log(`Connected to Room: ${room.name}`);
      this.onConnectionStateChange(true)

      room.participants.forEach(participant => {
        console.log(`Participant "${participant.identity}" is connected to the Room`);

        // Attach tracks for existing people ---------------------
        participant.tracks.forEach(publication => {
          if (publication.track) {
            // const track = publication.track;
            // document.getElementById('remote-media-div').appendChild(track.attach());
            if (publication.track.kind === 'data') {
              // console.log("Data track published")
              publication.track.on('message', data => {
                dataChannelDataReceived({data: data})
              });
            }else {
              // its an audio track since we don't do video...
              publication.track.attach();

              this.updateTravelerMutedState(!publication.track.isEnabled)

              track.on('disabled', () => {
                console.log("The traveler audio track was disabled")
                this.updateTravelerMutedState(true)

              });

              track.on('enabled', () => {
                console.log('The traveler audio track was enabled.');
                this.updateTravelerMutedState(false)
              });
            }
          }
        });


        participant.on('trackSubscribed', track => {
          console.log(`Participant "${participant.identity}" added ${track.kind} Track ${track.sid}`);
          if (track.kind === 'data') {
            track.on('message', data => {
              dataChannelDataReceived({data: data})
            });
          } else {
            // its an audio track since we don't do video...

            track.attach();
            this.updateTravelerMutedState(!track.isEnabled)

            track.on('disabled', () => {
              console.log("The traveler audio track was disabled")
              this.updateTravelerMutedState(true)

            });

            track.on('enabled', () => {
              console.log('The traveler audio track was enabled.');
              this.updateTravelerMutedState(false)
            });
          }

        });
      });

// Log new Participants as they connect to the Room
      room.on('participantConnected', participant => {
        this.onConnectionStateChange(true)
        console.log(`Participant "${participant.identity}" has connected to the Room`);
        participant.on('trackSubscribed', track => {

          if (track.kind === 'data') {
            track.on('message', data => {
              console.log(data)
              dataChannelDataReceived({data: data})
            });
          } else {
            // its an audio track since we don't do video...
            track.attach();
            this.updateTravelerMutedState(!track.isEnabled)

            track.on('disabled', () => {
              console.log("The traveler audio track was disabled")
              this.updateTravelerMutedState(true)
            });

            track.on('enabled', () => {
              console.log('The traveler audio track was enabled.');
              this.updateTravelerMutedState(false)
            });
          }
        });
      });

// Log Participants as they disconnect from the Room
      room.on('participantDisconnected', participant => {
        this.onConnectionStateChange(false)
        console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        // There is only one participant, so we can safely say they are unmuted now...
        this.updateTravelerMutedState(false)
      });

      room.on('disconnected', room => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });
      });
    });
  }


  muteUnmuteAudio = (toMute) => {
    let trackEnabled;
    this.room.localParticipant.audioTracks.forEach(track => {
        if(toMute) {
            // if (track.track.isEnabled){
                track.track.disable();
            // }
        } else {
            // if (!track.track.isEnabled) {
                track.track.enable();
            // }
        }
        trackEnabled = track.track.isEnabled;
    });
    return trackEnabled
}

  fetchTwilioToken = async () => {
    return await fetch("/generate_session_experience_token", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
          },
          // TODO: this should happen after selecting a tour.
          body: JSON.stringify({
            experience_id: this.experienceId
          })
        }
    );
  };

}

const BulletPoints = (props) => {
  let {bulletPoints} = props
  return <ul>
    {
      bulletPoints.map((bullet_point, index) => {
        return <li key={`bullet-point-${index}`} className='bullet_point'>
          <div className={'scene_script_bullet_point'} dangerouslySetInnerHTML={{__html: bullet_point}}/>
        </li>
      })
    }
  </ul>
}

class GuidedSession extends React.Component {
  debugEvents = {};
  addDebugEvent = (debugEvent) => {
    this.debugEvents[`${window.performance.now()}-${Math.random()}`] = debugEvent[1];
    Sentry.configureScope(scope => {
      scope.setExtra('debugEvents', this.debugEvents);
    });
  };

  constructor(props) {
    super(props);

    window.debugEvents = this.debugEvents;

    this.subscription = null;

    if (this.props.useTwilio === true) {
      // TODO: set client

      this.client = new TwilioClient(this.props.experienceId, this.twilioConnectionStateChange, this.updateTravelerMutedState);
    } else {
      this.client = new WebRtcAudioClient(
          this.props.currentUser,
          this.props.experienceId,
          this.props.ice,
          this.iceConnectionStateChange,
          {onDataReceivedHandler: dataChannelDataReceived},
          this.addDebugEvent
      );

      // Noop for checking whether we have mic access for now.
      this.client.mountWebRtcComponent((success) => {
        // TODO: extract method
        if (success) {
          this.setState({joinDisabled: false})
        } else {
          let message = 'Your browser does not have permission to access the microphone,' +
              ' please fix this and refresh the page.';
          this.setState({connectionState: message})
        }
      });
    }

    this.state = {
      mosaicMode: this.props.tour.name === 'Mosaic Creator',
      joinDisabled: false,
      connectionState: 'Not Attempted',
      scriptHeader: 'Intro Script:',
      script: props.tour.script,
      endSessionDisabled: false,
      hangupDisabled: false,
      previewImageUrl: this.props.previewImageUrl,
      buttonSpinner: null,
      visitedImages: [],
      nextZoomState: 'Zoom In',
      bulletPoints: [],
      scriptMode: 'Bullet Points',
      audioStatusText: 'Connect Audio',
      subscriptionActive: false,
      muted: false,
      travelerMuted: false,
      mosaic: this.props.mosaicProps
    }
  }

  nextScriptMode = () => {
    return this.state.scriptMode === 'Script' ? 'Bullet Points' : 'Script'
  }

  toggleScriptMode = () => {
    this.setState({scriptMode: this.nextScriptMode()} )
  }

  componentDidMount() {
    this.subscription = this.initActionCable(this.props, this.client, this.handleMosaicMessage);
    if(this.props.visualAssistEnabled && !this.state.mosaicMode) {
      const canvas = document.getElementById('eye-tracker-canvas')
      canvas.addEventListener('mousedown', (e) => {
        let coordinates = this.getCursorPositionDegrees(canvas, e);
        this.setImageRotation(JSON.stringify(coordinates))
      })
    }
  }

  componentWillUnmount() {
    if(this.subscription) {
      console.log("Unsubscribing from session messages channel.");
      this.subscription.unsubscribe();
    }

    if(this.client) {
      this.client.hangup();
    }
  }

  iceConnectionStateChange = (event, state) => {
    let joinDisabled = true;
    if(state === 'connected') {
      BEEP();
    }

    if (
       state === 'Not Attempted'
        || state === 'disconnected'
        || state === 'hangup'
        || state === 'not connected'
    ) {
      joinDisabled = false;
    }

    this.setState(
      {
        connectionState: state, 
        joinDisabled: joinDisabled,
      });
  };


  twilioConnectionStateChange = (connected) => {
    // TODO: there is an opportunity to auto reconnect here..
    if(connected) {
      BEEP();
    }

    const stateLabel = connected ? 'connected' : 'not connected'

    this.setState({connectionState: stateLabel, joinDisabled: connected});
  };

  handleMosaicMessage = (data) => {
    console.log("handling mosaic message...")
    this.setState(prevState => ({mosaic: {
      turns: [...prevState.mosaic.turns, data.turn], // appending new actions
      scheduledSessionid: this.props.mosaicProps.scheduledSessionId,
    }}));
  }

  updateImage = (imageObject) => {
    this.setState({buttonSpinner: imageObject.image_id});
    broadcastData({
      type: UPDATE_IMAGE,
      image: JSON.stringify(imageObject),
      experienceId: this.props.experienceId,
      script: imageObject.script,
      bulletPoint: imageObject.bullet_points
    })
  };

  setImageRotation = (rotation) => {
    broadcastData({
      type: SET_ROTATION,
      rotation: rotation,
      experienceId: this.props.experienceId
    })
  };

  getCursorPositionDegrees = (canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    const yRotation = -((event.clientX - rect.left)/rect.width  * 360 - 180);
    const xRotation = -((event.clientY - rect.top)/rect.height * 180 - 90 );
    return {x: xRotation, y: yRotation, z: 0}
  };

  setFov = (nextState) => {
    const fov = nextState === 'Zoom Out' ? '80' : '40'
    const newNextState = nextState === 'Zoom Out' ? 'Zoom In' : 'Zoom Out'
    broadcastData({
      type: SET_FOV,
      fov: fov,
      experienceId: this.props.experienceId
    })

    this.setState({nextZoomState: newNextState})
  }

  notConnectedState = () => {
    return this.state.connectionState === 'Not Attempted' || this.state.connectionState === 'disconnected' || this.state.connectionState === 'hangup' || this.state.connectionState === 'not connected'
  }
  render() {
    let activeSessionButtonClass = 'start-button session-button';
    let endSessionButtonClass = 'end-button session-button';

    if (this.state.joinDisabled &&
    !this.notConnectedState()) {
      activeSessionButtonClass = this.state.muted ? 'unmute-button session-button' : 'mute-button session-button'
    }

    let audioStatusText = this.state.muted ? 'Unmute' : 'Mute Audio'

    if(this.notConnectedState()) {
      audioStatusText = 'Connect Audio'
    }

    if(this.state.endSessionDisabled) {
      endSessionButtonClass = 'end-button disabled session-button';
    }

    let connectionStateClass = `${this.state.connectionState}-call-state call-state`;

    return (
      <React.Fragment>

        {this.props.tour.name !== 'Mosaic Creator' &&
            <h3 className={'tour-header-3'}>{this.props.tour.name}</h3>
        }
        {this.props.tour.name === 'Mosaic Creator' &&
            <h3 className={'tour-header-3'}>Acquaint: Mosaics for Peace</h3>
        }
        <div className={'tour-control-section'}>
          <button className={activeSessionButtonClass} onClick={
            () => {
              if (this.state.joinDisabled && !this.notConnectedState()) {
                this.muteUnmuteOnClick();
              } else {
                this.connectAudioOnClick();
            }
          }
          }>{audioStatusText}
          </button>
          <button className={endSessionButtonClass} disabled={this.state.hangupDisabled} onClick={
            async () => {
              this.setState({joinDisabled: true, hangupDisabled: true});
              await this.client.initiateHangup();
              this.iceConnectionStateChange(null, 'hangup');
              this.setState({joinDisabled: false, hangupDisabled: false});
            }
          }>Hangup</button>
          <button className={endSessionButtonClass} disabled={this.state.endSessionDisabled} onClick={
            async () => {
              if(!confirm("Are you sure you want to end the session?")) {
                return
              }

              this.setState({endSessionDisabled: true });
              broadcastData({ type: END_SESSION, from: this.props.userId, experienceId: this.props.experienceId });
              await this.client.hangup();
              window.location.replace("/user_redirect_end_session");
            }
          }>End Session</button>
        </div>
        <h5 className={'tour-header-5'} ><span><b>Traveler Name: </b></span><span>{this.props.travelerFirstName} {this.state.travelerMuted ? <b style={{color: 'red' }}>Traveler muted</b> : "" }</span>{'\u00A0'}{'\u00A0'}{'\u00A0'}<span><b>Connection State: </b></span><span className={connectionStateClass}>{this.state.connectionState}</span></h5>

        { this.props.tour.name !== 'Mosaic Creator' && <>
        <hr className={'tour-hr'} />
        <h4 className={'tour-header-4'}>Scene selection:</h4>
        <hr className={'tour-hr'} />
        <div className={'tour-control-section image-link-section'} >
          <ImageLinkList
              images={this.props.tour.images}
              visitedImages={this.state.visitedImages}
              buttonSpinner={this.state.buttonSpinner}
              callback={this.updateImage}
          />
        </div>
        <hr className={'tour-hr'} />


        <h4 className={'tour-header-4'} id={'script_header'}>{this.state.scriptHeader}</h4>
        <hr className={'tour-hr'} />
        <div className={'tour-control-section tour-text-content'}>
          { this.state.bulletPoints.length > 0 && <div className={'script-mode-selection'}>
            <button type={'button'} className={'basic-link-button'} onClick={this.toggleScriptMode}>{this.nextScriptMode()}</button>
          </div> }
          {this.state.scriptMode === 'Bullet Points' && this.state.bulletPoints.length > 0 && <BulletPoints bulletPoints={this.state.bulletPoints}/> }
          { (this.state.scriptMode !== 'Bullet Points' ||  this.state.bulletPoints.length === 0) &&
              <div id={'scene_script'} dangerouslySetInnerHTML={{__html: this.state.script}}/>
          }
        </div>
        <hr className={'tour-hr'} />
        <div className={'enable-zoom'}>
          <div className={'enable-zoom-label'}>Change Traveler Zoom:         </div>
          <button onClick={() => this.setFov(this.state.nextZoomState)} className={'enable-zoom-button'}>
            {this.state.nextZoomState === 'Zoom In' ? <ZoomInIcon divClass={'session-zoom-icon'}/> : <ZoomOutIcon divClass={'session-zoom-icon'}/>}
            {this.state.nextZoomState}
          </button>
        </div>
        <TourImageCanvas previewImageUrl={this.state.previewImageUrl} showIsMentor={this.state.mentoringSession} visualAssistEnabled={this.props.visualAssistEnabled} />
      </>
    }
        { this.props.tour.name === 'Mosaic Creator' &&
            <GuideMosaicComponentWrapper
                broadcastData={broadcastData}
                handleMosaicMessage={this.handleMosaicMessage}
                {...this.state.mosaic}
                experienceId={this.props.experienceId}
                currentUser={this.props.currentUser}
                scheduledSessionId={this.props.mosaicProps.scheduledSessionId}
                backgroundImagePath={this.props.mosaicProps.backgroundImagePath}
            />
        }
      </React.Fragment>
    );
  }

  connectAudioOnClick = () => {
    if(this.state.callInitiating) {
      return;
    }
    this.setState({callInitiating: true});
    setTimeout(() => {this.setState({callInitiating: false})}, 250);
    if (
        this.state.connectionState === 'Not Attempted'
        || this.state.connectionState === 'disconnected'
        || this.state.connectionState === 'hangup'
        || this.state.connectionState === 'not connected'
    ) {

      // warm up audio in the context of a user interaction
      let controlAudioElement = document.getElementById("callSounds");
      let playPromise = controlAudioElement.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          controlAudioElement.pause();
        }).catch(error => {
              console.warn(error);
            });
      }
      this.client.call(this.props.experienceId);
      this.setState(
        {
          audioStatusText: "Mute Audio",
          muted: false,
        });
    }
  }

  muteUnmuteOnClick = () => {
    let trackEnabled;
    if (this.state.muted) {
      trackEnabled = this.client.muteUnmuteAudio(false);
      if (trackEnabled) {
        this.setState({muted: false});
      }
    }
    else {
      trackEnabled = this.client.muteUnmuteAudio(true);
      if (!trackEnabled){
        this.setState({muted: true});
      }
    }
  }

  updateTravelerMutedState = (isMuted) => {
    this.setState({ travelerMuted: isMuted});
  }


  // TODO: mosaic message is munged in here for now... refactor.
  initActionCable = (props, client, handleMosaicMessage) => {
    const { currentUser, experienceId, tour } = props;
    const setState = this.setState.bind(this);
    const self = this;
    // TODO: we may need to store in a wider scope.
    const heartbeats = [];

    console.log('init action cable')
    let subscription = consumer.subscriptions.create(
      {
        channel: "SessionMessagesChannel",
        experience_id: experienceId
      }, {
      connected() {
        // Let the server know we are in the session.
        heartbeats.forEach((heartbeat) => {
          heartbeat.stop();
        });

        setState({subscriptionActive: true})

        let heartbeat = new Heartbeat(currentUser, experienceId)
        heartbeats.push(heartbeat)
        heartbeat.start()

        // Called when the subscription is ready for use on the server
        console.log(`connected to channel with experienceId: ${experienceId}`)
      },

      disconnected() {
        console.log(`disconnected from channel with experienceId: ${experienceId}`)
        // Called when the subscription has been terminated by the server
        setState({subscriptionActive: true})
      },

      async received(data) {
        if (data.type === IN_ENDED_EXPERIENCE) {
          // we are in the wrong experience, we should redirect to the right one.
          if(data.experience_id === experienceId) {
            window.location.replace(data.redirect_url);
            return
          }
        }
        if (data.type === TERMINATED_BY_FACILITATOR) {
          if(data.experience_id === experienceId) {
            window.location.replace(data.redirect_url);
            return
          }
        }
        // Called when there's incoming data on the websocket for this channel
        if (data.from === currentUser) return;
        switch (data.type) {
          case EXCHANGE:
            // TODO: WEBRTC ONLY
            return client.exchange(data);
          case ANSWER:
            // TODO: WEBRTC ONLY
            setState({joinDisabled: true});
            return client.initiateWebRtcHandshake();
          case END_SESSION:
            window.location.replace("/user_redirect_end_session");
            return;
          case SESSION_ENDED:
            window.location.replace("/user_redirect_end_session");
            return;
          case SESSION_EXPIRED:
            await client.hangup();
            window.location.replace("/volunteer_home");
            return;
          case IMAGE_UPDATED:
            // TODO ONLY if image matches current loading image, remove loading spinner.
            let newImage = tour.images.find(img => img.image_id === data.image_id);
            let buttonSpinner = null;
            if(self.state.buttonSpinner !== data.image_id){
              buttonSpinner = self.state.buttonSpinner;
            }

            setState({
              visitedImages: [...self.state.visitedImages, newImage.image_id],
              buttonSpinner: buttonSpinner,
              previewImageUrl: newImage.preview,
              script: newImage.script,
              scriptHeader: newImage.label,
              bulletPoints: newImage.bullet_points
            });
            return;
          default:
            return;
        }
      }
    });
    return subscription;
  }
}

GuidedSession.propTypes = {
  sessionId: PropTypes.string
};
export default GuidedSession
