import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    inputField: {
        marginBottom: theme.spacing(2),
    },
    scenarioList: {
        listStyle: 'none',
        padding: 0,
    },
    scenarioCard: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: '0!important', // Overrides the CardContent bottom padding
    },
    cardActions: {
        flexDirection: 'row-reverse',
    }
}));

function AiTrainingScenarioManager({ aiTrainingId }) {
    const classes = useStyles();
    const [aiTrainingScenarios, setAiTrainingScenarios] = useState([]);
    const [newScenario, setNewScenario] = useState('');
    const [replyPromptTemplateOverride, setReplyPromptTemplateOverride] = useState('');
    const [order, setOrder] = useState(0);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    const fetchCSRFToken = () => {
        return document.querySelector("[name='csrf-token']").content;
    };

    const handleError = (error) => {
        console.error("An error occurred:", error);
        setErrorDialogMessage(error.toString());
        setErrorDialogOpen(true);
    };

    useEffect(() => {
        loadAiTrainingScenarios();
    }, [aiTrainingId]);

    const loadAiTrainingScenarios = async () => {
        try {
            const response = await fetch(`/ai_training_scenarios.json?ai_training_id=${aiTrainingId}`);
            if (!response.ok) {
                const errorPayload = await response.json(); // Extract error details from response
                throw new Error(`Failed to load AI training scenarios: ${JSON.stringify(errorPayload)}`);
            }
            let data = await response.json();
            data = data.sort((a, b) => a.order - b.order);
            setAiTrainingScenarios(data);
        } catch (error) {
            handleError(error);
        }
    };

    const addAiTrainingScenario = async () => {
        if (!newScenario.trim()) return;
        try {
            const response = await fetch(`/ai_training_scenarios.json`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": fetchCSRFToken(),
                },
                body: JSON.stringify({
                    ai_training_scenario: {
                        scenario_text: newScenario,
                        reply_prompt_template_override: replyPromptTemplateOverride,
                        ai_training_id: aiTrainingId,
                        order: order,
                    },
                }),
            });
            if (!response.ok) {
                const errorPayload = await response.json(); // Extract error details from response
                throw new Error(`Failed to add AI training scenario: ${JSON.stringify(errorPayload)}`);
            }
            setReplyPromptTemplateOverride('');
            setOrder(0);  // Reset order to empty string
            setNewScenario('');
            loadAiTrainingScenarios();
        } catch (error) {
            handleError(error);
        }
    };

    const updateOrder = async (id, newOrder) => {
        try {
            const response = await fetch(`/ai_training_scenarios/${id}.json`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': fetchCSRFToken(),
                },
                body: JSON.stringify({
                    ai_training_scenario: {
                        order: parseInt(newOrder, 10),
                    },
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to update order');
            }
            loadAiTrainingScenarios();  // Reload scenarios to reflect the updated order
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const deleteAiTrainingScenario = async (id) => {
        try {
            const response = await fetch(`/ai_training_scenarios/${id}.json`, {
                method: "DELETE",
                headers: {
                    "X-CSRF-Token": fetchCSRFToken(),
                },
            });
            if (!response.ok) {
                const errorPayload = await response.json(); // Extract error details from response
                throw new Error(`Failed to delete AI training scenario: ${JSON.stringify(errorPayload)}`);
            }
            loadAiTrainingScenarios();
        } catch (error) {
            handleError(error);
        }
    };
    const handleCloseErrorDialog = () => {
        setErrorDialogOpen(false);
    };

    return (
        <div className={classes.root}>
            <h3>Manage AI Training Scenarios</h3>
            <div>
                <TextField
                    className={classes.inputField}
                    label="New AI Training Scenario"
                    variant="outlined"
                    fullWidth
                    value={newScenario}
                    onChange={(e) => setNewScenario(e.target.value)}
                    placeholder="Enter a new AI training scenario"
                />
                <TextField
                    className={classes.inputField}
                    label="Reply Prompt Template Override"
                    variant="outlined"
                    fullWidth
                    value={replyPromptTemplateOverride}
                    onChange={(e) => setReplyPromptTemplateOverride(e.target.value)}
                    placeholder="Enter an override for the reply prompt template"
                />
                <TextField
                    className={classes.inputField}
                    label="Order"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    placeholder="Enter the order of the scenario"
                />
                <Button variant="contained" color="primary" onClick={addAiTrainingScenario}>
                    Add Scenario
                </Button>
            </div>
            <ul className={classes.scenarioList}>
                {aiTrainingScenarios.map((scenario) => (
                    <Card key={scenario.id} className={classes.scenarioCard}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h6" component="h2">Text: {scenario.scenario_text}</Typography>
                            <Typography color="textSecondary">Override: {scenario.reply_prompt_template_override}</Typography>
                            <TextField
                                className={classes.orderInput}
                                type="number"
                                value={scenario.order}
                                onChange={(e) => {
                                    const updatedScenarios = aiTrainingScenarios.map((s) =>
                                        s.id === scenario.id ? { ...s, order: e.target.value } : s
                                    );
                                    setAiTrainingScenarios(updatedScenarios);
                                }}
                                onBlur={() => updateOrder(scenario.id, scenario.order)}
                            />                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <Button size="small" color="secondary" onClick={() => deleteAiTrainingScenario(scenario.id)}>
                                Delete
                            </Button>
                        </CardActions>
                    </Card>
                ))}
            </ul>
            <Dialog
                open={errorDialogOpen}
                onClose={handleCloseErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorDialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorDialog} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AiTrainingScenarioManager;
