import React from "react";

export const MembershipOption = (props) => {
    const {title, price, bulletPoints, id, onOptionSelect, activeMembershipKey} = props
    // TODO: "most popular" option
    // TODO: action on clicking select
    return (
        <div className={'membership-option'}>
            <div className={'membership-option-header'}>
                {title}
            </div>
            <div className={'membership-option-body'}>
                <div className={'membership-option-price'}>
                    <div className={'membership-option-price-dollars'}>
                        {price > 0 &&
                        <>
                            <div className={'dollar-sign'}>$</div>
                            <div className={'price'}>{price}</div>
                        </>
                        }
                        {price === 0 &&
                        <>
                            <div className={'dollar-sign'}></div>
                            <div className={'price'}>Free</div>
                        </>
                        }
                    </div>
                     <div className={'membership-option-price-unit-time'}>
                         {price > 0 && "Per Month"}
                    </div>
                </div>
                <div className={'membership-option-details'}>
                    {bulletPoints.map((bp) => <div key={bp} className={'membership-option-bullet-point'}>&#9702;    {bp}</div>)}
                </div>
                <div className={'membership-option-select-button'}>
                    { activeMembershipKey !== id && <button className={'basic-link-button'} onClick={() => onOptionSelect(id)}>Select
                    </button> }
                    {activeMembershipKey === id && <div className={'membership-option-your-membership'}>Your Current Membership.</div>}
                </div>
            </div>
        </div>
    )
}