import React from 'react';
import MicrocourseCard from './microcourse_card';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        flexGrow: 1,
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center', // Center the card within the grid item
    },
}));

const MicrocourseList = ({ microcourses }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.gridContainer}>
            {microcourses.map((microcourse, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItem} key={index}>
                    <MicrocourseCard microcourse={microcourse} />
                </Grid>
            ))}
        </Grid>
    );
};

export default MicrocourseList;
