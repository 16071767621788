import React, { useState } from "react";

export default function SessionInstructions() {
    const [isExpanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        setExpanded(!isExpanded);
    }

    const containerHeight = isExpanded ? 'auto' : '2.5rem'; // Assuming 2.5rem is roughly the height of the header

    return (
        <div style={{ height: containerHeight }} className={`session-instructions-container ${isExpanded ? 'expanded' : 'collapsed'}`} onClick={toggleExpand}>
            <div className={`session-header ${isExpanded ? 'expanded' : 'collapsed'}`}>
                {isExpanded ? "Session Instructions" : "CLICK FOR INSTRUCTIONS"}
            </div>

            <div className="session-body">
                <div className="content-header">
                    Session Steps
                </div>
                <ol>
                    <li>The "Join Session" button will be visible 10 minutes before your session starts.</li>
                    <li>After clicking the "Join Session" button, wait attentively to be matched with your session partner and be assigned a role (Traveler or Guide).</li>
                    <li>If you're the Traveler: Choose an experience, then click "I'm Ready".</li>
                    <li>If you're the Guide: Click the green "Connect" button.</li>
                    <li>If you're more than 5 minutes late, you might miss your session.</li>
                    <li>Always practice effort, curiosity, tolerance, and respect during the session. The goal is to learn from each other.</li>
                </ol>
                <div className={'pro-tip'}>Pro Tip: Run the device test before your session! It can fix random audio issues.</div>
            </div>
            <div className="session-footer">
                <div className="footer-header">Notes:</div>
                <ul>
                    <li>Members can call us at <a href='tel:+13607975214' >+1(360)-797-5214</a> (international charges may apply or use Whatsapp).</li>
                    <li>Internet Explorer is not supported, iPhones/iPads must use Safari.</li>
                    <li>Your device must have a microphone.</li>
                    <li>If you're late or facing connection issues, an admin may call you.</li>
                </ul>
            </div>
        </div>
    );
}




