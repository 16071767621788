import React from "react";
import ReactModal from "react-modal"

export default class VisualAssistModal extends React.Component {
    render() {
        let {tourId, isOpen, onClose, tourName} = this.props;

        return (
            <ReactModal appElement={$('.content-container')[0]} isOpen={isOpen}>
                <div className={'visual-assist-modal-container'}>
                    <div className="visual-assist-modal-content">
                        <div className={'main-text'}>
                            Would you like to use visual assist mode during your tour of {tourName}?
                        </div>
                        <div className={'sub-text'}>
                            Visual assist mode lets the guide control what part of the scene you are looking at. Use
                            this if you don't want to use a mouse, touchscreen, or VR headset to control what you are
                            looking at and instead let the guide do it for you.
                        </div>
                    </div>

                    <div className="visual-assist-modal-actions">
                        <a href={`/enter_vr/${tourId}?selection=true&visual_assist=false`}
                           className={'basic-link-button'}
                        >
                            No
                        </a>
                        <a href={`/enter_vr/${tourId}?selection=true&visual_assist=true`}
                           className={'basic-link-button'}>
                            Yes
                        </a>
                        <a className={'basic-link-button'} onClick={onClose}>
                            Cancel
                        </a>
                    </div>
                </div>
            </ReactModal>
        )
    }
}