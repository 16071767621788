import React from 'react';
import { Container, Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import SettingsIllustration from 'images/twilio_tests/SettingsIllustration.png';
import ErrorIllustration from 'images/twilio_tests/ErrorIllustration.png';
import {ErrorIcon} from "./TwilioIcons";

const useStyles = makeStyles({
    paper: {
        display: 'inline-block',
        borderRadius: '8px',
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    grantPermissions: {
        fontSize: '16px',
        paddingBottom: '16px',
    },
    /*
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
    */
    illustration: {
        height: '181px',
        width: '100%'
    },
    heading: {
        position: 'relative',
        marginTop: '20px',
    },
    errorIcon: {
        position: 'absolute',
        right: 'calc(100% + 18px)',
    },
});

export function getDeviceErrorPaneContent(error) {
    let errorHeadline = '';
    let errorMessage = '';

    if(!error) {
        return {
            errorHeadline: null,
            errorMessage: null
        }
    }
    switch (true) {
        // This error is emitted when the user or the user's system has denied permission to use the media devices
        case error.name === 'NotAllowedError':
            errorHeadline = 'Permissions needed';

            if (error.message === 'Permission denied by system') {
                // Chrome only
                errorMessage =
                    'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.';
            } else {
                errorMessage = `We can't access your microphone which means we don't have permissions for audio. Depending on your browser or operating system, these might live in "Settings".`;
            }

            break;

        // This error is emitted when input devices are not connected or disabled in the OS settings
        case error.name === 'NotFoundError':
            errorHeadline = 'Cannot find microphone or camera';
            errorMessage =
                'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.';
            break;

        // Other getUserMedia errors are less likely to happen in this app. Here we will display
        // the system's error message directly to the user.
        case Boolean(error):
            errorHeadline = 'Error acquiring media';
            errorMessage = `${error.name} ${error.message}`;
            break;
    }
    return {
        errorHeadline,
        errorMessage,
    };
}

export function CheckPermissions(props) {
    let {nextTest, addResult, results} = props;

    let [error, setError] = React.useState()

    let { errorHeadline, errorMessage } = getDeviceErrorPaneContent(error)

    const classes = useStyles();

    const handleClick = async () => {
        try {
            // get audio and video permissions then stop the tracks
            await navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(async (mediaStream) => {
                mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
                addResult('mic_access', {success: true, details: 'mic access granted'})
            });
            // The devicechange event is not fired after permissions are granted, so we fire it
            // ourselves to update the useDevices hook. The 100 ms delay is needed so that device labels are available
            // when the useDevices hook updates.
            // setTimeout(() => navigator.mediaDevices.dispatchEvent(new Event('devicechange')), 500);
            // dispatch({ type: 'next-pane' });
        } catch (error) {
            setError(error)
            addResult('mic_access', {success: false, details: 'no mic access', error: error.message})

            // TODO: we should log to splunk here
            // dispatch({ type: 'set-device-error', error });
        }
    };

    return (
        <Container>
            {!results['mic_access'] && !error && <Grid container alignItems="center" justifyContent={'center'}>

                <Grid item md={6}>
                    <Typography variant="h4" gutterBottom>
                        Check permissions
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Click the "Request Permissions" button to allow microphone access to Acquaint. You may see a pop-up to grant Acquaint permissions to access your
                        microphone. Sometimes, you must change the browser settings for your web page to
                    </Typography>


                    <button  type='button' className={'basic-link-button'} onClick={handleClick}>
                        Request permissions
                    </button>
                </Grid>

                <Grid item md={5}>
                    <Paper className={classes.paper}>
                        <Typography variant="body1" className={classes.grantPermissions}>
                            <strong>Grant permissions</strong>
                        </Typography>
                        <img src={SettingsIllustration} alt="Settings Illustration" className={classes.illustration} />
                    </Paper>
                </Grid>
            </Grid>
            }
            {error && <Grid container alignItems="center" justifyContent={'center'}>
                <Grid item md={6}>
                    <Typography variant="h3" gutterBottom className={classes.heading}>
                        <div className={classes.errorIcon}>
                            <ErrorIcon />
                        </div>{' '}
                        {errorHeadline}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        {errorMessage}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Update your settings to allow your browser permissions and refresh this page.</strong>
                    </Typography>

                    <button type="button" className={'basic-link-button'} onClick={() => window.location.reload()}>
                        Refresh page
                    </button>
                </Grid>

                <Grid item md={5}>
                    <Paper className={classes.paper}>
                        <Typography variant="body1" className={classes.grantPermissions}>
                            <strong>Permissions</strong>
                        </Typography>
                        <img src={ErrorIllustration} alt="Settings Illustration" className={classes.illustration} />
                    </Paper>
                </Grid>
            </Grid>
            }
            {results['mic_access']?.success &&
                <div>
                    <div>
                        It looks like we have microphone access, let's go!
                    </div>
                    <button  type='button' className={'basic-link-button'} onClick={nextTest}>
                        Proceed
                    </button>
                </div>
            }
        </Container>
    );
}