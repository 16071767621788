import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";

const RollupTable = ({ rollupData }) => {
    const [expandedRows, setExpandedRows] = useState({});

    const toggleRowExpansion = (timeslot) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [timeslot]: !prevState[timeslot],
        }));
    };

    // Convert rollupData to an array, sort by timeslot
    const sortedRollupData = Object.entries(rollupData).sort(([a], [b]) => a.localeCompare(b));
    const convertToLocalTime = (utcDate) => {
        const localDate = new Date(utcDate).toLocaleString("en-US", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
        return localDate;
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Timeslot</TableCell>
                        <TableCell>Total Probability</TableCell>
                        <TableCell>Scheduled Probability</TableCell>
                        <TableCell>Unscheduled Probability</TableCell>
                        <TableCell>Scheduled Country Counts</TableCell>
                        <TableCell>Unscheduled Country Counts</TableCell>
                        <TableCell>Scheduled Country Probabilities</TableCell>
                        <TableCell>Unscheduled Country Probabilities</TableCell>
                        <TableCell>Scheduled Role Counts</TableCell>
                        <TableCell>Unscheduled Role Counts</TableCell>
                        <TableCell>Scheduled Role Probabilities</TableCell>
                        <TableCell>Unscheduled Role Probabilities</TableCell>
                        <TableCell>Coverage Count</TableCell>
                        <TableCell>Scheduled Coverage Probability</TableCell>
                        <TableCell>Unscheduled Coverage Probability</TableCell>
                        <TableCell>Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRollupData.map(([timeslot, data], index) => (
                        <React.Fragment key={index}>
                            <TableRow onClick={() => toggleRowExpansion(timeslot)}>
                                <TableCell>{convertToLocalTime(timeslot)}: {data.cycle || 'current'}</TableCell>                                <TableCell>{data.total_probability}</TableCell>
                                <TableCell>{data.scheduled_probability}</TableCell>
                                <TableCell>{data.unscheduled_probability}</TableCell>

                                {/* Scheduled Country Counts */}
                                <TableCell>
                                    {Object.entries(data.scheduled_country_counts).map(
                                        ([country, count]) => (
                                            <div key={country}>{`${country}: ${count}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Unscheduled Country Counts */}
                                <TableCell>
                                    {Object.entries(data.unscheduled_country_counts).map(
                                        ([country, count]) => (
                                            <div key={country}>{`${country}: ${count}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Scheduled Country Probabilities */}
                                <TableCell>
                                    {Object.entries(data.scheduled_country_probabilities).map(
                                        ([country, probability]) => (
                                            <div key={country}>{`${country}: ${probability.toFixed(
                                                2
                                            )}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Unscheduled Country Probabilities */}
                                <TableCell>
                                    {Object.entries(data.unscheduled_country_probabilities).map(
                                        ([country, probability]) => (
                                            <div key={country}>{`${country}: ${probability.toFixed(
                                                2
                                            )}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Scheduled Role Counts */}
                                <TableCell>
                                    {Object.entries(data.scheduled_role_counts).map(
                                        ([role, count]) => (
                                            <div key={role}>{`${role}: ${count}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Unscheduled Role Counts */}
                                <TableCell>
                                    {Object.entries(data.unscheduled_role_counts).map(
                                        ([role, count]) => (
                                            <div key={role}>{`${role}: ${count}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Scheduled Role Probabilities */}
                                <TableCell>
                                    {Object.entries(data.scheduled_role_probabilities).map(
                                        ([role, probability]) => (
                                            <div key={role}>{`${role}: ${probability.toFixed(
                                                2
                                            )}`}</div>
                                        )
                                    )}
                                </TableCell>

                                {/* Unscheduled Role Probabilities */}
                                <TableCell>
                                    {Object.entries(data.unscheduled_role_probabilities).map(
                                        ([role, probability]) => (
                                            <div key={role}>{`${role}: ${probability.toFixed(
                                                2
                                            )}`}</div>
                                        )
                                    )}
                                </TableCell>

                                <TableCell>{data.coverage_count}</TableCell>
                                <TableCell>
                                    {data.scheduled_coverage_probability.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {data.unscheduled_coverage_probability.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {data.scores.overall_score.toFixed(2)}
                                    {expandedRows[timeslot] && (
                                        <div style={{ marginTop: "10px" }}>
                                            <div>
                                                <strong>Total Probability Score:</strong>{" "}
                                                {data.scores.total_probability_score}
                                            </div>
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RollupTable;
