import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CourseImage from './course_image';
import withTheme from '../with_theme';
import DescriptionRenderer from '../DescriptionRenderer';

const useStyles = makeStyles((theme) => ({
    microcourseContainer: {
        margin: theme.spacing(2),
        textAlign: 'left', // Ensures the container is aligned left
    },
    title: {
        marginBottom: theme.spacing(2),
        textAlign: 'left', // Ensures the title is aligned left
    },
    description: {
        marginBottom: theme.spacing(2),
        textAlign: 'left', // Ensures the description is aligned left
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`, // Increased space around dividers for better separation
    },
    linkSection: {
        margin: `${theme.spacing(2)}px 0`, // Increased space around the course link section
        textAlign: 'left', // Ensures the link section is aligned left
    },
    quizContainer: {
        marginTop: theme.spacing(2), // Adds space above quiz section
        textAlign: 'left', // Ensures the quiz container is aligned left
    },
    quizInfo: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1), // Adds space around quiz info for less cramped look
        textAlign: 'left', // Ensures the quiz info is aligned left
    },
    link: {
        marginRight: theme.spacing(1),
        textAlign: 'left', // Ensures the link is aligned left
    },
    editLink: {
        display: 'block',
        marginTop: theme.spacing(4), // Consistent spacing before edit link
        textAlign: 'left', // Ensures the edit link is aligned left
    },
    quizButton: {
        marginTop: theme.spacing(2), // Adds more space above the button specifically
        textAlign: 'left', // Ensures the button is aligned left
    },
}));

function Overview({ microcourseData }) {
    const classes = useStyles();

    return (
        <div className={classes.microcourseContainer}>
            <h2 className={classes.title}>{microcourseData.name}</h2>
            <CourseImage imageUrl={microcourseData.image_url} completed={microcourseData.completed} />
            <div className={classes.description}>
                <DescriptionRenderer htmlString={microcourseData.description} alwaysExpanded={true} />
            </div>
            {microcourseData.course_url && (
                <>
                    <hr className={classes.divider} />
                    <div className={classes.linkSection}>
                        <strong>Explore This Course:</strong> <a href={microcourseData.course_url}>Start Learning</a>
                    </div>
                </>
            )}
            {microcourseData.ai_training_challenge_data && (
                <div>
                    <hr className={classes.divider} />
                    <div className={classes.quizContainer}>
                        <strong>AI Training Challenge:</strong> {microcourseData.ai_training_challenge_data.name}
                        <div className={classes.quizInfo}>
                            {microcourseData.ai_training_challenge_data.completed ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.link}
                                        href={microcourseData.ai_training_challenge_data.link}
                                    >
                                        Review Challenge
                                    </Button>
                                    <span>Congrats, you're all done with the AI Training Challenge!</span>
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.link}
                                    href={microcourseData.ai_training_challenge_data.link}
                                >
                                    Start AI Training Challenge
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {microcourseData.quiz_data && (
                <div>
                    <hr className={classes.divider} />
                    <div className={classes.quizContainer}>
                        <strong>{microcourseData.quiz_data.passed ? "You've passed the quiz:" : "Test Your Knowledge, take the quiz:"}</strong> "{microcourseData.quiz_data.display_name}"
                        <div className={classes.quizInfo}>
                            <Button
                                variant="contained"
                                color={microcourseData.quiz_data.passed || !microcourseData.quiz_data.score ? "primary" : "secondary"}
                                className={`${classes.link} ${classes.quizButton}`} // Apply both link and quizButton styles
                                href={microcourseData.quiz_data.quiz_url}
                            >
                                {microcourseData.quiz_data.take_quiz_button_text}
                            </Button>
                            {microcourseData.quiz_data.score !== undefined && microcourseData.quiz_data.score !== null && (
                                <span>
                  Your Score: {microcourseData.quiz_data.score} / {microcourseData.quiz_data.max_score} ({microcourseData.quiz_data.passed ? "Passed" : "Try Again"})
                </span>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {microcourseData.can_edit && (
                <a href={microcourseData.edit_url} className={classes.editLink}>Edit This Course</a>
            )}
            <Button
                variant="contained"
                color="primary"
                className={classes.link}
                href={'/home'}
            >
                Go Home
            </Button>
        </div>
    );
}

export default withTheme(Overview);
