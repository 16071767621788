import React from "react"

class TrainingRoundList extends React.Component {
    render() {
        return (
            <div>
                <h6><strong>PAST ROUNDS</strong></h6>
                {this.props.completedRounds.map((round, index, array) => (
                    <div key={index}>
                        <p>Round {array.length - index}: {round.score}</p>
                    </div>
                ))}
            </div>
        );
    }
}

export default TrainingRoundList;