import {broadcastData, HEARTBEAT} from './guided_session'

export default class Heartbeat {
    HEARTBEAT_INTERVAL = 30000;
    terminated = false;
    started = false;

    constructor(userId, experienceId) {
        this.userId = userId;
        this.experienceId = experienceId;
        // this.heartbeat = this.heartbeat.bind(this);
    }

    start = () => {
        if(!this.started) {
            this.started = true
            setTimeout(this.heartbeat, this.HEARTBEAT_INTERVAL)
        }
    };

    stop = () => {
        this.terminated = true
    }
    heartbeat = () => {
        if(this.terminated) {
            return;
        }
        broadcastData({
            type: HEARTBEAT,
            userId: this.userId,
            experienceId: this.experienceId
        });
        setTimeout(this.heartbeat, this.HEARTBEAT_INTERVAL)
    };
}