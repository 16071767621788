import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Select, MenuItem, Checkbox, FormControlLabel, FormControl, InputLabel } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    filterContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        marginBottom: '1rem',
    },
    formControl: {
        minWidth: 120,
    },
});

export default function ActionTable() {
    const classes = useStyles();

    const query = new URLSearchParams(window.location.search);
    const initialPage = parseInt(query.get('page') || '0', 10);
    const initialRowsPerPage = parseInt(query.get('rowsPerPage') || '10', 10);
    const initialFilterState = query.get('state') || 'ready';
    const initialFilterType = query.get('type') || '';
    const initialIncludeExpired = query.get('includeExpired') === 'true';
    const initialSortField = query.get('sortField') || 'created_at';
    const initialSortDirection = query.get('sortDirection') || 'asc';

    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [filterState, setFilterState] = useState(initialFilterState);
    const [filterType, setFilterType] = useState(initialFilterType);
    const [includeExpired, setIncludeExpired] = useState(initialIncludeExpired);
    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [uniqueTypes, setUniqueTypes] = useState([]);
    const [sortField, setSortField] = useState(initialSortField);
    const [sortDirection, setSortDirection] = useState(initialSortDirection);

    useEffect(() => {
        fetchRows();
    }, [filterState, filterType, includeExpired, page, rowsPerPage, sortField, sortDirection]);

    const fetchRows = async () => {
        const response = await fetch(`/acquaint_actions.json?state=${filterState}&type=${filterType}&include_expired=${includeExpired}&page=${page}&rowsPerPage=${rowsPerPage}&sortField=${sortField}&sortDirection=${sortDirection}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        setRows(data.actions);
        setTotalCount(data.total_count);
        setUniqueTypes(data.unique_types);
        updateUrl();
    };

    const updateUrl = () => {
        const params = new URLSearchParams();
        if (filterState) params.set('state', filterState);
        if (filterType) params.set('type', filterType);
        params.set('includeExpired', includeExpired);
        params.set('page', page);
        params.set('rowsPerPage', rowsPerPage);
        params.set('sortField', sortField);
        params.set('sortDirection', sortDirection);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState(null, '', newUrl);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const states = {
        pending: 'pending',
        ready: 'ready',
        dismissed: 'dismissed',
        approved: 'approved',
        executed: 'executed',
        canceled: 'canceled',
        analysis_error: 'analysis_error',
        execution_error: 'execution_error'
    };

    return (
        <>
            <div className={classes.filterContainer}>
                <FormControl className={classes.formControl}>
                    <InputLabel>State</InputLabel>
                    <Select value={filterState} onChange={e => setFilterState(e.target.value)}>
                        <MenuItem value=""><em>None</em></MenuItem>
                        {Object.keys(states).map(state => (
                            <MenuItem key={state} value={states[state]}>{state}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <InputLabel>Type</InputLabel>
                    <Select value={filterType} onChange={e => setFilterType(e.target.value)}>
                        <MenuItem value=""><em>None</em></MenuItem>
                        {uniqueTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeExpired}
                            onChange={e => setIncludeExpired(e.target.checked)}
                            name="includeExpired"
                            color="primary"
                        />
                    }
                    label="Include Expired"
                />
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="actions table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>
                                Created
                                <button onClick={() => handleSort('created_at')}>
                                    {sortField === 'created_at' && sortDirection === 'asc' ? '↑' : '↓'}
                                </button>
                            </TableCell>
                            <TableCell>
                                Expiry Date
                                <button onClick={() => handleSort('expiry_date')}>
                                    {sortField === 'expiry_date' && sortDirection === 'asc' ? '↑' : '↓'}
                                </button>
                            </TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Show</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.action_label || 'N/A'}</TableCell>
                                <TableCell>{row.created_at}</TableCell>
                                <TableCell>{row.expiry_date}</TableCell>
                                <TableCell>{row.state}</TableCell>
                                <TableCell><a href={`/acquaint_actions/${row.id}`}>Show</a></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event, newPage) => setPage(newPage)}
                onChangeRowsPerPage={event => {
                    setRowsPerPage(+event.target.value);
                    setPage(0);
                }}
            />
        </>
    );
}
