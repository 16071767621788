import React from 'react'

export const ZoomOutIcon = (props) => {
    let {divClass, fill, size = "1.5rem"} = props;

    return (<div className={divClass} style={{height: size, width: size}}>
        <svg style={{height: size, width: size}} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
            <path
                fill={fill}
                d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h9v1h-9v-1z"
            />
        </svg>
    </div>)
}
