import React from "react"
import TourImagesSelect from './tour_images/TourImagesSelect'
import Select from "react-select";


const BulletPoint = (props) => {
    let { bulletPoint, onChange, removeBulletPoint } = props
    return <div className={'bullet-point-list-element'}>
        <input onChange={onChange} value={bulletPoint}/>
        <button type={'button'} className={'bullet-point-list-remove-button'} onClick={removeBulletPoint}>X</button>
    </div>
}

const BulletPointEditor = (props) => {
    let {bulletPointList, onChange} = props

    const addBulletPoint =  () => {
        bulletPointList.push('Fact about scene.')
        onChange({
            target: {
                value: bulletPointList
            }
        })
    };

    const onBulletPointChange = (event, index) => {
        bulletPointList[index] = event.target.value
        onChange({
            target: {
                value: bulletPointList
            }
        })
    }

    const removeBulletPoint =  (index) => {
        bulletPointList.splice(index, 1)
        onChange({
            target: {
                value: bulletPointList
            }
        })
    };


    const bulletPoints = bulletPointList.map((bulletPoint, index) => {
        return <li className={'bullet-point-list-item'} key={`bullet-points=${index}`}>
            <BulletPoint
                bulletPoint={bulletPoint}
                removeBulletPoint={() => removeBulletPoint(index)}
                onChange={(value) => onBulletPointChange(value, index)}
            />
        </li>
        }
    );

    return (
        <div>
            <div className={'tour-section-header'}>Edit Bullet Points</div>
            <ul className={'bullet-point-list'}>{bulletPoints}</ul>
            <button type={'button'} onClick={addBulletPoint}>Add Bullet Point</button>
        </div>
    )
}


export default class TourSceneEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...props.tourScene};
    }

    handleInputChange = (event, fieldName) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [fieldName]: value
        });
    };

    handleSelectChange = (selection, fieldName) => {
        this.setState({
            [fieldName]: selection.value
        });
    };

    render() {
        const {permissions, tourImages} = this.props

        let options = tourImages.map(v => {
            return {
                label: v.name,
                value: v.id
            }
        });

        return (
            <div>
                <div>
                    <div className='field'>
                        Name: <input
                        type={'text'}
                        value={this.state.name || ""}
                        name={'tour_scene[name]'}
                        onChange={(event) => this.handleInputChange(event, 'name')}
                    />
                    </div>
                    {(permissions === 'manager') &&
                    <div className='field'>
                        Order in tour: <input
                        type={'number'}
                        precision={0}
                        value={this.state.order || 0}
                        name={'tour_scene[order]'}
                        onChange={(event) => this.handleInputChange(event, 'order')}
                    />
                    </div>
                    }
                    <div className='field tour-textarea'>
                        <div className={'tour-textarea-label'}>Script:</div>
                        <textarea
                            rows={4}
                            cols={50}
                            value={this.state.script || ""}
                            name={'tour_scene[script]'}
                            onChange={(event) => this.handleInputChange(event, 'script')}
                        />
                    </div>

                    <div className={'field'}>
                        <BulletPointEditor
                            bulletPointList={this.state.bullet_points || []}
                            onChange={(event) => this.handleInputChange(event, 'bullet_points')}
                        />
                        {this.state.bullet_points.map((bulletPoint => {
                            return <input
                                type={'hidden'}
                                value={bulletPoint}
                                name={'tour_scene[bullet_points][]'}
                            />

                        }))}

                    </div>
                    {(permissions === 'manager') && <>
                        Select Image: <Select
                        options={options}
                        className='field'
                        value={options.filter(option => option.value === this.state.tour_image_id)}
                        onChange={(event) => this.handleSelectChange(event, 'tour_image_id')}
                    />

                        <input
                            type={'hidden'}
                            value={this.state.tour_image_id || ''}
                            name={'tour_scene[tour_image_id]'}
                        />
                    </>
                    }
                </div>
            </div>
        )
    }
}
