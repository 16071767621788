export class API {
    static async markParticipantAsRemoved(experimentId, participantId) {
        const response = await fetch(`/experiments/${experimentId}/experiment_participants/${participantId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            // Include both a general and a specific error message if available
            throw new Error(`Failed to remove participant: ${errorData.error} ${errorData.details ? '- ' + errorData.details : ''}`);
        }

        return response.json();
    }

    static async addExperimentParticipant(experimentId, userId) {
        const body = JSON.stringify({
            experiment_participant: {
                user_id: userId,
            },
        });

        const response = await fetch(`/experiments/${experimentId}/experiment_participants`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
            },
            body: body,
        });

        if (!response.ok) {
            const errorData = await response.json();
            // Include both a general and a specific error message if available
            throw new Error(`Failed to add experiment participant: ${errorData.error} ${errorData.details ? '- ' + errorData.details : ''}`);
        }
        return response.json();
    }

    static async inviteExperimentParticipant(experimentParticipantId) {
        const response = await fetch(`/experiment_participants/${experimentParticipantId}/invite`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'), // Ensure CSRF token is included for Rails
            }
        });

        if (!response.ok) {
            // Convert non-2xx HTTP responses into errors
            const errorData = await response.json();
            throw new Error(`Failed to send invitation: ${errorData.error}`);
        }

        return response.json();
    }
}

export default API;
