import React, {useState} from "react"
import Select from "react-select";


const UserSelect = (props) => {
    let { options, value, onChange, selection } = props;
    console.log(selection)
    return (
        <div className={'mb-2'}>
            <Select
                value={selection}
                options={options}
                onChange={onChange}
                selection={selection}
            />
        </div>
    )
}

const LastMinuteCoverageRequester = (props) => {
    let { options } = props;
    console.log(props)

    const [selection, setSelection] = useState(options[0]);
    const openURL = () => {
        window.open(`/last_minute_coverage_request/${selection.value}`);
    };

    const handleChange = (selected) => {
        console.log(selected)
        setSelection(selected);
    }

    return (
        <div>
            <UserSelect
                options={options}
                value={selection.value }
                onChange={handleChange}
                selection={selection}
            />
            <button onClick={openURL}>Request Coverage</button>
        </div>
    )
}

export default LastMinuteCoverageRequester