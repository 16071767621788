import React from 'react';

const PromptVariableDropdown = () => {
  const variables = [
    { label: '<ai_initial_response>', value: '<ai_initial_response>' },
    { label: '<user_input>', value: '<user_input>' },
  ];

  const handleVariableChange = (event) => {
    const selectedValue = event.target.value;

    // When user selects a variable, append the variable to the current reply prompt template
    const replyPromptTemplateField = document.getElementById('reply_prompt_template');
    if (replyPromptTemplateField) {
      replyPromptTemplateField.value = replyPromptTemplateField.value + selectedValue;
    }
  };

  return (
    <div className="field">
      <select id="variable" name="variable" onChange={handleVariableChange} value="">
        <option value="">Select a Variable</option>
        {variables.map((variable) => (
          <option key={variable.value} value={variable.value}>
            {variable.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PromptVariableDropdown;
