import { useState, useRef, useCallback } from 'react';

const useMessageHandler = (setChatsData, selectedChatRef, setMessages, isAtBottom, scrollToBottom, setCanSendFreeform, filterChattableType) => {
    const STATUS_ORDER = ['sent', 'delivered', 'read', 'undelivered'];

    function isValidStatusTransition(currentStatus, newStatus) {
        const currentIndex = STATUS_ORDER.indexOf(currentStatus);
        const newIndex = STATUS_ORDER.indexOf(newStatus);

        return currentIndex !== -1 && newIndex !== -1 && newIndex >= currentIndex;
    }

    const adaptMessage = (message) => ({
        ...message,
        name: message.name,
        user_profile_id: message.public_name_differentiator,
    });

    const handleReceiveMessage = useCallback((updatedMessage) => {
        if (filterChattableType && updatedMessage.chattable_type !== filterChattableType) {
            return;
        }

        if (updatedMessage.action === 'delete') {
            setMessages((prev) =>
                prev.map((msg) =>
                    msg.id === updatedMessage.message_id ? { ...msg, deleted: true } : msg
                )
            );
        } else if (updatedMessage.action === 'edit') {
            setMessages((prev) =>
                prev.map((msg) => {
                    if (msg.message_sid === updatedMessage.message_sid) {
                        const currentStatus = msg.data?.message_status;
                        const newStatus = updatedMessage.data?.message_status;

                        if (newStatus && currentStatus) {
                            if (isValidStatusTransition(currentStatus, newStatus)) {
                                return { ...msg, data: { ...updatedMessage.data } };
                            } else {
                                return {
                                    ...msg,
                                    data: {
                                        ...updatedMessage.data,
                                        message_status: currentStatus,
                                    },
                                };
                            }
                        }
                        return { ...msg, data: updatedMessage.data };
                    }
                    return msg;
                })
            );
        } else {
            const currentChat = selectedChatRef.current;
            const adaptedData = adaptMessage(updatedMessage);

            setChatsData((prevChats) => {
                const existingChat = prevChats.find((chat) => chat.chattable_id === updatedMessage.chattable_id);

                if (existingChat) {
                    if(updatedMessage.data.direction === 'inbound' && existingChat.chattable_type === 'WhatsAppContact') {
                        // We can immediately start sending freeform messages to volunteers if they
                        // send us an inbound message on whatsapp.
                        // Note that for some uses of useMessageHandler that don't use whatsApp, this method may be undefined.
                        setCanSendFreeform(true);
                    }

                    return prevChats.map((chat) =>
                        chat.chattable_id === updatedMessage.chattable_id
                            ? {
                                ...chat,
                                content: updatedMessage.content,
                                name: updatedMessage.name,
                                unreadCount: chat.id === currentChat?.id ? 0 : (chat.unreadCount || 0) + 1,
                                created_at: updatedMessage.created_at,
                            }
                            : chat
                    );
                } else {
                    return [
                        {
                            id: updatedMessage.id,
                            chattable_id: updatedMessage.chattable_id,
                            name: updatedMessage.name,
                            content: updatedMessage.content,
                            unreadCount: 1,
                            created_at: updatedMessage.created_at,
                            chattable_type: updatedMessage.chattable_type || 'General',
                            title: updatedMessage.title
                        },
                        ...prevChats,
                    ];
                }
            });

            if (currentChat && currentChat.chattable_id === updatedMessage.chattable_id) {
                setMessages((prev) => [...prev, adaptedData]);
                if (isAtBottom()) {
                    setTimeout(scrollToBottom, 100);
                }
            }
        }
    }, [setChatsData, selectedChatRef, setMessages, isAtBottom, scrollToBottom]);

    return handleReceiveMessage;
};

export default useMessageHandler;
