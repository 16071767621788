import {useEffect} from "react";
import consumer from "./consumer";

export const AdminSubscriber = ({ onReceiveMessage }) => {
    const initAdminCable = () => {
        return consumer.subscriptions.create(
            { channel: "AdminChannel" },
            {
                connected() {
                    console.log("Connected to Admin Channel");
                },
                disconnected() {
                    console.log("Disconnected from Admin Channel");
                },
                received(data) {
                    console.log("Admin message received:", data);
                    onReceiveMessage(data);
                },
            }
        );
    };

    useEffect(() => {
        const subscription = initAdminCable();
        return () => subscription.unsubscribe();
    }, []);

    return null;
};