import React from 'react'

export const ReturnIcon = (props) => {
    let {fill, size = "1rem"} = props;

    return (<div style={{height: size, width: size}}>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="36.438px" height="36.438px" viewBox="0 0 36.438 36.438"
             style="enable-background:new 0 0 36.438 36.438;"
        >
            <g>
                <path d="M26.58,17.594c0.417,0.417,0.962,0.625,1.508,0.625s1.091-0.208,1.508-0.625l5.332-5.331
		c0.001-0.002,0.002-0.002,0.002-0.002l1.508-1.508l-1.507-1.507c0,0-0.002-0.002-0.003-0.003l-4.977-4.977
		c-0.832-0.833-2.182-0.833-3.016,0c-0.834,0.834-0.834,2.184,0,3.017l1.336,1.336H12.088C5.422,8.619,0,14.043,0,20.708
		c0,6.666,5.423,12.089,12.088,12.089h21.333c1.179,0,2.133-0.955,2.133-2.134c0-1.178-0.954-2.133-2.133-2.133H12.088
		c-4.313,0-7.822-3.51-7.822-7.822c0-4.312,3.509-7.821,7.822-7.821h16.184l-1.691,1.691C25.746,15.411,25.746,16.761,26.58,17.594z
		"/>
            </g>

        </svg>
    </div>)

}
