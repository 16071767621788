import React, { useState, useEffect } from "react";


const KbContents = (props) => {
    const KB_BASE_URL = "/kb_contents";
    const KB_BASE_VIEW_URL = "/kb_contents";
    const KB_BASE_UPDATE_URL = "/kb_contents/update";

    const token = document.querySelector('meta[name="csrf-token"]').content;
    const [kbContents, setKbContents] = useState([]);

    useEffect(() => {
        setKbContents(props.data);
    }, []);

    const deleteContent = (id) => {
        const url = KB_BASE_URL + '/' + id;
 
        const confirmDelete = window.confirm('Are you sure you want to delete this content?');
        if (confirmDelete) {
            fetch(url, {
                method: "DELETE",
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {

                        return response.json();
                    }
                    throw new Error("Network response was not ok.");
                })
                .then(() => { window.location.replace(KB_BASE_URL) })
                .catch((error) => console.log(error.message));
        }

    };

    const viewContent = (id) => {
        const url = KB_BASE_VIEW_URL + '/' + id;
        window.location.replace(url);

    }
    const editContent = (id) => {
        const url = KB_BASE_UPDATE_URL + '/' + id;
        window.location.replace(url);

    }
    return (
        <div className="container mt-5">
            <table className="table">
                <thead>
                    <tr>
                        <th>Topic</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>View</th>
                        <th>Update</th>

                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {kbContents.map((kbContent) => (
                        <tr key={kbContent.id}>

                            <td>{kbContent.topic}</td>
                            <td>{kbContent.category}</td>
                            <td>{kbContent.status}</td>
                            <td>
                                <button onClick={() => viewContent(`${kbContent.id}`)} className={'appreciation-lightbox-yes-button'}>View</button>
                            </td>
                            <td>
                                <button onClick={() => editContent(`${kbContent.id}`)} className={'appreciation-lightbox-yes-button'}>Update</button>
                            </td>
                            <td>
                                <button onClick={() => deleteContent(`${kbContent.id}`)} className={'appreciation-lightbox-yes-button'}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
};

export default KbContents;